import React from "react";
import { T, DataTip } from '../../components/Translations';
import InputNumber from '../../Shared/InputNumber'
import { Select /*, Modal*/ } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setApplicationData } from "../../store/actions";

const { Option } = Select;

/**
 * template tu put in forms
 * @param {any} props contains: label, labelClass, formFieldClass, children
 * @exports FormField
 */
export function FormField(props) {
    const { label, labelClass, formFieldClass, children } = props;

    return (<div className={'form_fields ' + (formFieldClass || '')}>
        {(label || labelClass) && <div className={'data_label' + (labelClass || '')}><T>{label}</T></div>}
        {children}
    </div>);
}

export const ApplicationFormField = React.forwardRef((props, ref) => { // NOSONAR
    const { applicationData } = useSelector(state => state);
    const { startLabel, startLabelProps, endLabel, endLabelProps, bold, formFieldClass, fieldStyle, formFieldStyle, contStyle, value, min, max, name, type, setData, valuelist, disabled, calculate, hasInfoButton, infoValue, tabIndex, onError, allData, active, onKeyDown, allowDecimal, subapplication, data, readOnly, dataLength, id, required, allowMessages, translate } = props;

    let element;
    const dispatch = useDispatch();
    switch (type) {
        case 'number':
            element = <InputNumber ref={ref} tabIndex={tabIndex} value={value} min={min} max={max} name={name} label={startLabel || endLabel} setData={setData} disabled={disabled} calculate={calculate} onError={onError} allData={allData} active={active} id={id} onKeyDown={onKeyDown} allowDecimal={allowDecimal} subapplication={subapplication} data={data} required={required} allowMessages={allowMessages} />
            break;
        case 'select':
            element = <Select ref={ref} onKeyDown={onKeyDown} onChange={(e) => { //NOSONAR
                setData(item => ({ ...item, [name]: e })); //NOSONAR
                if (applicationData && applicationData[id]) {
                    dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], edited: true } }))
                }
                if (calculate) {
                    calculate()
                }
            }} size="default" value={value} disabled={disabled} style={fieldStyle}>
                {valuelist.map(i => (<Option key={typeof i === "object" ? i.value : i} value={typeof i === "object" ? i.value : i}>{typeof i === "object" ? <T>{`text.${i.text}`}</T> : (translate ? <T>{`${i}`}</T> : i)}</Option>))}
            </Select>
            break;
        case 'text':
            element = <b>{value}</b>
            break;
        case 'textInput':
            element = <input type="text" value={value} readOnly={readOnly} />
            break;
        default:
            break;
    }
    return (<div className={'form_fields ' + (formFieldClass || '')} style={formFieldStyle}>
        <div className={`input_cont`} style={{ ...contStyle}} disabled={disabled && name === 'Pboucle'} >
            {(startLabel || startLabelProps) && <text wrap="" data_length={dataLength || "xxxxxxx-large"} {...startLabelProps}><T>{startLabel}</T></text>}
            {element}
            {(endLabel || endLabelProps) && <text {...endLabelProps}>{bold ? <b><T>{endLabel}</T></b> : <T>{endLabel}</T>}</text>}
            {hasInfoButton && <DataTip title={infoValue}><div className="button micro info"><icon>info</icon></div></DataTip>}
        </div>
    </div>);
})


/**
 *a template to put in forms
 * @exports FieldGroup
 */
export function FieldGroup({ children, className }) {
    return (
        <div className={'field_group ' + (className || "")}>
            {children}
        </div>);
}