import React, { useEffect, useState, createRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { T } from '../../../../components/Translations';
import { ProductionMode, SubAppCode, SystemCode } from '../../../../project/Defines';
import { Select, Input, Table } from 'antd';
import { ApplicationFormField, FieldGroup, FormField } from '../../../../Shared/Form/formFields';
import { Project } from '../../../../project/Project';
import { setApplicationData } from '../../../../store/actions';

const { Option } = Select;

function Zone3(props) { //NOSONAR
    let fields = null;
    let showTable = false;
    const { mode, system, engine, setData, calculate, zone3ApiData, data, zone4Data, setZone4Data, solutionsRequest, setSystem, onError, history, application, subapplication, sectionHeaderStyle, setLastInputRef, zone, id, applicationData, activeRowIndex, setActiveRowIndex, setEtudeAllData } = props;
    const { calculatedData, allData } = engine;
    const dispatch = useDispatch();
    const [refs, setRefs] = useState({});

    useEffect(() => {
        setRefs((prev) => Array(1)
            .fill()
            .map((_, i) => refs[i] || createRef()),
        );
    }, [system]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (refs[0] && refs[0].current && applicationData && applicationData[id] && applicationData[id].edited) {
            refs[0].current.instance.focus()
        }

    }, [refs]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        zone4Data && zone4Data.opened && applicationData[id].edited && solutionsRequest();
    }, [zone4Data]);// eslint-disable-line react-hooks/exhaustive-deps

    let dataSource = [];
    let dataSourcePn = [];

    let columns = [];
    let columnsPn = [];

    /* NOTE:
    useEffect(() => {
        debugger
        if (calculatedData && typeof calculatedData.Pboucle === "number") {
            calculate(data);
            //(applicationData && applicationData[id] && applicationData[id].edited) ||
        } else if ((subapplication === SubAppCode.STATIONDELAVAGE || subapplication === SubAppCode.CAMPING || subapplication === SubAppCode.PROCESSUSINDUSTRIEL)) {
            calculate(data);
        }
    }, [calculatedData]);
    */

    if (allData && calculatedData && SystemCode.isValid(system)) {
        if (zone3ApiData) {
            // eslint-disable-next-line default-case
            switch (mode) {
                case ProductionMode.INSTANTANE:
                    // eslint-disable-next-line default-case
                    switch (system) {
                        case SystemCode.ECHANGEUR_A_PLAQUES:
                            dataSource = [
                                {
                                    key: zone3ApiData.Pn,
                                    name: zone3ApiData.Pn,
                                },
                            ];
                            columns = [
                                {
                                    title: <wrap center="" //NOSONAR
                                        ><text wrap="" style={{ textAlign: "center" }}><T>text.puissance_minimale_de_lechangeur_kw</T></text></wrap>,
                                    dataIndex: 'name',
                                    key: "column" + zone3ApiData.Pn,
                                }
                            ];
                            break;
                        case SystemCode.ECHANGEUR_A_PLAQUES_BALLONS_PRIMAIRES:
                            dataSource = [];

                            dataSourcePn = [{ key: zone3ApiData.Pn, Pn: zone3ApiData.Pn }];
                            // eslint-disable-next-line array-callback-return
                            zone3ApiData.PV && zone3ApiData.PV.map(d => {
                                let o = { key: Math.round(d.V), V: Math.round(d.V), P: d.P, Qe: d.Qe, INTER: d.INTER, Echangeur1: d.Echangeur1, Echangeur2: d.Echangeur2 };
                                dataSource.push(o);
                            });

                            columns = [
                                {
                                    title: <wrap center="" style={{ padding: "0px 5px" }} //NOSONAR
                                        ><text wrap="" style={{ maxWidth: 90, textAlign: "center" }}><T>text.FO_ETUDE_VOLUME</T></text></wrap>,
                                    dataIndex: 'V',
                                },
                                {
                                    title: <wrap center="" style={{ padding: "0px 5px" }} //NOSONAR
                                        ><text wrap="" style={{ maxWidth: 90, textAlign: "center" }}><T>text.FO_ETUDE_PUISSANCE_HYGIA</T></text></wrap>,
                                    dataIndex: 'P',
                                },
                                {
                                    title: <wrap center="" style={{ padding: "0px 5px" }} //NOSONAR
                                        ><text wrap="" style={{ maxWidth: 90, textAlign: "center" }}><T>text.FO_ETU_TANK_FLOW</T></text></wrap>,
                                    dataIndex: 'Qe',
                                }
                            ];
                            columnsPn = [
                                {
                                    title: <wrap center=""><text wrap="" style={{ textAlign: "center" }} //NOSONAR
                                    ><T>text.FO_ETU_EXCH_POWER</T></text></wrap>,
                                    dataIndex: 'Pn',
                                }
                            ];
                            break;
                    }
                    break;
                case ProductionMode.SISA:
                    // eslint-disable-next-line default-case
                    switch (system) {
                        case SystemCode.ECHANGEUR_BALLONS_ECS:
                        case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS:
                        case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS_HMWM:
                        case SystemCode.BALLONS_ECS_TANK_IN_TANK:
                        case SystemCode.BALLONS_ECS_PREPARATEURS:
                            dataSource = [];

                            // eslint-disable-next-line array-callback-return
                            zone3ApiData.Vs.map(d => {
                                let o = { key: Math.round(d.V), V: Math.round(d.V), PI: null, PA: null };

                                if (/SI/.test(d.Segment)) {
                                    o.PI = d.P;
                                    dataSource.push(o);
                                } else if (/SA|ACC/.test(d.Segment)) {
                                    o.PA = d.P;
                                    dataSource.push(o);
                                }

                            });

                            columns = [
                                {
                                    title: <wrap center="" style={{ padding: "0px 5px"}} //NOSONAR
                                        ><text wrap="" style={{ maxWidth: 90, textAlign: "center" }}><T>text.FO_ETUDE_VOLUME</T></text></wrap>,
                                    dataIndex: 'V',
                                    key: "column" + zone3ApiData.V,
                                    width: 90
                                },
                                {
                                    title: <wrap center="" style={{ padding: "0px 5px" }} //NOSONAR
                                        ><text wrap="" style={{ maxWidth: 90, textAlign: "center" }}><T>text.FO_ETUDE_INST_POWER</T></text></wrap>,
                                    dataIndex: 'PI',
                                    key: "column" + zone3ApiData.PI,
                                    width: 90
                                },
                                {
                                    title: <wrap center="" style={{ padding: "0px 5px" }} //NOSONAR
                                        ><text wrap="" style={{ maxWidth: 90, textAlign: "center" }}><T>text.FO_ETUDE_ACC_POWER</T></text></wrap>,
                                    dataIndex: 'PA',
                                    key: "column" + zone3ApiData.PA,
                                    width: 90
                                }
                            ];
                            break;
                    }
                    break;
                case ProductionMode.ACCUMULE:
                    // eslint-disable-next-line default-case
                    switch (system) { //NOSONAR
                        case SystemCode.BALLONS_ELECTRIQUES:
                            dataSource = [{
                                key: zone3ApiData.P,
                                V: Math.round(zone3ApiData.V),
                                P: zone3ApiData.P
                            }];
                            // NOTE: zone3ApiData.Vs.map(d => { return { key: d.V, V: d.V, PI: d.Segment === "1-SI" ? d.P : null, PA: d.Segment === "4-ACC" ? d.P : null } });
                            columns = [
                                {
                                    title: <wrap center=""><text wrap="" style={{ textAlign: "center" }} //NOSONAR
                                        ><T>text.FO_ETUDE_VOLUME</T></text></wrap>,
                                    dataIndex: 'V',
                                    key: "column" + zone3ApiData.V,
                                },
                                {
                                    title: <wrap center=""><text wrap="" style={{ textAlign: "center" }} //NOSONAR
                                        ><T>text.FO_ETUDE_PUISSANCE</T></text></wrap>,
                                    dataIndex: 'P',
                                    key: "column" + zone3ApiData.P,
                                }
                            ];
                            break;
                    }
                    break;
            }
        }

        // eslint-disable-next-line default-case
        switch (system) {
            case SystemCode.ECHANGEUR_A_PLAQUES:
                fields = <>
                    <FieldGroup>
                        <ApplicationFormField id={id} ref={refs[0]} required={true} allData={allData} data={data} subapplication={subapplication} onError={onError} startLabel="text.pertes_thermiques_du_bouclage" endLabel="kW" type="number" value={subapplication === SubAppCode.STATIONDELAVAGE || subapplication === SubAppCode.CAMPING || subapplication === SubAppCode.PROCESSUSINDUSTRIEL ? 0 : calculatedData.Pboucle} min={allData.Pboucle.minimum} max={allData.Pboucle.maximum} name="Pboucle" setData={setData} disabled={subapplication === SubAppCode.STATIONDELAVAGE || subapplication === SubAppCode.CAMPING || subapplication === SubAppCode.PROCESSUSINDUSTRIEL} calculate={calculate} formFieldClass="middle" allowDecimal={true} hasInfoButton={true} startLabelProps={{ className: " ", wrap: "", data_length: "xxx-large" }} infoValue="text.FO_HELP_PERTES" />
                    </FieldGroup>
                    <FieldGroup>
                        {allData.Tp && allData.Tp.enum && <ApplicationFormField id={id} setLastInputRef={setLastInputRef} startLabel="text.temperature_primaire" endLabel="text.celsius_degree" type="select" valuelist={allData.Tp.enum} value={calculatedData.Tp} name="Tp" setData={setData} formFieldClass="middle" startLabelProps={{ className: " ", wrap: "", data_length: "xxx-large" }} />}
                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} setLastInputRef={setLastInputRef} startLabel="text.temperature_sortie_sec_ech" endLabel="text.celsius_degree" type="number" value={calculatedData.TECS} disabled={true} formFieldClass="middle" startLabelProps={{ className: " ", wrap: "", data_length: "xxx-large" }} />
                    </FieldGroup>

                </>
                break;
            case SystemCode.ECHANGEUR_BALLONS_ECS:
            case SystemCode.BALLONS_ECS_TANK_IN_TANK:
            case SystemCode.BALLONS_ECS_PREPARATEURS:
                fields = <>
                    <FieldGroup>
                        <ApplicationFormField id={id} required={true} ref={refs[0]} allData={allData} data={data} subapplication={subapplication} onError={onError} startLabel="text.pertes_thermiques_du_bouclage" endLabel="text.FO_UNIT_KW" type="number" value={subapplication === SubAppCode.STATIONDELAVAGE || subapplication === SubAppCode.CAMPING || subapplication === SubAppCode.PROCESSUSINDUSTRIEL ? 0 : calculatedData.Pboucle} min={allData.Pboucle.minimum} max={allData.Pboucle.maximum} name="Pboucle" setData={setData} disabled={subapplication === SubAppCode.STATIONDELAVAGE || subapplication === SubAppCode.CAMPING || subapplication === SubAppCode.PROCESSUSINDUSTRIEL} calculate={calculate} formFieldClass="middle" allowDecimal={true} hasInfoButton={true} infoValue="text.FO_HELP_PERTES" startLabelProps={{ className: " ", wrap: "", data_length: "xxx-large" }} />
                    </FieldGroup>
                    <FieldGroup>
                        {allData.Tp && allData.Tp.enum && <ApplicationFormField id={id} setLastInputRef={setLastInputRef} startLabel="text.temperature_primaire" endLabel="text.celsius_degree" type="select" valuelist={allData.Tp.enum} value={calculatedData.Tp} name="Tp" setData={setData} formFieldClass="middle" startLabelProps={{ className: " ", wrap: "", data_length: "xxx-large" }} />}
                    </FieldGroup>
                    <FieldGroup>
                        {allData.TECS && allData.TECS.enum && <ApplicationFormField id={id} setLastInputRef={setLastInputRef} startLabel="text.temperature_stockage_ecs" endLabel="text.celsius_degree" type="select" valuelist={allData.TECS.enum} value={calculatedData.TECS} name="TECS" setData={setData} formFieldClass="middle" startLabelProps={{ className: " ", wrap: "", data_length: "xxx-large" }} />}
                    </FieldGroup>
                </>
                break;
            case SystemCode.BALLONS_ELECTRIQUES:
                fields = <>
                    <FieldGroup>
                        <ApplicationFormField id={id} ref={refs[0]} required={true} allData={allData} data={data} subapplication={subapplication} onError={onError} startLabel="text.pertes_thermiques_du_bouclage" endLabel="text.FO_UNIT_KW" type="number" value={subapplication === SubAppCode.STATIONDELAVAGE || subapplication === SubAppCode.CAMPING || subapplication === SubAppCode.PROCESSUSINDUSTRIEL ? 0 : calculatedData.Pboucle} min={allData.Pboucle.minimum} max={allData.Pboucle.maximum} name="Pboucle" setData={setData} disabled={subapplication === SubAppCode.STATIONDELAVAGE || subapplication === SubAppCode.CAMPING || subapplication === SubAppCode.PROCESSUSINDUSTRIEL} calculate={calculate} formFieldClass="middle" allowDecimal={true} hasInfoButton={true} infoValue="text.FO_HELP_PERTES" startLabelProps={{ className: " ", wrap: "", data_length: "xxx-large" }} />
                    </FieldGroup>
                    <FieldGroup>
                        {allData.TECS && allData.TECS.enum && <ApplicationFormField id={id} setLastInputRef={setLastInputRef} startLabel="text.temperature_stockage_ecs" endLabel="text.celsius_degree" type="select" valuelist={allData.TECS.enum} value={calculatedData.TECS} name="TECS" setData={setData} formFieldClass="middle" startLabelProps={{ className: " ", wrap: "", data_length: "xxx-large" }} />}
                    </FieldGroup>
                    <FieldGroup>
                        {allData.tr && allData.tr.enum && <ApplicationFormField id={id} setLastInputRef={setLastInputRef} startLabel="text.temps_de_reconstitution_du_stockage" endLabel="text.h" type="select" valuelist={allData.tr.enum} value={calculatedData.tr} name="tr" setData={setData} formFieldClass="middle" startLabelProps={{ className: " ", wrap: "", data_length: "xxx-large" }} />}
                    </FieldGroup>

                </>
                break;
            case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS:
            case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS_HMWM:
                fields = <>
                    <FieldGroup>
                        <ApplicationFormField id={id} ref={refs[0]} required={true} allData={allData} data={data} subapplication={subapplication} onError={onError} startLabel="text.pertes_thermiques_du_bouclage" endLabel="text.FO_UNIT_KW" type="number" value={subapplication === SubAppCode.STATIONDELAVAGE || subapplication === SubAppCode.CAMPING || subapplication === SubAppCode.PROCESSUSINDUSTRIEL ? 0 : calculatedData.Pboucle} min={allData.Pboucle.minimum} max={allData.Pboucle.maximum} name="Pboucle" setData={setData} disabled={subapplication === SubAppCode.STATIONDELAVAGE || subapplication === SubAppCode.CAMPING || subapplication === SubAppCode.PROCESSUSINDUSTRIEL} calculate={calculate} formFieldClass="middle" allowDecimal={true} hasInfoButton={true} infoValue="text.FO_HELP_PERTES" startLabelProps={{ className: " ", wrap: "", data_length: "xxx-large" }} />
                    </FieldGroup>
                    <FieldGroup>
                        {allData.TECS && allData.TECS.enum && <ApplicationFormField id={id} setLastInputRef={setLastInputRef} startLabel="text.temperature_stockage_ecs" endLabel="text.celsius_degree" type="select" valuelist={allData.TECS.enum} value={calculatedData.TECS} name="TECS" setData={setData} formFieldClass="middle" startLabelProps={{ className: " ", wrap: "", data_length: "xxx-large" }} />}
                    </FieldGroup>
                </>
                break;
            case SystemCode.ECHANGEUR_A_PLAQUES_BALLONS_PRIMAIRES:
                fields = allData.generateur && <>
                    <FieldGroup>
                        <ApplicationFormField id={id} ref={refs[0]} required={true} allData={allData} data={data} subapplication={subapplication} onError={onError} startLabel="text.pertes_thermiques_du_bouclage" endLabel="text.FO_UNIT_KW" type="number" value={subapplication === SubAppCode.STATIONDELAVAGE || subapplication === SubAppCode.CAMPING || subapplication === SubAppCode.PROCESSUSINDUSTRIEL ? 0 : calculatedData.Pboucle} min={allData.Pboucle.minimum} max={allData.Pboucle.maximum} name="Pboucle" setData={setData} calculate={calculate} formFieldClass="middle" allowDecimal={true} hasInfoButton={true} infoValue="text.FO_HELP_PERTES" startLabelProps={{ className: " ", wrap: "", data_length: "xxx-large" }} />
                    </FieldGroup>
                    <FieldGroup>
                        {allData.generateur && allData.generateur.enum && <ApplicationFormField id={id} setLastInputRef={setLastInputRef} startLabel="text.type_de_generateur" endLabel="" type="select" valuelist={allData.generateur.enum} value={calculatedData.generateur} name="generateur" fieldStyle={{ "width": 90 }} setData={setData} formFieldClass="middle" startLabelProps={{ className: " ", wrap: "", data_length: "xxx-large" }} />}
                    </FieldGroup>
                    <FieldGroup>
                        {allData.Tp && allData.Tp.enum && <ApplicationFormField id={id} setLastInputRef={setLastInputRef} startLabel="text.temperature_primaire" endLabel="text.celsius_degree" type="select" valuelist={allData.Tp.enum} value={calculatedData.Tp} name="Tp" setData={setData} formFieldClass="middle" startLabelProps={{ className: " ", wrap: "", data_length: "xxx-large" }} />}
                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} setLastInputRef={setLastInputRef} startLabel="text.temperature_sortie_sec_ech" endLabel="text.celsius_degree" type="number" value={calculatedData.TECS} disabled={true} formFieldClass="middle" startLabelProps={{ className: " ", wrap: "", data_length: "xxx-large" }} />
                    </FieldGroup>

                </>
                break;
        }


    }
    else {
        showTable = true;
        dataSource = [
            {
                key: 45,
                name: 450,
            },
            {
                key: 145,
                name: 1450,
            }, {
                key: 450,
                name: 450,
            },
            {
                key: 1450,
                name: 1450,
            },
        ];
        dataSourcePn = [];

        columns = [
            {
                title: <wrap center=""><text wrap="" style={{ textAlign: "center" }} //NOSONAR
                    ><T>text.puissance_minimale_de_lechangeur_kw</T></text></wrap>,
                dataIndex: 'name',
                key: "column",
            }
        ];

        // eslint-disable-next-line default-case
        switch (system) {
            case SystemCode.ECHANGEUR_A_PLAQUES_BALLONS_PRIMAIRES:
                fields = <>
                    <div className="form_fields small">
                        <div className="input_cont">
                            <text wrap="" data_length="xxx-large" //NOSONAR
                                ><T>text.pertes_thermiques_du_bouclage</T></text>
                            <Input required="" type="number" />
                            <div className="button micro info"><icon>info</icon></div>
                            <text><T>FO_UNIT_KW</T></text>
                        </div>
                    </div>
                    <div className="form_fields small">
                        <div className="input_cont">
                            <text wrap="" data_length="xxx-large" //NOSONAR
                                ><T>text.type_de_generateur</T></text>
                            <Select size="default">
                                <Option><T>text.chaudiere</T></Option>
                                <Option><T>text.rcu</T></Option>
                            </Select>
                        </div>
                    </div>
                    <div className="form_fields small" >
                        <div className="input_cont" //NOSONAR
                        >
                            <text wrap="" data_length="xxx-large" //NOSONAR
                            ><T>text.temperature_primaire</T></text>
                            <Select size="default">

                            </Select>
                            <text className=""><T>text.celsius_degree</T></text>
                        </div>
                    </div>
                    <div className="form_fields small">
                        <div className="input_cont">
                            <text wrap="" data_length="xxx-large" //NOSONAR
                            ><T>text.temperature_sortie_sec_ech</T></text>
                            <Input value="60" disabled="disabled" />
                            <text className=""><T>text.celsius_degree</T></text>
                        </div>
                    </div>
                </>
                break;
            case SystemCode.BALLONS_ECS_PREPARATEURS:
                fields = <>
                    <div className="form_fields small">
                        <div className="input_cont">
                            <text wrap="" data_length="xxx-large" //NOSONAR
                            ><T>text.pertes_thermiques_du_bouclage</T></text>
                            <Input required="" type="number" />
                            <div className="button micro info"><icon>info</icon></div>
                            <text><T>FO_UNIT_KW</T></text>
                        </div>
                    </div>
                    <div className="form_fields small">
                        <div className="input_cont">
                            <text wrap="" data_length="xxx-large" //NOSONAR
                            ><T>text.type_de_generateur</T></text>
                            <Select size="default">
                                <Option><T>text.chaudiere</T></Option>
                                <Option><T>text.rcu</T></Option>
                            </Select>
                        </div>
                    </div>
                    <div className="form_fields small" >
                        <div className="input_cont">
                            <text wrap="" data_length="xxx-large" //NOSONAR
                            ><T>text.temperature_primaire</T></text>
                            <Select size="default">

                            </Select>
                            <text className=""><T>text.celsius_degree</T></text>
                        </div>
                    </div>
                    <div className="form_fields small">
                        <div className="input_cont">
                            <text wrap="" data_length="xxx-large" //NOSONAR
                            ><T>text.temperature_stockage_ecs</T></text>
                            <Input value="60" disabled="disabled" />
                            <text className=""><T>text.celsius_degree</T></text>
                        </div>
                    </div>
                </>
                break;
            case SystemCode.ECHANGEUR_BALLONS_ECS:
            case SystemCode.BALLONS_ECS_TANK_IN_TANK:
                // case SystemCode.ECHANGEUR_A_PLAQUES:
                fields = <>
                    <div className="form_fields small">
                        <div className="input_cont">
                            <text wrap="" data_length="xxx-large" //NOSONAR
                            ><T>text.pertes_thermiques_du_bouclage</T></text>
                            <Input required="" type="number" />
                            <div className="button micro info"><icon>info</icon></div>
                            <text><T>FO_UNIT_KW</T></text>
                        </div>
                    </div>
                    <div className="form_fields small">
                        <div className="input_cont">
                            <text wrap="" data_length="xxx-large" //NOSONAR
                            ><T>text.temperature_primaire</T></text>
                            <Select size="default" defaultValue="80">

                            </Select>
                            <text className=""><T>text.celsius_degree</T></text>
                        </div>
                    </div>
                    <div className="form_fields small">
                        <div className="input_cont">
                            <text wrap="" data_length="xxx-large" //NOSONAR
                            ><T>text.temperature_stockage_ecs</T></text>
                            <Select size="default" defaultValue="60">

                            </Select>
                            <text className=""><T>text.celsius_degree</T></text>
                        </div>
                    </div>
                </>
                break;
            case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS:
            case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS_HMWM:
                fields = <>
                    <div className="form_fields small">
                        <div className="input_cont">
                            <text wrap="" data_length="xxx-large" //NOSONAR
                            ><T>text.pertes_thermiques_du_bouclage</T></text>
                            <Input required="" type="number" />
                            <div className="button micro info"><icon>info</icon></div>
                            <text><T>FO_UNIT_KW</T></text>
                        </div>
                    </div>
                    <div className="form_fields small">
                        <div className="input_cont">
                            <text wrap="" data_length="xxx-large" //NOSONAR
                            ><T>text.temperature_stockage_ecs</T></text>
                            <Select size="default" defaultValue="60">

                            </Select>
                            <text className=""><T>text.celsius_degree</T></text>
                        </div>
                    </div>
                </>
                break;
            case SystemCode.BALLONS_ELECTRIQUES:
                fields = <>
                    <div className="form_fields small">
                        <div className="input_cont">
                            <text wrap="" data_length="xxx-large" //NOSONAR
                            ><T>text.pertes_thermiques_du_bouclage</T></text>
                            <Input required="" type="number" />
                            <div className="button micro info"><icon>info</icon></div>
                            <text><T>FO_UNIT_KW</T></text>
                        </div>
                    </div>
                    <div className="form_fields small">
                        <div className="input_cont">
                            <text wrap="" data_length="xxx-large" //NOSONAR
                            ><T>text.temperature_stockage_ecs</T></text>
                            <Select size="default" defaultValue="60">

                            </Select>
                            <text className=""><T>text.celsius_degree</T></text>
                        </div>
                    </div>
                    <div className="form_fields small">
                        <div className="input_cont">
                            <text wrap="" data_length="xxx-large" //NOSONAR
                            ><T>text.temps_de_reconstitution_du_stockage</T></text>
                            <Select size="default" defaultValue="6">

                            </Select>
                            <text className=""><T>h</T></text>
                        </div>
                    </div>
                </>
                break;
        }
    }

    useEffect(() => {

        if (zone3ApiData && calculatedData && (calculatedData.Pboucle || calculatedData.Pboucle === 0)) {
            if (dataSource && dataSource.length === 1) {
                setActiveRowIndex(0);

                let rowData = { ...dataSource[0] };
                onRowClick(rowData, 0);
            }
        }
    }, [zone3ApiData]);// eslint-disable-line react-hooks/exhaustive-deps


    const onRowClick = (record, rowIndex, fromGrid) => {
        if (fromGrid) {
            setActiveRowIndex(rowIndex);
            dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], edited: true } }));
        }

        setZone4Data(item => ({ ...item, ...record, opened: true }));
        if (+zone === 4 && (applicationData && applicationData[id] && applicationData[id].edited)) {
            history.replace(Project.getPageUrl("applications", [application, subapplication, id, 4]), zone);
        } else {
            if (SystemCode.ECHANGEUR_A_PLAQUES === system) { //NOSONAR
                history.replace(Project.getPageUrl("applications", [application, subapplication, id, 4]), zone);
            } else {
                history.push(Project.getPageUrl("applications", [application, subapplication, id, 4]), zone);
            }
        }
    }


    useEffect(() => {
        return () => {
            let _zone = history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1, history.location.pathname.length);// NOTE GrogorM
            if (+_zone <= 3 && history.action === "POP") {
                setSystem(null);
                setEtudeAllData(item => ({ ...item, system: null }));
                dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], system: null } }));
            }
            /* NOTE: 
             * GrigorM
             setZone4Data(null);
            setZone3ApiData(null);
            setSystem(null);
            setProductionMode(null)
            setSystemData(null)
            */
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return <view filter="" transparent="" mini="" //NOSONAR
    >
        <wrap>
            <form>
                <div className="section_group">
                    <number style={sectionHeaderStyle}>3</number>
                    <div className="sections s_medium">
                       <div className='field_group'>
                            <div className="form_fields">
                                <div className="input_cont title">
                                    <text className="center" style={sectionHeaderStyle}><b><T>text.dimensionnement_du_systeme</T></b></text>
                                </div>
                            </div>
                       </div>
                        {fields}
                        {(zone3ApiData || showTable) && (<FormField>
                            {system === SystemCode.ECHANGEUR_A_PLAQUES_BALLONS_PRIMAIRES && <Table
                                className="no_hover"
                                pagination={false}
                                dataSource={dataSourcePn}
                                columns={columnsPn.map(c => { c.render = (val) => { return <wrap center=""><text>{val}</text></wrap> }; return c; })} //NOSONAR
                            />}
                            <Table
                                onRow={(record, rowIndex) => { //NOSONAR
                                    return {
                                        onClick: () => { onRowClick(record, rowIndex, true) }
                                    }
                                }}
                                rowClassName={(record, rowIndex) => { //NOSONAR
                                    return activeRowIndex === rowIndex ? "highlighted" : "";
                                }}
                                pagination={false}
                                dataSource={dataSource}
                                columns={columns.map(c => {
                                    c.render = (val, record) => { return <wrap center=""><text>{val}{record.INTER && c.dataIndex === 'V' ? <span>(*)</span> : null}</text></wrap> }; //NOSONAR
                                    return c;
                                })}
                            />
                        </FormField>)}
                        <div className="form_fields">
                            { calculatedData && calculatedData.Pboucle === 0 && 
                                <div className="input_cont" data-size="xxx-small">
                                    <icon red="" //NOSONAR
                                    >warning</icon>
                                    <text wrap=""><T>text.FO_COMMENT_NO_LOOPING</T></text>
                                </div>
                            }
                        </div>
                        <div className="form_fields">
                            { system === SystemCode.ECHANGEUR_A_PLAQUES_BALLONS_PRIMAIRES && calculatedData && calculatedData.generateur === "chaudiere" && 
                                <div className="input_cont" data-size="xxx-small">
                                    <text wrap=""><span>(*)</span><T>text.FO_ETU_COMMENT_HYGIATHERM</T></text>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </form>
        </wrap>
    </view>
}

export default connect(state => ({ isLoggedIn: state.isLoggedIn, applicationData: state.applicationData }), null)(Zone3);