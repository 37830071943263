import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { v4 as uuidv4 } from 'uuid';

/**
 * Slik Slider wrapper
 * @param {any} props contains: items
 * @exports Slider
 */
export function SlickSlider(props) {
    const { items, onItemRender } = props;
    const [settings, setSettings] = useState({});
    const [key, setKey] = useState(null);

    useEffect(() => {
        let _settings = items && items.length <= 4 ? {
            infinite: false,
            slidesToShow: items.length,
            slidesToScroll: 1,
            variableWidth: true
        } : {
            dots: true,
            infinite: false,
            speed: 300,
            slidesToShow: items && items.length < 4 ? items.length : 4,
            slidesToScroll: items && items.length < 4 ? items.length : 4,
            centerMode: false,
            variableWidth: items && items.length <= 4
        };
        setSettings(_settings);
        setKey(uuidv4());
    }, [items]);


    let slider = null;
    if (items) {
        slider = items.length <= 4 ?
            <div className="slick-slider slick-initialized" dir="ltr">
                <div className="slick-list">
                    <div className="slick-track" style={{ "opacity": 1, "transform": "translate3d(0px, 0px, 0px)" }} >
                        {
                            items.map((s, i) => {
                                return <div data-index="0" className="slick-slide slick-active" tabIndex="-1" aria-hidden="false" style={{
                                    "outline": "none"
                                }} key={i} //NOSONAR
                                >
                                    <div>
                                        {onItemRender(s, i) }
                                    </div>
                                </div>
                            })
                        }
                        
                    </div>
                </div>
            </div>
            :

            < Slider {...settings} key={key} >
                {props.children}
                {
                    items.map((s, i) => {
                        return onItemRender(s, i)
                    })
                }
            </Slider>
    }


    return slider;
}