import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { useRouteMatch } from 'react-router-dom';

import { message } from 'antd';

import moment from 'moment';

import { ApiUrl, Defines } from '../../project/Defines.js';
import { Project } from '../../project/Project.js';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities.js';

import ListView from '../../Shared/ListView/index.js';
import FooterToolbar from '../../Shared/FooterToolbar';
import { showLoader, hideLoader } from "../../Shared/Loader";
import { COLUMN_TYPE } from '../../Shared/ListView/helper.js';

import { T } from '../../components/Translations.js';
import { Ajax } from '../../components/Ajax.js';
import { PopupTypes } from '../../components/Popup.js';

import DownloadPDFPopup from './DownloadPDFPopup.js';
import ShareDocumentPopup from '../EtudeDocument/ShareDocumentPopup.js';

import { openPopup, closePopup } from '../../store/actions';

const shareLoaderName = 'SHARE_LOADER';

const listUpdateEvent = EVENT_NAME.ETUDE_LIST_UPDATE;

/**
 * the Etude page
 * @exports Etude
 */
function Etude(props) {
    const { history, network, networks } = props;
    const [applications] = useState(networks.find(n => n.id === network).applications);
    const dispatch = useDispatch();
    const [checkeds, setCheckeds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pdfUrls, setPdfUrls] = useState([]);
    const {params} = useRouteMatch();

    const getSystem = useCallback((record) => {
        if (record && record.application && record.subapplication && record.system) {
            const subapplications = applications.find(a => a.code === record.application).subApplications;
            const productionMode = subapplications.find(s => s.code === record.subapplication).productionMode;
            for (const idx in productionMode) {
                const system = productionMode[idx].subSystems.find(ss => ss.systemCode === record.system);
                if (system) {
                    return system.systemLabel;
                }
            }
        }
        return null;
    }, [applications]);

    const columns = [
        {
            text: 'text.FO_DOC_PROJET_CHANTIER',
            //titleCenter: true,
            type: COLUMN_TYPE.TEXT,
            noSort: true,
            children: [
                {
                    text: 'text.FO_DOC_SITENAME',
                    field: 'siteName',
                    width: 250,
                    type: COLUMN_TYPE.TEXT
                }
            ]
        },
        {
            text: 'text.FO_DOC_CLIENT',
            type: COLUMN_TYPE.TEXT,
            noSort: true,
            //titleCenter: true,
            children: [
                {
                    text: 'text.FO_DOC_CLIENTNAME',
                    field: 'clientName',
                    width: 250,
                    type: COLUMN_TYPE.TEXT
                }
            ]
        },
        {
            text: 'text.FO_DOC_ETUDE',
            //titleCenter: true,
            type: COLUMN_TYPE.TEXT,
            noSort: true,
            children: [
                {
                    text: 'text.FO_DOC_ID',
                    field: 'numero',
                    width: 150,
                    type: COLUMN_TYPE.TEXT
                },
                {
                    text: 'text.FO_DOC_VERSION',
                    field: 'version',
                    width: 80,
                    noSort: true,
                    type: COLUMN_TYPE.TEXT
                },
                {
                    text: 'text.FO_DOC_DATE',
                    field: 'created_at',
                    render: (text, record) => {
                        return <wrap>
                            <text>{moment(record?.created_at).format(Defines.Format.PrintDateTime)}</text>
                        </wrap>
                    },
                    width: 220,
                    type: COLUMN_TYPE.TEXT
                },
                {
                    text: 'text.FO_DOC_SYSTEMETUIDE',
                    field: 'system',
                    width: 200,
                    render: (text, record) => {
                        return <wrap>
                            <text style={{ overflowWrap: "break-word", whiteSpace: "normal"} }><T>{getSystem(record) || text}</T></text>
                        </wrap>
                    },
                    type: COLUMN_TYPE.TEXT,
                },
                {
                    text: 'text.FO_DOC_SOLUTION',
                    field: 'label',
                    type: COLUMN_TYPE.TEXT,
                    noSort: true,
                    render: (text, record) => {
                        const record_label = (Project.getEtudeLabel(record, props.culture) || '').split(';');
                        return record_label && <wrap>
                            <text>
                                {
                                    record_label.map((e, index) => {
                                        return <Fragment key={index} //NOSONAR
                                        >{e}<br /></Fragment>
                                    })
                                }
                            </text>
                        </wrap>
                    },
                }
            ]
        }
    ];

    const backApplicationPage = () => {
        history.push(Project.getPageUrl("applications"));
    }

    const goCurentApplicationPage = useCallback(() => {
        if (checkeds) {
            const id = checkeds[0].ID;
            if (id && checkeds[0].application && checkeds[0].subapplication) {
                history.push(Project.getPageUrl("applications", [checkeds[0].application, checkeds[0].subapplication, id]));
            } else {
                message.error("Invalid Etude");
            }
        }
    }, [checkeds, history])

    const onDelete = useCallback(() => {
        let IDs = [];

        checkeds.forEach(item => {
            const id = item.ID;
            if (IDs.indexOf(id) === -1) {
                IDs.push(id.toString())
            }
        });

        dispatch(openPopup({
            windowKey: 'wndConfirmDelete',
            type: PopupTypes.Confirm,
            text: <T>message.delete_confirm</T>,
            buttonYesText: <T>text.delete</T>,
            buttonNoText: <T>text.cancel</T>,
            yesCallback: function () {
                setIsLoading(true);
                Ajax.post({
                    url: ApiUrl.EtudeRemove,
                    data: {
                        selected: IDs 
                    },
                    success: function (response) {
                        if (!response.HasError) {
                            message.success(<T>message.data_has_been_successfully_updated</T>);
                            setCheckeds([]);
                            dispatchCustomEvent(listUpdateEvent, { action: "update" });
                        } else {
                            message.error(response.message);
                        }

                        setIsLoading(false);
                    }
                })
            }
        }))
    }, [checkeds, dispatch]);

    // const downloadPDF = useCallback((isChecked) => {
    //     setIsLoading(true);
    //     let IDs = [];
        
    //     checkeds.forEach(item => {
    //         const id = item.ID;
    //         if (IDs.indexOf(id) === -1) {
    //             IDs.push(id.toString())
    //         }
    //     });

    //     Ajax.download({
    //         url: ApiUrl.EtudePdf,
    //         target: '_blank',
    //         // fileName: `${}.pdf`,
    //         autoDownload: true,
    //         data: {
    //             etudes: IDs,
    //             showPrice: isChecked
    //             // culture: language
    //         },
    //         success: function (response) {
    //             setIsLoading(false);
    //         },
    //         error: function (response) {
    //             message.error(response.message);
    //             setIsLoading(false);
    //         }
    //     });

    // }, [checkeds]);

    const openDownloadPDFPopup = useCallback(() => {
        let IDs = [];
        
        checkeds.forEach(item => {
            const id = item.ID;
            if (IDs.indexOf(id) === -1) {
                IDs.push(id.toString())
            }
        });

        dispatch(openPopup({
            windowKey: 'wndDownloadPDF',
            component: DownloadPDFPopup,
            title: <T>text.FO_DOC_NOTEDECALCUL</T>,
            fullScreen: false,
            type: PopupTypes.DownloadPDF,
            bodyProps: { 
                //showPrice: isChecked
                onYesCallback: () => setTimeout(() =>  {
                    dispatch(closePopup('wndDownloadPDF'));
                }, '.5'),
                etudes: IDs,
                culture: props.culture,
                userToken: props.userToken
            }
        }))
    }, [checkeds, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    const openSharePopup = () => {
        showLoader(shareLoaderName);
        let IDs = [];

        checkeds.forEach(item => {
            const id = item.ID;
            if (IDs.indexOf(id) === -1) {
                IDs.push(id.toString())
            }
        });

        Ajax.post({
            url: ApiUrl.EtudePdfUrl,
            data: { etudes: IDs,
                    showPrice: false, 
                    dataItem: null,
                    isEtudeDetail: false
            },
            success: function (response) {
                hideLoader(shareLoaderName);
                if (response) {
                    setPdfUrls(response);
                }
            },
            error: function (response) {
                hideLoader(shareLoaderName);
                message.error(response.message);
            }
        });
    };

    useEffect(() => {
        if (pdfUrls.length > 0) {
            dispatch(
                openPopup({
                    component: ShareDocumentPopup,
                    title: <T>text.FO_DOC_SHARE</T>,
                    fullScreen: false,
                    type: PopupTypes.Email,
                    bodyProps: { fileData: pdfUrls }
                })
            );
        }
    }, [pdfUrls, dispatch]);

    const expandedColumnConfig = [
        {
            render: (text, record, index) => {
                return <wrap><text></text></wrap>;
            },
            width: 650,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.FO_DOC_VERSION',
            field: 'version',
            width: 80,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.FO_DOC_DATE',
            field: 'createdAt',
            width: 220,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                return <wrap>
                    <text>{moment(record?.createdAt).format(Defines.Format.PrintDateTime)}</text>
                </wrap>
            }
        },
        {
            text: 'text.FO_DOC_SYSTEMETUIDE',
            field: 'system',
            width: 200,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                return <wrap>
                    <text style={{ overflowWrap: "break-word", whiteSpace: "normal" }}><T>{getSystem(record) || text}</T></text>
                </wrap>
            },
        },
        {
            text: 'text.FO_DOC_SOLUTION',
            field: 'label',
            render: (text, record, index) => {
                const record_label = (Project.getEtudeLabel(record, props.culture) || '').split(';');
                return record_label && <wrap>
                    <text>
                        {
                            record_label.map((e, index) => {
                                return <Fragment key={index} //NOSONAR
                                >{e}<br /></Fragment>
                            })
                        }
                    </text>
                </wrap>
            },
            type: COLUMN_TYPE.TEXT
        }
        // {
        //     text: 'text.FO_DOC_SOLUTION',
        //     field: 'label',
        //     type: COLUMN_TYPE.TEXT
        // }
    ];

    const onExpandedRowRender = useCallback((record) => {
        if (!record.versions) return null;

        let dataSource = JSON.parse(record.versions); 
        return record.versions  && <wrap><ListView
            isSimple
            isChieldView
            noPaging
            skipEdit
            skipNewButton={true}
            // style={{ paddingLeft: 10 }}
            expandedTable={true}
            columnConfig={expandedColumnConfig}
            pagination={false}
            dataSource={dataSource}
            showHeader={false}
            style={{ "height": "auto", "paddingBottom": "20px", "paddingLeft": "40px" }}
            onClick={(record, rowIndex, event) => { //NOSONAR
                let arr = [...checkeds];
                let index = arr.findIndex(item => item.ID === record.ID);
                if (index !== -1) {
                    arr.splice(index, 1)
                    setCheckeds(arr);
                }
                else {
                    arr.push(record);
                    setCheckeds(arr);
                }

            }}

            rowSelection={{
                selectedRowKeys: checkeds.map(r => r.key),
                onSelect: (nestedRecord, selected) => {
                    let arr = [...checkeds];
                    if (selected) {
                        arr.push(nestedRecord);
                        setCheckeds(arr);
                    }
                    else {
                        let index = arr.findIndex(item => item.projectId === nestedRecord.projectId);
                        if(index !== -1) {
                            arr.splice(index, 1)
                            setCheckeds(arr);
                        }
                    }
                },
                hideSelectAll: true
            }}
        /></wrap>;
    }, [expandedColumnConfig]);// eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <ListView
            className="etude_list"
            isSimple
            noOverflow
            skipNewButton
            style={{ "maxHeight": "calc(100vh - 220px)"} }
            loading={isLoading}
            columnConfig={columns}
            apiUrl={ApiUrl.EtudeList}
            listUpdateEvent={listUpdateEvent}
            defaultSorting={{ Member: "created_at", SortDirection: 1 }}
            expandable={{
                defaultExpandAllRows: true,
                expandedRowRender: record => onExpandedRowRender(record),
                rowExpandable: (record) => {
                    return record.versions;
                },
             }}
            onClick={(record, rowIndex, event) => { //NOSONAR
                
                let arr = [...checkeds];
                let index = arr.findIndex(item => item.ID === record.ID);
                if(index !== -1) {
                    arr.splice(index, 1)
                    setCheckeds(arr);
                } 
                else {
                    arr.push(record);
                    setCheckeds(arr);
                }

            }}
            filters={[
                { name: 'search', type: 'search', align: 'center', value: params.name }
            ]}
            rowSelection={{
                selectedRowKeys: checkeds.map(r => r.key),
                onSelect: (record, selected, selectedRows) => {
                    let arr = [...checkeds];
                    if (selected) {
                        arr.push(record);
                        setCheckeds(arr);
                    }
                    else {
                        let index = arr.findIndex(item => item.ID === record.ID);
                        if(index !== -1) {
                            arr.splice(index, 1)
                            setCheckeds(arr);
                        }
                    }
                },
                hideSelectAll: true
            }}
        />
        <FooterToolbar toolbarKey="toolbarEtudeList">
            <toolbar transparent="">
                <wrap>
                    <action center="">
                        <div
                            onClick={(e) => backApplicationPage()}
                            effect='material'
                            className="button primary larger">
                            <icon></icon>
                            <text><T>text.FO_SIM_NEW</T></text>
                        </div>
                        <div
                            onClick={(e) => goCurentApplicationPage()}
                            disabled={checkeds.length !== 1}
                            className='button primary larger'>
                            <icon></icon>
                            <text><T>text.FO_SIM_OPEN</T></text>
                        </div>
                        <div
                            onClick={(e) => openDownloadPDFPopup()}
                            disabled={checkeds.length === 0}
                            className='button primary larger'>
                            <icon>upload</icon>
                            <text><T>text.FO_SIM_UPLOAD</T></text>
                        </div>
                        <div
                            onClick={(e) => openSharePopup()}
                            disabled={checkeds.length === 0}
                            className='button primary larger'>
                            <icon></icon>
                            <text><T>text.FO_SIM_SHARE</T></text>
                        </div>
                        <div
                            onClick={(e) => onDelete()}
                            disabled={checkeds.length === 0}
                            className='button primary larger'>
                            <icon>delete</icon>
                            <text><T>text.FO_SIM_DELETE</T></text>
                        </div>
                    </action>
                </wrap>
            </toolbar>
        </FooterToolbar>
    </>;
}

export default connect(state => ({
    networks: state.networks,
    network: state.network,
    culture: state.culture,
    userToken: state.userToken
}))(Etude);
