import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { SlickSlider } from '../../../../Shared/Slider';
import { PopupTypes } from '../../../../components/Popup';
import { T, DataTip } from '../../../../components/Translations';
import { /*AppPages,*/ AppPages, /*ProductionMode,*/ SystemCode } from '../../../../project/Defines';
import { Project } from '../../../../project/Project';
import { ApplicationFormField, FieldGroup } from '../../../../Shared/Form/formFields';
import { /*closePopup,*/ openPopup, setApplicationData } from '../../../../store/actions';
import { getZon4Defaults } from '../../../../components/Engine';
import { useRouteMatch } from 'react-router';
import { Picture } from '../../../../Shared/Picture/index';



function Zone4(props) {
    let fields = null;
    const dispatch = useDispatch();
    const { system, solutions, setSystem, setActiveRowIndex, productionMode, dataItem, setIsDocumentOpen, engine, mainSolution, setMainSolution, setSolutions, defaultSolution, zone4Data, setZone4Data, solutionDetailWindowKey, /*solutionsWindowKey,*/ isAlternativeSolution, setIsAlternativeSolution, sectionHeaderStyle, history,/* id, zone,*/ application, subapplication, setEtudeAllData, currentNetwork, applicationData, noSolutionMesssage } = props; const { calculatedData } = engine;
    const [showGrid, setShowGrid] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [zone4AllData, setZone4AllData] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const { params: { id, zone } } = useRouteMatch(Project.getPageUrl(AppPages.Applications, [':application?', ':subapplication?', ':id?', ":zone?"])); //NOSONAR
    

    useEffect(() => {
        let defaultsData = getZon4Defaults(system, currentNetwork.code, { Pboucle: !!calculatedData.Pboucle });
        //console.log(defaultsData);
        setZone4AllData(defaultsData.allData);

        if (applicationData && applicationData[id] && applicationData[id].edited) {
            setZone4Data({
                ...defaultsData.defaults,
                ...zone4Data,

                rechauff: defaultsData.defaults.rechauff,
                opened: true
            });
        }

        //setZone4Data({ ...zone4Data, rechauff: !!calculatedData.Pboucle }) //NOTE: Arman defaults how to set?
    }, [calculatedData.Pboucle]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //console.log(currentNetwork.brand);
        //if (currentNetwork.brand === "ACV"){
        if (currentNetwork.brand === "ACV" && (system === SystemCode.ECHANGEUR_BALLONS_ECS || system === SystemCode.BALLONS_ECS_TANK_IN_TANK || system === SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS_HMWM)) {
            if (zone4Data.CuveInox) {
                //debugger;
                setZone4Data({
                    ...zone4Data,
                    Jaquette: '',
                    Ouverture: ''
                });
            };
        }
    }, [zone4Data && zone4Data.CuveInox]);// eslint-disable-line react-hooks/exhaustive-deps


    let columns = [
        {
            title: <wrap><text wrap=""><T>FO_OTHERTAB_SOLUTION</T></text></wrap>,
            dataIndex: 'productNames',
            key: "productNames",
            render: (val, t) => {

                return <wrap v_align=''>

                    {solutions.length > 1 && t.principial &&
                        <holder>
                            <picture style={{ backgroundImage: "url(/content/img/logo/project.png)" }} micro=""></picture>
                        </holder>
                    }
                    <text style={{ whiteSpace: "pre" }}>{val}</text>

                </wrap>
            }
        },
        {
            width: 100,
            title: <wrap><text wrap=""><T>FO_OTHERTAB_USEFUL_VOLUME</T></text></wrap>,
            dataIndex: 'V',
            key: "volume_utile",
        },
        {
            width: 100,
            title: <wrap><text wrap=""><T>FO_OTHERTAB_POWER</T></text></wrap>,
            dataIndex: system === SystemCode.ECHANGEUR_A_PLAQUES_BALLONS_PRIMAIRES || system === SystemCode.ECHANGEUR_BALLONS_ECS ? 'Pn' : "P",
            key: "Puissance",
        },
        {
            width: 100,
            title: <wrap><text wrap=""><T>FO_OTHERTAB_WIDTH</T></text></wrap>,
            dataIndex: 'largeur_mini',
            key: "largeur_mini",
        },
        {
            width: 100,
            title: <wrap><text wrap=""><T>FO_OTHERTAB_HEIGHT</T></text></wrap>,
            dataIndex: 'hauteur_min',
            key: "hauteur_min",
        }
    ];

    if (((props.currentNetwork && props.currentNetwork.showPrice))){
        columns.push({
            width: 100,
            title: <wrap><text wrap=""><T>FO_OTHERTAB_PRICE</T></text></wrap>,
            dataIndex: 'prix',
            key: "prix",
        })
    }
    //useEffect(()=> {
    //    setEtudeAllData(item => ({ ...item, zone4Data: zone4Data}))
    //}, [zone4Data])

    const onChangeRadio = (cuve) => {
        dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], edited: true } })); 
        setZone4Data({ ...zone4Data, CuveInox: cuve });
    };

    if (system && zone4AllData) {
        // eslint-disable-next-line default-case
        switch (system) {
            case SystemCode.ECHANGEUR_A_PLAQUES:
                fields = <>
                    <FieldGroup>
                        <div className="form_fields middle">
                            <div className="input_cont"><text underline=""><T>text.contraintes_chaufferie_acces_si_connues</T></text>
                                <DataTip title={'text.FO_HELP_SIZE'}>
                                    <div className="button micro info"><icon>info</icon></div>
                                </DataTip>
                            </div>
                        </div>
                        <div className="form_fields middle"><div className="input_cont"><text underline=""><T>FO_EXCHANGE</T></text></div></div>
                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.largeur_de_passage" type="number" value={zone4Data.L} setData={setZone4Data} name="L" endLabel="FO_UNIT_MM" formFieldClass="middle" startLabelProps={{ className: "" }} />
                        <div className="form_fields middle">
                            <div className="input_cont">
                                <group>
                                    <label classic=""><input name="extensible" checked={zone4Data.extensible} onChange={(e) => {
                                        dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], edited: true } }))
                                        setZone4Data({ ...zone4Data, extensible: e.target.checked });
                                    }} type="checkbox" /><box><check></check></box><text><T>text.FO_EXTENSIBLE</T></text></label>
                                    <DataTip title={'text.FO_HELP_EXTENSIBLE'}>
                                        <div className="button micro info"><icon>info</icon></div>
                                    </DataTip>
                                </group>
                            </div>
                        </div>
                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.hauteur_sous_plafond" type="number" value={zone4Data.H} setData={setZone4Data} name="H" endLabel="FO_UNIT_MM" formFieldClass="middle" startLabelProps={{ className: "" }} />
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="FO_ZONE4_PRIM_PUMP" name="circPrim" type="select" valuelist={zone4AllData.circPrim.enum} value={zone4Data.circPrim} setData={setZone4Data} formFieldClass="middle" />
                    </FieldGroup>
                </>
                break;
            case SystemCode.ECHANGEUR_A_PLAQUES_BALLONS_PRIMAIRES:
                fields = <>
                    <FieldGroup>
                        <div className="form_fields middle">
                            <div className="input_cont">
                                <text underline="" //NOSONAR
                                ><T>text.contraintes_chaufferie_acces_si_connues</T></text>
                                <DataTip title={'text.FO_HELP_SIZE'}>
                                    <div className="button micro info"><icon>info</icon></div>
                                </DataTip>
                            </div>
                        </div>
                        <div className="form_fields middle">
                            <div className="input_cont"><text underline="" //NOSONAR
                            ><T>text.circulateur_de_charge_du_des_ballons</T></text></div></div>
                        <div className="form_fields middle">
                            <div className="input_cont"><text underline="" //NOSONAR
                        ><T>text.echangeur_zone4</T></text></div></div>
                        <div className="form_fields middle"><div className="input_cont"><text underline="" //NOSONAR 
                        ><T>text.ballons_primaires </T></text></div></div>
                        {/*<div className="form_fields middle"><div className="input_cont"><text underline=""><T>text.ballons_primaires</T></text></div></div>*/}
                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.largeur_de_passage" type="number" value={zone4Data.L} setData={setZone4Data} name="L" endLabel="FO_UNIT_MM" formFieldClass="middle" startLabelProps={{ className: "" }} />
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="FO_ETU_PUMP_BRAND" name="brand" type="select" valuelist={zone4AllData.brand.enum} value={zone4Data.brand} setData={setZone4Data} formFieldClass="middle" />

                        <div className="form_fields middle"></div>


                        {/*<div className="form_fields middle">*/}
                        {/*    <div className="input_cont">*/}
                        {/*        <label classic=""><input name="extensible" checked={zone4Data.extensible} onChange={(e) => { setZone4Data({ ...zone4Data, extensible: e.target.checked }) }} type="checkbox" /><box><check></check></box><text><T>text.FO_EXTENSIBLE</T></text></label>*/}
                        {/*        <div className="button micro info" onClick={(e) => infoButtonClick("text.FO_HELP_EXTENSIBLE")}><icon>info</icon></div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.nombre" name="nbBallon" type="select" valuelist={zone4AllData.nbBallon.enum} value={zone4Data.nbBallon} setData={setZone4Data} formFieldClass="middle" />

                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.hauteur_sous_plafond" type="number" value={zone4Data.H} setData={setZone4Data} name="H" endLabel="FO_UNIT_MM" formFieldClass="middle" startLabelProps={{ className: "" }} />
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="FO_ZONE4_PUMP_CIRC" name="circ" type="select" valuelist={zone4AllData.circ.enum} value={zone4Data.circ} setData={setZone4Data} formFieldClass="middle" />

                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="FO_ZONE4_PRIM_PUMP" name="circPrim" type="select" valuelist={zone4AllData.circPrim.enum} value={zone4Data.circPrim} setData={setZone4Data} formFieldClass="middle" />

                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="FO_ZONE4_JACKET" name="Jaquette" type="select" valuelist={zone4AllData.Jaquette.enum} value={zone4Data.Jaquette} setData={setZone4Data} formFieldClass="middle" />
                    </FieldGroup>
                </>
                break;
            case SystemCode.ECHANGEUR_BALLONS_ECS:
                const Jaquette = <ApplicationFormField id={id} dataLength="xxx-large" startLabel="FO_ZONE4_JACKET" name="Jaquette" type="select" valuelist={zone4AllData.Jaquette.enum} value={zone4Data.Jaquette} setData={setZone4Data} formFieldClass="middle" />;
                const Ouverture = <ApplicationFormField id={id} dataLength="xxx-large" startLabel="FO_ZONE4_OPENING" name="Ouverture" type="select" valuelist={zone4AllData.Ouverture.enum} value={zone4Data.Ouverture} setData={setZone4Data} formFieldClass="middle" />;

                fields = <>
                    <FieldGroup>
                        <div className="form_fields middle">
                            <div className="input_cont"><text underline=""><T>text.contraintes_chaufferie_acces_si_connues</T></text>
                                <DataTip title={'text.FO_HELP_SIZE'}>
                                    <div className="button micro info"><icon>info</icon></div>
                                </DataTip>
                            </div>
                        </div>
                        <div className="form_fields middle"><div className="input_cont"><text underline=""><T>text.echangeur_zone4</T></text></div></div>
                        <div className="form_fields middle"><div className="input_cont"><text underline=""><T>text.ballons_ecs</T></text></div></div>
                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.largeur_de_passage" type="number" value={zone4Data.L} setData={setZone4Data} name="L" endLabel="FO_UNIT_MM" formFieldClass="middle" startLabelProps={{ className: "" }} />
                        <div className="form_fields middle">
                            <div className="input_cont">
                                <label classic=""><input name="extensible" checked={zone4Data.extensible} onChange={(e) => {
                                    dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], edited: true } }))
                                    setZone4Data({ ...zone4Data, extensible: e.target.checked });
                                }} type="checkbox" /><box><check></check></box><text><T>text.FO_EXTENSIBLE</T></text></label>
                                <DataTip title={"text.FO_HELP_EXTENSIBLE"}>
                                    <div className="button micro info"><icon>info</icon></div>
                                </DataTip>
                            </div>
                        </div>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.nombre" name="nbBallon" type="select" valuelist={zone4AllData.nbBallon.enum} value={zone4Data.nbBallon} setData={setZone4Data} formFieldClass="middle" />

                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.hauteur_sous_plafond" type="number" value={zone4Data.H} setData={setZone4Data} name="H" endLabel="FO_UNIT_MM" formFieldClass="middle" startLabelProps={{ className: "" }} />
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="FO_ZONE4_PRIM_PUMP" name="circPrim" type="select" valuelist={zone4AllData.circPrim.enum} value={zone4Data.circPrim} setData={setZone4Data} formFieldClass="middle" />
                        <div className="form_fields middle">
                            {currentNetwork.brand === "ACV" ? Jaquette
                            : <div className="input_cont">
                                <text data_length="xxx-large"><T>text.cuve</T></text>
                                {/*NOTE add input change Arman*/}
                                <label radio=""><input name="CuveInox" defaultChecked={!zone4Data.CuveInox} type="radio" value={false} onChange={(e) => onChangeRadio(false)} /><box><check></check></box><text><T>text.emaillee</T></text></label>
                                <label radio=""><input name="CuveInox" defaultChecked={zone4Data.CuveInox} type="radio" value={true} onChange={(e) => onChangeRadio(true)} /><box><check></check></box><text><T>text.inox</T></text></label>
                            </div>}
                        </div>
                    </FieldGroup>
                    <FieldGroup>
                        <div className="form_fields middle"></div>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="FO_ZONE4_SEC_PUMP" name="circSec" type="select" valuelist={zone4AllData.circSec.enum} value={zone4Data.circSec} setData={setZone4Data} formFieldClass="middle" />
                        {currentNetwork.brand === "ACV" ? Ouverture : Jaquette}
                    </FieldGroup>
                    {currentNetwork.brand !== "ACV" && <FieldGroup>
                        <div className="form_fields middle"></div>
                        <div className="form_fields middle"></div>
                        {Ouverture}
                        {/*<div className="form_fields middle">*/}
                        {/*    <div className="input_cont">*/}
                        {/*        <text><T>FO_ZONE4_OPENING</T></text>*/}
                        {/*        */}{/*NOTE add input change Arman*/}
                        {/*        <label radio=""><input name="Ouverture" defaultChecked={zone4Data.Ouverture === "BR"} type="radio" value={"BR"} onChange={(e) => { setZone4Data({ ...zone4Data, Ouverture: "BR" }) }} /><box><check></check></box><text><T>FO_RES_FLANGE</T></text></label>*/}
                        {/*        <label radio=""><input name="Ouverture" defaultChecked={zone4Data.Ouverture === "TH"} type="radio" value={"TH"} onChange={(e) => { setZone4Data({ ...zone4Data, Ouverture: "TH" }) }} /><box><check></check></box><text><T>FO_RES_MANHOLE</T></text></label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </FieldGroup>}

                </>
                break;

            case SystemCode.BALLONS_ECS_TANK_IN_TANK:
                fields = <>
                    <FieldGroup>
                        <div className="form_fields middle">
                            <div className="input_cont"><text underline=""><T>text.contraintes_chaufferie_acces_si_connues</T></text>
                                <DataTip title={'text.FO_HELP_SIZE'}>
                                    <div className="button micro info"><icon>info</icon></div>
                                </DataTip>
                            </div>
                        </div>
                        <div className="form_fields middle"><div className="input_cont"><text underline=""><T>text.ballons_ecs</T></text></div></div>
                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.largeur_de_passage" type="number" value={zone4Data.L} setData={setZone4Data} name="L" endLabel="FO_UNIT_MM" formFieldClass="middle" startLabelProps={{ className: "" }} />
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.nombre" name="nbBallon" type="select" valuelist={zone4AllData.nbBallon.enum} value={zone4Data.nbBallon} setData={setZone4Data} formFieldClass="middle" />

                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.hauteur_sous_plafond" type="number" value={zone4Data.H} setData={setZone4Data} name="H" endLabel="FO_UNIT_MM" formFieldClass="middle" startLabelProps={{ className: "" }} />
                    </FieldGroup>
                </>
                break;
            case SystemCode.BALLONS_ECS_PREPARATEURS:
                const JaquettePrep = <ApplicationFormField id={id} dataLength="xxx-large" startLabel="FO_ZONE4_JACKET" name="Jaquette" type="select" valuelist={zone4AllData.Jaquette.enum} value={zone4Data.Jaquette} setData={setZone4Data} formFieldClass="middle" />;
                const OuverturePrep = <ApplicationFormField id={id} dataLength="xxx-large" startLabel="FO_ZONE4_OPENING" name="Ouverture" type="select" valuelist={zone4AllData.Ouverture.enum} value={zone4Data.Ouverture} setData={setZone4Data} formFieldClass="middle" />;

                fields = <>
                    <FieldGroup>
                        <div className="form_fields middle">
                            <div className="input_cont"><text underline=""><T>text.contraintes_chaufferie_acces_si_connues</T></text>
                                <DataTip title={'text.FO_HELP_SIZE'}>
                                    <div className="button micro info"><icon>info</icon></div>
                                </DataTip>
                            </div>
                        </div>
                        <div className="form_fields middle"><div className="input_cont"><text underline=""><T>text.ballons_ecs</T></text></div></div>
                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.largeur_de_passage" type="number" value={zone4Data.L} setData={setZone4Data} name="L" endLabel="FO_UNIT_MM" formFieldClass="middle" startLabelProps={{ className: "" }} />
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.nombre" name="nbBallon" type="select" valuelist={zone4AllData.nbBallon.enum} value={zone4Data.nbBallon} setData={setZone4Data} formFieldClass="middle" />
                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.hauteur_sous_plafond" type="number" value={zone4Data.H} setData={setZone4Data} name="H" endLabel="FO_UNIT_MM" formFieldClass="middle" startLabelProps={{ className: "" }} />
                        <div className="form_fields middle">
                            <div className="input_cont">
                                {currentNetwork.brand === "ACV" ? JaquettePrep
                                    : <>
                                        <text data_length="xxx-large"><T>text.cuve</T></text>
                                        <label radio=""><input name="CuveInox" defaultChecked={!zone4Data.CuveInox} type="radio" value={false} onChange={(e) => onChangeRadio(false)} /><box><check></check></box><text><T>text.emaillee</T></text></label>
                                        <label radio=""><input name="CuveInox" defaultChecked={zone4Data.CuveInox} type="radio" value={true} onChange={(e) => onChangeRadio(true)} /><box><check></check></box><text><T>text.inox</T></text></label>
                                    </>
                                }
                            </div>
                        </div>

                    </FieldGroup>
                    <FieldGroup>
                        <div className="form_fields middle"></div>
                        {currentNetwork.brand === "ACV" ? OuverturePrep : JaquettePrep}
                    </FieldGroup>
                    {currentNetwork.brand !== "ACV" && <FieldGroup> 
                        <div className="form_fields middle"></div>
                        {OuverturePrep}
                    </FieldGroup>}
                </>
                break;
            case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS:
                fields = <>
                    <FieldGroup>
                        <div className="form_fields middle">
                            <div className="input_cont"><text underline=""><T>text.contraintes_chaufferie_acces_si_connues</T></text>
                                <DataTip title={'text.FO_HELP_SIZE'}>
                                    <div className="button micro info"><icon>info</icon></div>
                                </DataTip>
                            </div>
                        </div>
                        <div className="form_fields middle"><div className="input_cont"><text underline=""><T>text.accumulateur_s_gaz</T></text></div></div>
                        <div className="form_fields middle"><div className="input_cont"><text underline=""><T>text.ballons_ecs</T></text></div></div>
                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.largeur_de_passage" type="number" value={zone4Data.L} setData={setZone4Data} name="L" endLabel="FO_UNIT_MM" formFieldClass="middle" startLabelProps={{ className: "" }} />

                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.nombre" name="nbAcc" type="select" valuelist={zone4AllData.nbAcc.enum} value={zone4Data.nbAcc} setData={setZone4Data} formFieldClass="middle" />

                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.nombre" name="nbBallon" type="select" valuelist={zone4AllData.nbBallon.enum} value={zone4Data.nbBallon} setData={setZone4Data} formFieldClass="middle" />
                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.hauteur_sous_plafond" type="number" value={zone4Data.H} setData={setZone4Data} name="H" endLabel="FO_UNIT_MM" formFieldClass="middle" startLabelProps={{ className: "" }} />

                        {/*<ApplicationFormField id={id}  dataLength="xxx-large" startLabel="text.FO_ZONE4_GAZ_TYPE" name="typeGaz" type="select" valuelist={[{ text: "FO_ZONE4_G20", value: "G20" }, { text: "FO_ZONE4_G25", value: "G25" }, { text: "FO_ZONE4_G31", value: "G31" }]} value={zone4Data.typeGaz} setData={setZone4Data} formFieldClass="middle" />*/}
                        <div className="form_fields middle"></div>


                        <div className="form_fields middle">
                                <div className="input_cont">
                                <text data_length="xxx-large"><T>text.cuve</T></text>
                                <label radio=""><input name="CuveInox" defaultChecked={!zone4Data.CuveInox} type="radio" value={false} onChange={(e) => onChangeRadio(false)} /><box><check></check></box><text><T>text.emaillee</T></text></label>
                                <label radio=""><input name="CuveInox" defaultChecked={zone4Data.CuveInox} type="radio" value={true} onChange={(e) => onChangeRadio(true)} /><box><check></check></box><text><T>text.inox</T></text></label>
                            </div> 
                        </div>
                    </FieldGroup>
                    <FieldGroup>
                        <div className="form_fields middle"></div>
                        <div className="form_fields middle"></div>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="FO_ZONE4_JACKET" name="Jaquette" type="select" valuelist={zone4AllData.Jaquette.enum} value={zone4Data.Jaquette} setData={setZone4Data} formFieldClass="middle" />
                    </FieldGroup>
                    <FieldGroup>
                        <div className="form_fields middle"></div>
                        <div className="form_fields middle"></div>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="FO_ZONE4_OPENING" name="Ouverture" type="select" valuelist={zone4AllData.Ouverture.enum} value={zone4Data.Ouverture} setData={setZone4Data} formFieldClass="middle" />
                    </FieldGroup>
                </>
                break;
            case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS_HMWM:
                fields = <>
                    <FieldGroup>
                        <div className="form_fields middle">
                            <div className="input_cont"><text underline=""><T>text.contraintes_chaufferie_acces_si_connues</T></text>
                                <DataTip title={'text.FO_HELP_SIZE'}>
                                    <div className="button micro info"><icon>info</icon></div>
                                </DataTip>
                            </div>
                        </div>
                        <div className="form_fields middle"><div className="input_cont"><text underline=""><T>text.accumulateur_s_gaz</T></text></div></div>
                        <div className="form_fields middle"><div className="input_cont"><text underline=""><T>text.ballons_ecs</T></text></div></div>
                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.largeur_de_passage" type="number" value={zone4Data.L} setData={setZone4Data} name="L" endLabel="FO_UNIT_MM" formFieldClass="middle" startLabelProps={{ className: "" }} />

                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.nombre" name="nbAcc" type="select" valuelist={zone4AllData.nbAcc.enum} value={zone4Data.nbAcc} setData={setZone4Data} formFieldClass="middle" />

                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.nombre" name="nbBallon" type="select" valuelist={zone4AllData.nbBallon.enum} value={zone4Data.nbBallon} setData={setZone4Data} formFieldClass="middle" />
                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.hauteur_sous_plafond" type="number" value={zone4Data.H} setData={setZone4Data} name="H" endLabel="FO_UNIT_MM" formFieldClass="middle" startLabelProps={{ className: "" }} />

                        <div className="form_fields middle"></div>

                        <div className="form_fields middle">
                            <div className="input_cont">
                                <text data_length="xxx-large"><T>text.cuve</T></text>
                                <label radio=""><input name="CuveInox" defaultChecked={!zone4Data.CuveInox} type="radio" value={false} onChange={(e) => onChangeRadio(false)} /><box><check></check></box><text><T>text.emaillee</T></text></label>
                                <label radio=""><input name="CuveInox" defaultChecked={zone4Data.CuveInox} type="radio" value={true} onChange={(e) => onChangeRadio(true)} /><box><check></check></box><text><T>text.inox</T></text></label>
                            </div>
                        </div>
                    </FieldGroup>
                    <FieldGroup>
                        <div className="form_fields middle"></div>
                        <div className="form_fields middle"></div>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="FO_ZONE4_JACKET" name="Jaquette" type="select" valuelist={zone4AllData.Jaquette.enum} value={zone4Data.Jaquette} setData={setZone4Data} formFieldClass="middle" disabled={currentNetwork.brand === "ACV" && zone4Data.CuveInox} />
                    </FieldGroup>
                    <FieldGroup>
                        <div className="form_fields middle"></div>
                        <div className="form_fields middle"></div>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="FO_ZONE4_OPENING" name="Ouverture" type="select" valuelist={zone4AllData.Ouverture.enum} value={zone4Data.Ouverture} setData={setZone4Data} formFieldClass="middle" disabled={currentNetwork.brand === "ACV" && zone4Data.CuveInox} />
                    </FieldGroup>

                </>
                break;
            case SystemCode.BALLONS_ELECTRIQUES:
                const JaquetteBE = <ApplicationFormField id={id} dataLength="xxx-large" startLabel="FO_ZONE4_JACKET" name="Jaquette" type="select" valuelist={zone4AllData.Jaquette.enum} value={zone4Data.Jaquette} setData={setZone4Data} formFieldClass="middle" />;
                const QuveInox = <>
                    <div className="input_cont">
                        <text data_length="xxx-large"><T>text.cuve</T></text>
                        <label radio=""><input name="CuveInox" type="radio" defaultChecked={!zone4Data.CuveInox} onChange={(e) => onChangeRadio(false)} /><box><check></check></box><text><T>text.emaillee</T></text></label>
                        <label radio=""><input name="CuveInox" type="radio" defaultChecked={zone4Data.CuveInox} onChange={(e) => onChangeRadio(true)} /><box><check></check></box><text><T>text.inox</T></text></label>
                    </div>
                </>
                
                fields = <>
                    <FieldGroup>
                        <div className="form_fields middle">
                            <div className="input_cont"><text underline=""><T>text.contraintes_chaufferie_acces_si_connues</T></text>
                                <DataTip title={'text.FO_HELP_SIZE'}>
                                    <div className="button micro info"><icon>info</icon></div>
                                </DataTip>
                            </div>
                        </div>
                        <div className="form_fields middle"><div className="input_cont"><text underline=""><T>text.ballons_electriques</T></text></div></div>
                        <div className="form_fields middle"><div disabled={!calculatedData.Pboucle} className="input_cont"><text underline=""><T>text.rechauffeur_de_boucle</T></text></div></div>
                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.largeur_de_passage" type="number" value={zone4Data.L} setData={setZone4Data} name="L" endLabel="FO_UNIT_MM" formFieldClass="middle" startLabelProps={{ className: "" }} />
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.nombre" name="nbBallon" type="select" valuelist={zone4AllData.nbBallon.enum} value={zone4Data.nbBallon} setData={setZone4Data} formFieldClass="middle" />
                        <div className="form_fields middle">
                            <div className="input_cont">
                                <label classic=""><input disabled={!calculatedData.Pboucle} type="checkbox" name="rechauff" onChange={(e) => {
                                    dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], edited: true } }))
                                    setZone4Data({ ...zone4Data, rechauff: e.target.checked });
                                }} checked={zone4Data.rechauff} /><box><check></check></box><text><T>text.a_inclure_dans_la_solution</T></text></label>
                            </div>
                        </div>

                    </FieldGroup>
                    <FieldGroup>
                        <ApplicationFormField id={id} dataLength="xxx-large" startLabel="text.hauteur_sous_plafond" type="number" value={zone4Data.H} setData={setZone4Data} name="H" endLabel="FO_UNIT_MM" formFieldClass="middle" startLabelProps={{ className: "" }} />
                        <div className="form_fields middle">
                            {currentNetwork.brand === "ACV" ? 
                                zone4AllData.CuveInox ? [QuveInox, JaquetteBE] :
                                 JaquetteBE :
                                 QuveInox
                            }
                        </div>
                        <div className="form_fields middle">
                        </div>
                    </FieldGroup>
                    {currentNetwork.brand !== "ACV" && <FieldGroup>
                        <div className="form_fields middle"></div>
                        {JaquetteBE}
                        <div className="form_fields middle"></div>
                    </FieldGroup>}
                    {/*<FieldGroup>
                        <div className="form_fields middle"></div>

                        <div className="form_fields middle">
                            <div className="input_cont">
                                <text><T>FO_ZONE4_OPENING</T></text>
                                NOTE add input change Arman
                                <label radio=""><input name="Ouverture" defaultChecked={true} type="radio" value={true} /><box><check></check></box><text><T>FO_RES_FLANGE</T></text></label>
                                <label radio=""><input name="Ouverture" type="radio" value={false} /><box><check></check></box><text><T>FO_RES_MANHOLE</T></text></label>
                            </div>
                        </div>

                        <div className="form_fields middle"></div>
                    </FieldGroup>*/}
                </>
                break;
        }
    }



    const openDetails = (data) => {
        dispatch(openPopup({
            title: data.label,
            windowKey: solutionDetailWindowKey,
            fullScreen: false,
            type: PopupTypes.SolutionDetails,
            bodyProps: { ...data, ...dataItem, ...calculatedData, productionMode }
        }));
    };

    const openSolutions = () => {
        setShowGrid(true);
    };

    useEffect(() => {
        if (defaultSolution) {
            setShowGrid(false);
            setSelectedItems([defaultSolution]);
        }
    }, [defaultSolution]);

    /*
     * 
    useEffect(() => {
        debugger
        if (applicationData && applicationData[id] && applicationData[id].edited) {
            zone4Data && solutionsRequest();
        }
    }, [zone4Data]);
    */

    useEffect(() => {
        return () => {
            let _zone = history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1, history.location.pathname.length);// NOTE GrogorM
            if (history.action === "POP" && _zone !== 'document' && _zone !== 'etude') {
                if (system === SystemCode.ECHANGEUR_A_PLAQUES) {
                    history.replace(Project.getPageUrl(AppPages.Applications, [application, subapplication, id, 2]))
                    setSystem(null);
                    setEtudeAllData(item => ({ ...item, system: null}));
                    dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], system: null } }));
                }
                setSolutions(null);
                setMainSolution(null);
                setActiveRowIndex(null);
                setZone4Data(null);
            } else if (_zone === 'document') {
                setIsDocumentOpen(true)
            }
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps




    return <>
        <view filter="" auto_height="" transparent="">
            <wrap>
                <div className="section_group">
                    <number style={sectionHeaderStyle}>4</number>
                    <div className="sections extra">
                        <div className="form_fields">
                            <div className="input_cont title">
                                <text className="" style={sectionHeaderStyle}><b><T>text.autres_criteres_de_selection_de_la_solution_remplissage_facultatif</T></b></text>
                            </div>
                        </div>
                        {zone4AllData && fields}
                    </div>
                </div>
            </wrap>
        </view>
        <view transparent="">
            {solutions && solutions.length > 0 && isAlternativeSolution &&
                <toolbar>
                    <wrap>
                        <action left="">
                            <div className="button primary" onClick={() => {
                                setMainSolution(defaultSolution);
                                setEtudeAllData(item => ({ ...item, mainSolution: defaultSolution }));
                                setIsAlternativeSolution(false);
                            }}>Revenir à la solution préconisée par Atlantic</div>
                        </action>
                    </wrap>
                </toolbar>
            }
            <collapsible fixed="">
                <div className="section_group compact">

                    <div className="form_fields auto_width">
                        <div className="section_title"><text><b><T>text.FO_SOL_SOLUTION</T></b></text></div><div className="form_separator"></div>

                        {mainSolution && (props.userToken !== "guest" && (props.currentNetwork && props.currentNetwork.showPrice)) && //mainSolution.showPrice &&
                            <group><badge filled=""><T>text.FO_DEF_PRICE</T> {Project.formatToNumber(mainSolution.prix)} <T>text.FO_SOL_CURRENCY</T></badge></group>
                        }

                        {mainSolution &&
                            <div className="data_help">
                                {system !== SystemCode.ECHANGEUR_A_PLAQUES &&
                                    <>
                                        <text><span><T>text.FO_SOL_WIDTH</T>: <b>{mainSolution.largeur_mini} <T>text.FO_UNIT_MM</T></b></span></text>
                                        <text><span><T>text.FO_SOL_HEIGHT</T>: <b>{mainSolution.hauteur_min} <T>text.FO_UNIT_MM</T></b></span></text>
                                    </>
                                }
                            </div>
                        }

                    </div>
                </div>


                <div className="section_group">
                    <div className="sections auto">
                        {noSolutionMesssage && <div className="section_title"><text><b><T>{noSolutionMesssage}</T></b></text></div>}
                        {mainSolution && mainSolution.products && mainSolution.products &&
                            <SlickSlider
                                items={mainSolution.products}
                                onItemRender={(s, i) => //NOSONAR
                                    <thumb key={i} >
                                        <wrap horizontal="">
                                            <wrap>
                                                <Picture middle="" url={s.previewUrl ? "'" + s.previewUrl + "'" : '/content/img/icons/picto_pas_de_photo.png'}></Picture>
                                                {/*<picture middle="" style={{ backgroundImage: `url(${s.previewUrl ? "'" + s.previewUrl + "'" : '/content/img/icons/picto_pas_de_photo.png'})`, backgroundSize: (s.type === 'pompes' ? 'contain' : 'cover') }}>*/}

                                                {/*</picture>*/}
                                            </wrap>
                                            {s.previewUrls && s.previewUrls.length > 1 &&
                                                <wrap bottom="">
                                                    <picture small="" style={{ backgroundImage: `url(${s.previewUrls[1] ? "'" + s.previewUrls[1] + "'" : '/content/img/icons/resistance_droite.png'})` }}>
                                                        <action onClick={(e) => {
                                                            openDetails(s)
                                                            /* NOTE: history.push(Project.getPageUrl(AppPages.SolutionDetails, [application, subapplication, AppPages.SolutionDetails]));*/
                                                        }}><a title="Click to more info">&nbsp;</a></action>{// eslint-disable-line jsx-a11y/anchor-is-valid
                                                        }
                                                    </picture>
                                                </wrap>
                                            }
                                        </wrap>
                                        <detail>
                                            <div className="title"><span>{Project.getProductLabel(s, props.culture)}</span></div>
                                        </detail>

                                        <action center="" onClick={(e) => { openDetails(s) }}>
                                            <a className="button primary" title="Click to more info"><text><T>FO_ETUDE_INFO</T></text></a>{/* eslint-disable-line jsx-a11y/anchor-is-valid */}
                                        </action>
                                    </thumb>
                                }
                            >


                            </SlickSlider>
                        }


                    </div>
                </div>
            </collapsible>
            {solutions && !showGrid && <toolbar scroll="" accent="">
                <wrap>
                    <text><T>text.FO_SOL_OTHERSOLUTION</T>, <text underline="" onClick={openSolutions}><a style={{ color: "inherit" }}><i><T>text.FO_READ_MORE</T></i></a></text></text>{/* eslint-disable-line jsx-a11y/anchor-is-valid */}
                </wrap>
            </toolbar>
            }
            {showGrid && solutions &&
                <view transparent="" colorize="">
                    <toolbar compact="" transparent="">

                        <action right=""><button className="button micro" onClick={() => {
                            //setSelectedItems([])
                            setShowGrid(false)
                        }}><icon className="primary">close</icon></button></action>
                    </toolbar>
                    <Table
                        onRow={(record, rowIndex) => { //NOSONAR
                            return {
                                onClick: () => {
                                    setSelectedItems([record]);
                                    setMainSolution(record);
                                    setEtudeAllData(item => ({ ...item, mainSolution: record }));
                                    dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], edited: true } }));
                                }
                            }
                        }}
                        rowSelection={{
                            type: "radio",
                            onChange: (selectedRowKeys, selectedRows) => {
                                setSelectedItems(selectedRows);
                                setMainSolution(selectedRows[0]);
                                setEtudeAllData(item => ({ ...item, mainSolution: selectedRows[0] }));
                            },
                            selectedRowKeys: selectedItems.map((item) => { return mainSolution.key || item.key })
                        }}
                        pagination={false}
                        dataSource={solutions}
                        columns={columns}
                        columns2={columns.map((c, index) => {
                            c.render = (val, t) => { //NOSONAR
                                return <wrap v_align='' key={index} //NOSONAR
                                >
                                    {c.key === "productNames" && <>
                                        {t.products.map((s, idx) => {
                                            return <holder key={idx} //NOSONAR
                                            >
                                                {
                                                    idx === 0 && t.principial && <picture style={{ backgroundImage: "url(/content/img/logo/project.png)" }} micro=""></picture>
                                                }
                                                <text><span>{s.label}</span></text>
                                            </holder>
                                        })}
                                    </>}
                                    {c.key !== "productNames" && <text style={{ whiteSpace: "pre" }}>{val}</text>}

                                </wrap>
                            };
                            return c;
                        })}
                    // rowClassName={(record) => record.className }
                    />
                </view>
            }
        </view>
        {/* <toolbar transparent="">
            <action right="">
                <div
                    className="button primary"
                    onClick={() => { openDocumentDetails() }}
                >Finaliser mon étude</div>
            </action>
        </toolbar> */}
    </>
}

export default connect(state => ({ isLoggedIn: state.isLoggedIn, userToken: state.userToken, currentNetwork: state.networks && state.networks.find(item => item.id === state.network), applicationData: state.applicationData, culture: state.culture }), null)(Zone4);