import React, { useState } from 'react';

import { ValidationInput, ValidationDropDownValueList } from '../Form/validation';

/**
 * used as the template of cell in list view during inline edit
 * @param {any} props contains title, editable, children, dataIndex, record, valueList, maxLength, dropdownStyle, onChangeData, editingRecord, setEditingRecord, required, customRenderer, readOnly
 */
const EditableCell = (props) => { //NOSONAR
    const {
        title,
        editable,
        children,
        dataIndex,
        record,
        valuelist,
        additionalData,
        disableCach,
        maxLength,
        dropdownStyle,
        onChangeData,
        editingRecord,
        setEditingRecord,
        customRenderer,
        readOnly,
        validation,
        ...restProps
    } = props;
    const [editing, setEditing] = useState(false);
    const isRowEditing = editingRecord && editingRecord.ID && editingRecord.ID === record.ID;
    const toggleEdit = () => {
        if (!isRowEditing) {
            setEditing(!editing);

            setEditingRecord({
                ...editingRecord,
                [dataIndex]: record[dataIndex],
            });
        }
    };

    const save = async (e, editMode) => {
        try {
            editMode || toggleEdit();
            onChangeData && onChangeData({
                ...(editingRecord.ID ? editingRecord : record)
            });
        } catch (errInfo) {
        }
    };

    const onChange = (e) => {
        if (isRowEditing) {
            setEditingRecord({ ...editingRecord, [dataIndex]: e.target ? e.target.value : e });
        }
    }

    let childNode = children;
    if (editable || (isRowEditing && !readOnly)) {
        let optionsProps = null;
        if (valuelist) {
            optionsProps = valuelist.map ? { items: valuelist.map((item, i) => ({ text: item.text, value: item.value })) } : { valuelist, additionalData, disableCach };
        }
        childNode = editing || isRowEditing ? (
            <wrap>
                {
                    valuelist ?
                        (
                            <ValidationDropDownValueList
                                validation={validation}
                                name={dataIndex}
                                value={editingRecord[dataIndex]}
                                onBlur={save} //NOSONAR
                                onChange={editable ? (e) => { save(e, true); } : onChange} //NOSONAR
                                {...optionsProps}
                                style={{ width: '100%', height: '100%' }}
                                {...(dropdownStyle ? { dropdownStyle: dropdownStyle } : {})} //NOSONAR
                            />
                        ) : ((customRenderer && customRenderer({ ...props, onChange, editingRecord, setEditingRecord, validation }))
                            || <ValidationInput validation={validation} type='text' value={editingRecord[dataIndex]} onKeyPress={(event) => { //NOSONAR
                                if (event.key === "Enter") {
                                    save()
                                }
                        }} onBlur={save} maxLength={maxLength} name={dataIndex} onChange={onChange} //NOSONAR
                                className={'ant-input'} />
                        )
                }
            </wrap>
        ) : (<wrap onClick={toggleEdit}> <text>{children}</text></wrap>);
    }

    return <td {...restProps} > {childNode}</td >;
};

export default EditableCell;