/* eslint no-eval: 0 */

import { notification } from "antd";
import { useEffect, useCallback, useState } from "react";
import { AppCode, SubAppCode, ExpertModeTypes, /*ProductionMode,*/ SystemCode } from "../project/Defines";
import { deepCopy, isSameObject } from "../project/utilities";
import { showLoader, hideLoader } from "../Shared/Loader";
import store from '../store';
import valeurParDefaut from "../valeurParDefaut.json"
import zone4Defaults from "../zone4Defaults.json"

// eslint-disable-next-line no-unused-vars
const showMessage = (text) => { 
    notification.info({
        message: `Notification`,
        description: text,
        placement: 'top',
        duration: 0,
        key: "engineMessage"
    });
}

const excludeChange = (key, system, value, parentValue) => {
    switch (system) {
        case SystemCode.BALLONS_ECS_TANK_IN_TANK:
        case SystemCode.BALLONS_ECS_PREPARATEURS:
            if (key === "Tp") {
                return !((parentValue === 65 && value === 70) || (parentValue === 70 && (value === 70 || value === 75)) || (parentValue === 75 && value !== 90));
			}
            break;
        case SystemCode.ECHANGEUR_BALLONS_ECS:
            if (key === "Tp") {
                return !(parentValue === 65 && value === 65);
            }
            break;
        default:
            break;
    }

    return false;
}

const isExpertModeField = (field, subApp) => {
    let expertModeFields = [];

    switch (subApp) {
        case SubAppCode.LOGEMENTSPRIVES:
        case SubAppCode.LOGEMENTSSOCIAUX:
            expertModeFields = ["consoT1", "consoT2", "consoT3", "consoT4", "consoT5", "consoT6", "consoBatiment"];
            break;
        case SubAppCode.RESIDENCESSENIORS:
            expertModeFields = ["consoT1", "consoBatiment"];
            break;
        case SubAppCode.RESIDENCESETUDIANTE:
        case SubAppCode.FOYERDEJEUNESTRAVAILLEURS:
            expertModeFields = ["Cjpl", "ECSbyCouv", "tRestau", "Simult"];
            break;
        case SubAppCode.CAMPING:
            expertModeFields = ["PfB", "NbL", "NbBV", "NbBL"];
            break;
        case SubAppCode.AFFAIRES:
        case SubAppCode.MONTAGNE:
        case SubAppCode.TOURISME:
            expertModeFields = ["Cjpc", "ECSbyCouv", "tRestau", "Simult",];
            break;
        case SubAppCode.STADEFOOTBALL:
        case SubAppCode.STADERUGBY:
            expertModeFields = ["TpsEcDch", "TpsTotDch", "QDch"];
            break;
        case SubAppCode.GYMNASE:
            expertModeFields = ["TpsEcDch", "TpsTotDch", "QDch", "TpsSesDch"];
            break;
        case SubAppCode.INTERNAT:
            expertModeFields = ["QDch", "TpsEcDch", "TpsTotDch", "ConsoJourLit"];
            break;
        case SubAppCode.TRADITIONNEL:
        case SubAppCode.GASTRONOMIQUE:
        case SubAppCode.RAPIDE:
        case SubAppCode.COLLECTIF:
            expertModeFields = ["ECSbyRepas", "tRestau"];
            break;
        case SubAppCode.HOPITALCLINIQUEDOUCHES:
        case SubAppCode.EHPAD:
            expertModeFields = ["VJRef"];
            break;
        case SubAppCode.VESTIAIREDENTREPRISE:
            expertModeFields = ["QDch", "TpsEcDch", "TpsTotDch"];
            break;
        case SubAppCode.CASERNE:
            expertModeFields = ["QDch", "TpsEcDch", "TpsTotDch", "PointeModif"];
            break;
        case SubAppCode.STATIONDELAVAGE:
            expertModeFields = ["TpsBrosse", "TpsHp", "TpsRinc", "TpsSSPuis", "NbPuisages"];
            break;
        case SubAppCode.SALLEDEFITNESS:
        case SubAppCode.PISCINE:
            expertModeFields = ["NbPuisages", "TpsAffl", "Affl", "NAffl", "QDch", "TpsEcDch", "TpsTotDch"];
            break;

        // no default
    }

    return expertModeFields.indexOf(field) !== -1
}

export const useEngine = ({ application, subapplication, productionMode, system, data }) => {
 
    const [defaultData, setDefaultData] = useState(null);
    const [calculatedData, setCalculatedData] = useState(null);
    const [allData, setAllData] = useState(null);
    const [expertModeChanged, setExpertModeChanged] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [allowMessages, setAllowMessages] = useState(false);

    const loaderName = 'ENGINE_REQUEST_LOADER';

    const getMinimum = useCallback((_data, key) => {
        let script = "";
        let expression = "";
        let tempData = {};

        if (_data[key].params) {
            //_data[key].params.forEach((p) => { script += `let ${p}=${_data[p].default || 0};` }) 
            _data[key].params.forEach((p) => {
                tempData[p] = (_data[p] && _data[p].default) || 0;
            });

            script = `const {${_data[key].params.join(",")}} = this;`;

            if (_data[key].minimum instanceof Array || _data[key].params.filter((p) => new RegExp(p).test(_data[key].minimum)).length) {
                if (_data[key].minimum instanceof Array) {
                    expression = _data[key].minimum.slice(0, _data[key].minimum.length - 1).join(";");
                    expression += "; return " + _data[key].minimum[_data[key].minimum.length - 1];
                } else {
                    expression = "; return " + _data[key].minimum;
                }

                script += expression;
                // eslint-disable-next-line no-new-func 
                let f = new Function(script); //NOSONAR 
                return Math.round(f.call(tempData));
            }
        }

        return _data[key].minimum;
    }, []);

    const calculate = useCallback((defaults) => { //NOSONAR
        let keys = Object.keys(defaults);
        let _defaultsData = {};
        let tempData = {};

        for (let i = 0; i < keys.length; i++) { //NOSONAR
            let key = keys[i];
            let script = "";
            _defaultsData[key] = { ...defaults[key] };

            switch (typeof _defaultsData[key].default) {
                case "string":
                    if (defaults[key].default === "empty") {
                        _defaultsData[key].default = "";
                    } else {
                        if (_defaultsData[key].params && (_defaultsData[key].params.filter((p) => new RegExp(p).test(_defaultsData[key].default)).length || /minimum|maximum/.test(_defaultsData[key].default))) {
                            let hasMinimumParam = /minimum/.test(_defaultsData[key].default);
                            if (hasMinimumParam) {
                                tempData.minimum = getMinimum(_defaultsData, key);
                            }

                            script = `const {${_defaultsData[key].params.join(",") + (hasMinimumParam ? ", minimum" : '')}} = this; return ${defaults[key].default}`;

                            // eslint-disable-next-line no-new-func 
                            let f = new Function(script); //NOSONAR
                            let val = f.call(tempData);

                            _defaultsData[key].default = val ? Math.round(val) : '';
                        }
                    }
                    break;
                case 'object':
                    let objKey = _defaultsData[_defaultsData[key].keyName].default;
                    if (objKey) {
                        if (_defaultsData[key].params) {
                            let s = null;
                            if (_defaultsData[key].default) {
                                if (typeof _defaultsData[key].default[objKey] === "object") {
                                    if (!_defaultsData[key].default[objKey]) {
                                        s = 0;
                                    } else {
                                        s = _defaultsData[key].default[objKey].default
                                    }
                                } else {
                                    s = _defaultsData[key].default[objKey];
                                }
                            } else {
                                s = 0;
                            }

                            script = `const {${_defaultsData[key].params.join(",")}} = this; return ${s}`;

                            // eslint-disable-next-line no-new-func 
                            let f = new Function(script); //NOSONAR
                            let val = f.call(tempData);
                            _defaultsData[key].default = val ? Math.round(val) : '';
                        } else {
                            if (_defaultsData[key].less) {
                                for (let i = 0; i < _defaultsData[key].less.length; i++) {
                                    if (objKey < _defaultsData[key].less[i]) {
                                        objKey = _defaultsData[key].less[i];
                                        break;
                                    }
                                }
                            }
                            // objKey = 5; piti vercnem _defaultsData[key].default i en keyov@ 
                            let value = _defaultsData[key].default[objKey];

                            if (typeof value === "object") {
                                _defaultsData[key] = _defaultsData[key].default[objKey];
                            } else {
                                _defaultsData[key].default = _defaultsData[key].default[objKey];
							}
                        }
                    } else {
                        _defaultsData[key].default = ""
                    }

                    break;
                case 'undefined':
                    break;
                default:
                    break;
            }

            tempData[key] = _defaultsData[key].default || 0;
        }

        return _defaultsData;
    }, [getMinimum]);

    const getDefaults = useCallback(() => {
        let defaults, _defaultsData;

        if (application) {
            defaults = valeurParDefaut["applications"][application][subapplication];
        }

        if (productionMode) {
            defaults = valeurParDefaut["systems"][productionMode][system];
        }

        _defaultsData = calculate(defaults);

        return _defaultsData;
    }, [application, subapplication, productionMode, system, calculate]);

    /* NOTE:
        const getMaximum = useCallback((_data, key) => { //NOSONAR
        let script = "";
        if (_data[key].params) {
            _data[key].params.forEach((p) => { script += `let ${p}=${_data[p].default || 0};` });

            if (_data[key].maximum instanceof Array || _data[key].params.filter((p) => new RegExp(p).test(_data[key].maximum)).length) {
                if (_data[key].maximum instanceof Array) {
                    script += _data[key].maximum.join(";");
                } else {
                    script += _data[key].maximum;
                }
                return Math.round(eval(script));
            }
        }

        return _data[key].maximum;
    }, []);*/ 

    const calculateMinMax = useCallback((_data, originalJson) => { //NOSONAR
        let _isValid = true;
        let newData = deepCopy(_data);

        let keys = Object.keys(_data);
        for (let i = 0; i < keys.length; i++) { //NOSONAR
            let key = keys[i];
            let script = "", expression = "";

            let tempData = {};
            let paramsArr = _data[key].params || [];
            let minMaxParamArr = _data[key].minMaxParams || [];

            if (typeof _data[key].minimum === "object" && !(_data[key].minimum instanceof Array)) {
                if (originalJson) {
                    newData[key].default = originalJson[key].default;
                    let fieldDefault = calculate(newData)[key].default;
                    tempData._default = fieldDefault || 0;
                    _data[key].minimum = _data[key].minimum.default;
                }
            }

            if (typeof _data[key].maximum === "object" && !(_data[key].maximum instanceof Array)) {
                if (originalJson) {
                    newData[key].default = originalJson[key].default;
                    let fieldDefault = calculate(newData)[key].default;
                    tempData._default = fieldDefault || 0;
                    _data[key].maximum = _data[key].maximum.default;
                }
            }

            if (paramsArr.length || minMaxParamArr.length || Object.keys(tempData).length) {
                paramsArr.forEach((p) => {
                    tempData[p] = _data[p].default || 0;
                    //params += `let ${p}=${_data[p].default || 0};`
                });

                minMaxParamArr.forEach((p) => {
                    tempData[`${p}Min`] = _data[p].minimum || 0;
                    tempData[`${p}Max`] = _data[p].maximum || 0;
                    //params += `let ${p}Min=${_data[p].minimum || 0}; let ${p}Max=${_data[p].maximum || 0};`
                });

                let allKeys = Object.keys(tempData);

                if (_data[key].minimum && ((_data[key].minimum instanceof Array || allKeys.filter((p) => new RegExp(p).test(_data[key].minimum)).length))) {
                    //script = params
                    script = `const {${Object.keys(tempData).join(",")}} = this;`;

                    if (_data[key].minimum instanceof Array) {
                        //script += _data[key].minimum.join(";")
                        expression = _data[key].minimum.slice(0, _data[key].minimum.length - 1).join(";");
                        expression += "; return " + _data[key].minimum[_data[key].minimum.length - 1];
                    } else {
                        //script += _data[key].minimum
                        expression = "; return " + _data[key].minimum;
                    }
                    script += expression;

                    // eslint-disable-next-line no-new-func 
                    let f = new Function(script); //NOSONAR
                    _data[key].minimum = Math.round(f.call(tempData));

                    if (_data[key].default && _data[key].default < _data[key].minimum && _isValid) {
                        _isValid = false;
                        setIsValid(false);
                    }
                } else if (_data[key].default && _data[key].default && _data[key].minimum && _isValid) { //NOSONAR
                    if (_data[key].default < _data[key].minimum) {
                        _isValid = false;
                        setIsValid(false);
                    }
                }

                if (_data[key].maximum && ((_data[key].maximum instanceof Array || allKeys.filter((p) => new RegExp(p).test(_data[key].maximum)).length) || minMaxParamArr.length)) {
                    //script = params
                    script = `const {${Object.keys(tempData).join(",")}} = this;`;

                    if (_data[key].maximum instanceof Array) {
                        expression = _data[key].maximum.slice(0, _data[key].maximum.length - 1).join(";");
                        expression += "; return " + _data[key].maximum[_data[key].maximum.length - 1];
                        //script += _data[key].maximum.join(";")
                    } else {
                        //script += _data[key].maximum
                        expression = "; return " + _data[key].maximum;
                    }

                    script += expression;

                    // eslint-disable-next-line no-new-func 
                    let f = new Function(script); //NOSONAR
                    _data[key].maximum = Math.round(f.call(tempData));
                    //_data[key].maximum = Math.round(eval(script))

                    if (_data[key].default > _data[key].maximum && _isValid) {
                        _isValid = false;
                        setIsValid(false);
                    }
                } else if (_data[key].maximum) {
                    if (_data[key].default && _data[key].default > _data[key].maximum && _isValid) {
                        _isValid = false;
                        setIsValid(false);
                    }
                }
            } else {
                if (_data[key].default && ((_data[key].maximum && _data[key].default > _data[key].maximum) || (_data[key].minimum && _data[key].default < _data[key].minimum)) && _isValid) {
                     _isValid = false;
                    setIsValid(false);
				}
			}
        }

        return _data;

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const mapDefaultsToData = useCallback((defaults) => {
        let d = {};
        Object.keys(defaults).map(key => d[key] = defaults[key].default); //NOSONAR

        return d;
    }, []);

    const isFieldChanged = useCallback((newData, oldData, field) => {
        return newData[field] !== oldData[field];
    }, [])

    useEffect(() => {
        if (SubAppCode.isValid(subapplication) || (system && SystemCode.isValid(system))) {
            const defaults = getDefaults();
            setDefaultData(defaults);

            if (!data || !Object.keys(data).length) {
                /*NOTE: Arman must be delete
                setCalculatedData(mapDefaultsToData(defaults));
                setAllData(calculateMinMax(defaults));*/
            } else {
                if (data.Pboucle || data.Pboucle === 0) {
                    defaults.Pboucle.default = data.Pboucle;
                }
            }

            let originalJson = null;

            if (application && subapplication) {
                originalJson = deepCopy(valeurParDefaut["applications"][application][subapplication]);
            }

            setCalculatedData(mapDefaultsToData(defaults));
            setAllData(calculateMinMax(defaults, originalJson));
        }
    }, [subapplication, system, calculateMinMax, mapDefaultsToData, getDefaults]);// eslint-disable-line react-hooks/exhaustive-deps

    const getDefaultOptions = (_defaults, _field, _data) => {
        let text;
        let defaultOptions = {};

        if (typeof _defaults[_field].default === "object") {
            let objKey = _data[_defaults[_field].keyName];
            if (_defaults[_field].less) {
                for (let i = 0; i < _defaults[_field].less.length; i++) {
                    if (objKey < _defaults[_field].less[i]) {
                        objKey = _defaults[_field].less[i];
                        break;
                    }
                }
            }

            let d = _defaults[_field].default[objKey];


            if (typeof d === "object") {
                text = d.default;

                defaultOptions = d;
            } else {
                text = d;
                defaultOptions.default = d;
            }
        } else {
            text = _defaults[_field].default;
            defaultOptions.default = text;
        }

        defaultOptions.defaultContainsParam = !!(_defaults[_field].params && _defaults[_field].params.filter((p) => new RegExp(p).test(text)).length) || new RegExp("minimum").test(text); //NOSONAR

        return defaultOptions;

        /*return !!_defaults[_field].params.filter((p) => new RegExp(p).test(text)).length*/ /*NOSONAR*/
	}

    useEffect(() => { //NOSONAR
        if (!SubAppCode.isValid(subapplication) && system === undefined) {
            !data && setCalculatedData({});
        } else {

                if (data) {
                    if (!isSameObject(data, calculatedData)) {
                        let _data = { ...data };
                        let defaults, keys, _defaultsData; //, newData;
                        let changedExpertModeField = null;

                        setIsValid(true);

                        if (application) {
                            setAllowMessages(true);
                            defaults = deepCopy(valeurParDefaut["applications"][application][subapplication]);
                            let originalJson = deepCopy(valeurParDefaut["applications"][application][subapplication]);
                            keys = Object.keys(defaults);


                            for (let i = 0; i < keys.length; i++) { //NOSONAR
                                let key = keys[i];

                                if (data.resetExpertMode && isExpertModeField(key, subapplication)) {

                                    setExpertModeChanged(false);
                                    continue;
                                }

                                let defaultOptions = getDefaultOptions(defaults, key, data);
                                if (data[key] === calculatedData[key]) {
                                    if (defaults[key].params && defaultOptions.defaultContainsParam) {//stugel ete params uni u peramsic inch vor mek@ defaulti hashvarknerum ka
                                        if (!defaults[key].params.filter(p => new RegExp(p).test(defaultOptions.default) && isFieldChanged(_data, calculatedData, p)).length && (!defaults[key].keyName || data[defaults[key].keyName] === calculatedData[defaults[key].keyName])) {
                                            defaults[key].default = data[key];//defaultOptions.enum && (defaults[key] = defaultOptions)
                                        }
                                    } else {
                                        if (defaults[key].keyName) {
                                            if (data[defaults[key].keyName] === calculatedData[defaults[key].keyName]) {
                                                defaults[key].default = data[key];
                                                defaultOptions.enum && (defaults[key].enum = defaultOptions.enum);
                                            } else {
                                                let objKey = data[defaults[key].keyName];

                                                if (defaults[key].less) {
                                                    for (let i = 0; i < defaults[key].less.length; i++) {
                                                        if (objKey < defaults[key].less[i]) {
                                                            objKey = defaults[key].less[i];
                                                            break;
                                                        }
                                                    }
                                                }

                                                let _d = defaults[key].default[objKey];

                                                if (typeof _d === "object") {
                                                    defaults[key] = _d;
                                                } else {
                                                    if (_d) {
                                                        defaults[key].default = _d;
                                                    } else {
                                                        defaults[key].default = null;
                                                    }
                                                    //ete default@ objecta u tvyal key ov arjeq chka ban chenq anum
                                                }
                                            }
                                        } else {
                                            defaults[key].default = data[key];
                                        }
                                    }
                                } else {
                                    if (isExpertModeField(key, subapplication)) {
                                        setExpertModeChanged(true);
                                        changedExpertModeField = key;
                                    }

                                    /* NOTE:
                                     * if (defaults[key].keyName) { //NOSONAR
                                       defaults[key] = defaults[key].default[data[defaults[key].keyName]];
                                       defaults[key].default = data[key];
                                    } else {
                                    }*/
                                    defaults[key].default = data[key];
                                    defaultOptions.enum && (defaults[key].enum = defaultOptions.enum);
                                }
                            }
                            _defaultsData = calculate(defaults, data, calculatedData);
                            setCalculatedData(mapDefaultsToData(_defaultsData));
                            setAllData(calculateMinMax(_defaultsData, originalJson));

                            if (changedExpertModeField) {
                                let defaultsOriginal = getDefaults();
                                // eslint-disable-next-line eqeqeq 
                                if (_defaultsData[changedExpertModeField].default == defaultsOriginal[changedExpertModeField].default) {
                                    setExpertModeChanged(false);
                                }
                            }

                        }

                        if (productionMode) {
                            defaults = deepCopy(valeurParDefaut["systems"][productionMode][system]);
                            keys = Object.keys(defaults);

                            for (let i = 0; i < keys.length; i++) { //NOSONAR
                                let key = keys[i];
                                let defaultOptions = getDefaultOptions(defaults, key, data);
                                if (data[key] === calculatedData[key]) {

                                    if (defaults[key].params && defaultOptions.defaultContainsParam) {//stugel ete params uni u peramsic inch vor mek@ defaulti hashvarknerum ka
                                        if (!defaults[key].params.filter(p => isFieldChanged(_data, calculatedData, p)).length && (!defaults[key].keyName || data[defaults[key].keyName] === calculatedData[defaults[key].keyName])) {
                                            defaults[key].default = data[key];//defaultOptions.enum && (defaults[key] = defaultOptions)
                                        }
                                    } else {
                                        if (defaults[key].keyName) {
                                            if (data[defaults[key].keyName] === calculatedData[defaults[key].keyName] || excludeChange(key, system, data[key], data[defaults[key].keyName])) {
                                                defaults[key].default = data[key];
                                                defaultOptions.enum && (defaults[key].enum = defaultOptions.enum);
                                            } else {
                                                let _d = defaults[key].default[data[defaults[key].keyName]];

                                                if (typeof _d === "object") {
                                                    defaults[key] = _d;
                                                } else {
                                                    if (_d) {
                                                        defaults[key].default = _d;
                                                    } else {
                                                        defaults[key].default = null;
                                                    }
                                                    //ete default@ objecta u tvyal key ov arjeq chka ban chenq anum
                                                }
                                            }
                                        } else {
                                            defaults[key].default = data[key];
                                        }
                                    }
                                } else {
                                    defaults[key].default = data[key];
                                    defaultOptions.enum && (defaults[key].enum = defaultOptions.enum);
                                }
                            }

                            _defaultsData = calculate(defaults, data, calculatedData)
                            setCalculatedData(mapDefaultsToData(_defaultsData))
                            setAllData(calculateMinMax(_defaultsData))
                        }
                    }
                } else {
                    if (SubAppCode.isValid(subapplication)) {
                        let originalJson = deepCopy(valeurParDefaut["applications"][application][subapplication]);
                        const defaults = getDefaults()
                        setDefaultData(defaults);
                        setCalculatedData(mapDefaultsToData(defaults))
                        setAllData(calculateMinMax(defaults, originalJson));
                    }
                }
            }
    }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

    const sendRequest = (data, url, success) => {
        const controller = new AbortController();
        const { signal } = controller;

        showLoader(loaderName);
        
        /* NOTE:
         * let url = "https://app-aquamed-moteur-dev.azurewebsites.net/ecs";
        switch (application) {
           case AppCode.LOGEMENTS:
               url += `/calcul/logement/${subapplication}/etape1`;
               break;
           case AppCode.CAMPING:
               url += "/calcul/camping/etape1";
               break;
           case AppCode.HOTEL:
               url += `/calcul/hotel/${path}`;
               break;
           case AppCode.ETABLISSEMENTSSPORTIFS:
               url += `/calcul/sport/${subapplication}/etape1`
               break;

        }*/ //NOSONAR

        const params = [];

        Object.keys(data).forEach(key => params.push(key + "=" + (data[key] === false ? false : (data[key] || 0))));
        if (params.length > 0) {
            url += "?" + params.join("&");

            if (!data.TECS) {
                url += "&TECS=60"
			}
        }

        let token = store.getState().userData.accessToken;
        fetch(url, {
            signal,
            headers: {
                'Authorization': 'Bearer ' + token
            }
            }).then((Response) => {
            return Response.json()
        })
            .catch(function (error) {
                console.log(error);
                hideLoader(loaderName);
            }).then((result) => {
                hideLoader(loaderName);
                success(result);
            })

        return controller;
    }

    return { defaultData, calculatedData, allData, setAllData, sendRequest, expertModeChanged, setExpertModeChanged, isValid, setIsValid, setCalculatedData, allowMessages, setAllowMessages }
}

export const getUrl = (application, subapplication, etap, path, productionMode, system) => {
    let url = process.env.REACT_APP_MOTEUR_API_URL ?? "https://app-aquamed-moteur-dev.azurewebsites.net/ecs";

    switch (application) {
        case AppCode.LOGEMENTS:
            url += `/calcul/logement/${subapplication}/etape1` + (etap || "");
            break;
        case AppCode.CAMPING:
            url += "/calcul/camping/etape1" + (etap || "");
            break;
        case AppCode.HOTEL:
            url += `/calcul/hotel/${path}` + (etap || "");
            break;
        case AppCode.ETABLISSEMENTSSPORTIFS:
            url += `/calcul/sport/${subapplication}/etape1` + (etap || "");
            break;
        case AppCode.RESTAURANT:
            url += `/calcul/restaurant/${subapplication}/etape1` + (etap || "");
            break;
        case AppCode.CASERNE:
            url += `/calcul/${subapplication}/etape1` + (etap || "");
            break;
        case AppCode.SANTE:
            url += `/calcul/sante/${subapplication}/etape1` + (etap || "");
            break;
        case AppCode.AUTRETERTIAIRE:
            url += `/calcul/autre_tertiaire/${subapplication}/etape1` + (etap || "");
            break;
        case AppCode.ETABLISSEMENTSSCOLAIRES:
            url += `/calcul/etablissement_scolaires/${subapplication}/etape1` + (etap || "");
            break;
        case AppCode.PROCESSUSINDUSTRIEL:
            url += `/calcul/indus/etape1` + (etap || "");
            break;
        default:
            url += (path || "");
            break;
    }

    return url;
}

export const requestData = (data, app, subApp, etap, expertModeType) => {
    const normalizedData = { ...data };
    let path = null;

    // eslint-disable-next-line default-case
    switch (subApp) {
        case SubAppCode.LOGEMENTSPRIVES:
        case SubAppCode.LOGEMENTSSOCIAUX:
            if (ExpertModeTypes.APPARTEMENT === expertModeType || !expertModeType) {
                delete normalizedData["consoBatiment"];
            } else {
                ["consoT1", "consoT2", "consoT3", "consoT4", "consoT5", "consoT6"].map((item) => { //NOSONAR
                    delete normalizedData[item];
                    return null;
                })
            }
            break
        case SubAppCode.RESIDENCESSENIORS:
            if (ExpertModeTypes.LOGEMENT === expertModeType || !expertModeType) {
                delete normalizedData["consoBatiment"];
            } else {
                delete normalizedData["consoT1"];
            }
            ["tr"].map((item) => { //NOSONAR
                delete normalizedData[item];
                return null;
            })
            break
        case SubAppCode.AFFAIRES:
        case SubAppCode.MONTAGNE:
        case SubAppCode.TOURISME:
            path = `${data.code}/etape1`;
            delete normalizedData.code;
            break;
        case SubAppCode.CAMPING:
            normalizedData.bdm = normalizedData.bdm === "oui" ? "true" : "false";
            break;
        case SubAppCode.INTERNAT:
            break;
        case SubAppCode.CASERNE:
        case SubAppCode.VESTIAIREDENTREPRISE:
            if (!normalizedData.TpsActSport) {
                delete normalizedData.TpsActSport;
            }
            break;
    }

    return {
        normalizedData: normalizedData,
        url: getUrl(app, subApp, etap, path)
    }
}

export const getZon4Defaults = (system, network, params) => {
    if (!network || !zone4Defaults[network]) {
        network = "default";
    }

    let defaults = deepCopy(zone4Defaults[network][system]);
    let allData = { ...defaults };

    let keys = Object.keys(defaults);

    for (let i = 0; i < keys.length; i++) { //NOSONAR
        let key = keys[i];

        if (typeof defaults[key] === "object") {
            defaults[key] = defaults[key].default;
		}

        if (/^this/.test(defaults[key])) {
            // eslint-disable-next-line no-new-func 
            let f = new Function(`return ${defaults[key]}`); //NOSONAR
            defaults[key] = f.call(params);// eval(defaults[key])
        }
    }

    return { defaults, allData };
}