import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { T } from '../components/Translations';
import { AppPages } from '../project/Defines.js';
import { Project } from '../project/Project.js';
import { Popover } from 'antd';


/**
 * the Home page
 * @exports Home
 */
function Home(props) {
    const { history } = props;
    const [applications /*, setApplications*/] = useState([]);
    const [, setRecharcherOpen] = useState(false);
    const [searchEtudeData, setSearchEtudeData] = useState('');

    useEffect(() => {
        if (props.userToken && props.userToken === "guest") {
            history.push(Project.getPageUrl(AppPages.Applications))
        }
    }, [props.userToken]) // eslint-disable-line react-hooks/exhaustive-deps
        
    return <>
        <view transparent="" home="">
            <view intro="" transparent="">
                <login>
                    <login_cont>
                        <wrap id="container" multiple="multiple">
                            <blur>
                                <text description=""><T>text.FO_HOME_TITLE</T></text>
                                <div holder="" center="">
                                    <navigate_item className="invert">
                                        <wrap>
                                            {
                                                <div className="popover">
                                                    <div className="navigate hover" style={{ marginLeft: "-5px" }}
                                                        onClick={() => history.push(Project.getPageUrl(AppPages.Etude, searchEtudeData))}
                                                    >
                                                        <icon>search</icon>
                                                        <Link to={`${Project.getPageUrl(AppPages.Etude, searchEtudeData)}`}><text><T>text.FO_HOME_MYSTUDIES</T></text></Link>
                                                    </div>
                                                    <div className="popover-menu navigate">
                                                        <div className="form_fields">
                                                            <input
                                                                style={{backgroundColor: '#1e2b44'}}
                                                                type="text"
                                                                placeholder="Search"
                                                                onChange={(e) => {
                                                                    setSearchEtudeData(e.target.value);
                                                                }}
                                                                onKeyPress={(e) => {
                                                                    if (e?.key === "Enter") {
                                                                        setRecharcherOpen(false);
                                                                        history.push(Project.getPageUrl(AppPages.Etude, searchEtudeData));
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                1>2 && <Popover placement="left" content={<div className="form_fields">
                                                    <input
                                                        type="text"
                                                        placeholder="Search"
                                                        onChange={(e) => {
                                                            setSearchEtudeData(e.target.value);
                                                        }}
                                                        onKeyPress={(e) => {
                                                            if (e?.key === "Enter") {
                                                                setRecharcherOpen(false);
                                                                history.push(Project.getPageUrl(AppPages.Etude, searchEtudeData));
                                                            }
                                                        }}
                                                    /></div>}>
                                                    <div className="navigate hover" style={{ marginLeft: "-5px" }}>
                                                        <icon>search</icon>
                                                        <Link to={`${Project.getPageUrl(AppPages.Etude, searchEtudeData)}`}><text><T>text.FO_HOME_MYSTUDIES</T></text></Link>
                                                    </div>
                                                </Popover>
                                            }
                                        </wrap>
                                    </navigate_item>
                                    <navigate_item className="invert">
                                        <wrap>{applications &&
                                            <div>
                                                <div 
                                                    className="navigate hover"
                                                    onClick={() => history.push(Project.getPageUrl(AppPages.Applications))}
                                                >
                                                    <icon>plus</icon>
                                                    <Link to={Project.getPageUrl(AppPages.Applications)} replace={true} >
                                                        <text><T>text.FRONT_NEW_STUDY</T></text>
                                                    </Link>
                                                </div>
                                                
                                            </div>
                                        }
                                        </wrap>
                                    </navigate_item>
                                </div>
                            </blur>
                        </wrap>
                    </login_cont>
                </login>
            </view>
        </view>
        <back style={{ backgroundImage: "url(/content/img/homenew2.jpg)" }}></back>
    </>;
}

export default connect(state => ({ isLoggedIn: state.isLoggedIn, networks: state.networks, network: state.network, userToken: state.userToken }), null)(Home);