import React, { useState } from 'react';
import { connect } from 'react-redux';
import { T } from '../../../../components/Translations';
import { ProductionMode, ProductTypes } from '../../../../project/Defines';

function SolutionDetails(props) {
    const { productionMode } = props;
    const [dataItem] = useState({ ...props });

    const renderSwitch = () => { //NOSONAR
        // eslint-disable-next-line default-case
        switch (dataItem.type) {
            case ProductTypes.DHW_tank:
                return (
                    !dataItem.merged ?
                        <tbody>
                            <tr>
                                <th><wrap left=""><text><T>text.FO_INFO_MODEL</T></text></wrap></th>
                                <td>
                                    <wrap left="">
                                        <ul point="">
                                            <li><text><T>text.cuve</T></text></li>
                                            <li><text><T>text.FO_INFO_CONFIG</T></text></li>
                                            <li><text><T>text.FO_INFO_OPENING</T></text></li>
                                            <li><text><T>text.FO_INFO_JACKET</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                                <td>
                                    <wrap left="">
                                        <ul>
                                            <li><text>{(props.stockage_inox ? <T>text.FO_INFO_LINING_INOX</T> : <T>text.FO_INFO_ENAMEL</T>) || "-"}</text></li>
                                            <li><text>{(props.stockECS_tb ? <T>text.FO_INFO_TB</T> : <T>text.FO_INFO_STANDARD</T>) || "-"}</text></li>
                                            <li><text>{(props.stockECS_type_bride_TH === "BR" && <T>text.FO_INFO_HANDHOLE</T>) || (props.stockECS_type_bride_TH === "TH" && <T>text.FO_INFO_MANHOLE</T>) || "-"}</text></li>
                                            <li><text>{props.stockage_jaquette ? <T>{"text.FO_INFO_" + props.stockage_jaquette}</T> : "-"}</text></li>
                                        </ul>
                                    </wrap>
                                </td>
                            </tr>
                            <tr>
                                <th><wrap left=""><text><T>text.FO_INFO_REF</T></text></wrap></th>
                                <td>
                                    <wrap left="">
                                        <ul point="">
                                            <li><text><T>text.ballon_ecs</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                                <td>
                                    <wrap left="">
                                        <ul>
                                            <li><text>{props.sku}</text></li>
                                        </ul>
                                    </wrap>
                                </td>
                            </tr>
                            <tr>
                                <th><wrap left=""><text><T>text.FO_INFO_TECH_FEATURES_BIS</T></text></wrap></th>
                                <td style={{ verticalAlign: 'top' }}>
                                    <wrap left="">
                                        <ul point="">
                                            <li><text><T>text.FO_INFO_REAL_CAPACITY</T></text></li>
                                            <li><text><T>text.FO_INFO_OP_PRESSURE</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                                <td>
                                    <wrap left="">
                                        <ul>
                                            <li><text>{props.stockage_capacite_utile || "-"} <T>text.FO_UNIT_L</T></text></li>
                                            <li><text>{props.stockECS_pression_service || "-"} <T>FO_UNIT_BAR</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                            </tr>
                            <tr>
                                <th><wrap left=""><text><T>text.FO_INFO_DIMENSIONS</T></text></wrap></th>
                                <td>
                                    <wrap left="">
                                        <ul point="">
                                            <li><text><T>text.FO_INFO_DIAMETER</T></text></li>
                                            <li><text><T>text.FO_INFO_MIN_LENGTH</T></text></li>
                                            <li><text><T>text.FO_INFO_HEIGHT</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                                <td>
                                    <wrap left="">
                                        <ul>
                                            <li><text>{props.stockage_diametre_hors_tout || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                            <li><text>{props.stockage_largeur_passage || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                            <li><text>{props.stockECS_hauteur_hors_tout || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                            </tr>
                            <tr>
                                <th> <wrap left=""><text><T>text.FO_INFO_WEIGHT</T></text></wrap></th>
                                <td>
                                    <wrap left="">
                                        <ul point="">
                                            <li><text><T>text.FO_INFO_UNLOADED</T></text></li>
                                            <li><text><T>text.FO_INFO_WEIGHT_LOADED</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                                <td>
                                    <wrap left="">
                                        <ul>
                                            <li><text>{props.stockage_poids_a_vide || "-"} <T>text.FO_UNIT_KG</T></text></li>
                                            <li><text>{props.stockage_poids_en_service || "-"} <T>text.FO_UNIT_KG</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                            </tr>
                            <tr>
                                <th><wrap left=""><text><T>text.FO_INFO_OPTIONS</T></text></wrap></th>
                                <td colSpan="2">
                                    <wrap left="">
                                        <ul point="">
                                            <li><text><T>{props.options}</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                            </tr>
                        </tbody> :
                        <tbody>
                            <tr>
                                <th><wrap left=""><text><T>text.FO_INFO_MODEL</T></text></wrap></th>
                                <td>
                                    <wrap left="">
                                        <ul point="">
                                            <li><text><T>text.cuve</T></text></li>
                                            <li><text><T>text.FO_INFO_CONFIG</T></text></li>
                                            <li><text><T>text.FO_INFO_OPENING</T></text></li>
                                            <li><text><T>text.FO_INFO_JACKET</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                                <td>
                                    <wrap left="">
                                        <ul>
                                            <li><text>{(props.stockage_inox ? <T>text.FO_INFO_LINING_INOX</T> : <T>text.FO_INFO_ENAMEL</T>) || "-"}</text></li>
                                            <li><text>{(props.stockECS_tb ? <T>text.FO_INFO_TB</T> : <T>text.FO_INFO_STANDARD</T>) || "-"}</text></li>
                                            <li><text>{(props.stockECS_type_bride_TH === "BR" && <T>text.FO_INFO_HANDHOLE</T>) || (props.stockECS_type_bride_TH === "TH" && <T>text.FO_INFO_MANHOLE</T>) || "-"}</text></li>
                                            <li><text>{props.stockage_jaquette ? <T>{"text.FO_INFO_" + props.stockage_jaquette}</T> : "-"}</text></li>
                                        </ul>
                                    </wrap>
                                </td>
                            </tr>
                            <tr>
                                <th><wrap left=""><text><T>text.FO_INFO_RESISTANCE</T></text></wrap></th>
                                <td>
                                    <wrap left="">
                                        <ul point="">
                                            <li><text><T>text.type</T></text></li>
                                            <li><text><T>text.FO_INFO_TENSION</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                                <td>
                                    <wrap left="">
                                        <ul>
                                            <li><text>{(props.subProduct.resistance_type ? <T>{props.subProduct.resistance_type}</T> : "-")}</text></li>
                                            <li><text>{props.subProduct.resistance_tension_nature || "-"}</text></li>
                                        </ul>
                                    </wrap>
                                </td>
                            </tr>
                            <tr>
                                <th><wrap left=""><text><T>text.FO_INFO_REF</T></text></wrap></th>
                                <td>
                                    <wrap left="">
                                        <ul point="">
                                            <li><text><T>text.ballons_ecs</T></text></li>
                                            <li><text><T>text.FO_INFO_RESISTANCE</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                                <td>
                                    <wrap left="">
                                        <ul>
                                            <li><text>{props.sku || "-"}</text></li>
                                            <li><text>{props.subProduct.sku || "-"}</text></li>
                                        </ul>
                                    </wrap>
                                </td>
                            </tr>
                            <tr>
                                <th><wrap left=""><text><T>text.FO_INFO_TECH_FEATURES_BIS</T></text></wrap></th>
                                <td>
                                    <wrap left="">
                                        <ul point="">
                                            <li><text><T>text.FO_INFO_HEATED_VOLUME</T></text></li>
                                            <li><text><T>text.FO_INFO_OP_PRESSURE</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                                <td>
                                    <wrap left="">
                                        <ul>
                                            <li><text>{props.subProduct.resistance_droite_courbe === "COURBE" ? props.stockECS_volume_ECS_chauffe_coudee : props.stockECS_volume_ECS_chauffe} <T>text.FO_UNIT_L</T></text></li>
                                            <li><text>{props.stockECS_pression_service || "-"} <T>text.FO_UNIT_BAR</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                            </tr>
                            <tr>
                                <th><wrap left=""><text><T>text.FO_INFO_DIMENSIONS</T></text></wrap></th>
                                <td>
                                    <wrap left="">
                                        <ul point="">
                                            <li><text><T>text.FO_INFO_OVERALL_DIAMETER</T></text></li>
                                            <li><text><T>text.FO_INFO_MIN_LENGTH</T></text></li>
                                            <li><text><T>text.FO_INFO_HEIGHT_WITH_EXTENSION</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                                <td>
                                    <wrap left="">
                                        <ul>
                                            <li><text>{props.stockage_diametre_hors_tout || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                            <li><text>{props.stockage_largeur_passage || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                            <li><text>{props.stockECS_hauteur_hors_tout || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                            </tr>
                            <tr>
                                <th> <wrap left=""><text><T>text.FO_INFO_WEIGHT</T></text></wrap></th>
                                <td>
                                    <wrap left="">
                                        <ul point="">
                                            <li><text><T>text.FO_INFO_UNLOADED</T></text></li>
                                            <li><text><T>text.FO_INFO_WEIGHT_LOADED</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                                <td>
                                    <wrap left="">
                                        <ul>
                                            <li><text>{props.stockage_poids_a_vide || "-"} <T>text.FO_UNIT_KG</T></text></li>
                                            <li><text>{props.stockage_poids_en_service || "-"} <T>text.FO_UNIT_KG</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                            </tr>
                            <tr>
                                <th><wrap left=""><text><T>text.FO_INFO_OPTIONS</T></text></wrap></th>
                                <td colSpan="2">
                                    <wrap left="">
                                        <ul point="">
                                            <li><text><T>{props.options}</T></text></li>
                                        </ul>
                                    </wrap>
                                </td>
                            </tr>
                        </tbody>);
            case ProductTypes.Primary_tank:
                return (
                    <tbody>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_MODEL</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_TANK</T></text></li>
                                        <li><text><T>text.FO_INFO_SIZE</T></text></li>
                                        <li><text><T>text.FO_ZONE4_JACKET</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{(props.stockage_inox ? <T>text.FO_INFO_STEEL</T> : <T>text.FO_INFO_STAINLESS_STEEL</T>) || "-"}</text></li>
                                        <li><text>{(props.stockBallPrim_tb ? <T>text.FO_INFO_TB</T> : <T>text.FO_INFO_STANDARD</T>) || "-"}</text></li>
                                        <li><text>{props.stockage_jaquette || "-"}</text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_REF</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.ballon_ecs</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.sku}</text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_TECH_FEATURES_BIS</T></text></wrap></th>
                            <td style={{ verticalAlign: 'top' }}>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_USEFUL_VOLUME</T></text></li>
                                        <li><text><T>text.FO_INFO_OP_PRESSURE</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.stockage_capacite_utile} <T>text.FO_UNIT_L</T></text></li>
                                        <li><text>{props.stockBallPrim_pression_service || "-"} <T>text.FO_UNIT_BAR</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_DIMENSIONS</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_OVERALL_DIAMETER</T></text></li>
                                        <li><text><T>text.FO_INFO_DIAMTER_WITHOUT_JACKET</T></text></li>
                                        <li><text><T>text.FO_SOL_WIDTH</T></text></li>
                                        <li><text><T>text.FO_INFO_TILTING_DIMENSION</T></text></li>
                                        <li><text><T>FO_INFO_HEIGHT_WITH_EXTENSION</T></text></li>
                                        <li><text><T>text.FO_INFO_HEIGHT</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.stockage_diametre_hors_tout || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        <li><text>{props.stockage_diametre_cuve_sans_ja || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        <li><text>{props.stockage_largeur_passage || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        <li><text>{props.stockage_cote_basculement || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        <li><text>{props.stockBallPrim_height || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        <li><text>{props.stockage_hauteur_mini_local || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th> <wrap left=""><text><T>text.FO_INFO_WEIGHT</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_UNLOADED</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.stockage_poids_a_vide || "-"} <T>text.FO_UNIT_KG</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_OPTIONS</T></text></wrap></th>
                            <td colSpan="2">
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>{props.options}</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                    </tbody>);
            case ProductTypes.Preparation_tank:
                return (
                    <tbody>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_MODEL</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.cuve</T></text></li>
                                        <li><text><T>text.FO_INFO_COIL_MATERIAL</T></text></li>
                                        <li><text><T>text.FO_INFO_OPENING</T></text></li>
                                        <li><text><T>text.FO_INFO_JACKET</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{(props.stockage_inox ? <T>text.FO_INFO_LINING_INOX</T> : <T>text.FO_INFO_ENAMEL</T>) || "-"}</text></li>
                                        <li><text>{props.stockBallPrep_coil_material ? <T>{props.stockBallPrep_coil_material}</T> : "-"}</text></li>
                                        <li><text>{(props.stockBallPrep_type_bride_TH === "BR" && <T>text.FO_INFO_HANDHOLE</T>) || (props.stockBallPrep_type_bride_TH === "TH" && <T>text.FO_INFO_MANHOLE</T>) || "-"}</text></li>
                                        <li><text>{props.stockage_jaquette ? <T>{"text.FO_INFO_" + props.stockage_jaquette}</T> : "-"}</text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_REF</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.ballons_ecs_preparateurs</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.sku}</text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_TECH_FEATURES</T></text></wrap></th>
                            <td style={{ verticalAlign: 'top' }}>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_REAL_CAPACITY</T></text></li>
                                        <li><text><T>text.FO_INFO_POWER</T></text></li>
                                        <li><text><T>text.FO_INFO_PRIMARY_FLOW_RATE</T></text></li>
                                        <li><text><T>text.FO_INFO_PRESSURE_LOSS</T></text></li>
                                        <li><text><T>text.FO_INFO_OR_PRESSURE</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.stockage_capacite_utile} <T>text.FO_UNIT_L</T></text></li>
                                        <li><text>{props.puissanc !== 0 ? props.puissance : "-"} <T>text.FO_UNIT_KW</T></text></li>
                                        <li><text>{props.debit !== 0 ? props.debit : "-"} <T>text.FO_UNIT_M3H</T></text></li>
                                        <li><text>{props.pdc !== 0 ? props.pdc : "-"} <T>text.FO_UNIT_MBAR</T></text></li>
                                        <li><text>{props.stockBallPrep_pression_service || "-"} <T>text.FO_UNIT_BAR</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_DIMENSIONS</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_DIAMETER</T></text></li>
                                        <li><text><T>text.FO_INFO_MIN_LENGTH </T></text></li>
                                        <li><text><T>text.FO_INFO_HEIGHT</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.stockage_diametre_hors_tout || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        <li><text>{props.stockage_largeur_passage || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        <li><text>{props.stockBallPrep_hauteur_hors_tout || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th> <wrap left=""><text><T>text.FO_INFO_WEIGHT</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_UNLOADED</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.stockage_poids_a_vide || "-"} <T>text.FO_UNIT_KG</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_OPTIONS</T></text></wrap></th>
                            <td colSpan="2">
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>{props.options}</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <wrap left="">
                                    <ul>
                                        <li><text><T args={[props.Tp, props.TEFS, props.TECS]}>FO_INFO_ASTERISK</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                    </tbody>);
            case ProductTypes.Tank_in_tank:
                return (
                    <tbody>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_MODEL</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.cuve</T></text></li>
                                        <li><text><T>text.FO_INFO_OPENING</T></text></li>
                                        <li><text><T>text.FO_INFO_JACKET</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{(props.stockage_inox ? <T>text.FO_INFO_LINING_INOX</T> : <T>text.FO_INFO_ENAMEL</T>) || "-"} </text></li>
                                        <li><text>{(props.stockTinT_type_bride_TH === "BR" && <T>text.FO_INFO_HANDHOLE</T>) || (props.stockTinT_type_bride_TH === "TH" && <T>text.FO_INFO_MANHOLE</T>) || "-"}</text></li>
                                        <li><text>{props.stockage_jaquette ? <T>{"text.FO_INFO_" + props.stockage_jaquette}</T> : "-"}</text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_REF</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.ballons_ecs_preparateurs</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.sku}</text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_TECH_FEATURES</T></text></wrap></th>
                            <td style={{ verticalAlign: 'top' }}>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_REAL_CAPACITY</T></text></li>
                                        <li><text><T>text.FO_INFO_POWER</T></text></li>
                                        <li><text><T>text.FO_INFO_PRIMARY_FLOW_RATE</T></text></li>
                                        <li><text><T>text.FO_INFO_PRESSURE_LOSS</T></text></li>
                                        <li><text><T>text.FO_INFO_OR_PRESSURE</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.stockage_capacite_utile || "-"} <T>text.FO_UNIT_L</T></text></li>
                                        <li><text>{props.puissance !== 0 ? props.puissance : ""} <T>text.FO_UNIT_KW</T></text></li>
                                        <li><text>{props.debit !== 0 ? props.debit : ""} <T>text.FO_UNIT_M3H</T></text></li>
                                        <li><text>{props.pdc !== 0 ? props.pdc : ""} <T>text.FO_UNIT_MBAR</T></text></li>
                                        <li><text>{props.stockTinT_pression_service || "-"} <T>text.FO_UNIT_BAR</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_DIMENSIONS</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_DIAMETER</T></text></li>
                                        <li><text><T>text.FO_INFO_MIN_LENGTH </T></text></li>
                                        <li><text><T>text.FO_INFO_HEIGHT</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.stockage_diametre_hors_tout || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        <li><text>{props.stockage_largeur_passage || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        <li><text>{props.stockTinT_hauteur_hors_tout || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th> <wrap left=""><text><T>text.FO_INFO_WEIGHT</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_UNLOADED</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.stockage_poids_a_vide || "-"} <T>text.FO_UNIT_KG</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_OPTIONS</T></text></wrap></th>
                            <td colSpan="2">
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>{props.options}</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <wrap left="">
                                    <ul>
                                        <li><text><T args={[props.Tp, props.TEFS, props.TECS]}>FO_INFO_ASTERISK</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                    </tbody>);
            case ProductTypes.Plates_Exchanger:
                return (
                    <tbody>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_MODEL</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="" style={{ marginTop: "5px" }}>
                                        <li style={productionMode === ProductionMode.SISA ? { marginBottom: "20px" } : {}}><text><T>text.FO_INFO_PUMP</T></text></li>
                                        <li><text><T>text.FO_INFO_GASKET</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text><T>text.FO_INFO_PRIMARY</T> {(props.exchange_nb_primaire_circ === 1 && <T>text.FO_INFO_SIMPLE</T>) || (props.exchange_nb_primaire_circ === 2 && <T>text.FO_INFO_DOUBLE</T>)}</text></li>
                                        {productionMode === ProductionMode.SISA && <li><text> <T>text.FO_INFO_SECONDARY</T> {(props.exchange_nb_secondaire_circ === 1 && <T>text.FO_INFO_SIMPLE</T>) || (props.exchange_nb_secondaire_circ === 2 && <T>text.FO_INFO_DOUBLE</T>)}</text></li>}
                                        <li><text><T>{props.exchange_type_joint || "-"}</T> <T>text.FO_INFO_EPDM</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_REF</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_EXCHANGE</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.sku}</text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_TECH_FEATURES</T></text></wrap></th>
                            <td style={{ verticalAlign: 'top' }}>
                                <wrap left="">
                                    <ul point="" style={{ marginTop: "5px" }}>
                                        <li><text><T>text.FO_INFO_POWER</T></text></li>
                                        <li><text><T>text.FO_INFO_CIRCULATOR_FLOW</T></text></li>
                                        <li style={productionMode === ProductionMode.SISA ? { marginBottom: "30px", paddingTop: "35px" } : {}}><text><T>text.FO_INFO_AVAI_HMT</T></text></li>
                                        <li><text><T>text.FO_INFO_OP_PRESSURE</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.puissancePrimaire} <T>text.FO_UNIT_KW</T></text></li>
                                        <li><text><T>text.FO_INFO_PRIMARY</T> {props.debitPrimaire} <T>text.FO_UNIT_M3H</T></text></li>
                                        {productionMode === ProductionMode.SISA && <li style={{ marginBottom: "10px" }}><text><T>text.FO_INFO_SECONDARY</T> {props.debitSecondaire} <T>text.FO_UNIT_M3H</T></text></li>}
                                        <li><text><T>text.FO_INFO_PRIMARY</T> {props.HMTPrimaire} <T>text.FO_UNIT_MCE</T></text></li>
                                        {productionMode === ProductionMode.SISA && <li style={{ marginBottom: "10px" }}><text><T>text.FO_INFO_SECONDARY</T> {props.HMTSecondaire} <T>text.FO_UNIT_MCE</T></text></li>}
                                        <li><text><T>text.FO_INFO_PRIMARY</T> {props.exchange_service_pressure_prim || "-"} <T>text.FO_UNIT_BAR</T></text></li>
                                        <li><text><T>text.FO_INFO_SECONDARY</T> {props.exchange_service_pressure_sec || "-"} <T>text.FO_UNIT_BAR</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_DIMENSIONS</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_WIDTH</T></text></li>
                                        <li><text><T>text.FO_INFO_DEPTH</T></text></li>
                                        <li><text><T>text.FO_INFO_HEIGHT</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.exchange_width || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        <li><text>{props.exchange_depth || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        <li><text>{props.exchange_height || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th> <wrap left=""><text><T>text.FO_INFO_WEIGHT</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_UNLOADED</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.exchange_poids_a_vide || "-"} <T>text.FO_UNIT_KG</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_OPTIONS</T></text></wrap></th>
                            <td colSpan="2">
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>{props.options}</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <wrap left="">
                                    <ul>
                                        <li><text><T args={[props.Tp, props.TEFS, props.TECS]}>FO_INFO_ASTERISK</T></text></li>
                                        {/*<li><text>(*) Avec températures: primaire = {props.Tp} °C, eau frode = {props.TEFS} °C, sortie production = {props.TECS} °C </text></li>*/}
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                    </tbody>
                );
            case ProductTypes.Pump:
                return (<tbody>
                    <tr>
                        <th><wrap left=""><text><T>text.FO_INFO_MODEL</T></text></wrap></th>
                        <td>
                            <wrap left="">
                                <ul point="">
                                    <li><text><T>text.FO_INFO_PUMP_HYGIA</T></text></li>
                                    <li><text><T>text.FO_INFO_PUMP_BRAND</T></text></li>
                                </ul>
                            </wrap>
                        </td>
                        <td>
                            <wrap left="">
                                <ul>
                                    <li><text>{(props.pompe_simple ? <T>text.FO_INFO_SIMPLE</T> : <T>text.FO_INFO_DOUBLE</T>) || "-"}</text>
                                    </li>
                                    <li><text><T>{props.pompe_marque !== "" ? props.pompe_marque : "-"}</T></text></li>
                                </ul>
                            </wrap>
                        </td>
                    </tr>
                    <tr>
                        <th><wrap left=""><text><T>text.FO_INFO_REF</T></text></wrap></th>
                        <td>
                            <wrap left="">
                                <ul point="">
                                    <li><text><T>text.FO_POMPES</T></text></li>
                                </ul>
                            </wrap>
                        </td>
                        <td>
                            <wrap left="">
                                <ul>
                                    <li><text>{props.sku}</text></li>
                                </ul>
                            </wrap>
                        </td>
                    </tr>
                    <tr>
                        <th><wrap left=""><text><T>text.FO_INFO_TECH_FEATURES_BIS</T></text></wrap></th>
                        <td style={{ verticalAlign: 'top' }}>
                            <wrap left="">
                                <ul point="">
                                    <li><text><T>text.FO_INFO_MAXIMUM_FLOW</T></text></li>
                                </ul>
                            </wrap>
                        </td>
                        <td>
                            <wrap left="">
                                <ul>
                                    <li><text> {props.pompe_debit_maxi || "-"} <T>text.FO_UNIT_M3H</T></text></li>
                                </ul>
                            </wrap>
                        </td>
                    </tr>
                </tbody>);
            case ProductTypes.HeatMaster_WaterMaster:
                return (
                    <tbody>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_MODEL</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.cuve</T></text></li>
                                        <li><text><T>text.FO_INFO_GAS_TYPE</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{(props.WMHM_inox ? <T>text.FO_INFO_LINING_INOX</T> : <T>text.FO_INFO_ENAMEL</T>) || "-"}</text></li>
                                        <li><text><T>{props.WMHM_type_gaz !== "" ? props.WMHM_type_gaz : "-"}</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_REF</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_ACC_GAS</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.sku}</text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_TECH_FEATURES</T></text></wrap></th>
                            <td style={{ verticalAlign: 'top' }}>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_REAL_CAPACITY</T></text></li>
                                        <li><text><T>text.FO_INFO_POWER</T></text></li>
                                        <li><text><T>text.FO_INFO_OP_PRESSURE</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.WMHM_capacite_utile || "-"} <T>text.FO_UNIT_L</T></text></li>
                                        <li><text>{props.WMHM_puissance_utile || "-"} <T>text.FO_UNIT_KW</T></text></li>
                                        <li><text>{props.WMHM_pression_max_sanitaire || "-"} <T>text.FO_UNIT_BAR</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_DIMENSIONS</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_LENGTH</T></text></li>
                                        <li><text><T>text.FO_INFO_DEPTH</T></text></li>
                                        <li><text><T>text.FO_INFO_HEIGHT</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.WMHM_largeur_produit || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        <li><text>{props.WMHM_profondeur_produit || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        <li><text>{props.WMHM_hauteur_hors_tout || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th> <wrap left=""><text><T>text.FO_INFO_WEIGHT</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_UNLOADED</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.WMHM_poids_a_vide || "-"} <T>text.FO_UNIT_KG</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_OPTIONS</T></text></wrap></th>
                            <td colSpan="2">
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>{props.options}</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <wrap left="">
                                    <ul>
                                        <li><text><T>text.FO_INFO_HEATING_EXCLUDED</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                    </tbody>
                );
            case ProductTypes.Sanigaz:
                return (
                    <tbody>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_MODEL</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.cuve</T></text></li>
                                        <li><text><T>text.FO_INFO_GAS_TYPE</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{(props.accgaz_type_cuve ? <T>text.FO_INFO_LINING_INOX</T> : <T>text.FO_INFO_ENAMEL</T>) || "-"}</text></li>
                                        <li><text><T>{props.accgaz_type_gaz !== "" ? props.accgaz_type_gaz : "-"}</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_REF</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_ACC_GAS</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.sku}</text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_TECH_FEATURES_BIS</T></text></wrap></th>
                            <td style={{ verticalAlign: 'top' }}>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_REAL_CAPACITY</T></text></li>
                                        <li><text><T>text.FO_INFO_POWER</T></text></li>
                                        <li><text><T>text.FO_INFO_OP_PRESSURE</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.accgaz_capacite_nominale || "-"} <T>text.FO_UNIT_L</T></text></li>
                                        <li><text>{props.accgaz_puissance_utile || "-"} <T>text.FO_UNIT_KW</T></text></li>
                                        <li><text>{props.accgaz_pression_service || "-"} <T>text.FO_UNIT_BAR</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_DIMENSIONS</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_DIAMETER</T></text></li>
                                        <li><text><T>text.FO_INFO_HEIGHT</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.accgaz_diametre_hors_tout || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        <li><text>{props.accgaz_hauteur_hors_tout || "-"} <T>text.FO_UNIT_MM</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th> <wrap left=""><text><T>text.FO_INFO_WEIGHT</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_UNLOADED</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.accgaz_poids_a_vide || "-"} <T>text.FO_UNIT_KG</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_OPTIONS</T></text></wrap></th>
                            <td colSpan="2">
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>{props.options}</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                    </tbody>
                );
            case ProductTypes.RSB:
                return (
                    <tbody>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_MODEL</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.cuve</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text><T>{props.RSB_revetement !== "" ? props.RSB_revetement : "-"}</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_RESISTANCE</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_POWER</T></text></li>
                                        <li><text><T>text.type</T></text></li>
                                        <li><text><T>text.FO_INFO_TENSION</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.RSB_puissance ? props.RSB_puissance : "-"} <T>text.FO_UNIT_KW</T></text></li>
                                        <li><text><T>{props.RSB_type !== "" ? props.RSB_type : "-"}</T></text></li>
                                        <li><text><T>{props.RSB_tension !== "" ? props.RSB_tension : "-"}</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_REF</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.rechauffeur</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.sku}</text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_TECH_FEATURES_BIS</T></text></wrap></th>
                            <td style={{ verticalAlign: 'top' }}>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_CAPACITY</T></text></li>
                                        <li><text><T>text.FO_INFO_OP_PRESSURE</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.stockage_capacite_utile || "-"} <T>text.FO_UNIT_L</T></text></li>
                                        <li><text>{props.RSB_pression_service ? props.RSB_pression_service : "-"} <T>text.FO_UNIT_BAR</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th><wrap left=""><text><T>text.FO_INFO_DIMENSIONS</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_OVERALL_LENGTH</T></text></li>
                                        <li><text><T>text.FO_INFO_DIAMETER</T></text></li>
                                        <li><text><T>text.FO_INFO_HEIGHT</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.RSB_width ? props.RSB_width : "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        <li><text>{props.RSB_depth ? props.RSB_depth : "-"} <T>text.FO_UNIT_MM</T></text></li>
                                        <li><text>{props.RSB_height ? props.RSB_height : "-"} <T>text.FO_UNIT_MM</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                        <tr>
                            <th> <wrap left=""><text><T>text.FO_INFO_WEIGHT</T></text></wrap></th>
                            <td>
                                <wrap left="">
                                    <ul point="">
                                        <li><text><T>text.FO_INFO_UNLOADED</T></text></li>
                                        <li><text><T>text.FO_INFO_WEIGHT_LOADED</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                            <td>
                                <wrap left="">
                                    <ul>
                                        <li><text>{props.RSB_poids_a_vide ? props.RSB_poids_a_vide : "-"} <T>text.FO_UNIT_KG</T></text></li>
                                        <li><text>{props.RSB_poids_en_service ? props.RSB_poids_en_service : "-"} <T>text.FO_UNIT_KG</T></text></li>
                                    </ul>
                                </wrap>
                            </td>
                        </tr>
                    </tbody>
                );
        }
    };

    return <>
        <view transparent="">
            <view intro="" transparent="" space="">
                <div className="table_view colorize">
                    <table>
                        <colgroup>
                            <col style={{ width: "170px" }} />
                            <col style={{ width: "250px" }} />
                            <col style={{ width: "250px" }} />
                        </colgroup>

                        {renderSwitch()}

                    </table>
                </div>
            </view>
        </view>
    </>;
}

export default connect(state => ({ isLoggedIn: state.isLoggedIn }), null)(SolutionDetails);