import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import { message } from 'antd';

import { ApiUrl, AppPages, Defines } from '../../project/Defines.js';
import { Project } from '../../project/Project.js';

import { Ajax } from '../../components/Ajax';
import { T } from '../../components/Translations';
import { PopupTypes } from '../../components/Popup';

import useDetail from '../../Shared/useDetail';
import DetailView from '../../Shared/DetailView';
import { showLoader, hideLoader } from "../../Shared/Loader";

import { openPopup, setApplicationData } from '../../store/actions/index.js';
import ShareDocumentPopup from './ShareDocumentPopup';
import { Picture } from '../../Shared/Picture/index';

import moment from 'moment';
import i18n from '../../Shared/Translation/i18n.js';

// const loaderName = 'PDF_LOADER';
const shareLoaderName = 'SHARE_LOADER';

function DocumentDetails(props) {
    const history = useHistory();
    const { id } = useParams(false);
    const { system, solutions, mainSolution, /*allData,*/ application, subapplication, setIsDocumentOpen, isDocumentOpen, setEtudeDataModel, etudeDataModel, userToken, currentNetwork, networkId, applicationData, setBreadcrumbItems, subApplicationsList } = props;
    const [productLabels, setProductLabels] = useState();
    const [products, setProducts] = useState();
    const [pdfUrl, setPdfUrl] = useState();
    const [isChecked, setIsChecked] = useState(currentNetwork && currentNetwork.showPrice ? ((applicationData && applicationData[id] && typeof applicationData[id].isChecked === 'boolean' ? applicationData[id].isChecked : true)) : false);
    const dispatch = useDispatch();

    const { isNew, dataItem, setDataItem, updateData, hasChanges, originalDataItem, onChangeData, /*onChangeData, onDetailDelete, hasChanges, closePopup,*/  detailRef /* validation, isLoading, loadData*/ } = useDetail({
        data: {},
        updateUrl: ApiUrl.EtudeUpdate,
        validationFields: ['siteName', 'siteCP', 'siteCity', 'clientName']
        // validationFields: ['siteName', 'siteAdress', 'clientName']
    });

    useEffect(() => {
        setEtudeDataModel({ ...dataItem });
        dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], etudeDataModel: { ...etudeDataModel, ...dataItem } } }));
    }, [onChangeData]) // eslint-disable-line react-hooks/exhaustive-deps
   
    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);

            changePath(response);
        }
    }, [setDataItem, detailRef, history]); // eslint-disable-line react-hooks/exhaustive-deps

    const changePath = useCallback((response) => {
        if (detailRef.current) {
            if (id.length === 36 || id !== response.ID) {
                let basePath = history.location.pathname;
                let newPath = basePath.replace(id, response.ID);
                history.replace(newPath);
                setBreadcrumbItems([
                    { name: 'Home', url: Project.getPageUrl(AppPages.Home), label: <T>text.FO_ARIANE_HOME</T> },
                    { name: 'Etude', url: Project.getPageUrl(AppPages.Applications), label: <T>text.FO_ARIANE_STUDY</T> },
                    { name: subApplicationsList[0].appLabel, url: Project.getPageUrl(AppPages.Applications, [application, subapplication, response.ID, 4]), label: <T>{`text.${subApplicationsList[0].appLabel}`}</T> },
                    { name: 'Document', label: <T>text.FO_ARIANE_DOCUMENT</T> }
                ]);
            }
            dispatch(setApplicationData({ ...applicationData, [response.ID]: { ...applicationData[id], etudeDataModel: response,  edited: false } }));
        }
    }, [id, history, detailRef, setApplicationData, applicationData])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { //NOSONAR
        
        if (isDocumentOpen) {
          
            //let isNew = id && (typeof (id) === "string" && (id.length === 36 || id === "create"));
            //if (!etudeDataModel || (etudeDataModel && !etudeDataModel.numero) || (id && !isNew)) {
                //let _id = !isNew ? id : 0;

                //if (!isNew) {
                //    showLoader(loaderName);
                //    Ajax.post({
                //        url: ApiUrl.EtudeUpdateJson,
                //        data: { id, jsonData: JSON.stringify(allData) },
                //        success: function (response) {
                //            const _response = { ...response, version: response.version };
                //            setDataItem(_response);
                //            setEtudeDataModel(_response);
                //            hideLoader(loaderName);
                //            changePath(response);
                //        }
                //    })
                //} else {
                //    Ajax.get({
                //        url: ApiUrl.EtudeGetData,
                //        data: { id: _id },
                //        success: function (response) {
                //            if (response) {
                //                const _response = { ...response, ...etudeDataModel };
                //                _response.jsonData = JSON.stringify(allData);
                //                setDataItem(_response);
                //                setEtudeDataModel(_response);
                //                //changePath(response);
                //            } else {
                //                message.error('API error');
                //            }
                //        },
                //        error: function () {
                //            message.error('API error');
                //        }
                //    })
                //}
            //} else {
            //    setDataItem(etudeDataModel);
            //}
            applicationData[id]?.etudeDataModel && setDataItem({ ...applicationData[id]?.etudeDataModel});
            dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], edited: false } }));
        }
    }, [isDocumentOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!mainSolution) {
            return;
        }
        let _products = [];
        let _productNames = [];
        let _productCounts = [];

        for (const element of mainSolution.products) {
            let _productName = Project.getProductLabel(element, props.culture);
            let _productIndex = _productNames.indexOf(_productName);
            if (_productIndex === -1) {
                _products.push(element);
                _productNames.push(_productName);
                _productCounts.push(0);
                _productIndex = _productNames.indexOf(_productName);
            }
            _productCounts[_productIndex]++;
        }
        let _productLabels = [];
        for (const _productName of _productNames) {
            const _productIndex = _productNames.indexOf(_productName);
            _productLabels.push(_productCounts[_productIndex] + "x" + _productName);
        }
        setProductLabels(_productLabels);
        setProducts(_products);
    }, [solutions, mainSolution, props.culture]);

    const resetData = () => {
        setDataItem(originalDataItem, false);
    }

    const backDocument = () => {
        history.push(Project.getPageUrl("applications", [application, subapplication, id, 4]));
        setIsDocumentOpen(false);
    }


    const radioChange = useCallback(e => {
        setIsChecked(e.target.checked);
        dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], isChecked: e.target.checked } }));
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    // const downloadPDF = useCallback(() => {
    //     if (dataItem) {
    //         showLoader(loaderName);
    //         Ajax.download({
    //             url: ApiUrl.EtudePdf,
    //             target: '_blank',
    //             // fileName: `${}.pdf`,
    //             autoDownload: true,
    //             data: {
    //                 etudes: dataItem.ID === 0 ? "" : id,
    //                 showPrice: isChecked,
    //                 dataItem: dataItem,
    //                 isEtudeDetail: true,
    //                 networkId: networkId
    //                 // isShare: isShare ? true : false,
    //             },
    //             success: function (response) {
    //                 hideLoader(loaderName);
    //             },
    //             error: function (response) {
    //                 message.error(response.message);
    //                 hideLoader(loaderName);
    //             }
    //         });
    //     }
    // }, [id, isChecked, dataItem]);

    // console.log(dataItem?.ID, id, networkId);

    const openShareDocumentPopup = () => {
        showLoader(shareLoaderName);
        Ajax.post({
            url: ApiUrl.EtudePdfUrl,
            data: {
                etudes: dataItem.ID === 0 ? "" : id,
                showPrice: isChecked,
                dataItem: dataItem,
                isEtudeDetail: true,
                _networkId: networkId
            },
            success: function (response) {
                hideLoader(shareLoaderName);
                if (response) {
                    console.log(response);
                    setPdfUrl(response);
                }
            },
            error: function (response) {
                hideLoader(shareLoaderName);
                message.error(response.message);
            }
        });
    };

    useEffect(() => {
        if (pdfUrl) {
            dispatch(
                openPopup({
                    component: ShareDocumentPopup,
                    title: <T>text.FO_DOC_SHARE</T>,
                    fullScreen: false,
                    type: PopupTypes.Email,
                    bodyProps: { fileData: pdfUrl }
                })
            );
        }
    }, [pdfUrl]); // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        {dataItem && <DetailView
            hasChanges={hasChanges}
            showPopup={true}
            showWindowCont={false}
        >
            <view vertical="" form="" transparent="">
                <view transparent="" s_middle="">
                    <div className="section_group">
                        <div className="sections extra">
                            <div className="form_fields">
                                <div className="data_label"><text data-size="x-large"><T>text.FO_DOC_ETUDE_DETAILS</T></text></div>
                            </div>
                            <div className="form_fields">
                                <div className="data_label title"><text data-size="large"><T>text.FO_DOC_PROJET_CHANTIER</T></text></div>
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_REFERENCE</T></b></text>
                                    <icon pen=""></icon>
                                    <input type="text" name="reference" value={dataItem?.reference} onChange={onChangeData} maxLength="20" />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className={"input_cont " + (!dataItem?.siteName ? "required" : "")}>
                                    <text label=""><b><T>text.FO_DOC_SITENAME</T></b>(*)</text>
                                    <icon pen=""></icon>
                                    <input required="" type="text" name="siteName" value={dataItem?.siteName} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_SITEADRESS</T></b></text>
                                    <icon pen=""></icon>
                                    <input type="text" name="siteAdress" value={dataItem?.siteAdress} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className={"input_cont " + (!dataItem?.siteCP ? "required" : "")}>
                                    <text label=""><b><T>text.FO_DOC_SITECP</T></b>(*)</text>
                                    <icon pen=""></icon>
                                    <input required="" type="text" name="siteCP" value={dataItem?.siteCP} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className={"input_cont " + (!dataItem?.siteCity ? "required" : "")}>
                                    <text label=""><b><T>text.FO_DOC_SITECITY</T></b>(*)</text>
                                    <icon pen=""></icon>
                                    <input required="" type="text" name="siteCity" value={dataItem?.siteCity} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>

                            <div className="form_fields">
                                <div className="data_label title"><text data-size="large"><T>text.FO_DOC_CLIENT</T></text></div>
                                <div className={"input_cont " + (!dataItem?.clientName ? "required" : "")}>
                                    <text label=""><b><T>text.FO_DOC_CLIENTNAME</T></b>(*)</text>
                                    <icon pen=""></icon>
                                    <input required="" type="text" name="clientName" value={dataItem?.clientName} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_CLIENTCONTACT</T></b></text>
                                    <icon pen=""></icon>
                                    <input type="text" name="clientContact" value={dataItem?.clientContact} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_CLIENTADRESS</T></b></text>
                                    <icon pen=""></icon>
                                    <input type="text" name="clientAdress" value={dataItem?.clientAdress} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_CLIENTCP</T></b></text>
                                    <icon pen=""></icon>
                                    <input type="text" name="clientCP" value={dataItem?.clientCP} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_CLIENTCITY</T></b></text>
                                    <icon pen=""></icon>
                                    <input type="text" name="clientCity" value={dataItem?.clientCity} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_CLIENTCMAIL</T></b></text>
                                    <icon pen=""></icon>
                                    <input type="text" name="clientMail" value={dataItem?.clientMail} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_CLIENTPHONENUMBER</T></b></text>
                                    <icon pen=""></icon>
                                    <input type="text" name="clientPhoneNumber" value={dataItem?.clientPhoneNumber} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>

                            <div className="form_fields">
                                <div className="data_label title"><text data-size="large"><T>text.FO_DOC_ETUDE</T></text></div>
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_ID</T></b></text>
                                    <icon></icon>
                                    <input type="text" name="numero" value={dataItem?.numero} disabled={true} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_VERSION</T></b></text>
                                    <icon></icon>
                                    <input type="text" name="version" value={dataItem?.version} disabled={true} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_DATE</T></b></text>
                                    <icon></icon>
                                    <input
                                        type="text"
                                        name="date"
                                        value={moment(isNew ? dataItem?.created_at : dataItem?.updated_at).format(Defines.Format.PrintDate)}
                                        disabled={true}
                                    />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_SYSTEMETUIDE</T></b></text>
                                    <icon></icon>
                                    <input type="text" name="system" value={system} disabled={true} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont top">
                                    <text label=""><b><T>text.FO_DOC_SOLUTION</T></b></text>
                                    <icon></icon>
                                    <text wrap="" className="data_red">{
                                        productLabels && productLabels.map((l, index) => {
                                            return <>{l}<br /></>
                                        })
                                    }</text>
                                    <i></i>
                                </div>
                            </div>

                            <div className="form_fields"><div className="data_label"><span>(*) champs obligatoires</span></div></div>

                            <div className="form_fields">
                                <div className="data_label"><T>text.FO_DOC_DEFINITION</T></div>
                                <textarea compact="" name="definition" value={dataItem?.definition} onChange={onChangeData}></textarea>
                            </div>
                        </div>
                    </div>
                    <toolbar transparent="">
                        <wrap>
                            <div
                                effect='material'
                                onClick={() => backDocument()}
                                className="button primary normal">
                                <icon>left</icon><text><T>text.FO_DOC_PREVIOUS</T></text>
                            </div>
                            <div
                                onClick={(e) => resetData()}
                                className='button primary normal '>
                                <icon>undo</icon><text><T>text.FO_DOC_REINITIALISER</T></text>
                            </div>
                            {userToken !== "guest" &&

                                <div
                                    onClick={(e) => updateData(updateSuccessCallback)}
                                    className='button primary normal '>
                                    <icon>save</icon><text><T>text.FO_DOC_SAVE</T></text>
                                </div>
                            }
                        </wrap>
                    </toolbar>
                </view>
                <view transparent="">
                    <div className="section_group compact">
                        <div className="sections extra">
                            <div className="form_fields"><div className="data_label"><text data-size="x-large"><T>text.FO_DOC_NOTEDECALCUL</T></text></div></div>
                            <div className="form_fields">
                                <div className="input_cont ">
                                    {currentNetwork && currentNetwork.showPrice && <wrap auto="">
                                        <label checkbox="" large="">
                                            <input
                                                name="show_price"
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={radioChange}
                                            />
                                            <box><check></check></box>
                                            <text wrap=""><T>text.FO_DOC_PRICE</T></text>
                                        </label>
                                    </wrap>}
                                    <wrap>
                                        <toolbar vertical="" transparent="">
                                            <wrap>
                                                {/* <div
                                                    // onClick={() => downloadPDF()}
                                                    disabled={!dataItem.siteName || dataItem.siteName === '' || 
                                                              !dataItem.siteCP || dataItem.siteCP === '' || 
                                                              !dataItem.siteCity || dataItem.siteCity === '' || 
                                                              !dataItem.clientName || dataItem.clientName === '' ? true : false}
                                                    className="button primary extra"
                                                >
                                                    <a href={process.env.REACT_APP_API_URL + ApiUrl.EtudePdf + "?etudes=" + (dataItem.ID === 0 ? "" : id) + "&showPrice=" + isChecked + "&isEtudeDetail=" + true + "&networkId=" + networkId} target="_blank"><text left=""><T>text.FO_DOC_UPLOAD</T></text></a>
                                                </div> */}
                                                <form
                                                    action={process.env.REACT_APP_API_URL + ApiUrl.EtudePdf}
                                                    // onSubmit={downloadPDF}
                                                    method="post"
                                                    target='_blank'
                                                >
                                                    <div
                                                        disabled={!dataItem.siteName || dataItem.siteName === '' || 
                                                            !dataItem.siteCP || dataItem.siteCP === '' || 
                                                            !dataItem.siteCity || dataItem.siteCity === '' || 
                                                            !dataItem.clientName || dataItem.clientName === '' ? true : false}
                                                        className="button primary extra"
                                                    >
                                                        <input type="hidden" name='user-Token' value={props.userToken} />
                                                        <input type="hidden" name='etudes' value={dataItem.ID === 0 ? "" : id} />
                                                        <input type="hidden" name='showPrice' value={isChecked} />
                                                        <input type="hidden" name="isEtudeDetail" value={true} />
                                                        <input type="hidden" name="networkId" value={networkId} />
                                                        <input type="hidden" name="culture" value={props.culture} />
                                                        <input type="hidden" name="_json" value={JSON.stringify(dataItem)} />
                                                        <input 
                                                            type="submit" 
                                                            value={i18n('text.FO_DOC_UPLOAD')} 
                                                            data-weight="700"
                                                            //  style={{ minWidth: "100%", backgroundColor: "transparent", cursor: "pointer", fontWeight: 700}}    
                                                        />
                                                        {/* <text lrft=""><T>text.FO_DOC_UPLOAD</T></text> */}
                                                    </div>
                                                </form>
                                                
                                                <div
                                                    onClick={() => openShareDocumentPopup()}
                                                    disabled={!dataItem.siteName || dataItem.siteName === '' || 
                                                              !dataItem.siteCP || dataItem.siteCP === '' || 
                                                              !dataItem.siteCity || dataItem.siteCity === '' || 
                                                              !dataItem.clientName || dataItem.clientName === '' ? true : false}
                                                    className="button primary extra">
                                                    <text lrft=""><T>text.FO_DOC_SHARE</T></text>
                                                </div>
                                            </wrap>
                                        </toolbar>
                                    </wrap>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section_group compact">
                        <div className="sections extra">
                            <div className="form_fields"><div className="data_label"><text data-size="x-large"><T>text.FO_DOC_DOCUMENTATION</T></text></div></div>
                            <div className="section_group no_flex ">
                                {products && products.map((s, index) => {
                                    return <div className="sections auto" key={index} //NOSONAR
                                    >
                                        <div className="form_fields">
                                            <div className="data_label title"><text>{(productLabels ? productLabels[index] : "")}</text></div>
                                            <div className="input_cont list">
                                                <Picture url={s.previewUrl ? "'" + s.previewUrl + "'" : '/content/img/icons/picto_pas_de_photo.png'}></Picture>
                                                {/*<picture style={{ backgroundImage: `url(${s.previewUrl ? "'" + s.previewUrl + "'" : '/content/img/icons/picto_pas_de_photo.png'})`, backgroundSize: (s.type === 'pompes' ? 'contain' : 'cover') }}></picture>*/}
                                                {s.documents &&
                                                    <wrap>
                                                        <toolbar vertical="" transparent="">
                                                            <wrap left="">
                                                                {/* to do map for typeDoc and typeName */}
                                                                {
                                                                    s.documents && s.documents.map((d, index) => {
                                                                        return d.language === props.culture && <div
                                                                            // onClick={() => {console.log(s)}}
                                                                            className="button list_element">
                                                                            <picture style={{ "display": "flex", backgroundImage: `url(/content/img/logo/typeDoc/${Project.getDocLogo(d.typeDoc)}.png)` }} micro="" ></picture>
                                                                            <a href={d.url} target="_blank"><text>{d.nameDoc}</text></a> {//eslint-disable-line react/jsx-no-target-blank 
                                                                            }
                                                                        </div>
                                                                    })
                                                                }

                                                            </wrap>
                                                        </toolbar>
                                                    </wrap>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </view>
            </view>
        </DetailView>}
    </>
    );
}

export default connect(state => ({
    isLoggedIn: state.isLoggedIn,
    currentNetwork: state.networks && state.networks.find(item => item.id === state.network),
    networkId: state.network,
    applicationData: state.applicationData,
    culture: state.culture,
    userToken: state.userToken
}), null)(DocumentDetails);