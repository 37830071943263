import { Table } from 'antd';
import React from 'react';
import { connect } from 'react-redux';


function Solutions(props) {

    return <>
        <view transparent="">
            <view intro="" transparent="" space="">
                <div className="table_view colorize">
                    <Table
                        onRow={(record, rowIndex) => { //NOSONAR
                            return {
                                onClick: () => { props.setMainSolution(record) }
                            }
                        }}
                        rowSelection={{
                            type: "radio",
                            onChange: (selectedRowKeys, selectedRows) => { props.setMainSolution(selectedRows[0]); }
                        }}
                        pagination={false}
                        dataSource={props.solutions}
                        columns={props.columns.map(c => { c.render = (val) => { return <wrap left=""><text>{val}</text></wrap> }; return c; })} //NOSONAR
                    />
                </div>
            </view>
        </view>
    </>;
}

export default connect(state => ({ isLoggedIn: state.isLoggedIn }), null)(Solutions);