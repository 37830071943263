/**
 * the formats for different types of data
 */
export const Defines = {
    Format: {
        MomentDate: "YYYY/MM/DD",
        MomentDateTime: "YYYY/MM/DD HH:mm",
        MomentTime: "HH:mm",
        MomentFullTime: "HH:mm:ss",

        PrintDate: "DD/MM/YYYY", //"MM/dd/yyyy",
        PrintDateTime: "DD/MM/yyyy HH:mm",
        FileNameDate: "yyyyMMdd",
        FileNameDateTime: "yyyy_MM_dd_HH-mm",

        ExpirationDate: "MMddyy",

        InputDate: "MM/dd/yyyy",
        InputTime: "h:mm tt",

        Time: "HH:mm",

        MonthDate: "MMMM, yyyy",

        Number0: "n0"
    },
    Messages: {
        //confirmDelete: "Delete",
        //cancelDelete: "Cancel",
        Yes: "Yes",
        No: "No",
    }
};

/**
 * shorthands to get page urls
 */
export const UserRoles = {
    USER: "user",
}

export const AppPages = {
    Home: "home",

    Login: "login",
    Logout: "logout",
    Register: "register",
    Forgot: "forgot",
    NotFound: "notfound",
    Template: "template",
    TemplateDetail: "templatedetail",

    Applications: "applications",
    Etude: "etude",
    EtudePickup: "etudepickup",
    EtudeDetails: "etudedetails",
    SolutionDetails: "solutiondetails",
    Logement: "logement",
    Camping: "camping",
    Hotel: "hotel",
    EtablissementsSportifs: "stade",
    Caserne: "caserne",
    EtablissementsScolaires: "etablissements_scolaires",
    EtablissementDeSante: "etablissement_de_sante",
    Restaurant: "restaurant",
    AutreTertiaire: "autre_tertiaire",
    ProcessusIndustriel: "indus",

    MyAccount: "myaccount",
    FAQ: "faq",
    CGU: "cgu"
    // DocumentDetails: "documentdetails"

}

export const StoreKeys = {
    UserToken: "USER-TOKEN",
    UserData: "userData",
    LoggedIn: "loggedIn",
    ListStates: "listStates",
    Culture: "culture",
    CultureMode: "cultureMode",
    Network: "network",
    Networks: "networks",
    ApplicationData: "applicationData",
    NumberInProcess: "numberInProcess",
    Role: "role",
    ExpireTime: "exptime",
}

export const NetworksCodes = {
    ACVFR: "ACVFR",
    YGNBE: "YGNBE",
    YGNIT: "YGNIT",
    ATLFR: "ATLFR",
    ACVUK: "ACVUK",
    ACVES: "ACVES",
    HAMUK: "HAMUK",
    ACVGABe: "ACVGABe"
}

export const DocCodes = {
    WEB: "WEB",
    WORD: "WORD",
    PDF: "PDF",
    REVIT: "REVIT",
    BART: "BART"
}

export const AppCode = {
    LOGEMENTS: "logement",
    HOTEL: "hotel",
    CAMPING: "camping",
    ETABLISSEMENTSSPORTIFS: "sport",
    // ETABLISSEMENTSSPORTIFS: "etablissementssportifs",
    ETABLISSEMENTSSCOLAIRES: "etablissements_scolaires",
    CASERNE: "caserne",
    ETABLISSEMENTDESANTE: "etablissement_de_sante",
    RESTAURANT: "restaurant",
    AUTRETERTIAIRE: "autre_tertiaire",
    // PROCESSUSINDUSTRIEL: "processus_industriel",
    PROCESSUSINDUSTRIEL: "indus",
    SANTE: "sante"
}

export const SubAppCode = {
    LOGEMENTSPRIVES: "prives",
    LOGEMENTSSOCIAUX: "sociaux",
    RESIDENCESSENIORS: "seniors",
    RESIDENCESETUDIANTE: "etudiant",
    FOYERDEJEUNESTRAVAILLEURS: "travailleur",
    CAMPING: "camping",
    AFFAIRES: "affaires",
    TOURISME: "tourisme",
    MONTAGNE: "montagne",
    STADEFOOTBALL: "football",
    STADERUGBY: "rugby",
    GYMNASE: "gymnase",
    PISCINE: "piscine",
    SALLEDEFITNESS: "multisport",
    CASERNE: "caserne",
    INTERNAT: "internat",
    HOPITALCLINIQUEDOUCHES: "hopital",
    EHPAD: "ehpad",
    TRADITIONNEL: "traditionnel",
    GASTRONOMIQUE: "gastronomique",
    RAPIDE: "rapide",
    COLLECTIF: "collectif",
    VESTIAIREDENTREPRISE: "vestiaire",
    STATIONDELAVAGE: "station",
    PROCESSUSINDUSTRIEL: "indus",
    isValid: (subApp) => {
        return SubAppCode.LOGEMENTSPRIVES === subApp ||
            SubAppCode.LOGEMENTSSOCIAUX === subApp ||
            SubAppCode.RESIDENCESSENIORS === subApp ||
            SubAppCode.RESIDENCESETUDIANTE === subApp ||
            SubAppCode.FOYERDEJEUNESTRAVAILLEURS === subApp ||
            SubAppCode.CAMPING === subApp ||
            SubAppCode.AFFAIRES === subApp ||
            SubAppCode.TOURISME === subApp ||
            SubAppCode.STADEFOOTBALL === subApp ||
            SubAppCode.STADERUGBY === subApp ||
            SubAppCode.GYMNASE === subApp ||
            SubAppCode.PISCINE === subApp ||
            SubAppCode.SALLEDEFITNESS === subApp ||
            SubAppCode.MONTAGNE === subApp ||
            SubAppCode.TRADITIONNEL === subApp ||
            SubAppCode.GASTRONOMIQUE === subApp ||
            SubAppCode.RAPIDE === subApp ||
            SubAppCode.COLLECTIF === subApp ||
            SubAppCode.CASERNE === subApp ||
            SubAppCode.VESTIAIREDENTREPRISE === subApp ||
            SubAppCode.STATIONDELAVAGE === subApp ||
            SubAppCode.EHPAD === subApp ||
            SubAppCode.HOPITALCLINIQUEDOUCHES === subApp ||
            SubAppCode.INTERNAT === subApp ||
            SubAppCode.PROCESSUSINDUSTRIEL === subApp;
    }
}

export const ProductionMode = {
    INSTANTANE: "instantane",
    SISA: "SI/SA",
    ACCUMULE: "accumule"
}
export const SystemCode = {
    ECHANGEUR_A_PLAQUES_BALLONS_PRIMAIRES: "hygiatherm",
    ECHANGEUR_A_PLAQUES: "echangeur",
    BALLONS_ECS_PREPARATEURS: "serpentin",
    ACCUMULATEURS_GAZ_BALLONS_ECS: "sanigaz",
    ACCUMULATEURS_GAZ_BALLONS_ECS_HMWM: "hmwm",
    BALLONS_ECS_TANK_IN_TANK: "tankintank",
    ECHANGEUR_BALLONS_ECS: "echangeur_ballon",
    BALLONS_ELECTRIQUES: "electrique",
    isValid: (systemCode) => {
        return SystemCode.ECHANGEUR_A_PLAQUES === systemCode ||
            SystemCode.ECHANGEUR_BALLONS_ECS === systemCode ||
            SystemCode.BALLONS_ELECTRIQUES === systemCode ||
            SystemCode.ECHANGEUR_A_PLAQUES_BALLONS_PRIMAIRES === systemCode ||
            SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS === systemCode || 
            SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS_HMWM === systemCode || 
            SystemCode.BALLONS_ECS_TANK_IN_TANK === systemCode ||
            SystemCode.BALLONS_ECS_PREPARATEURS === systemCode;
    }
}

export const ProductTypes = {
    Plates_Exchanger: "exchange",
    DHW_tank: "stockecs",
    Primary_tank: "stockprim",
    Preparation_tank: "stockprep",
    Tank_in_tank: "stocktint",
    Sanigaz: "accgaz",
    HeatMaster_WaterMaster: "hmwm",
    Pump: "pompes",
    RSB: "rsb",
    Resistance: "resistance"
}

export const ExpertModeTypes = {
    APPARTEMENT: "appartement",
    BATIMENT: "batiment",
    LOGEMENT: "logement"

}

export const DefaultNetworks = [
    NetworksCodes.ACVFR,
    NetworksCodes.YGNBE,
    NetworksCodes.YGNIT,
    NetworksCodes.ATLFR,
    NetworksCodes.ACVUK,
    NetworksCodes.ACVES,
    NetworksCodes.HAMUK,
    NetworksCodes.ACVGABe
];

export const Logo = {
    ACV: 'acv',
    ATLANTIC: 'atlantic',
    HAMWORTHY: 'hamworthy',
    YGNIS: 'ygnis'
}

/**
 * the url endings for each action of api
 */
export const ApiUrl = {
    //TranslationJson: '/api/FrontView/GetJson?file={{ns}}&lang={{lng}}',
    TranslationJson: '/api/FrontView/GetTranslations?',
    Login: "/account/login",
    LoginByCode: "/account/loginCode",
    LoginGuest: "/account/LoginGuest",
    Logout: "/account/logout",
    Check: "/account/check",
    Connect: "/wso/SSOConnect",
    Register: "/wso/SSORegister",
    //ConnectAs: "/account/ConnectAs",
    //Register: "/account/register",
    //Forgot: "/account/forgot",

    SocieteUserLoad: "/api/SocieteUser/ResetFormData",
    SocieteUserUpdate: "/api/SocieteUser/UpdateFormData",

    ValueList: "/api/valuelist/getlist",

    GetSalesNetworks: "/api/FrontView/GetSalesNetworks",

    StatisticsTrack: "/api/FrontView/Track",

    //AttachmentsList: "/api/Attachments/GetListData",
    //AttachmentsLoad: "/api/Attachments/ResetFormData",
    //AttachmentsUpdate: "/api/Attachments/UpdateFormData",
    //AttachmentsDelete: "/api/Attachments/DeleteFormData",

    //GetSalesNetworkApplications: "/api/FrontView/GetSalesNetworkApplications",
    //GetSalesNetworkSubApplications: "/api/FrontView/GetSalesNetworkSubApplications",

    //GetSalesNetworkSystems: "/api/FrontView/GetSalesNetworkSystems",
    GetProducts: "/api/EtudeProduct/GetProducts",

    GetFAQ: "/api/FaqView/GetFAQ",

    //FrontDocumentList: "/api/FrontDocument/GetListData",
    //FrontDocumentLoad: "/api/FrontDocument/ResetFormData",
    //FrontDocumentUpdate: "/api/FrontDocument/UpdateFormData",
    //FrontDocumentDelete: "/api/FrontDocument/DeleteFormData",
    //FrontDocumentUpdateJson: "/api/FrontDocument/UpdateFormDataJson",

    //FrontDocumentGetData: "/api/FrontDocument/GetData",
    //EtudeRemove: "/api/FrontDocument/RemoveSelected",


    EtudeList: "/api/Etude/GetListData",
    EtudeLoad: "/api/Etude/ResetFormData",
    EtudeUpdate: "/api/Etude/UpdateFormData",
    EtudeDelete: "/api/Etude/DeleteFormData",
    EtudeUpdateJson: "/api/Etude/UpdateFormDataJson",

    EtudeGetData: "/api/Etude/GetData",
    EtudeRemove: "/api/Etude/RemoveSelected",

    EtudePdf: "/api/Etude/Etude.pdf",
    EtudePdfUrl: "/api/Etude/GetPdfURL",


}
