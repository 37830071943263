/*eslint-disable*/
import React, { Component, useEffect, useCallback, useState } from 'react';
import $ from 'jquery';

import { useDispatch } from 'react-redux';
import { PopupTypes } from '../components/Popup.js';
import { openPopup } from '../store/actions';
import moment from 'moment';

import { DatePicker, TimePicker, InputNumber, Select, message, Table, Dropdown, Tooltip } from 'antd';
import ResizeableTitle from '../Shared/ListView/ResizableTitle';
import { COLUMN_TYPE } from '../Shared/ListView/helper.js';
import { T } from '../components/Translations.js';
import ListView from '../Shared/ListView/index.js';
import { ApiUrl, AppPages } from '../project/Defines.js';
import { TabStrip } from '../Shared/TabStrip/index.js';
import CustomUpload from '../Shared/CustomUpload/index.js';

const { Option } = Select;
export function TemplateDetails() {
    const [dataItem, setDataItem] = useState({});
    const children = [];
    for (let i = 10; i < 36; i++) {
        children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
    }

    return <div id="windowContent" className="window_cont">
        <form>
            <toolbar><button type="submit" className="button primary"><text>Test Form Submit</text></button> <separator vertical=""></separator> <div className="button accent"><text>Secondary Action</text></div><separator vertical=""></separator>    <div id="disableControls" effect="material" className="button"><text>Disable Controls</text></div> </toolbar>
            <div className="form_container">
                <div className="section_group">
                    <div className="sections ">
                        <div className="form_fields"><div className="data_label">Sample Input</div><input type="text" placeholder="Nice Placeholder" defaultValue={""} /></div>
                        <div className="form_fields"><div className="data_label">Sample Disabled Input</div><input type="text" placeholder="Nice Placeholder" defaultValue={""} /></div>
                        <div className="form_fields"><div className="data_label">Sample Password Icon</div><div className="input_cont"><input id="txtPassword1" type="password" placeholder="Password" autoComplete={"new-password"} defaultValue={""} /><i></i></div></div>
                        <div className="form_fields"><div className="data_label">Sample Password Text</div><div className="input_cont"><input id="txtPassword2" type="password" placeholder="Password" autoComplete={"new-password"} defaultValue={""} /><i></i></div></div>
                        <div className="form_fields"><div className="data_label">Fixed Label</div><label className="input_cont"><text>Fixed Label</text><input type="text" placeholder="Placeholder" autoComplete={"new-password"} defaultValue={""} /><i></i></label></div>
                        <div className="form_fields"><div className="section_title"><text>Sample Nice Title Goes Here</text></div><div className="form_separator"></div></div>
                        <div className="form_fields">
                            <div className="data_label">Radio Switcher</div>
                            <div id="RadioSwitcher" role-type="checklist" className="option_bar">
                                <label>
                                    <input type="radio" defaultValue="value1" name="RS" />
                                    <wrap effect="material"><text>Star Lord</text> </wrap>
                                </label>
                                <label>
                                    <input type="radio" defaultValue="value2" name="RS" />
                                    <wrap effect="material"><text>Yondu Udonta</text></wrap>
                                </label>
                                <label>
                                    <input type="radio" defaultValue="value3" name="RS" />
                                    <wrap effect="material"><icon>photo</icon> <text>Rocket Raccoon</text> </wrap>
                                </label>
                            </div>
                        </div>
                        <div className="form_fields">
                            <div className="data_label">Checkbox Switcher</div>
                            <div id="CheckboxSwitcher" role-type="checklist" className="option_bar">
                                <label>
                                    <input type="checkbox" defaultValue="value1" />
                                    <wrap effect="material"><text>Star Lord</text> </wrap>
                                </label>
                                <label><input type="checkbox" defaultValue="value2" />
                                    <wrap effect="material"><text>Yondu Udonta</text></wrap>
                                </label>
                                <label>
                                    <input type="checkbox" defaultValue="value3" />
                                    <wrap effect="material"><text>Rocket Raccoon</text> </wrap>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="sections ">
                        <div className="form_fields">
                            <div className="data_label">Input Container With Action</div>
                            <div className="input_cont">
                                <icon>unlock</icon>
                                <input required="required" type="text" placeholder="Placeholder" />
                                <div effect="material" className="button"><text>Action</text></div>
                                <i></i>
                            </div>
                        </div>
                        <div className="form_fields">
                            <div className="data_label">Sample Password Icon</div>
                            <div className="input_cont">
                                <input id="" type="password" required="required" placeholder="Password" autoComplete={"new-password"} password="password" />
                                <div className="button micro"><icon>eye</icon></div>
                                <i></i>
                            </div>
                        </div>
                        <div className="form_fields"><div className="data_label">Readonly Container</div><div className="data_read"><text>Sample Readonly Container</text></div></div>
                        <div className="form_fields"><div className="data_label">Text Area<span>Optional</span></div><textarea placeholder="Fixed Height"></textarea></div>
                        <div className="form_fields"><div className="data_label">Cell Phone<span>Optional</span></div><input placeholder="Phone" type="text" defaultValue={""} /><input placeholder="Other phone" type="text" defaultValue="" /></div><div className="field_group">
                            <div className="form_fields large"><div className="data_label">City<tip>Small Help Tip</tip></div><input type="text" defaultValue={""} /></div>
                            <div className="form_fields"><div className="data_label">Postal Code</div><input name="ZipPostal" type="text" defaultValue={""} /></div></div>
                        <div className="form_fields"><div className="form_separator"></div></div>
                        <div className="form_fields">
                            <div className="data_label">Notifications</div>
                            <div className="data_help">Configure settings when and how You'll get notified for content changes</div>
                            <div className="option_group">
                                <div className="option_bar">
                                    <div title=""><text>New Content</text></div>
                                    <label data-tip="Get Notified via Email" effect="material">
                                        <input type="checkbox" defaultValue="value1" />
                                        <wrap><icon>mail</icon></wrap>
                                    </label>
                                    <label data-tip="Get Notified via Notification Center" effect="material">
                                        <input type="checkbox" defaultValue="value2" />
                                        <wrap><icon>bell</icon></wrap>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sections">
                        <div className="form_fields">
                            <div className="section_title"><text>Sample Nice Title Goes Here</text></div>
                            <div className="form_separator"></div>
                        </div>
                        <div className="form_fields">
                            <div className="data_label">Drop Down List</div>
                            <Select placeholder="Select Character" id="ddlAntd" size="default" defaultValue={"a1"}>
                                {children}
                            </Select>
                        </div>
                        <div className="form_fields">
                            <div className="data_label">Combo Box</div>
                            <Select
                                showSearch
                                placeholder="Select Character" id="ddlgAntd"
                                optionFilterProp="children"
                                filterOption={(input, option) => //NOSONAR
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {children}
                            </Select>
                        </div>
                        <div className="form_fields">
                            <div className="data_label">Multi Select</div>
                            <Select
                                mode="multiple"
                                size="default"
                                placeholder="Please select"
                                defaultValue={['a10', 'c12']}>
                                {children}
                            </Select>
                        </div>
                        <div className="form_fields">
                            <div className="data_label">Date Picker</div>
                            <DatePicker name="dtpAntd" defaultValue={moment('2015/01/01', 'YYYY/MM/DD')} format='YYYY/MM/DD' />
                        </div>
                        <div className="form_fields">
                            <div className="data_label">Time Picker</div>
                            <TimePicker name="tpAntd" defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                        </div>
                        <div className="form_fields">
                            <div className="data_label">Numeric</div>
                            <InputNumber min={1} max={100000} defaultValue={3} />
                        </div>
                        <div className="form_fields">
                            <div className="data_label">Date/Time Picker<span>Optional</span></div>
                            <DatePicker name="dtpAntd" defaultValue={moment('2015/01/01', 'YYYY/MM/DD')} format='YYYY/MM/DD' />
                            <TimePicker name="tpAntd" defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                        </div>
                        <div className="form_fields input-validation-error">
                            <div className="data_label">Uploader</div>
                            {/* <FormField label="text.logo"> */}
                                <CustomUpload
                                    imageUrl='image_url'
                                    imageField='images'
                                    dataItem={dataItem}
                                    setDataItem={setDataItem}
                                    type={'aaa'}
                                />
                            {/* </FormField> */}
                            <div className="input_cont">
                                <icon yellow="yellow">warning</icon>
                                <div className="data_help">Configure settings when and how You'll get notified for content changes</div>
                            </div>
                        </div>
                        <div className="form_fields">
                            <div className="data_label">Text Area<span>Optional</span></div>
                            <textarea placeholder="Fixed Height" className="input-validation-error"></textarea>
                        </div>
                    </div>

                    <div className="sections wide">
                        <div className="form_fields">
                            <div className="data_label">Image Container</div>
                            <div className="input_cont ">
                                <picture style={{ backgroundImage: "url(/content/img/bg1.png)" }}></picture>
                            </div>
                        </div>
                        <div className="form_fields">
                            <div className="data_label">Image Container</div>
                            <div className="input_cont ">
                                <picture middle="" style={{ backgroundImage: "url(/content/img/connection.jpg)" }}></picture>
                            </div>
                        </div>
                        <div className="form_fields">
                            <div className="data_label">Image Container</div>
                            <div className="input_cont ">
                                <picture large="" style={{ backgroundImage: "url(/content/img/home.jpg)" }}></picture>
                            </div>
                        </div>
                    </div>







                </div>
            </div>
        </form>
    </div>
}

export function TemplateServiceCallDetails() {
    const [tabIndex, setTabIndex] = useState(0);

    return (<>
            <div className="form_container">
                {/* <container> */}
                <view  vertical="">
                    <view mini="" scroll="">
                        <div className='section_group'>
                            <div className='sections'>
                                <form>
                                        <cont>
                                            <textarea placeholder="Fixed Height" className=""></textarea>
                                            <decor><label><text><line></line><wrap><span>Drop Down List</span></wrap></text><line></line></label><border></border></decor>
                                        </cont>
                                        <cont>
                                            <DatePicker className="" name="dtpAntd" defaultValue={moment('2015/01/01', 'YYYY/MM/DD')} format='YYYY/MM/DD' />
                                            <decor><label><text><line></line><wrap><icon>calendar</icon></wrap></text><line></line></label><border></border></decor>
                                        </cont>
                                        <div className="form_fields">
                                            <div className="section_title"><text>Sample Nice Title Goes Here</text></div>
                                            <div className="form_separator"></div>
                                        </div>


                                        <field_group>
                                            <cont>
                                                <TimePicker className="" name="tpAntd" defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                                                <decor><label><text><line></line><wrap><span>Time Picker</span></wrap></text><line></line></label><border></border></decor>
                                            </cont>
                                            <cont>
                                                <InputNumber className="" min={1} max={100000} defaultValue={3} />
                                                <decor><label><text><line></line><wrap><span>Numeric</span></wrap></text><line></line></label><border></border></decor>
                                            </cont>
                                        </field_group>
                                </form>
                            </div>
                        </div>
                    </view>
                    <view>
                        <TabStrip 
                            onChange={(selected) => setTabIndex(selected)}  //NOSONAR
                            // skipSecondaryTabs={isNew} 
                            selectedIndex={tabIndex}
                    >
                        <div title='text.customer'>
                            <view scroll="">
                                <div className='section_group'>
                                    <div className='sections'>
                                        <form>
                                            <cont>
                                                <input type="text" placeholder="Nice Placeholder" />
                                                <decor><label><text><line></line><wrap><span><T>text.appointment_date</T></span></wrap></text><line></line></label><border></border></decor>
                                            </cont>

                                            <cont>
                                                <DatePicker className="" name="dtpAntd" defaultValue={moment('2015/01/01', 'YYYY/MM/DD')} format='YYYY/MM/DD' />
                                                <decor><label><text><line></line><wrap><icon>calendar</icon></wrap></text><line></line></label><border></border></decor>
                                            </cont>


                                            <field_group>
                                                <cont>
                                                    <TimePicker className="" name="tpAntd" defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                                                    <decor><label><text><line></line><wrap><span>Time Picker</span></wrap></text><line></line></label><border></border></decor>
                                                </cont>
                                                <cont>
                                                    <InputNumber className="" min={1} max={100000} defaultValue={3} />
                                                    <decor><label><text><line></line><wrap><span>Numeric</span></wrap></text><line></line></label><border></border></decor>
                                                </cont>
                                            </field_group>
                                        </form>
                                    </div>
                                    <div className='sections'>
                                        <form>
                                            <cont>
                                                <input type="text" placeholder="Nice Placeholder" />
                                                <decor><label><text><line></line><wrap><span><T>text.appointment_date</T></span></wrap></text><line></line></label><border></border></decor>
                                            </cont>

                                            <cont>
                                                <DatePicker className="" name="dtpAntd" defaultValue={moment('2015/01/01', 'YYYY/MM/DD')} format='YYYY/MM/DD' />
                                                <decor><label><text><line></line><wrap><icon>calendar</icon></wrap></text><line></line></label><border></border></decor>
                                            </cont>
                                        </form>
                                    </div>
                                </div>
                            </view>


                            </div>
                        <div title='text.product'>
                            <view scroll="">
                                <div className='section_group'>
                                    <div className='sections'>
                                        <form>
                                            <cont>
                                                <input type="text" placeholder="Nice Placeholder" />
                                                <decor><label><text><line></line><wrap><span><T>text.problem_type</T></span></wrap></text><line></line></label><border></border></decor>
                                            </cont>
                                        </form>
\                                    </div>
                                </div>
                            </view>
                            </div>
                        <div title='text.remark'>
                            <view scroll="">
                                <div className='section_group'>
                                    <div className='sections'>
                                        <form>
                                            <cont>
                                                <input type="text" placeholder="Nice Placeholder" />
                                                <decor><label><text><line></line><wrap><span><T>text.call_type</T></span></wrap></text><line></line></label><border></border></decor>
                                            </cont>
                                        </form>
                                    </div>
                                </div>
                            </view>
                            </div>
                        <div title='text.attachments'>
                            <view scroll="">
                                <div className='section_group'>
                                    <div className='sections'>
                                        <form>
                                            <cont>
                                                <input type="text" placeholder="Nice Placeholder" />
                                                <decor><label><text><line></line><wrap><span><T>text.status</T></span></wrap></text><line></line></label><border></border></decor>
                                            </cont>
                                        </form>
                                    </div>
                                </div>
                            </view>
                            </div>
                        <div title='text.fees'>
                            <view scroll="">
                                <div className='section_group'>
                                    <div className='sections'>
                                        <form>

                                        </form>
                                    </div>
                                </div>
                            </view>

                            </div>
                        <div title='text.spare_parts'>
                            <view scroll="">
                                <div className='section_group'>
                                    <div className='sections'>
                                        <form>

                                        </form>
                                    </div>
                                </div>
                            </view>

                            </div>
                        </TabStrip>
                    </view>
                </view>
                
            </div>
                {tabIndex < 2 &&
                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div className='button'>
                                    <T>text.save</T>
                                </div>
                                <div className='button'>
                                    <T>text.delete</T>
                                </div>
                            </action>
                        </wrap>
                    </toolbar>
                }
    </>)
}

export function Template() {
    const [resizeColumns, setResizeColumns] = useState();
    const [_columns, set_Columns] = useState();

    const dispatch = useDispatch();

    const loadData = () => {
        $(document).ready(function () {

            function counter() {


                $("[counter]").each(function () {
                    let count = $(this).find('count')
                    let data = $(this).find('wrap').children('item:visible').length,
                        countTo = data;
                    if (data < 1) {
                        $(this).addClass('no_result')
                    }
                    else {
                        $(this).removeClass('no_result')
                    }

                    $({ countNum: count.text() }).animate({
                        countNum: countTo
                    },

                        {
                            duration: 250,
                            easing: 'linear',
                            step: function () {
                                count.text(Math.floor(this.countNum));
                            },
                            complete: function () {
                                count.text(this.countNum);
                            }
                        });
                });

            }
            $(".tab_strip").each(function (index) {
                let tab = $(this);

                $(tab.find("li")[0]).addClass("selected");
                $(tab.find(">div>div")[0]).addClass("selected");

                tab.find("li").each(function (idx) {
                    let tab_li = $(this);
                    tab_li.on("click", function (e) {
                        tab.find("li").removeClass("selected");
                        tab.find(">div>div").removeClass("selected");
                        tab_li.addClass("selected");

                        $(tab.find(">div>div")[idx]).addClass("selected");
                    })
                })
            });

            let icon_count = $("[icons]").find('item').length;
            $(".icon_search").attr("placeholder", "Search " + icon_count + " Icons");
            $(".icon_search").on("keyup", function () {
                counter();
                let value = $(this).val().toLowerCase();
                $("grid wrap item").filter(function () {
                    $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
                });
            });

            let item = $("view[icons]").find("item").on("click", function () {
                item.not(this).removeClass('selected');
                $(this).toggleClass('selected');


                message.success("Icon Name Copied to Clipboard");


                let $temp = $("<input>");
                $("body").append($temp);
                $temp.val($(this).find("icon").text()).select();
                document.execCommand("copy"); //NOSONAR
                $temp.remove();

            });

            counter();

            $("#btnOpenWindow").click(function () {

                dispatch(openPopup({
                    windowKey: "key1",
                    fullScreen: false,
                    title: $(this).text(),
                    type: PopupTypes.TemplateDetails,
                }));
            });

            $("#btnOpenFullscreenWindow").click(function () {
              
                dispatch(openPopup({
                    windowKey: "key2",
                    fullScreen: true,
                    title: $(this).text(),
                    type: PopupTypes.TemplateDetails,
                }));
            });

           

            $("#btnOpenNoHeaderWindow").click(function () {

                dispatch(openPopup({
                    windowKey: "key3",
                    fullScreen: false,
                    showHeader: false,
                    title: $(this).text(),
                    type: PopupTypes.TemplateDetails,
                }));
            });

            $("#btnOpenServiceCallWindow").click(function () {

                dispatch(openPopup({
                    windowKey: "key4",
                    fullScreen: true,
                    title: $(this).text(),
                    type: PopupTypes.TemplateServiceCallDetails,
                }));
            });

        });
    };

    useEffect(() => {
        loadData();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const children = [];
    for (let i = 10; i < 36; i++) {
        children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
    }

    const components = {
        header: {
            cell: ResizeableTitle
        }
    };

    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            width: 200
        },
        {
            title: "Amount",
            dataIndex: "amount",
            width: 100,
            sorter: (a, b) => a.amount - b.amount
        },
        {
            title: "Type",
            dataIndex: "type",
            width: 100
        },
        {
            title: "Note",
            dataIndex: "note",
            width: 100
        },
        {
            title: "Action",
            key: "action",
            render: () => <a href="#">Delete</a>
        }
    ];


    const data = [
        // {
        //   key: 0,
        //   date: "2018-02-1transfertransfertransfertransfertransfertransfertransfertransfertransfertransfertransfertransfertransfer1",
        //   amount: 120,
        //   type: "incometransfertransfertransfertransfertransfertransfertransfertransfertransfertransfer",
        //   note: "transfertransfertransfertransfertransfertransfertransfertransfertransfer"
        // },
        // {
        //   key: 1,
        //   date: "2018-03-11",
        //   amount: 243,
        //   type: "income",
        //   note: "transfer"
        // },
        // {
        //   key: 2,
        //   date: "2018-04-11",
        //   amount: 98,
        //   type: "income",
        //   note: "transfer"
        // }
    ];

    for (let i = 0; i < 1000; i++) {
        data.push({
            key: i,
            date: "2018-03-11",
            amount: 243,
            type: "income",
            note: "transfer"
        })
    }


    useEffect(() => {
        setResizeColumns(columns);
    }, [])

    useEffect(() => {
        if (resizeColumns) {
            const updatedColumns = resizeColumns.map(resizeConfig);

            set_Columns(updatedColumns);
        }
    }, [resizeColumns])

    const handleResize = useCallback(index => (e, { size }) => {

        setResizeColumns((_resizeColumns) => {
            const nextColumns = [..._resizeColumns];
            nextColumns[index] = {
                ...nextColumns[index],
                width: size.width
            };
            return nextColumns;
        });
    }, [resizeColumns]);

    const resizeConfig = useCallback(
        (col, index) => ({
            ...col,
            onHeaderCell: column => ({
                width: column.width,
                // onResizeStop: onResizeStop(index),
                onResize: handleResize(index),
            })
        }), [handleResize]);

    let actionMenu =
        <div className="list_menu ant-dropdown-menu">
            <ul>
                <li command="user"><a><icon></icon><text><T>text.open</T></text></a></li>
                <li className="separator"></li>
                <li command="user"><a><icon></icon><text><T>text.decline</T></text></a></li>
                <li className="separator"></li>
                <li command="user"><a><icon></icon><text><T>text.sample_action</T></text></a></li>
                <li className="separator"></li>
                <li command="user"><a><icon></icon><text><T>text.action</T></text></a></li>
            </ul>
        </div>;


    const columnsServiceCalls = [
        {
            text: ' ',
            noSort: 'true',
            render: (text, record) => {
                return (
                    <wrap>
                        <elastic>
                            <action>
                                <div className="button extra"><icon>pen_edit</icon></div>
                            </action>

                        </elastic>
                    </wrap>);
            },
            width: 40,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.status',
            noSort: 'true',
            field: 'status',
            render: (text, record) => {
                return (
                    <wrap>
                        {text === "Accepted\n" &&
                        <group>
                            <badge private="" filled="">Private</badge>
                            <badge error="" filled="">Filled</badge>
                        </group>
                        }

                        {text === "Change Request" &&
                            <group><badge new="" filled="">Change Request</badge></group>
                        }
                        {text === "Pending" &&
                        <group>
                            <badge filled=""><text code="">410225</text></badge>
                            <badge process="" filled=""><text code="">110224</text></badge>
                        </group>
                        }
                    </wrap>
                );
            },

            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.service_call',
            noSort: 'true',
            field: 'code',
            render: (text, record) => {
                return (
                    <wrap>
                        <text code=''>{text}</text>
                    </wrap>
                );
            },
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.subject',
            noSort: 'true',
            field: 'subject',
            render: (text, record) => {
                return (
                    <wrap>
                        <text light="">{text}</text>
                    </wrap>
                );
            },
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.status',
            noSort: 'true',
            field: 'status',
            render: (text, record) => {
                return (
                    <wrap>
                        {text === "Accepted\n" &&
                            <label checkbox=""><input type="checkbox"/><box><check></check></box><text>Checkbox</text></label>                        
                            }
    
                        {text === "Change Request" &&
                            <text data-weight="700">{text}</text>

                        }
                        {text === "Pending" &&
                            <elastic>
                                <action>
                                <div className="button extra"><icon>undo</icon></div>
                                <div className="button extra"><icon>weather</icon></div>
                                <div className="button extra"><icon>reload</icon></div>
                                </action>
                                <text className="preview  auto_title">Pending</text>
                            </elastic>
                        }
                    </wrap>
                );
            },
            width: 350,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.from',
            noSort: 'true',
            field: 'wish_date_from',
            render: (text, record) => {
                return (
                    <wrap>
                        <text>{text}</text>
                    </wrap>
                );
            },
            type: COLUMN_TYPE.TEXT
        },
        {
            text: ' ',
            noSort: 'true',
            render: (text, record) => {
                return (
                    <wrap>

                        <Dropdown
                            className=""
                            trigger={['hover']}
                            overlay={actionMenu} placement="topRight">
                            <Tooltip placement="right" title="">
                                <div className="button micro"><icon>more</icon></div>
                            </Tooltip>
                        </Dropdown>
                    </wrap>
                );
            },
            width: 50,
            type: COLUMN_TYPE.TEXT
        }
    ];

    return (
        <>
            <div className="tab_strip">

                <wrap>
                    <ul>
                        <li effect="material"><text>Icons & Badges</text></li>
                        <li effect="material"><text>Buttons & Switchers</text></li>
                        <li effect="material"><text>Empty</text></li>
                        <li effect="material"><text>Inputs & Forms</text></li>
                        <li effect="material"><text>Overlay Toolbar</text></li>
                        <li effect="material"><text>Resizable</text></li>
                        <li effect="material"><text>Typeface</text></li>
                        <li effect="material"><text>Layout</text></li>
                        <li effect="material"><text>Filters flow</text></li>
                        <li effect="material"><text>ServiceCall</text></li>
                        <li effect="material"><text>Filters</text></li>
                        <li effect="material"><text>Tableview</text></li>
                        <li effect="material"><text>test</text></li>
                    </ul>
                </wrap>

                <div>
                    <div>
                        <container>
                            <view icons="true">
                                <toolbar className="srch" effect="material"><icon>search</icon> <input className="icon_search" type="text" placeholder="Search Here" /></toolbar>
                                <scroller>
                                    <grid counter={""}>
                                        <toolbar><count data-count="1024">0</count>  <separator vertical={""}></separator><div className={"button"}>Section Title</div></toolbar>
                                        <wrap>


                                            <item className={"selected"} effect="material"> <tag></tag> <icon>calc</icon><text>Calculator</text></item>
                                            <item effect="material"> <tag>transcode</tag> <icon>convert</icon><text>Convert</text></item>
                                            <item effect="material"> <tag>tools</tag> <icon>wrench</icon><text>Wrench</text></item>
                                            <item effect="material"> <tag>cast</tag> <icon>stream</icon><text>Stream</text></item>
                                            <item effect="material"> <tag>plane chat</tag> <icon>send</icon><text>Plane</text></item>
                                            <item effect="material"> <tag>house</tag> <icon>home</icon><text>Home</text></item>
                                            <item effect="material"> <tag>message social bubble</tag> <icon>chat</icon><text>Chat</text></item>
                                            <item effect="material"> <tag>message social bubble</tag> <icon>push</icon><text>Chat</text></item>
                                            <item effect="material"> <tag>device monitor</tag> <icon>display</icon><text>Display</text></item>
                                            <item effect="material"> <tag>device monitor</tag> <icon>tall_display</icon><text>Display</text></item>
                                            <item effect="material"> <tag>idea info</tag> <icon>bulb</icon><text>Bulb</text></item>
                                            <item effect="material"> <tag>warning</tag> <icon>error</icon><text>Error</text></item>
                                            <item effect="material"> <tag>social save bookmark</tag> <icon>heart</icon><text>Heart</text></item>
                                            <item effect="material"> <tag>social save bookmark</tag> <icon>heart_filled</icon><text>Heart</text></item>
                                            <item effect="material"> <tag>social</tag> <icon>twitter</icon><text>Twitter</text></item>
                                            <item effect="material"> <tag>social</tag> <icon>twitter_filled</icon><text>Twitter</text></item>
                                            <item effect="material"> <tag>social</tag> <icon>windows</icon><text>Windows</text></item>
                                            <item effect="material"> <tag>social</tag> <icon>apple</icon><text>Apple</text></item>
                                            <item effect="material"> <tag>social</tag> <icon>play_store</icon><text>Google Play</text></item>
                                            <item effect="material"> <tag>error</tag> <icon>warning</icon><text>Warning</text></item>

                                            <item effect="material"> <tag></tag> <icon>question</icon><text>Question Mark</text></item>
                                            <item effect="material"> <tag>done</tag> <icon>success</icon><text>Success</text></item>

                                            <item effect="material"> <tag>archive box</tag> <icon>box</icon><text>Archive</text></item>
                                            <item effect="material"> <tag>photo picture</tag> <icon>photo</icon><text>Photo</text></item>
                                            <item effect="material"> <tag></tag> <icon>book</icon><text>Book</text></item>
                                            <item effect="material"> <tag>pencil</tag> <icon>edit</icon><text>Pencil</text></item>
                                            <item effect="material"> <tag>pencil edit</tag> <icon>pen_edit</icon><text>Pencil</text></item>
                                            <item effect="material"> <tag></tag> <icon>expand</icon><text>Expand</text></item>
                                            <item effect="material"> <tag></tag> <icon>eye</icon><text>Eye</text></item>
                                            <item effect="material"> <tag></tag> <icon>file</icon><text>File</text></item>
                                            <item effect="material"> <tag></tag> <icon>filter</icon><text>Filter</text></item>
                                            <item effect="material"> <tag></tag> <icon>flag</icon><text>Flag</text></item>
                                            <item effect="material"> <tag>file </tag> <icon>folder</icon><text>Folder</text></item>
                                            <item effect="material"> <tag></tag> <icon>full_left</icon><text>End</text></item>
                                            <item effect="material"> <tag>notificaion</tag> <icon>bell</icon><text>Bell</text></item>
                                            <item effect="material"> <tag></tag> <icon>bookmark</icon><text>Bookmark</text></item>
                                            <item effect="material"> <tag></tag> <icon>bookmark_filled</icon><text>Bookmark</text></item>
                                            <item effect="material"> <tag></tag> <icon>calendar</icon><text>Calendar</text></item>
                                            <item effect="material"> <tag>arrow</tag> <icon>down</icon><text>Down</text></item>
                                            <item effect="material"> <tag>arrow</tag> <icon>download</icon><text>Download</text></item>
                                            <item effect="material"> <tag></tag> <icon>drink</icon><text>Drink</text></item>






                                            <item effect="material"> <tag>photo picture</tag> <icon>camera</icon><text>Camera</text></item>
                                            <item effect="material"> <tag></tag> <icon>cancel</icon><text></text>Cancel</item>
                                            <item effect="material"> <tag></tag> <icon>cast</icon><text>Cast</text></item>
                                            <item effect="material"> <tag></tag> <icon>clock</icon><text>Clock</text></item>
                                            <item effect="material"> <tag></tag> <icon>clock_filled</icon><text>Clock</text></item>
                                            <item effect="material"> <tag></tag> <icon>close</icon><text>Close</text></item>
                                            <item effect="material"> <tag></tag> <icon>collapse</icon><text>Collapse</text></item>
                                            <item effect="material"> <tag>view</tag> <icon>column</icon><text>Column</text></item>
                                            <item effect="material"> <tag>view</tag> <icon>list</icon><text>Grid</text></item>
                                            <item effect="material"> <tag></tag> <icon>cube</icon><text>Cube</text></item>
                                            <item effect="material"> <tag>configuration control dashboard setting speed system tool</tag> <icon>dashboard</icon>Dashboard<text></text></item>
                                            <item effect="material"> <tag>remove</tag> <icon>delete</icon><text>Delete</text></item>
                                            <item effect="material"> <tag>success</tag> <icon>done</icon><text>Done</text></item>


                                            <item effect="material"> <tag></tag><icon>woven</icon><text>Woven</text></item>
                                            <item effect="material"> <tag></tag><icon>woven_filled</icon><text>Woven</text></item>
                                            <item effect="material"> <tag>clipboard</tag><icon>paste</icon><text>Paste</text></item>
                                            <item effect="material"> <tag></tag><icon>rss</icon><text>Rss</text></item>
                                            <item effect="material"> <tag></tag><icon>copy</icon><text>Copy</text></item>
                                            <item effect="material"> <tag>Paint</tag><icon>fill</icon><text>Fill</text></item>


                                            <item effect="material"> <tag></tag><icon>code</icon><text>Code</text></item>
                                            <item effect="material"> <tag>code</tag><icon>embed</icon><text>Embed</text></item>
                                            <item effect="material"> <tag>delete</tag><icon>remove</icon><text>Remove</text></item>


                                            <item effect="material"> <tag>direction</tag><icon>arrow_left</icon><text>Arrow</text></item>
                                            <item effect="material"> <tag>direction</tag><icon>arrow_right</icon><text>Arrow</text></item>
                                            <item effect="material"> <tag>direction</tag><icon>arrow_up</icon><text>Arrow</text></item>
                                            <item effect="material"> <tag>direction</tag><icon>arrow_down</icon><text>Arrow</text></item>

                                            <item effect="material"> <tag>direction</tag><icon>arrow_up_left</icon><text>Arrow</text></item>
                                            <item effect="material"> <tag>direction</tag><icon>arrow_up_right</icon><text>Arrow</text></item>
                                            <item effect="material"> <tag>direction</tag><icon>arrow_down_left</icon><text>Arrow</text></item>
                                            <item effect="material"> <tag>direction</tag><icon>arrow_down_right</icon><text>Arrow</text></item>

                                            <item effect="material"> <tag>direction</tag><icon>big_arrow_up</icon><text>Arrow</text></item>
                                            <item effect="material"> <tag>direction</tag><icon>big_arrow_down</icon><text>Arrow</text></item>

                                            <item effect="material"> <tag>direction</tag><icon>previous</icon><text>Arrow</text></item>
                                            <item effect="material"> <tag>direction</tag><icon>next</icon><text>Arrow</text></item>

                                            <item effect="material"> <tag>video film movie</tag> <icon>transcode</icon><text>Convert</text></item>
                                            <item effect="material"> <tag>video film movie</tag> <icon>movie</icon><text>Movie</text></item>
                                            <item effect="material"> <tag>video film movie</tag> <icon>movie_filled</icon><text>Convert</text></item>

                                            <item effect="material"> <tag>chart dashboard</tag> <icon>pie</icon><text>Pie</text></item>
                                            <item effect="material"> <tag>chart dashboard</tag> <icon>bar</icon><text>Bar</text></item>
                                            <item effect="material"> <tag>chart dashboard</tag> <icon>line</icon><text>Line</text></item>

                                        </wrap>

                                    </grid>

                                    <grid counter={""}>
                                        <toolbar><count>0</count>  <separator vertical={""}></separator><div className={"button"}>Section Title</div></toolbar>
                                        <wrap>
                                            <item effect="material"> <tag>arrow</tag> <icon>revert</icon><text>Revert</text></item>
                                            <item effect="material"> <tag>arrow</tag> <icon>undo</icon><text>Undo</text></item>


                                            <item effect="material"> <tag>refresh reload load</tag> <icon>reload</icon><text>Reload</text></item>

                                            <item effect="material"> <tag>refresh reload load</tag> <icon>refresh</icon><text>Refresh</text></item>
                                            <item effect="material"> <tag>refresh reload load</tag> <icon>load</icon><text>Load</text></item>
                                            <item effect="material"> <tag>refresh reload load</tag> <icon>process</icon><text>Process</text></item>

                                            <item effect="material"> <tag></tag> <icon>full_right</icon><text>Start</text></item>
                                            <item effect="material"> <tag></tag> <icon>fullscreen_exit</icon><text>Exit Fullscreen</text></item>
                                            <item effect="material"> <tag></tag> <icon>fullscreen</icon><text></text>Fullscreen</item>
                                            <item effect="material"> <tag>menu navigation</tag> <icon>grid</icon><text>Grid</text></item>




                                            <item effect="material"> <tag>picture photo</tag> <icon>image</icon><text>Image</text></item>
                                            <item effect="material"> <tag>picture photo</tag> <icon>image_filled</icon><text>Image</text></item>
                                            <item effect="material"> <tag></tag> <icon>info</icon><text>Info</text></item>
                                            <item effect="material"> <tag>arrow</tag> <icon>left</icon><text>Left</text></item>
                                            <item effect="material"> <tag></tag> <icon>link</icon><text>Link</text></item>
                                            <item effect="material"> <tag></tag> <icon>lock</icon><text>Lock</text></item>
                                            <item effect="material"> <tag>envelope</tag> <icon>mail</icon><text>Mail</text></item>
                                            <item effect="material"> <tag>navigation</tag> <icon>menu</icon><text>Menu</text></item>
                                            <item effect="material"> <tag> delete remove</tag> <icon>minus</icon><text>Minus</text></item>
                                            <item effect="material"> <tag>dark weather</tag> <icon>moon</icon><text>Moon</text></item>
                                            <item effect="material"> <tag>dark weather</tag> <icon>cloud</icon><text>Cloud</text></item>
                                            <item effect="material"> <tag> snow</tag> <icon>weather</icon><text>Weather</text></item>
                                            <item effect="material"> <tag>hot cold </tag> <icon>temperature</icon><text>Temperature</text></item>

                                            <item effect="material"> <tag>menu</tag> <icon>more</icon><text>More</text></item>
                                            <item effect="material"> <tag>menu</tag> <icon>options</icon><text>Option</text></item>
                                            <item effect="material"> <tag>map</tag> <icon>pin</icon><text>Pin</text></item>
                                            <item effect="material"> <tag></tag> <icon>play_filled</icon><text>Play</text></item>
                                            <item effect="material"> <tag></tag> <icon>play</icon><text>Play</text></item>
                                            <item effect="material"> <tag></tag> <icon>Pause</icon><text>Pause</text></item>
                                            <item effect="material"> <tag></tag> <icon>volume_up</icon><text>Speaker</text></item>
                                            <item effect="material"> <tag></tag> <icon>volume_off</icon><text>Speaker</text></item>
                                            <item effect="material"> <tag>volume</tag> <icon>mute</icon><text>Speaker</text></item>
                                            <item effect="material"> <tag>add add</tag> <icon>plus</icon><text>Plus</text></item>
                                            <item effect="material"> <tag>add add</tag> <icon>create</icon><text>Create</text></item>

                                            <item effect="material"> <tag>arrow</tag> <icon>right</icon><text>Right</text></item>
                                            <item effect="material"> <tag>disk</tag> <icon>save</icon><text></text>Save</item>
                                            <item effect="material"> <tag>find magnifier</tag> <icon>search</icon><text>Search</text></item>
                                            <item effect="material"> <tag>gear tool</tag> <icon>settings</icon><text>Setting</text></item>
                                            <item effect="material"> <tag>gear tool</tag> <icon>settings_filled</icon><text>Setting</text></item>
                                            <item effect="material"> <tag></tag> <icon>share</icon><text>Share</text></item>
                                        </wrap>

                                    </grid>

                                    <grid counter={""}>
                                        <toolbar><count>0</count>  <separator vertical={""}></separator><div className={"button"}>Section Title</div></toolbar>
                                        <wrap>

                                            <item effect="material"> <tag>filter</tag> <icon>sort</icon><text>Filter</text></item>
                                            <item effect="material"> <tag></tag> <icon>star_filled</icon><text>Star</text></item>
                                            <item effect="material"> <tag></tag> <icon>star</icon><text>Star</text></item>
                                            <item effect="material"> <tag></tag> <icon>table</icon><text>Table</text></item>
                                            <item effect="material"> <tag></tag> <icon>tag</icon><text>Tag</text></item>
                                            <item effect="material"> <tag></tag> <icon>tag_filled</icon><text>Tag</text></item>
                                            <item effect="material"> <tag>view</tag> <icon>thumbnail</icon><text>Thumbnail</text></item>
                                            <item effect="material"> <tag></tag> <icon>ticket</icon><text>Ticket</text></item>
                                            <item effect="material"> <tag>lock</tag> <icon>unlock</icon><text>Lock</text></item>
                                            <item effect="material"> <tag>arrow</tag> <icon>up</icon><text>Up</text></item>
                                            <item effect="material"> <tag>arrow</tag> <icon>upload</icon><text>Upload</text></item>
                                            <item effect="material"> <tag>account</tag> <icon>user</icon><text>User</text></item>
                                            <item effect="material"> <tag>account user</tag> <icon>account</icon><text>Account</text></item>
                                        </wrap>

                                    </grid>

                                    <grid counter={""}>
                                        <toolbar><count data-count="1024">0</count>  <separator vertical={""}></separator><div className={"button"}>Video</div></toolbar>
                                        <wrap>
                                            <item effect="material"> <tag>video</tag> <icon>captions</icon><text>Captions</text></item>
                                            <item effect="material"> <tag>video</tag> <icon>captions_filled</icon><text>Captions</text></item>

                                            <item effect="material"> <tag>video</tag> <icon>720</icon><text>720</text></item>
                                            <item effect="material"> <tag>full hd </tag> <icon>1080</icon><text>1080</text></item>

                                            <item effect="material"> <tag>video</tag> <icon>hd</icon><text>High Definition</text></item>
                                            <item effect="material"> <tag>video</tag> <icon>fhd</icon><text>Full HD</text></item>
                                            <item effect="material"> <tag>video</tag> <icon>uhd</icon><text>Ultra HD</text></item>
                                            <item effect="material"> <tag>video</tag> <icon>hdr</icon><text>High Dinamic Range</text></item>

                                            <item effect="material"> <tag>video</tag> <icon>2k</icon><text>2K</text></item>
                                            <item effect="material"> <tag>video</tag> <icon>4k</icon><text>4K</text></item>
                                            <item effect="material"> <tag>video</tag> <icon>shd</icon><text>Super HD</text></item>






                                        </wrap>

                                    </grid>



                                    <grid counter={""}>
                                        <toolbar><count>0</count>  <separator vertical={""}></separator><div className={"button"}>Editor</div></toolbar>
                                        <wrap>

                                            <item effect="material"> <tag></tag><icon>center</icon><text>Center</text></item>

                                            <item effect="material"> <tag></tag><icon>align_left</icon><text>Align Left</text></item>
                                            <item effect="material"> <tag></tag><icon>align_right</icon><text>Align Right</text></item>
                                            <item effect="material"> <tag></tag><icon>align_top</icon><text>Align Top</text></item>
                                            <item effect="material"> <tag></tag><icon>align_bottom</icon><text>Align Bottom</text></item>
                                            <item effect="material"> <tag></tag><icon>align_middle</icon><text>Align Middle</text></item>
                                            <item effect="material"> <tag></tag><icon>bold</icon><text></text>Bold</item>
                                            <item effect="material"> <tag></tag><icon>underline</icon><text>Underline</text></item>
                                            <item effect="material"> <tag></tag><icon>italic</icon><text>Italic</text></item>

                                            <item effect="material"> <tag></tag><icon>bullets</icon><text>bullets</text></item>




                                            <item effect="material"> <tag></tag><icon>strikethrough</icon><text>Strikethrough</text></item>
                                            <item effect="material"> <tag></tag><icon>t_text</icon><text>Text</text></item>
                                            <item effect="material"> <tag></tag><icon>text_shadow</icon><text>Text Effect</text></item>
                                            <item effect="material"> <tag></tag><icon>text_transform</icon><text>Uppercase</text></item>
                                            <item effect="material"> <tag></tag><icon>text</icon><text>Text</text></item>


                                            <item effect="material"> <tag></tag><icon>left_text</icon><text>Text</text></item>
                                            <item effect="material"> <tag></tag><icon>right_text</icon><text>Text</text></item>


                                            <item effect="material"> <tag></tag><icon>uppercase</icon><text>Text</text></item>
                                            <item effect="material"> <tag></tag><icon>lowercase</icon><text>Text</text></item>



                                        </wrap>

                                    </grid>


                                    <grid counter={""}>
                                        <toolbar><count>0</count>  <separator vertical={""}></separator><div className={"button"}>Shopping</div></toolbar>
                                        <wrap>

                                            <item effect="material"> <tag></tag><icon>storefront</icon><text>Store</text></item>
                                            <item effect="material"> <tag></tag><icon>shopping_cart_filled</icon><text>Cart</text></item>
                                            <item effect="material"> <tag></tag><icon>wallet_filled</icon><text>Wallet</text></item>
                                            <item effect="material"> <tag></tag><icon>shopping_bag_filled</icon><text>Bag</text></item>
                                            <item effect="material"> <tag></tag><icon>local_mall_filled</icon><text>Mall</text></item>
                                            <item effect="material"> <tag></tag><icon>shopping_basket_filled</icon><text>Basket</text></item>

                                            <item effect="material"> <tag></tag><icon>store_filled</icon><text>Store</text></item>
                                            <item effect="material"> <tag></tag><icon>shopping_cart</icon><text>Cart</text></item>
                                            <item effect="material"> <tag></tag><icon>wallet</icon><text>Wallet</text></item>
                                            <item effect="material"> <tag> card credit</tag><icon>payment</icon><text>Payment</text></item>
                                            <item effect="material"> <tag></tag><icon>shopping_bag</icon><text>Bag</text></item>
                                            <item effect="material"> <tag></tag><icon>local_mall</icon><text>Mall</text></item>

                                            <item effect="material"> <tag></tag><icon>shopping_basket</icon><text>Basket</text></item>
                                            <item effect="material"> <tag></tag><icon>giftcard</icon><text>Gift</text></item>
                                            <item effect="material"> <tag></tag><icon>store</icon><text>Store</text></item>


                                        </wrap>

                                    </grid>


                                    <grid counter={""}>
                                        <toolbar><count data-count="1024">0</count>  <separator vertical={""}></separator><div className={"button"}>Section Title</div></toolbar>
                                        <wrap>

                                            <item effect="material">
                                                <tag></tag>
                                                <icon_color>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                        <circle fill="#4086cd" cx="15" cy="15" r="12" />
                                                        <path fill="#ffffff" d="M802,551.294a5.173,5.173,0,0,1-1.529.447,2.762,2.762,0,0,0,1.168-1.54,5.239,5.239,0,0,1-1.689.679,2.58,2.58,0,0,0-1.963-.88,2.719,2.719,0,0,0-2.653,2.776,3.034,3.034,0,0,0,.068.634,7.478,7.478,0,0,1-5.491-2.9,2.835,2.835,0,0,0-.36,1.391,2.764,2.764,0,0,0,1.186,2.3,2.553,2.553,0,0,1-1.211-.324v0.02a2.751,2.751,0,0,0,2.137,2.724,2.516,2.516,0,0,1-.7.1,2.487,2.487,0,0,1-.5-0.052,2.674,2.674,0,0,0,2.485,1.928A5.194,5.194,0,0,1,789,559.751,7.321,7.321,0,0,0,793.088,561a7.711,7.711,0,0,0,7.585-7.9c0-.123,0-0.239-0.006-0.362A5.461,5.461,0,0,0,802,551.294Z" transform="translate(-780 -540)" />
                                                    </svg>
                                                </icon_color>
                                                <text>Twitter</text>
                                            </item>

                                            <item effect="material">
                                                <tag></tag>
                                                <icon_color>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                        <circle fill="#57bb63" cx="15" cy="15" r="12" />
                                                        <path fill="#ffffff" d="M885.128,561.646a6.884,6.884,0,0,1-3.324-.849L878,562.007l1.24-3.661a6.761,6.761,0,0,1-.986-3.523A6.876,6.876,0,1,1,885.128,561.646Zm0-12.559a5.765,5.765,0,0,0-5.781,5.736,5.675,5.675,0,0,0,1.1,3.363l-0.722,2.131,2.221-.707a5.751,5.751,0,1,0,3.181-10.523h0ZM888.6,556.4a0.847,0.847,0,0,0-.323-0.2c-0.168-.083-1-0.488-1.151-0.543a0.265,0.265,0,0,0-.38.083c-0.112.168-.435,0.544-0.533,0.656a0.26,0.26,0,0,1-.365.042,4.632,4.632,0,0,1-1.356-.83,5.031,5.031,0,0,1-.937-1.158,0.241,0.241,0,0,1,.074-0.341c0.075-.075.168-0.195,0.252-0.293a1.112,1.112,0,0,0,.169-0.279,0.307,0.307,0,0,0-.014-0.293c-0.042-.084-0.38-0.907-0.52-1.241s-0.28-.279-0.379-0.279-0.211-.014-0.323-0.014a0.625,0.625,0,0,0-.45.209,1.871,1.871,0,0,0-.589,1.395,3.228,3.228,0,0,0,.688,1.729,6.889,6.889,0,0,0,2.879,2.525c1.713,0.669,1.713.446,2.023,0.418a1.707,1.707,0,0,0,1.137-.795,1.392,1.392,0,0,0,.1-0.8h0Z" transform="translate(-870 -540)" />
                                                    </svg>

                                                </icon_color>
                                                <text>Whatsapp</text>
                                            </item>

                                            <item effect="material">
                                                <tag></tag>
                                                <icon_color>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                        <circle fill="#346da6" className={"cls-1"} cx="15" cy="15" r="12" />
                                                        <path fill="#fff" className={"cls-2"} d="M823.31,562.006V555H822v-2.324h1.307v-1.406c0-1.895.52-3.262,2.423-3.262H828v2.319H826.4a0.975,0.975,0,0,0-.981,1.184v1.165h2.458L827.544,555h-2.122v7.011H823.31Z" transform="translate(-810 -540)" />
                                                    </svg>

                                                </icon_color>
                                                <text>Facebook</text>
                                            </item>

                                            <item effect="material">
                                                <tag></tag>
                                                <icon_color>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                        <circle fill="#006097" className={"cls-1"} cx="15" cy="15" r="12" />
                                                        <path fill="#fff" d="M852.1,560v-6.608h-2.034V560H852.1Zm0-8.95a1.049,1.049,0,1,0-1.067,1.042h0.012A1.007,1.007,0,0,0,852.1,551.047Zm3.318,8.95v-3.765a1.543,1.543,0,0,1,.073-0.547,1.273,1.273,0,0,1,2.459.547V560H860v-3.866c0-2.07-1.1-3.034-2.569-3.034a2.221,2.221,0,0,0-2.026,1.136l0.015-.884h-2.044c0.029,0.633,0,6.648,0,6.648h2.044Z" transform="translate(-840 -540)" />
                                                    </svg>

                                                </icon_color>
                                                <text>LinkedIn</text>
                                            </item>

                                        </wrap>

                                    </grid>


                                    <grid counter={""}>
                                        <toolbar><count data-count="1024">0</count>  <separator vertical={""}></separator><div className={"button"}>Playlist Layout</div></toolbar>
                                        <wrap>
                                            <item effect="material"> <tag></tag> <icon>layout_9</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_9_1</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_10_2</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_10_1</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_11</icon><text>Layout</text></item>

                                            <item effect="material"> <tag></tag> <icon>layout_1_1</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_1_2</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_1</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_2_1</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_2_2</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_2</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_3_1</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_3_2</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_3_3</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_3</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_4_1</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_4_2</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_4_3</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_4</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_5_1</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_5_2</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_5_3</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_5</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_6_1</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_6_2</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_6_3</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_6</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_7_1</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_7_2</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_7_3</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_7</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_8_1</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_8_2</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_8_3</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_8</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_portrait</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout_portrait_1</icon><text>Layout</text></item>
                                            <item effect="material"> <tag></tag> <icon>layout</icon><text>Layout</text></item>
                                        </wrap>

                                    </grid>
                                </scroller>
                            </view>
                        </container>

                    </div>
                    <div>
                        <view vertical="">
                            <view>
                                <toolbar scroll=""><wrap><div className="button">Button & Toggles</div></wrap></toolbar>

                                <list demo="">
                                    <demo_wrap>
                                        <div effect="material" className="button primary"><text>Primary Button</text><icon>star</icon></div>
                                        <div effect="material" className="button accent"><icon>search</icon><text>Accent Button</text></div>
                                        <div effect="material" className="button"><text>Simple Button</text></div>
                                        <a effect="material" href="#" className="button"><icon>heart</icon><text>Anchor Button</text></a>
                                        <div effect="material" data-tip="This Is Responsive Button, Resize Browser Window" /*effect="material"*/ className="button adaptive white user_name"><icon>pin</icon><text>Responsive button</text></div>
                                    </demo_wrap>
                                    <demo_wrap>
                                        <div effect="material" className="button error"><tip>Reconfiguring ...</tip><separator vertical=""></separator><text>Cancel</text></div>
                                    </demo_wrap>
                                    <demo_wrap>
                                        <div effect="material" data-tip="This Is Mini Button" className="button primary mini"><icon>bulb</icon></div>
                                        <div effect="material" data-tip="This Is Mini Button" className="button accent mini"><icon>photo</icon></div>
                                        <div effect="material" data-tip="This Is Mini Button" className="button mini"><icon>share</icon></div>
                                    </demo_wrap>
                                    <demo_wrap>
                                        <div effect="material" className="button primary large"><icon>play</icon><text>Large Button</text></div>
                                        <div effect="material" className="button accent large"><icon>mail</icon><text>Large Button</text></div>
                                        <div effect="material" className="button large"><icon>edit</icon><text>Large Button</text></div>
                                    </demo_wrap>
                                    <demo_wrap>
                                        <label classic=""><input type="checkbox" /><box><check></check></box><text>Simple Checkbox</text></label>
                                        <label classic=""><input type="checkbox" /><box><check></check></box><text>Simple Checkbox</text></label>
                                        <separator vertical=""></separator>
                                        <label classic=""><input defaultChecked={true} type="checkbox" disabled="disabled" /><box><check></check></box><text>Simple Checkbox</text></label>
                                        <label classic=""><input type="checkbox" /><box><check></check></box><text><separator vertical=""></separator></text>Simple Checkbox</label>
                                        <separator vertical=""></separator>
                                        <label classic=""><input type="checkbox" /><text>Simple Checkbox</text><separator vertical=""></separator><box><check></check></box></label>
                                    </demo_wrap>

                                    <demo_wrap>
                                        <label checkbox=""><input type="checkbox" /><box><check></check></box><text>Checkbox</text></label>
                                        <label checkbox=""><input disabled="disabled" defaultChecked={true} type="checkbox" /><box><check></check></box><text>Disabled Checkbox</text></label>
                                        <label checkbox="" simple=""><input type="checkbox" /><box><check></check></box><text></text></label>
                                        <separator vertical=""></separator>
                                        <label checkbox=""><input defaultChecked={true} type="checkbox" /><box><check></check></box><text>Checkbox</text></label>
                                    </demo_wrap>


                                    <demo_wrap>
                                        <label data-tip="This can be used only to activate section, examples on <b>Input Forms</b> Section" simple="" checkbox="">
                                            <name><b>Section Title</b></name>
                                            <separator vertical=""></separator>
                                            <input type="checkbox" />
                                            <tip></tip>
                                        </label>

                                    </demo_wrap>



                                    <demo_wrap>
                                        <label radio=""><input name="sample" defaultChecked={true} type="radio" /><box><check></check></box><text>Radio Button Title</text></label>
                                        <label radio=""><input name="sample" type="radio" /><box><check></check></box><text>Radio Button Title</text></label>
                                        <separator vertical=""></separator>
                                        <label radio=""><input disabled="disabled" defaultChecked={true} name="sample" type="radio" /><box><check></check></box><text>Disabled Radio Button</text></label>
                                    </demo_wrap>
                                </list>

                            </view>
                            <view>
                                <toolbar><div className="caption"><text><b>Badges</b></text></div></toolbar>
                                <list demo="">

                                    <demo_wrap>
                                        <group>
                                            <badge>Simple Outline Badge</badge>
                                            <separator vertical=""></separator>
                                            <badge accent="" filled="">Accent Color Badge</badge>
                                        </group>
                                    </demo_wrap>

                                    <demo_wrap>
                                        <group>

                                            <badge error="" filled="">Error</badge>
                                            <badge new="" filled="">New Item</badge>
                                            <badge private="" filled="">Private Item</badge>
                                            <badge process="" filled="">Yellow Item</badge>
                                        </group>
                                    </demo_wrap>


                                    <demo_wrap>
                                        <group>

                                            <badge error="">Error</badge>
                                            <badge new="">New Item</badge>
                                            <badge private="">Private Item</badge>
                                        </group>
                                    </demo_wrap>
                                    <demo_wrap>
                                        <group compact=""><badge><text>10:00 - 12:00</text></badge></group>
                                        <separator vertical=""></separator>
                                        <group compact=""><badge><icon green="">done</icon><text>10:00 - 12:00</text></badge></group>
                                    </demo_wrap>

                                </list>
                            </view>
                        </view>
                    </div>

                    <div>
                        <container>
                            <view>
                                <toolbar>
                                    <div id="btnActionControl" data-tip="This Will Open Context menu" effect="material" className="button mini"><icon>options</icon></div>
                                    <separator vertical=""></separator>
                                    <div style={{ marginLeft: "auto" }} id="btnSort" effect="material" className="button mini"><icon>sort</icon></div>
                                </toolbar>



                            </view>
                        </container>
                    </div>
                    <div>
                        <TemplateDetails />
                    </div>

                    <div>
                        <view>
                            <div className="alert_contianer">
                                <alert warning="warning">
                                    <icon>info</icon>
                                    <text marquee="marquee"><span>Your email address is not verified. Verify it to receive emails from us.</span></text>
                                    <action>
                                        <div className="button accent"><text>Verify</text></div>
                                        <separator vertical="vertical"></separator>
                                        <div className="button mini close"><icon>close</icon></div>
                                    </action>
                                </alert>
                            </div>

                            <toolbar scroll="scroll">
                                <wrap>
                                    <div effect="material" className="button primary adaptive"><icon>save</icon><text>Save Changes</text></div>

                                    <separator vertical=""></separator>

                                    <div effect="material" className="button "><text>Delete Overlay</text></div>

                                    <separator vertical=""></separator>

                                    <div className="button mini  " data-tip="Create New Object"><icon>plus</icon></div>
                                    <separator vertical=""></separator>
                                    <div className="button mini " data-tip="Copy"><icon>copy</icon></div>
                                    <button disabled className="button mini" data-tip="paste "><icon>Paste</icon></button>
                                    <separator vertical=""></separator>
                                    <div className="button mini " data-tip="Remove Selected Item"><icon>delete</icon></div>

                                </wrap>
                            </toolbar>
                            <toolbar scroll="scroll" details="details" >
                                <wrap>
                                    <action>
                                        <wrap>
                                            <div className="form_fields line">
                                                <div className="data_label">Feed URL</div>
                                                <input name="FeedURL" type="text" setvalue="" />
                                            </div>
                                            <separator vertical=""></separator>
                                            <div className="form_fields line small">
                                                <div className="data_label">Durations<tip>sec</tip></div>
                                                <input name="Duration" type="text" />
                                            </div>
                                        </wrap>
                                    </action>
                                </wrap>
                            </toolbar>
                            <toolbar scroll="scroll" details="details" highlight="highlight">
                                <wrap>
                                    <action>

                                        <wrap>
                                            <div className="form_fields line">
                                                <div className="data_label">Drop Down List</div>
                                                <Select placeholder="Select Character" id="ddlAntd" size="default" defaultValue={"a1"}>
                                                    {children}
                                                </Select>
                                            </div>

                                            <div className="form_fields line">
                                                <div className="data_label">Combo Box</div>
                                                <Select
                                                    showSearch
                                                    placeholder="Select Character" id="ddlgAntd"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => //NOSONAR
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {children}
                                                </Select>
                                            </div>

                                            <div className="form_fields line">
                                                <div className="data_label">Numeric</div>
                                                <InputNumber min={1} max={100000} defaultValue={3} />
                                            </div>

                                            <div className="form_fields line">
                                                <div className="data_label">Multi Select</div>
                                                <Select
                                                    mode="multiple"
                                                    size="default"
                                                    placeholder="Please select"
                                                    defaultValue={['a10', 'c12']}>
                                                    {children}
                                                </Select>
                                            </div>
                                            <div className="form_fields line">
                                                <div className="data_label">Time Picker</div>
                                                <TimePicker name="tpAntd" defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                                            </div>

                                            <div className="form_fields line">
                                                <div className="data_label">Date Picker</div>
                                                <DatePicker name="dtpAntd" defaultValue={moment('2015/01/01', 'YYYY/MM/DD')} format='YYYY/MM/DD' />
                                            </div>

                                        </wrap>
                                    </action>
                                </wrap>
                            </toolbar>
                            <toolbar highlight="highlight" scroll="scroll" multiline="multiline">
                                <wrap wide="wide">
                                    <action>
                                        <wrap>
                                            <div className="form_fields line">
                                                <div className="data_label">Letter</div>
                                                <div role-type="checklist" className="option_bar">
                                                    <label>
                                                        <input type="checkbox" name="lettres" defaultValue={"A"} />
                                                        <wrap effect="material"><text>A</text></wrap>
                                                    </label>

                                                    <label>
                                                        <input type="checkbox" name="lettres" defaultValue={"B"} />
                                                        <wrap effect="material"><text>B</text></wrap>
                                                    </label>
                                                    <label>
                                                        <input type="checkbox" name="lettres" defaultValue={"C"} />
                                                        <wrap effect="material"><text>C</text> </wrap>
                                                    </label>
                                                    <label>
                                                        <input type="checkbox" name="lettres" defaultValue={"D"} />
                                                        <wrap effect="material"><text>D</text> </wrap>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form_fields line small">
                                                <div className="data_label">Add Letter</div>
                                                <div className="input_cont">
                                                    <input type="text" name="lettre" placeholder="" maxLength="1" defaultValue={""} />
                                                    <button className="button micro" type="button"><icon>plus</icon></button>
                                                </div>
                                            </div>
                                        </wrap>
                                    </action>

                                    <action>
                                        <wrap>
                                            <div className="form_fields line small">
                                                <div className="data_label">Quantite</div>
                                                <div className="input_cont">
                                                    <input name="quantite" type="text" placeholder="quantity" defaultValue={"1"} />
                                                    <i></i>
                                                </div>
                                            </div>
                                            <div className="form_fields line">
                                                <input name="periode" type="text" placeholder="periode" defaultValue={""} />
                                            </div>
                                            <div className="form_fields large line">
                                                <input name="commentaire" type="text" placeholder="comment" defaultValue={""} />
                                            </div>
                                            <div className="form_fields line">
                                                <input name="complement" type="text" placeholder="complement" defaultValue={""} />
                                            </div>
                                        </wrap>
                                    </action>
                                </wrap>
                            </toolbar>
                        </view>
                    </div>

                    <div>
                        <view>
                            <Table
                                bordered
                                components={components}
                                columns={_columns}
                                dataSource={data}
                                scroll={{ x: 1500, y: 800 }}
                            />
                        </view>
                    </div>




                    <div>
                        <toolbar>
                            <text><b>Data length</b></text>
                            <group>
                                <separator vertical=""></separator>
                                <badge data_length="small">small</badge>
                                <badge data_length="x-small">x-small</badge>
                                <badge data_length="xx-small">xx-small</badge>
                                <badge data_length="xxx-small">xxx-small</badge>
                                <badge data_length="medium">medium</badge>
                            </group>
                        </toolbar>
                        <view vertical="" adaptive="">
                            <view mini="">
                                <toolbar>Currently in Use</toolbar>
                                <view space="" scroll="">
                                    <text data-weight="700" data-size="xxx-large" wrap="">Quicksand</text>
                                    <icon></icon>
                                    <text light="" wrap="">
                                        Quicksand is a display sans serif with rounded terminals. The project was initiated by Andrew Paglinawan in 2008 using geometric shapes as a core foundation.
                                    </text>
                                    <icon></icon>
                                    <text wrap="" red="">
                                        Quicksand is a display sans serif with rounded terminals. The project was initiated by Andrew Paglinawan in 2008 using geometric shapes as a core foundation.
                                    </text>
                                    <icon></icon>
                                    <text  wrap="" green="">
                                        Quicksand is a display sans serif with rounded terminals. The project was initiated by Andrew Paglinawan in 2008 using geometric shapes as a core foundation.
                                    </text>
                                    <icon></icon>
                                    <text wrap="" yellow="">
                                        Quicksand is a display sans serif with rounded terminals. The project was initiated by Andrew Paglinawan in 2008 using geometric shapes as a core foundation.
                                    </text>
                                    <icon></icon>
                                    <text wrap="" blue="">
                                        Quicksand is a display sans serif with rounded terminals. The project was initiated by Andrew Paglinawan in 2008 using geometric shapes as a core foundation.
                                    </text>
                                    <icon></icon>
                                    <text light="" wrap="" orange="">
                                        Quicksand is a display sans serif with rounded terminals. The project was initiated by Andrew Paglinawan in 2008 using geometric shapes as a core foundation.
                                    </text>
                                </view>
                            </view>
                            <view mini="">
                                <toolbar>
                                    <text>Preview</text>
                                </toolbar>

                                <view space="" scroll="">
                                    <text light="">Thin 100</text>
                                    <icon></icon>
                                    <text demotext="" wrap="" data-weight="100" data-size="x-large">The quick brown fox jumps over the lazy dog</text>
                                    <separator horizontal=""></separator>

                                    <text light="">Light 300</text>
                                    <icon></icon>
                                    <text demotext="" wrap="" data-weight="300" data-size="x-large">The quick brown fox jumps over the lazy dog</text>
                                    <separator horizontal=""></separator>

                                    <text light="">Regular 400</text>
                                    <icon></icon>
                                    <text demotext="" wrap="" data-weight="" data-size="x-large">The quick brown fox jumps over the lazy dog</text>
                                    <separator horizontal=""></separator>

                                    <text light="">Medium 500</text>
                                    <icon></icon>
                                    <text demotext="" wrap="" data-weight="500" data-size="x-large">The quick brown fox jumps over the lazy dog</text>
                                    <separator horizontal=""></separator>

                                    <text light="">Thin 600</text>
                                    <icon></icon>
                                    <text demotext="" wrap="" data-weight="600" data-size="x-large">The quick brown fox jumps over the lazy dog</text>
                                    <separator horizontal=""></separator>

                                    <text light="">Thin 700</text>
                                    <icon></icon>
                                    <text demotext="" wrap="" data-weight="700" data-size="x-large">The quick brown fox jumps over the lazy dog</text>
                                    <separator horizontal=""></separator>

                                    <text light="">Bold 800</text>
                                    <icon></icon>
                                    <text demotext="" wrap="" data-weight="800" data-size="x-large">The quick brown fox jumps over the lazy dog</text>
                                </view>
                            </view>
                            <view>
                                <toolbar>Glyphs</toolbar>
                                <view scroll="">
                                    <grid>
                                        <wrap mini="">

                                            <div>A</div>
                                            <div>B</div>
                                            <div>C</div>
                                            <div>Č</div>
                                            <div>Ć</div>
                                            <div>D</div>
                                            <div>Đ</div>
                                            <div>E</div>
                                            <div>F</div>
                                            <div>G</div>
                                            <div>H</div>
                                            <div>I</div>
                                            <div>J</div>
                                            <div>K</div>
                                            <div>L</div>
                                            <div>M</div>
                                            <div>N</div>
                                            <div>O</div>
                                            <div>P</div>
                                            <div>Q</div>
                                            <div>R</div>
                                            <div>S</div>
                                            <div>Š</div>
                                            <div>T</div>
                                            <div>U</div>
                                            <div>V</div>
                                            <div>W</div>
                                            <div>X</div>
                                            <div>Y</div>
                                            <div>Z</div>
                                            <div>Ž</div>
                                            <div>a</div>
                                            <div>b</div>
                                            <div>c</div>
                                            <div>č</div>
                                            <div>ć</div>
                                            <div>d</div>
                                            <div>đ</div>
                                            <div>e</div>
                                            <div>f</div>
                                            <div>g</div>
                                            <div>h</div>
                                            <div>i</div>
                                            <div>j</div>
                                            <div>k</div>
                                            <div>l</div>
                                            <div>m</div>
                                            <div>n</div>
                                            <div>o</div>
                                            <div>p</div>
                                            <div>q</div>
                                            <div>r</div>
                                            <div>s</div>
                                            <div>š</div>
                                            <div>t</div>
                                            <div>u</div>
                                            <div>v</div>
                                            <div>w</div>
                                            <div>x</div>
                                            <div>y</div>
                                            <div>z</div>
                                            <div>ž</div>
                                            <div>А</div>
                                            <div>Б</div>
                                            <div>В</div>
                                            <div>Г</div>
                                            <div>Ґ</div>
                                            <div>Д</div>
                                            <div>Ђ</div>
                                            <div>Е</div>
                                            <div>Ё</div>
                                            <div>Є</div>
                                            <div>Ж</div>
                                            <div>З</div>
                                            <div>Ѕ</div>
                                            <div>И</div>
                                            <div>І</div>
                                            <div>Ї</div>
                                            <div>Й</div>
                                            <div>Ј</div>
                                            <div>К</div>
                                            <div>Л</div>
                                            <div>Љ</div>
                                            <div>М</div>
                                            <div>Н</div>
                                            <div>Њ</div>
                                            <div>О</div>
                                            <div>П</div>
                                            <div>Р</div>
                                            <div>С</div>
                                            <div>Т</div>
                                            <div>Ћ</div>
                                            <div>У</div>
                                            <div>Ў</div>
                                            <div>Ф</div>
                                            <div>Х</div>
                                            <div>Ц</div>
                                            <div>Ч</div>
                                            <div>Џ</div>
                                            <div>Ш</div>
                                            <div>Щ</div>
                                            <div>Ъ</div>
                                            <div>Ы</div>
                                            <div>Ь</div>
                                            <div>Э</div>
                                            <div>Ю</div>
                                            <div>Я</div>
                                            <div>а</div>
                                            <div>б</div>
                                            <div>в</div>
                                            <div>г</div>
                                            <div>ґ</div>
                                            <div>д</div>
                                            <div>ђ</div>
                                            <div>е</div>
                                            <div>ё</div>
                                            <div>є</div>
                                            <div>ж</div>
                                            <div>з</div>
                                            <div>ѕ</div>
                                            <div>и</div>
                                            <div>і</div>
                                            <div>ї</div>
                                            <div>й</div>
                                            <div>ј</div>
                                            <div>к</div>
                                            <div>л</div>
                                            <div>љ</div>
                                            <div>м</div>
                                            <div>н</div>
                                            <div>њ</div>
                                            <div>о</div>
                                            <div>п</div>
                                            <div>р</div>
                                            <div>с</div>
                                            <div>т</div>
                                            <div>ћ</div>
                                            <div>у</div>
                                            <div>ў</div>
                                            <div>ф</div>
                                            <div>х</div>
                                            <div>ц</div>
                                            <div>ч</div>
                                            <div>џ</div>
                                            <div>ш</div>
                                            <div>щ</div>
                                            <div>ъ</div>
                                            <div>ы</div>
                                            <div>ь</div>
                                            <div>э</div>
                                            <div>ю</div>
                                            <div>я</div>
                                            <div>Α</div>
                                            <div>Β</div>
                                            <div>Γ</div>
                                            <div>Δ</div>
                                            <div>Ε</div>
                                            <div>Ζ</div>
                                            <div>Η</div>
                                            <div>Θ</div>
                                            <div>Ι</div>
                                            <div>Κ</div>
                                            <div>Λ</div>
                                            <div>Μ</div>
                                            <div>Ν</div>
                                            <div>Ξ</div>
                                            <div>Ο</div>
                                            <div>Π</div>
                                            <div>Ρ</div>
                                            <div>Σ</div>
                                            <div>Τ</div>
                                            <div>Υ</div>
                                            <div>Φ</div>
                                            <div>Χ</div>
                                            <div>Ψ</div>
                                            <div>Ω</div>
                                            <div>α</div>
                                            <div>β</div>
                                            <div>γ</div>
                                            <div>δ</div>
                                            <div>ε</div>
                                            <div>ζ</div>
                                            <div>η</div>
                                            <div>θ</div>
                                            <div>ι</div>
                                            <div>κ</div>
                                            <div>λ</div>
                                            <div>μ</div>
                                            <div>ν</div>
                                            <div>ξ</div>
                                            <div>ο</div>
                                            <div>π</div>
                                            <div>ρ</div>
                                            <div>σ</div>
                                            <div>τ</div>
                                            <div>υ</div>
                                            <div>φ</div>
                                            <div>χ</div>
                                            <div>ψ</div>
                                            <div>ω</div>
                                            <div>ά</div>
                                            <div>Ά</div>
                                            <div>έ</div>
                                            <div>Έ</div>
                                            <div>έ</div>
                                            <div>Ή</div>
                                            <div>ί</div>
                                            <div>ϊ</div>
                                            <div>ΐ</div>
                                            <div>Ί</div>
                                            <div>ό</div>
                                            <div>Ό</div>
                                            <div>ύ</div>
                                            <div>ΰ</div>
                                            <div>ϋ</div>
                                            <div>Ύ</div>
                                            <div>Ϋ</div>
                                            <div>Ώ</div>
                                            <div>Ă</div>
                                            <div>Â</div>
                                            <div>Ê</div>
                                            <div>Ô</div>
                                            <div>Ơ</div>
                                            <div>Ư</div>
                                            <div>ă</div>
                                            <div>â</div>
                                            <div>ê</div>
                                            <div>ô</div>
                                            <div>ơ</div>
                                            <div>ư</div>
                                            <div>1</div>
                                            <div>2</div>
                                            <div>3</div>
                                            <div>4</div>
                                            <div>5</div>
                                            <div>6</div>
                                            <div>7</div>
                                            <div>8</div>
                                            <div>9</div>
                                            <div>0</div>
                                            <div>‘</div>
                                            <div>?</div>
                                            <div>’</div>
                                            <div>“</div>
                                            <div>!</div>
                                            <div>”</div>
                                            <div>(</div>
                                            <div>%</div>
                                            <div>)</div>
                                            <div>#</div>
                                            <div>&amp;</div>
                                            <div>\</div>
                                            <div>&lt;</div>
                                            <div>-</div>
                                            <div>+</div>
                                            <div>÷</div>
                                            <div>×</div>
                                            <div>=</div>
                                            <div>&gt;</div>
                                            <div>®</div>
                                            <div>©</div>
                                            <div>$</div>
                                            <div>€</div>
                                            <div>£</div>
                                            <div>¥</div>
                                            <div>¢</div>
                                            <div>:</div>
                                            <div>;</div>
                                            <div>,</div>
                                            <div>.</div>
                                            <div>*</div>

                                        </wrap>
                                    </grid>
                                </view>
                            </view>
                        </view>
                    </div>


                    <div>
                        <view horizontal="" space="">
                            <view auto_height="" vertical="">
                                <view auto="">
                                    <demospace tip=""> <text light="" wrap="">Filter bar 1</text></demospace>

                                </view>
                                <view auto="">
                                    <demospace tip=""> <text light="" wrap="">Filter bar 2</text></demospace>
                                </view>
                            </view>
                            <view vertical="" >
                                <view mini="" >
                                    <demospace tip=""> <icon large="" light="">info</icon> <text light="" wrap="">Filter bar 3</text></demospace>
                                    <form></form>
                                </view>
                                <view horizontal="">
                                    <view auto_height="">
                                        <demospace tip=""> <text light="" wrap="">Filter bar 4</text></demospace>
                                    </view>
                                    <view>
                                        <view vertical="">
                                            <view>
                                                <list>
                                                    <wrap>
                                                        <demospace tip=""> <icon large="" light="">info</icon> <text light="" wrap="">This is adaptive switchable section, and will overlap neighbor section if it not enough room to show them side by side. Resize browser window to see it in action. This is configurable option ...</text></demospace>
                                                    </wrap>
                                                </list>
                                            </view>
                                            <view>
                                                <list>
                                                    <wrap>
                                                        <demospace tip=""> <icon large="" light="">info</icon> <text light="" wrap="">This is adaptive switchable section, and will overlap neighbor section if it not enough room to show them side by side. Resize browser window to see it in action. This is configurable option ...</text></demospace>
                                                    </wrap>
                                                </list>
                                            </view>
                                        </view>
                                        <toolbar>
                                            <action right="">
                                                <button type="submit" className="button primary"><text>Test Form Submit</text></button>
                                                <separator vertical=""></separator>
                                                <div className="button accent"><text>Secondary Action</text></div>
                                                <separator vertical=""></separator>
                                                <div className="button"><text>Disable Controls</text></div>
                                            </action>
                                        </toolbar>
                                    </view>
                                </view>
                            </view>
                        </view>
                    </div>


                    <div>
                        <view horizontal="" space="" transparent="">
                            <view vertical="" transparent="" auto_height="">
                                <view filter="" auto="" transparent="">
                                    <wrap>
                                        <div className="section_group">
                                            <number>1</number>
                                            <div className="sections auto">
                                                    <div className="form_fields">
                                                        <div className="input_cont title">
                                                            <text className=""><b>Bâtiment</b></text>
                                                            <Select placeholder="Select Character" id="ddlAntd" size="default" defaultValue={"a1"}>
                                                                {children}
                                                            </Select>
                                                            <div className="button micro primary dark"><text><b>i</b></text></div>
                                                        </div>
                                                    </div>

                                                <div className="field_group">
                                                    <div className="form_fields small">
                                                        <div className="input_cont">
                                                            <input required="required" type="text" placeholder="Placeholder" />
                                                            <text className="">T1</text>
                                                        </div>
                                                    </div>
                                                    <div className="form_fields small">
                                                        <div className="input_cont">
                                                            <input required="required" type="text" placeholder="Placeholder" />
                                                            <text className="">T4</text>
                                                        </div>
                                                    </div>
                                                    <div className="form_fields"></div>
                                                </div>

                                                <div className="field_group">
                                                    <div className="form_fields small">
                                                        <div className="input_cont">
                                                            <input required="required" type="text" placeholder="Placeholder" />
                                                            <text className="">T2</text>
                                                        </div>
                                                    </div>
                                                    <div className="form_fields small">
                                                        <div className="input_cont">
                                                            <input required="required" type="text" placeholder="Placeholder" />
                                                            <text className="">T5</text>
                                                        </div>
                                                    </div>
                                                    <div className="form_fields small">
                                                        <div className="input_cont">
                                                            <icon rotate="90" data-size="large">pause</icon>
                                                            <input required="required" type="text" placeholder="Placeholder" value="10" />
                                                            <text className="description middle" wrap="">Nombre total de logements</text>
                                                            <div className="button micro primary dark"><text><b>i</b></text></div>                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="field_group">
                                                    <div className="form_fields small">
                                                        <div className="input_cont">
                                                            <input required="required" type="text" placeholder="Placeholder" />
                                                            <text className="">T3</text>
                                                        </div>
                                                    </div>
                                                    <div className="form_fields small">
                                                        <div className="input_cont">
                                                            <input required="required" type="text" placeholder="Placeholder" />
                                                            <text className="">T6</text>
                                                        </div>
                                                    </div>
                                                    <div className="form_fields"></div>
                                                </div>
                                            </div>


                                            <div className="sections s_medium">
                                                <div className="form_fields small">
                                                    <div className="input_cont title">
                                                        <text className="" ><b>Estimation des besoins ECS</b></text>
                                                        <div className="button micro primary dark"><text><b>i</b></text></div>                                                    </div>
                                                </div>

                                                <div className="form_fields small">
                                                    <div className="input_cont">
                                                        <text className="aligned">Volume pointe 10 min</text>
                                                        <input required="required" type="text" placeholder="Placeholder" value="186" />
                                                        <text className="">litres à 60°C </text>
                                                    </div>
                                                </div>
                                                <div className="form_fields small">
                                                    <div className="input_cont">
                                                        <text className="aligned">Durée pointe pluri-horaire</text>
                                                        <input required="required" type="text" placeholder="Placeholder" value="1.39" />
                                                        <text className="">h</text>
                                                    </div>
                                                </div>
                                                <div className="form_fields small">
                                                    <div className="input_cont">
                                                        <text className="aligned">Volume pointe pluri-horaire</text>
                                                        <input required="required" type="text" placeholder="Placeholder" value="718" />
                                                        <text className="">litres à 60°C </text>
                                                    </div>
                                                </div>
                                                <div className="form_fields small">
                                                    <div className="input_cont">
                                                        <text className="aligned">Volume journalier</text>
                                                        <input required="required" type="text" placeholder="Placeholder" value="1050" />
                                                        <text className="">litres à 60°C </text>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <toolbar transparent="">
                                            <action>
                                                <button type="submit" className="button primary"><text>Test Form Submit</text></button>
                                            </action>
                                        </toolbar>
                                    </wrap>
                                </view>

                                <view filter="" auto="" transparent="">
                                    <wrap>
                                        <div className="section_group">
                                            <number>2</number>
                                            <div className="sections s_medium">
                                                <div className="form_fields ">
                                                    <div className="input_cont title">
                                                        <text className=""><b>Mode de production d’ECS </b></text>
                                                    </div>
                                                </div>
                                                <label radio=""><input name="sample" type="radio" /><box><check></check></box><text>Instantané (dont Hygiatherm)</text></label>
                                                <label radio=""><input name="sample" type="radio" /><box><check></check></box><text>Semi-instantané/semi-accumulé</text></label>
                                                <label radio=""><input name="sample" type="radio" /><box><check></check></box><text>Accumulé</text></label>
                                            </div>
                                            <div className="sections s_medium">
                                                <div className="form_fields ">
                                                    <div className="input_cont title">
                                                        <text className=""><b>Système</b></text>
                                                    </div>
                                                </div>
                                                <label radio=""><input name="sample" type="radio" /><box><check></check></box><text>Echangeur + ballon ECS</text></label>
                                                <label radio=""><input name="sample" type="radio" /><box><check></check></box><text>Ballon échangeur</text></label>
                                                <label radio=""><input name="sample" type="radio" /><box><check></check></box><text>Accumulateur gaz</text></label>
                                                <label radio=""><input name="sample" type="radio" /><box><check></check></box><text>Pompe à chaleur</text></label>
                                            </div>
                                            </div>
                                    </wrap>
                                </view>
                            </view>
                            <view vertical="" transparent="">
                                <view mini="" filter="" transparent="">
                                    <wrap>
                                        <form>
                                            <div className="section_group">
                                                <number>3</number>
                                                <div className="sections s_medium">
                                                    <div className="form_fields">
                                                        <div className="input_cont title">
                                                            <text className="center" ><b>Calcul des besoins <br /> (Puissance / Volume)</b></text>
                                                        </div>
                                                    </div>
                                                    <div className="form_fields">
                                                        <div className="input_cont">
                                                            <text className="">Pertes thermiques du bouclage</text>
                                                        </div>
                                                    </div>
                                                    <div className="form_fields">
                                                        <div className="input_cont">
                                                            <Select placeholder="Select Character" id="ddlAntd" size="default" defaultValue={"a3"}>
                                                                {children}
                                                            </Select>
                                                            <div className="button micro primary dark"><text><b>i</b></text></div>                                                        </div>
                                                    </div>
                                                    <div className="form_fields small">
                                                        <div className="input_cont">
                                                            <text className="aligned"> Perte de bouclage</text>
                                                            <input required="" type="text" placeholder="Placeholder" />
                                                        </div>
                                                    </div>
                                                    <div className="form_fields small">
                                                        <div className="input_cont">
                                                            <text className="aligned">Température EFS</text>
                                                            <Select placeholder="Select Character" id="ddlAntd" size="default" defaultValue={"a1"}>
                                                                {children}
                                                            </Select>
                                                            <text className="">C</text>
                                                        </div>
                                                    </div>
                                                    <div className="form_fields small">
                                                        <div className="input_cont">
                                                            <text className="aligned">Température stockage ECS</text>
                                                            <Select placeholder="Select Character" id="ddlAntd" size="default" defaultValue={"a2"}>
                                                                {children}
                                                            </Select>
                                                            <text className="">C</text>
                                                        </div>
                                                    </div>
                                                    <div className="form_fields small">
                                                        <div className="input_cont">
                                                            <text className="aligned">Température primaire</text>
                                                            <Select placeholder="Select Character" id="ddlAntd" size="default" defaultValue={"a3"}>
                                                                {children}
                                                            </Select>
                                                            <text className="">C</text>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                         </form>
                                    </wrap>
                                </view>


                                <view horizontal="" transparent="">
                                    <view filter="" auto_height="">
                                        <wrap>
                                            <div className="section_group">
                                                <number>4</number>
                                                <div className="sections extra">
                                                    <div className="form_fields">
                                                        <div className="input_cont title">
                                                            <text className=""><b>Autres critères de choix de la solution</b></text>
                                                        </div>
                                                    </div>
                                                    <div className="field_group">
                                                        <div className="form_fields middle">
                                                            <div className="input_cont">
                                                                <text className="">Type De Ballon</text>
                                                                <Select placeholder="Select Character" id="ddlAntd" size="default" defaultValue={"a1"}>
                                                                    {children}
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div className="form_fields small">
                                                            <div className="input_cont">
                                                                <text className="">Largeur passage de porte</text>
                                                                <input required="" type="text" placeholder="Placeholder" />
                                                                <text className="">cm</text>
                                                            </div>
                                                        </div>
                                                        <div className="form_fields small">
                                                            <div className="input_cont">
                                                                <text className="">hauteur sous plafond</text>
                                                                <input required="" type="text" placeholder="Placeholder" />
                                                                <text className="">cm</text>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </wrap>
                                    </view>

                                    <view vertical="">
                                        <view scroll="" transparent="">
                                            <collapsible fixed="">
                                                <div className="section_group">
                                                    <div className="sections wide">
                                                        <div className="form_fields">
                                                            <div className="section_title"><text><b>Solution Title Goes Here</b></text></div><div className="form_separator"></div>
                                                            <group><badge filled="">150 $</badge></group>
                                                            <div className="data_help">
                                                                <text><span>Largeur de passage mini: <b>1020 mm</b></span></text>
                                                                <text><span>Hauteur mini du local: <b>2415 mm</b></span></text>
                                                            </div>
                                                            <div className="input_cont">
                                                                <thumb className="select">
                                                                    <wrap>
                                                                        <picture middle="" style={{ backgroundImage: "url(/content/img/test/item1.png)" }}>
                                                                            <action fit=""><a click="" href="#" title="Click to more info"></a></action>
                                                                        </picture>
                                                                        <detail>
                                                                            <text><span>Item Name</span></text>
                                                                        </detail>
                                                                    </wrap>
                                                                </thumb>
                                                                <thumb>
                                                                    <wrap>
                                                                        <picture middle="" style={{ backgroundImage: "url(/content/img/test/item2.png)" }}>
                                                                            <action fit=""><a click="" href="#" title="Click to more info"></a></action>
                                                                        </picture>
                                                                        <detail>
                                                                            <text><span>Item Name</span></text>
                                                                        </detail>
                                                                    </wrap>
                                                                </thumb>
                                                                <thumb>
                                                                    <wrap>
                                                                        <picture middle="" style={{ backgroundImage: "url(/content/img/test/item3.png)" }}>
                                                                            <action fit=""><a click="" href="#" title="Click to more info"></a></action>
                                                                        </picture>
                                                                        <detail>
                                                                            <text><span>Item Name</span></text>
                                                                        </detail>
                                                                    </wrap>
                                                                </thumb>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <view data_info="" space="" transparent="">
                                                    <div className="section_group">
                                                        <div className="sections">
                                                            <div className="form_fields">
                                                                <div className="data_label">Nom</div>
                                                                <input type="text" />
                                                            </div>
                                                        </div>
                                                        <div className="sections">
                                                            <div className="form_fields">
                                                                <div className="data_label">Nom</div>
                                                                <input type="text" />
                                                            </div>
                                                        </div>
                                                        <div className="sections">
                                                            <div className="form_fields">
                                                                <div className="data_label">Nom</div>
                                                                <input type="text" />
                                                            </div>
                                                        </div>
                                                        </div>
                                                </view>
                                            </collapsible>
                                            <collapsible>
                                                <toolbar highlight="">
                                                    <wrap>
                                                        <div className="button mini"><icon>plus</icon></div>
                                                        <div className="button "><text>Alternate Solution Name</text></div>
                                                    </wrap>
                                                </toolbar>

                                                <div className="section_group">
                                                    <div className="sections wide">
                                                        <div className="form_fields">
                                                            <div className="section_title"><text><b>Solution Title Goes Here</b></text></div><div className="form_separator"></div>
                                                            <group><badge filled="">150 $</badge></group>
                                                            <div className="data_help">
                                                                <text><span>Largeur de passage mini: <b>1020 mm</b></span></text>
                                                                <text><span>Hauteur mini du local: <b>2415 mm</b></span></text>
                                                            </div>
                                                            <div className="input_cont">
                                                                <thumb>
                                                                    <wrap>
                                                                        <picture middle="" style={{ backgroundImage: "url(/content/img/test/item3.png)" }}>
                                                                            <action fit=""><a click="" href="#" title="Click to more info"></a></action>
                                                                        </picture>
                                                                        <detail>
                                                                            <text><span>Item Name</span></text>
                                                                        </detail>
                                                                    </wrap>
                                                                </thumb>
                                                                <thumb>
                                                                    <wrap>
                                                                        <picture middle="" style={{ backgroundImage: "url(/content/img/test/item2.png)" }}>
                                                                            <action fit=""><a click="" href="#" title="Click to more info"></a></action>
                                                                        </picture>
                                                                        <detail>
                                                                            <text><span>Item Name</span></text>
                                                                        </detail>
                                                                    </wrap>
                                                                </thumb>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </collapsible>
                                            <collapsible className="open">
                                                <toolbar highlight="">
                                                    <wrap>
                                                        <div className="button mini"><icon>minus</icon></div>
                                                        <div className="button "><text>Alternate Solution Name</text></div>
                                                    </wrap>
                                                </toolbar>

                                                <div className="section_group">
                                                    <div className="sections wide">
                                                        <div className="form_fields">
                                                            <div className="section_title"><text><b>Solution Title Goes Here</b></text></div><div className="form_separator"></div>
                                                            <group><badge filled="">150 $</badge></group>
                                                            <div className="data_help">
                                                                <text><span>Largeur de passage mini: <b>1020 mm</b></span></text>
                                                                <text><span>Hauteur mini du local: <b>2415 mm</b></span></text>
                                                            </div>
                                                            <div className="input_cont">
                                                                <thumb>
                                                                    <wrap>
                                                                        <picture middle="" style={{ backgroundImage: "url(/content/img/test/item3.png)" }}>
                                                                            <action fit=""><a click="" href="#" title="Click to more info"></a></action>
                                                                        </picture>
                                                                        <detail>
                                                                            <text><span>Item Name</span></text>
                                                                        </detail>
                                                                    </wrap>
                                                                </thumb>
                                                                <thumb>
                                                                    <wrap>
                                                                        <picture middle="" style={{ backgroundImage: "url(/content/img/test/item2.png)" }}>
                                                                            <action fit=""><a click="" href="#" title="Click to more info"></a></action>
                                                                        </picture>
                                                                        <detail>
                                                                            <text><span>Item Name</span></text>
                                                                        </detail>
                                                                    </wrap>
                                                                </thumb>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </collapsible>
                                        </view>
                                    </view>

                                    <toolbar>
                                        <action right="">
                                            <button type="submit" className="button primary"><text>Test Form Submit</text></button>
                                            <separator vertical=""></separator>
                                            <div className="button accent"><text>Secondary Action</text></div>
                                            <separator vertical=""></separator>
                                            <div className="button"><text>Disable Controls</text></div>
                                        </action>
                                    </toolbar>
                                </view>
                            </view>
                        </view>
                    </div>



                    <div>
                        <ListView
                            // {...props}
                            columnConfig={columnsServiceCalls}
                            apiUrl={ApiUrl.ServiceCallList}
                            skipNewButton
                            // deleteUrl={ApiUrl.BrandsDelete}
                            // detailUrl={Project.getPageUrl(AppPages.BrandDetails)}
                            // newButtonTip='text.create_new_brand'
                            // listUpdateEvent={EVENT_NAME.BRAND_LIST_UPDATE}
                            listName={AppPages.ServiceCalls}
                        />
                    </div>
                    <div>
                        <container>
                            <view vertical="" adaptive="">
                                <switchable className="" mini="">
                                    <switch><wrap><text>Filter bar</text></wrap><icon>collapse</icon></switch>
                                    <view className="filter_container">
                                        <div className="filter_content">
                                            <wrap>
                                                <div className="filter_block">
                                                    <div className="data_cell">
                                                        <wrap block="">
                                                            <cont>
                                                                <Select
                                                                    className="input-validation-error"
                                                                    mode="multiple"
                                                                    size="default"
                                                                    placeholder="Please select"
                                                                    defaultValue={['a10', 'c12']}>
                                                                </Select>
                                                                <decor><label><text><line></line><wrap><span>Multi Select</span></wrap></text><line></line></label><border></border></decor>
                                                            </cont>
                                                        </wrap>
                                                    </div>
                                                 </div>
                                                <div className="filter_block filtered">
                                                    <span className="title">
                                                        <span>Date Picker</span>
                                                        <button command="clear" type="button" className="button"><text>Clear</text></button>
                                                    </span>

                                                    <div className="data_cell">
                                                        <wrap block="">
                                                            <cont>
                                                                <DatePicker className="input-validation-error" name="dtpAntd" defaultValue={moment('2015/01/01', 'YYYY/MM/DD')} format='YYYY/MM/DD' />
                                                                <decor><label><text><line></line><wrap><icon>error</icon></wrap></text><line></line></label><border></border></decor>
                                                            </cont>
                                                            <cont>
                                                                <TimePicker className="input-validation-error" name="tpAntd" defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                                                                <decor><label><text><line></line><wrap><span>Time Picker</span></wrap></text><line></line></label><border></border></decor>
                                                            </cont>
                                                        </wrap>
                                                    </div>
                                                </div>

                                                <div className="filter_block">
                                                    <div className="data_cell">
                                                        <wrap block="">
                                                            <div className="options">
                                                                <label>
                                                                    <input type="checkbox" value="true"/>
                                                                    <wrap><text>Public</text></wrap>
                                                                </label>
                                                                <label>
                                                                    <input type="checkbox" value="false" />
                                                                    <wrap><text>Private</text> </wrap>
                                                                </label>
                                                            </div>
                                                        </wrap>
                                                    </div>
                                                </div>
                                             </wrap>
                                        </div>
                                        <toolbar>
                                            <action right=" ">
                                                <button command="Apply" className="button  primary" type="button"><text>Apply</text></button>
                                            </action>
                                        </toolbar>
                                    </view>
                                </switchable>
                                <view>
                                    <toolbar scroll="" className="filter_result filtered">
                                        <wrap>
                                            <div sort="" className="button mini"><icon>menu</icon></div>
                                            <separator vertical=""></separator>
                                            <div className="button accent" command="clear"><text>Clear All</text></div>
                                            <separator vertical=""></separator>
                                            <div className="filtered_item"><span command="remove" value="true"><text>Bookmarks</text></span></div>
                                            <div className="filtered_item" value="DraftFlag"><label>Visibility</label><span command="remove" value="true"><text>Private</text></span></div>
                                            <div className="filtered_item" value="CreateDate"><label>Uploaded</label><span command="remove" value="From"><text>From 10/19/2021</text></span></div>
                                        </wrap>
                                    </toolbar>
                                    <list>
                                        <wrap>
                                            <demospace tip=""> <icon large="" light="">info</icon> <text light="" wrap="">This is adaptive switchable section, and will overlap neighbor section if it not enough room to show them side by side. Resize browser window to see it in action. This is configurable option ...</text></demospace>
                                        </wrap>
                                    </list>
                                </view>
                                <gap></gap>
                            </view>
                        </container>
                    </div>

                    <div>
                        <view horizontal="">
                            <view vertical="">
                                <view>
                                    <toolbar><text>Simple Table Markup (<b>Fixed</b>)</text></toolbar>
                                    <view space="" scroll="">
                                        <div className="table_view">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th><wrap left=""><text>Sample Title</text></wrap></th>
                                                        <th><wrap><text>Sample text</text></wrap></th>
                                                        <th><wrap><text>Sample text</text></wrap></th>
                                                        <th action=""> </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th><wrap left=""><text>Sample Title</text></wrap></th>
                                                        <td><wrap><text>Sample text</text></wrap></td>
                                                        <td><wrap><text>Sample text</text></wrap></td>
                                                        <td><action right=""><div effect="material" className="button extra"><icon>delete</icon></div></action></td>
                                                    </tr>
                                                    <tr>
                                                        <th><wrap left=""><text>Sample Title</text></wrap></th>
                                                        <td><wrap><text>Sample text</text></wrap></td>
                                                        <td><wrap><text>Sample text</text></wrap></td>
                                                        <td><action right=""><div effect="material" className="button extra"><icon>delete</icon></div></action></td>
                                                    </tr>
                                                    <tr>
                                                        <th><wrap left=""><text>Sample Title</text></wrap></th>
                                                        <td><wrap><text>Sample text</text></wrap></td>
                                                        <td><wrap><text>Sample text</text></wrap></td>
                                                        <td><action right=""><div effect="material" className="button extra"><icon>delete</icon></div></action></td>
                                                    </tr>
                                                    <tr>
                                                        <th><wrap left=""><text>Sample Title</text></wrap></th>
                                                        <td><wrap><text>Sample text</text></wrap></td>
                                                        <td><wrap><text>Sample text</text></wrap></td>
                                                        <td><action right=""><div effect="material" className="button extra"><icon>delete</icon></div></action></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="fab_container bottom right"><div effect="material" className="button fab primary"><icon>plus</icon></div></div>
                                    </view>
                                </view>
                                <view horizontal="">
                                    <view>
                                        <toolbar><text>Simple Table Markup (<b>Adaptive</b>)</text></toolbar>
                                        <view space="">
                                            <div className="table_view adaptive">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th className="large"><text>Sample Title</text></th>
                                                            <th><text>Another Title</text></th>
                                                            <th><text>Title</text></th>
                                                            <th action=""> </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </view>

                                    </view>
                                    <view>
                                        <toolbar><text>Simple Table Markup (with <b>Colgroup</b>)</text></toolbar>
                                        <view space="" compact_view="">
                                            <div className="table_view">
                                                <table>
                                                    <colgroup>
                                                        <col width="100px" />
                                                        <col width="200px" />
                                                        <col />
                                                        <col width="40px" />
                                                    </colgroup>
                                                    <thead>
                                                        <tr>
                                                            <th><text>Sample Title</text></th>
                                                            <th><text>Another Title</text></th>
                                                            <th><text>Title</text></th>
                                                            <th action=""> </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </view>
                                    </view>
                                </view>
                            </view>
                            <view auto_height="" small="">
                                <toolbar><text><b>Advanced</b> Table Markup (Adaptive)</text></toolbar>
                                <view space="" >
                                    <div className="table_view adaptive">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="large"><label simple="" checkbox=""><name><b>Text Title</b></name><separator vertical=""></separator><input type="checkbox" /><tip></tip></label></th>
                                                    <th action=""> </th>
                                                    <th><label simple="" checkbox=""><name><b>TimePicker Title</b></name><separator vertical=""></separator><input type="checkbox" checked="checked" /><tip></tip></label></th>
                                                    <th><label simple="" checkbox=""><name><b>DatePicker Title</b></name><separator vertical=""></separator><input type="checkbox" /><tip></tip></label></th>
                                                    <th><label simple="" checkbox=""><name><b>Select  Title</b></name><separator vertical=""></separator><input type="checkbox" /><tip></tip></label></th>
                                                    <th><label simple="" checkbox=""><name><b>Input Title</b></name><separator vertical=""></separator><input type="checkbox" /><tip></tip></label></th>
                                                    <th action=""> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><text>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</text></td>
                                                    <td><wrap><label classic=""><input type="checkbox" /><box><check></check></box></label></wrap></td>
                                                    <td>
                                                        <wrap>
                                                            <TimePicker name="tpAntd" placeholder="From" className="input-validation-error" />
                                                            <DatePicker name="dtpAntd" format='MM/DD/YY' placeholder="To" />
                                                        </wrap>
                                                    </td>
                                                    <td>
                                                        <wrap>
                                                            <DatePicker name="dtpAntd" format='MM/DD/YY' placeholder="To" />
                                                        </wrap>
                                                    </td>
                                                    <td>
                                                        <wrap>
                                                            <Select
                                                                showSearch
                                                                placeholder="Created by" id="ddlgAntd"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => //NOSONAR
                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {children}
                                                            </Select>
                                                        </wrap>
                                                    </td>
                                                    <td><wrap><input type="text" /></wrap></td>
                                                    <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><wrap><label classic=""><input type="checkbox" /><box><check></check></box></label></wrap></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><wrap><label classic=""><input type="checkbox" /><box><check></check></box></label></wrap></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </view>
                            </view>
                        </view>
                    </div>



                    <div>
                        <view space="" vertical="" form="" transparent="">
                            <view space="" transparent="" middle="">
                                <div className="form_container">
                                    <div className="section_group">
                                        <div className="sections extra">
                                            <div className="form_fields"><div className="data_label"><text data-size="x-large">DETAILS DE L'ETUDE</text></div></div>

                                            <div className="form_fields">
                                                <div className="data_label title"><text data-size="large"> Project / chantier</text></div>
                                                <div className="input_cont">
                                                    <text label=""><b>Votre Refetence</b></text>
                                                    <icon>pen_edit</icon>
                                                    <input required="" type="text" placeholder="Placeholder" />
                                                    <i></i>
                                                </div>
                                            </div>
                                            <div className="form_fields">
                                                <div className="input_cont">
                                                    <text label=""><b>Votre Refetence</b></text>
                                                    <icon>pen_edit</icon>
                                                    <input required="" type="text" placeholder="Placeholder" />
                                                    <i></i>
                                                </div>
                                            </div>
                                            <div className="form_fields">
                                                <div className="input_cont">
                                                    <text label=""><b>Votre Refetence</b></text>
                                                    <icon>pen_edit</icon>
                                                    <input required="" type="text" placeholder="Placeholder" />
                                                    <i></i>
                                                </div>
                                            </div>


                                            <div className="form_fields">
                                                <div className="data_label title"><text data-size="large"> Project / chantier</text><tip><text data-size="small">(Small Help Tip)</text></tip></div>
                                                <div className="input_cont">
                                                    <text label=""><b>Votre Refetence</b></text>
                                                    <icon>pen_edit</icon>
                                                    <input required="" type="text" placeholder="Placeholder" />
                                                    <i></i>
                                                </div>
                                            </div>
                                            <div className="form_fields"><div className="data_label"><span>(*) champs obligatoires</span></div></div>

                                            <div className="form_fields">
                                                <div className="data_label">Text Area</div>
                                                <textarea placeholder="Fixed Height"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <toolbar transparent="">
                                        <div className="button large extra primary"><icon>save</icon><text>ENREGISTRER MON PROJECT</text></div>
                                    </toolbar>
                                </div>
                            </view>
                            <view space="" transparent="">
                                <div  className="section_group compact">
                                    <div className="sections extra">
                                        <div className="form_fields"><div className="data_label"><text data-size="x-large">NOTE DE CALCUL</text></div></div>
                                        <div className="form_fields">
                                            <div className="input_cont ">
                                                <wrap auto="">
                                                    <label radio="" large="">
                                                        <input type="radio" checked="" />
                                                        <box><check></check></box>
                                                        <text wrap="">Inciure le prix public du systeme danss la note de synthese</text>
                                                    </label>
                                                </wrap>
                                                <wrap>
                                                    <toolbar vertical="" transparent="">
                                                        <wrap>
                                                            <div className="button primary extra">
                                                                <text left="">Large Button</text>
                                                                <picture style={{ backgroundImage: "url(/content/img/connection.jpg)" }} micro="" ></picture>
                                                            </div>
                                                            <div className="button primary extra">
                                                                <text lrft="">Large Button</text>
                                                                <picture style={{ backgroundImage: "url(/content/img/connection.jpg)" }} micro="" ></picture>
                                                            </div>
                                                        </wrap>
                                                    </toolbar>
                                                </wrap>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section_group compact">
                                    <div className="sections extra">
                                        <div className="form_fields"><div className="data_label"><text data-size="x-large">DOCUMENTATION</text></div></div>
                                        <div className="section_group no_wrap">
                                            <div className="sections">
                                                <div className="form_fields">
                                                    <div className="data_label title"><text>RUBIS 214 SI</text></div>
                                                    <div className="input_cont list">
                                                        <picture style={{ backgroundImage: "url(/content/img/connection.jpg)" }}></picture>
                                                        <wrap>
                                                            <toolbar vertical="" transparent="">
                                                                <wrap>
                                                                    <div className="button list_element">
                                                                        <picture style={{ backgroundImage: "url(/content/img/connection.jpg)" }} micro="" ></picture>
                                                                        <text>Large Button</text>
                                                                    </div>
                                                                    <div className="button list_element">
                                                                        <picture style={{ backgroundImage: "url(/content/img/connection.jpg)" }} micro="" ></picture>
                                                                        <text>Large Button</text>
                                                                    </div>
                                                                    <div className="button list_element">
                                                                        <picture style={{ backgroundImage: "url(/content/img/connection.jpg)" }} micro="" ></picture>
                                                                        <text>Large Button</text>
                                                                    </div>
                                                                    <div className="button list_element">
                                                                        <picture style={{ backgroundImage: "url(/content/img/connection.jpg)" }} micro="" ></picture>
                                                                        <text>Large Button</text>
                                                                    </div>
                                                                    <div className="button list_element">
                                                                        <picture style={{ backgroundImage: "url(/content/img/connection.jpg)" }} micro="" ></picture>
                                                                        <text>Large Button</text>
                                                                    </div>
                                                                </wrap>
                                                            </toolbar>
                                                        </wrap>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sections">
                                                <div className="form_fields">
                                                    <div className="data_label title"><text>CORHYDRO</text></div>
                                                    <div className="input_cont list">
                                                        <picture style={{ backgroundImage: "url(/content/img/connection.jpg)" }}></picture>
                                                        <wrap>
                                                            <toolbar vertical="" transparent="">
                                                                <wrap>
                                                                    <div className="button list_element">
                                                                        <picture style={{ backgroundImage: "url(/content/img/connection.jpg)" }} micro="" ></picture>
                                                                        <text>Large Button</text>
                                                                    </div>
                                                                    <div className="button list_element">
                                                                        <picture style={{ backgroundImage: "url(/content/img/connection.jpg)" }} micro="" ></picture>
                                                                        <text>Large Button</text>
                                                                    </div>
                                                                    <div className="button list_element">
                                                                        <picture style={{ backgroundImage: "url(/content/img/connection.jpg)" }} micro="" ></picture>
                                                                        <text>Large Button</text>
                                                                    </div>
                                                                    <div className="button list_element">
                                                                        <picture style={{ backgroundImage: "url(/content/img/connection.jpg)" }} micro="" ></picture>
                                                                        <text>Large Button</text>
                                                                    </div>
                                                                    <div className="button list_element">
                                                                        <picture style={{ backgroundImage: "url(/content/img/connection.jpg)" }} micro="" ></picture>
                                                                        <text>Large Button</text>
                                                                    </div>
                                                                </wrap>
                                                            </toolbar>
                                                        </wrap>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                </div>
                            </view>
                        </view>


                    </div>
                </div>
            </div>
        </>
    );

}



export function Template3(props) {
    return (<div>{props}</div>);
}

export class Template2 extends Component {
    // constructor(props) {
    //     super(props);
    // };

    render() {
        return (<div>Template</div>);
    }
}
