import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { footerAddToolbar, footerRemoveToolbar, footerUpdateToolbar } from '../../store/actions';

function FooterToolbarsBody(props) {
    
    return <>{props.footerToolbars && props.footerToolbars.map((p, i) => {
        return <Fragment key={i} //NOSONAR
        >{p.children}</Fragment>
    })}</>;
}


export const FooterToolbars = connect(state => ({ footerToolbars: state.footerToolbars }))(FooterToolbarsBody);

function FooterToolbar(props) {
    const { footerAddToolbar, footerRemoveToolbar, toolbarKey, footerUpdateToolbar } = props;

    useEffect(() => {
        footerAddToolbar(props);
        return () => footerRemoveToolbar(toolbarKey);
    }, []);//eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        footerUpdateToolbar(toolbarKey, props);
    }, [props.children]);// eslint-disable-line react-hooks/exhaustive-deps

    return (<></>);
}

export default connect(null, dispatch => ({
    footerUpdateToolbar: (key, props) => dispatch(footerUpdateToolbar(key, props)),
    footerAddToolbar: (props) => dispatch(footerAddToolbar(props)),
    footerRemoveToolbar: (key) => dispatch(footerRemoveToolbar(key))
}))(FooterToolbar);