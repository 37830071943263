import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';

function Scroll(props) {
    const [showButton, setShowButton] = useState(false);
    const [showLeftButton, setShowLeftButton] = useState(true);
    const [showRightButton, setShowRightButton] = useState(true);
    const allowScroll = useRef(true);

    const container = useRef(null);
    const {
        scrollSpeed,
        scrollSpeedOnWeel,
        isScrollOnWeel,
        leftButtonClass,
        rightButtonClass,
        useScrollButtons,
        scrollToElementClass
    } = props;

    useEffect(() => {
        if (useScrollButtons) {
            const domElement = ReactDOM.findDOMNode(container.current);

            const resizeObserver = new ResizeObserver(resizeHandler(checkShowButton));
            resizeObserver.observe(domElement);

            let cleanScrollOnWell;
            isScrollOnWeel && (cleanScrollOnWell = scrollOnWeel());
            return () => {
                resizeObserver.unobserve(domElement)
                isScrollOnWeel && cleanScrollOnWell()
                container.current = null;
                allowScroll.current = null;
            };
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const scrollOnWeel = () => {
        const domElement = ReactDOM.findDOMNode(container.current);
        const wheelHandler = (e) => {
            e.deltaY < 0 ? scrollLeft(scrollSpeedOnWeel) : scrollRight(scrollSpeedOnWeel);
        }

        domElement.addEventListener('wheel', wheelHandler);

        return () => {
            domElement.removeEventListener('wheel', wheelHandler);
        };
    }

    const checkShowButton = () => {
        if (useScrollButtons) {
            const domElement = ReactDOM.findDOMNode(container.current);
            if (domElement) {
                setShowButton(domElement.offsetWidth < domElement.scrollWidth);
                setButtons();
                scrollToElementClass && scrollToElement();
            }
        }
    };

    const resizeHandler = (func) => {
        let timer;
        return () => {
            timer && clearTimeout(timer);
            timer = setTimeout(func, 100);
        }
    };

    const setButtons = () => {
        const domElement = ReactDOM.findDOMNode(container.current);
        const _scrollLeft = domElement.scrollLeft;

        setShowLeftButton(domElement.scrollLeft > 0);

        allowScroll.current = false;
        domElement.scrollLeft += 1;
        if(_scrollLeft === domElement.scrollLeft) {
            setShowRightButton(false)
        } else {
            domElement.scrollLeft -= 1;
            setShowRightButton(true)
        }
    }

    const scrollLeft = (speed) => {
        ReactDOM.findDOMNode(container.current).scrollLeft -= (speed || scrollSpeed);
        setButtons()
    };
    const scrollRight = (speed) => {
        ReactDOM.findDOMNode(container.current).scrollLeft += (speed || scrollSpeed);
        setButtons()
    };
    const scrollToElement = () => {
        const domElement = ReactDOM.findDOMNode(container.current);
        const element = domElement.getElementsByClassName(scrollToElementClass)[0];
        element && element.scrollIntoView();
        setButtons()
    }

    const scrollHandler = (scroll) => {
        let interval;
        const mouseUpHandler = () => {
            document.removeEventListener('mouseup', mouseUpHandler);
            clearInterval(interval)
        };
        document.addEventListener('mouseup', mouseUpHandler);
        interval = setInterval(() => scroll())
    };

    const onScroll = (e) => {
        if(allowScroll.current) {
            setButtons()
        }
    }

    return (
        <>
            {showButton && <div className={leftButtonClass + (showLeftButton ? "" : " hidden") } onMouseDown={() => scrollHandler(scrollLeft)}><icon>left</icon></div>}
            <ScrollContainer onEndScroll={() => allowScroll.current = false} onStartScroll={() => allowScroll.current = true} onScroll={onScroll} //NOSONAR
                ref={container} {...props}>
                {props.children}
            </ScrollContainer>
            {showButton && <div className={rightButtonClass + (showRightButton ? "" : " hidden") } onMouseDown={() => scrollHandler(scrollRight)}><icon>right</icon></div>}
        </>
    )
}

Scroll.defaultProps = {
    vertical: false,
    useScrollButtons: true,
    isScrollOnWeel: true,

    leftButtonClass: 'button btn-scroll left micro primary',
    rightButtonClass: 'button btn-scroll right micro primary',

    scrollSpeed: 2,
    scrollSpeedOnWeel: 20,
    scrollToElementClass: 'selected'
}

Scroll.propTypes = {
    vertical: PropTypes.bool,
    useScrollButtons: PropTypes.bool,
    isScrollOnWeel: PropTypes.bool,

    leftButtonClass: PropTypes.string,
    rightButtonClass: PropTypes.string,

    scrollSpeed: PropTypes.number,
    scrollSpeedOnWeel: PropTypes.number,
    scrollToElementClass: PropTypes.string
}

export default Scroll;