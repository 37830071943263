import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import store from 'store';
import { message } from 'antd';

import { Ajax } from '../components/Ajax';
import { ApiUrl, StoreKeys } from '../project/Defines';
import { Project } from '../project/Project';
import Popups from '../components/Popup';

import Menu from './Navigation/Menu';
import Loader from '../Shared/Loader';
import { CustomTooltips } from '../Shared/CustomTooltip';
import { FooterToolbars } from '../Shared/FooterToolbar';
import { refreshLogin } from '../store/actions/index.js';


/**
 * The layout component specifies the site layout, top menu based on login state and active states of top menu items
 * @param {object} props  { isLoggedIn, popups, culture, role, etc.}
 */
function Layout(props) {
    useEffect(() => {
        message.config({
            // top: 400,
            duration: 4
        });

        const check = function () {
            if (props.isLoggedIn && props.userToken !== "guest") {
                const exptime = store.get(StoreKeys.ExpireTime);

                if (!exptime || exptime < new Date()) {
                    Ajax.post({
                        url: ApiUrl.Check,
                        data: {},
                        success: function (response) {
                            if (response) {
                                if (response.accessToken && response.userToken && response.logoutUrl) {
                                    //let user = store.get(StoreKeys.UserData);
                                    //user.accessToken = response.accessToken;
                                    //user.logoutUrl = response.logoutUrl;
                                    //store.set(StoreKeys.UserToken, response.userToken);
                                    //store.set(StoreKeys.UserData, user);

                                    props.dispatch(refreshLogin({
                                        userToken: response.userToken,
                                        accessToken: response.accessToken,
                                        logoutUrl: response.logoutUrl
                                    }
                                    ));
                                }
                                
                                store.set(StoreKeys.ExpireTime, (new Date()).setMinutes((new Date()).getMinutes() + 1));
                            }
                        }
                    })
                }
            }
        }
        const timer = setInterval(check, 6e4);
        check();
        return () => {
            clearInterval(timer)
        }
    }, [props.isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

    let location = window.location.pathname.toLowerCase();
    return (
        <>
            <Popups />
            {(props.isLoggedIn && !Project.skipHeader(location) &&
                <Menu role={props.role} culture={props.culture} />
            )}
            <container scroll="">
                <view auto_height="" transparent=""
                    //key={i18n.language}
                    {...(location && location.indexOf('applications/') !== -1 ? { "className": "etude"} : {})}>
                {(props.isLoggedIn && !Project.skipHeader(location) &&
                    <>
                        

                        <view auto_height="" {...(location && location.indexOf('/etude') !== -1 ? { "no_overflow": "" } : {})}>
                            <Popups fullScreen />
                            {props.children}
                        </view>
                    </>
                ) || props.children}
                
                
                </view>
                <CustomTooltips />
            </container>
            
            <FooterToolbars />
            <Loader />
        </>
    );
}


export default connect(state => ({
    isLoggedIn: state.isLoggedIn,
    popups: state.popups,
    culture: state.culture,
    role: state.role,
    userToken: state.userToken
}))(withRouter(Layout));