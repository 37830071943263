import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { Ajax } from '../../components/Ajax';
import { closePopup, updatePopupTitle } from './../../store/actions';
import { useDelete, useCustomEvent, dispatchCustomEvent, isSameObject } from '../../project/utilities';
import { validate } from '../Form/validation';
import { showLoader, hideLoader } from '../Loader';
import { T } from '../../components/Translations';

/**
 * custom hook used in detail components to provide the necessary methods
 * @param props contains data, updateUrl, dataLoadUrl, onLoadData, windowKey, nameField, deleteUrl, deleteKeys, backPage, additionalData, listUpdateEvent, validationFields, detailUrl
 * @exports useDetail
 */
export default function useDetail({ data,
    updateUrl,
    dataLoadUrl,
    dataLoadParams,
    onLoadData,
    windowKey,
    nameField,
    deleteUrl,
    deleteKeys,
    backPage,
    additionalData,
    listUpdateEvent,
    validationFields,
    changingValidation,
    skipInitialValidation,
    detailUrl,
    reloadEvent,
    detailProps,
    externalValidation }) {
    const dispatch = useDispatch();
    const [hasChanges, setHasChanges] = useState(false);
    const [originalDataItem, setOriginalDataItem] = useState(null);
    const [dataItem, setDataItem] = useState(null);
    const [validation, setValidation] = useState({});
    const [allowValidation, setAllowValidation] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [loading, setLoading] = useState(false);
    const detailRef = useRef(false);
    const history = useHistory();
    const [loaderName] = useState(uuidv4());
    const loadData = useCallback((id) => {
        if (loading) return;
        setLoading(true);
        Ajax.get({
            url: dataLoadUrl,
            data: dataLoadParams || { id: id || data.ID || 0 },
            success: function (response) {
                detailRef.current && ((onLoadData && onLoadData(response, setDataItemInternal)) || setDataItemInternal(response));
                setLoading(false);
            },
            error: function () {
                setLoading(false);
            }
        })
        return true;
    }, [dataLoadUrl, onLoadData, data.ID, detailRef, loading]);// eslint-disable-line react-hooks/exhaustive-deps

    const setDataItemInternal = useCallback((data, hasChanges = false) => {
        if (detailRef.current && data) {
            setHasChanges(hasChanges);
            dispatchCustomEvent(windowKey, { hasChange: hasChanges });
            setDataItem(data);
            if (!hasChanges) {
                setOriginalDataItem(data);
            }
        }
    }, [windowKey]);

    const setReload = useCallback(() => {
         dataItem && setDataItemInternal({ ...dataItem, reloadData: true }) 
    }, [dataItem, setDataItemInternal]);

    useCustomEvent(reloadEvent, setReload);

    useEffect(() => {
        return () => {
            hideLoader(loaderName);
        };
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        updating || loading ? showLoader(loaderName) : hideLoader(loaderName);
    }, [updating, loading, loaderName]);

    useEffect(() => {
        detailRef.current = true;
        setAllowValidation(validationFields && !skipInitialValidation);
        return () => { detailRef.current = false; }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (dataItem && allowValidation) {
            setValidation(validate({ data: dataItem, validationFields }));
        }
    }, [dataItem, changingValidation && validationFields]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (detailRef.current) {
            (dataLoadUrl && loadData()) ||
                setDataItemInternal(data);
        }
    }, [data.ID]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (detailRef.current) {
            if (dataItem && dataItem.reloadData) {
                loadData(dataItem.ID);
            }
        }
    }, [dataItem && dataItem.reloadData]);// eslint-disable-line react-hooks/exhaustive-deps

    const closeDetailPopup = useCallback(() => {
        dispatch(closePopup(windowKey));
    }, [windowKey, dispatch]);

    const updateData = useCallback((successCallback, errorCallback, options = {}) => { //NOSONAR
        if (updating) return;
        setUpdating(true);
        let validationData = { isValid: true };
        if (!options.skipValidation && validationFields) {
            validationData = validate({ data: dataItem, validationFields });
            setValidation(validationData);
            setAllowValidation(true);
        }
        if (validationData.isValid && (!externalValidation || externalValidation.isValid)) {
            Ajax.post({
                url: updateUrl,
                data: options.overrideData || { ...dataItem, ...additionalData, ...options.additionalData },
                success: function (response) {
                    setUpdating(false);
                    if (response) {
                        if (response.HasError) {
                            message.error(response.Message);
                            errorCallback && errorCallback(response);
                        }
                        else {
                            response.Message && message.success(response.Message);
                            windowKey && detailProps && detailProps.editTitle && dispatch(updatePopupTitle({ windowKey, title: detailProps.editTitle }));
                            successCallback && successCallback(response);
                            if (detailUrl && response.ID !== dataItem.ID) {
                                history.push(detailUrl + "/" + response.ID);
                            }
                        }
                        if (allowValidation && detailRef.current) { setAllowValidation(validationFields && !skipInitialValidation); }
                    }
                },
                error: function (response) {
                    setUpdating(false);
                    message.error(response.message || response.title);
                    errorCallback && errorCallback(response);
                }
            })
        } else {
            message.error(<T>message.fill_in_required_fields</T>);
            setUpdating(false);
            errorCallback && errorCallback();
        }
    }, [dataItem, updateUrl, validationFields, allowValidation, detailUrl, updating, setUpdating, detailProps]);// eslint-disable-line react-hooks/exhaustive-deps

    const onChangeData = useCallback((event, callback) => {
        let newData = { ...dataItem };
        let deepObject = newData;
        let name = event.target.name;
        const regex = /\.|\[|\]/; //NOSONAR
        if (name && name.search(regex) !== -1) {
            let deepProps = name.split(regex).filter(p => p !== '');
            for (let i = 0; i < deepProps.length - 1; i++) {
                deepObject[deepProps[i]] = Array.isArray(deepObject[deepProps[i]]) ? [...deepObject[deepProps[i]]] : { ...deepObject[deepProps[i]] };
                deepObject = deepObject[deepProps[i]];
            }
            name = deepProps[deepProps.length - 1];
        }
        if (event.target && name) {
            switch (event.target.type) { //NOSONAR
                case 'checkbox':
                    deepObject[name] = event.target.checked;
                    break;
                default:
                    deepObject[name] = event.target.value;
                    break;
            }
        }
        
        callback && callback(event, newData);
        setDataItem(newData);

        setHasChanges(false);
        dispatchCustomEvent(windowKey, { hasChange: false });

        if (originalDataItem && originalDataItem.ID === newData.ID && !isSameObject(newData, originalDataItem)) {
            setHasChanges(true);
            dispatchCustomEvent(windowKey, { hasChange: true });
        }
        
    }, [dataItem, originalDataItem, windowKey]);

    const onDetailDelete = useDelete({
        nameField,
        deleteUrl,
        backPage,
        listUpdateEvent,
        deleteKeys
    });

    return { isNew: dataItem && !Boolean(dataItem.ID), dataItem, originalDataItem, setDataItem: setDataItemInternal, updateData, hasChanges, onChangeData, closePopup: closeDetailPopup, onDetailDelete, detailRef, validation, updating };
}

