import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import i18n from '../../../../Shared/Translation/i18n';
import { T, DataTip } from '../../../../components/Translations';
import { ProductionMode, SystemCode } from '../../../../project/Defines';
import { Project } from '../../../../project/Project';
import { setApplicationData } from '../../../../store/actions';

function Zone2(props) {
    const dispatch = useDispatch();
    const [subApplications, setSubApplications] = useState(null);

    const { setSystem, setProductionMode, setZone3ApiData, setZone4Data, zone4Data, history, application, subapplication, productionMode, system, id, sectionHeaderStyle, zone, setCalculatedApiData, systemData, setSolutions, applicationData, setMainSolution, setDefaultSolution, productionModes, setActiveRowIndex, setEtudeAllData } = props;

    useEffect(() => {
        if (productionMode && productionModes) {
            setSubApplications(productionModes.find(m => m.code === productionMode).subSystems)
        }
    }, [productionModes, productionMode])

    const applicationChange = (e, system) => {
        //setSubApplications(system.subSystems)
        setProductionMode(system.code);
        setZone4Data(null);
        setZone3ApiData(null);
        //setSystemData(null)
        setSolutions(null);
        setMainSolution(null);
        setDefaultSolution(null);
        if (system.code === ProductionMode.ACCUMULE) {
            setSystem(SystemCode.BALLONS_ELECTRIQUES);
            setEtudeAllData(item => ({ ...item, system: SystemCode.BALLONS_ELECTRIQUES }));
            dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], edited: true } }));
        } else {
            //setSystem(null)
            subApplicationChange(null, null);
        }
        /* 
         * if (+zone !== 2) {
            history.push(Project.getPageUrl("applications", [application, subapplication, id, 2]));
        }*/
        setEtudeAllData(item => ({ ...item, productionMode: system.code }));
    }
    useEffect(() => {
        return () => {
            let _zone = history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1, history.location.pathname.length);// NOTE GrogorM
            if (+_zone === 1 && history.action === "POP") {
                setProductionMode(null);
                setCalculatedApiData(null);
                setEtudeAllData(item => ({ ...item, productionMode: null, calculatedApiData: null }));
                //dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], productionMode: null, calculatedApiData: null } }))
            }
            /* NOTE: 
             * GrigorM
             setZone4Data(null);
            setZone3ApiData(null);
            setSystem(null);
            setProductionMode(null)
            setSystemData(null)
            */
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    const subApplicationChange = (e, subSystem) => {
        setSystem(subSystem);
        setZone4Data(null);
        setZone3ApiData(null);
        //setSystemData(null)
        setSolutions(null);
        setMainSolution(null);
        setDefaultSolution(null);
        setActiveRowIndex(null);
        setEtudeAllData(item => ({ ...item, system: subSystem }));
        dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], edited: true, system: subSystem  } }));
    }

    useEffect(() => { //NOSONAR
        if (system) {
            if (applicationData && applicationData[id] && !applicationData[id].edited) {
                return
            }

            /* 
             * if (applicationData.edited) {
                setZone4Data(null);
                setZone3ApiData(null);
            }*/

            if (systemData && system === SystemCode.ECHANGEUR_A_PLAQUES) {
                /*
                 * setSystemData(item => ({ ...item, Pboucle: applicationData.edited ? "" : applicationData.systemData.Pboucle }));
                if (applicationData.edited) {
                    setSolutions(null);
                }*/
            }
           
            if (applicationData && applicationData[id] && applicationData[id].edited) {
                if (+zone === 3) {
                    history.replace(Project.getPageUrl("applications", [application, subapplication, id, 3]));
                } else {
                    history.push(Project.getPageUrl("applications", [application, subapplication, id, 3]));
                }

                if (applicationData && applicationData[id] && applicationData[id].zone4Data && applicationData[id].zone4Data.opened && zone !== 'document' && zone4Data) {
                    history.push(Project.getPageUrl("applications", [application, subapplication, id, 4]));
                }
            }

        }
    }, [system, application, subapplication, id]);// eslint-disable-line react-hooks/exhaustive-deps


    return <>{productionModes &&

        <wrap>
            <div className="section_group">
                <number style={sectionHeaderStyle}>2</number>
                <div className="sections ">
                    <div className="form_fields ">
                        <div className="input_cont title">
                            <text className="" style={sectionHeaderStyle}><b><T>text.mode_de_production_decs</T></b></text>
                        </div>
                    </div>
                    {
                        productionModes.map((system, index) => (
                            <group key={index} //NOSONAR
                            >
                                <label key={system.value} radio="">
                                    <input name="application" defaultChecked={productionMode === system.code} type="radio" onChange={(e) => { applicationChange(e, system) }} />
                                    <box><check></check></box>
                                    <text><T>{system.value}</T></text>
                                </label>
                                <DataTip title={() => { //NOSONAR
                                    // eslint-disable-next-line default-case
                                    switch (system.value) {
                                        case "FO_MODE_INSTANT":
                                            return i18n("text.FO_HELP_INSTANT")
                                        case "FO_MODE_SI_SA":
                                            return i18n("text.FO_HELP_SI/SA")
                                        case "FO_MODE_ACCUMULATE":
                                            return i18n("text.FO_HELP_ACCUMULATE")
                                    }
                                }}>
                                    <div className="button micro info"><icon>info</icon></div>
                                </DataTip>
                            </group>
                        ))}
                </div>

                {subApplications && <>
                    <div className="sections ">
                        <div className="form_fields ">
                            <div className="input_cont title">
                                <text className="" style={sectionHeaderStyle}><b><T>text.systeme</T></b></text>
                            </div>
                        </div>
                        {subApplications.map(subSystem => (
                            <label key={subSystem.systemLabel} radio=""><input name="subApplication" checked={system === subSystem.systemCode} type="radio" onChange={(e) => { subApplicationChange(e, subSystem.systemCode); }} /><box><check></check></box><text wrap=""><T>{subSystem.systemLabel}</T></text></label>
                        ))}
                    </div>
                </>
                }
            </div>
        </wrap>
    }
    </>
}

export default connect(state => ({ isLoggedIn: state.isLoggedIn, applicationData: state.applicationData }), null)(Zone2);