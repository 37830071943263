import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import { Dropdown } from 'antd';

import { T } from '../../components/Translations';
import { AppPages /*, ApiUrl, StoreKeys*/ } from '../../project/Defines';
import { Project } from '../../project/Project';

import { changeCulture, closeAllPopups, logout } from '../../store/actions';

import Scroll from '../../Shared/Scroll/Scroll';

const LanguageChange = (props) => {
    const [ visibleLanguegeMenu, setVisibleLanguegeMenu ] = useState(false);
    let { culture } = props;
    const dispatch = useDispatch();
    const ref = useRef(false);

    useEffect(() => {
        ref.current = true;

        return () => { ref.current = false; }

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const changeLanguage = lng => {
        if (ref.current) {
            dispatch(changeCulture(lng));
        }
    };

    const handleVisibleChange = () => {
        setVisibleLanguegeMenu(!visibleLanguegeMenu);
    };

    const handleMenuClick = () => {
        setVisibleLanguegeMenu(false);
    };

    let langMenu =
        <div 
            onClick={() => handleMenuClick(true)} //NOSONAR
            className="list_menu ant-dropdown-menu"
        >
            <ul>
                {
                    props.network && props.networks && props.networks.find(n => n.id === props.network).languages?.map((item, index) => {
                        return <li key={index} onClick={() => changeLanguage(item.code)} //NOSONAR
                        ><a><icon></icon><text>{item.label}</text></a></li>// eslint-disable-line jsx-a11y/anchor-is-valid 
                    })
                }
            </ul>
        </div>;

    return <>
        <Dropdown
            onClick={() => setVisibleLanguegeMenu(true)} //NOSONAR
            className=""
            trigger={['click']}
            overlay={langMenu} placement="topRight"
            onVisibleChange={() => handleVisibleChange(true)} //NOSONAR
            visible={visibleLanguegeMenu}
        >
            <div command="langMenu" effect="material" className="button language accent">
                <text>{props.network && props.networks && (props.networks.find(n => n.id === props.network).languages?.find(l => l.code === culture) || props.networks.find(n => n.id === props.network).languages?.find(l => l.isDefault) || {}).label}</text>
                <icon>down</icon>
            </div>
        </Dropdown>

    </>
}
/**
 * represents the control for selecting current interface language */
export const LanguageChangeComponent = connect(state => ({
    culture: state.culture,
    network: state.network,
    networks: state.networks
}), null)(LanguageChange);

export const menuItems = [

    { to: AppPages.Home, text: <T>text.home</T> },
];

/**
 * The layout component specifies the site layout, top menu based on login state and active states of top menu items
 * @param {object} props  { isLoggedIn, popups, culture, role, etc.}
 */
function Menu(props) {
    const { role, userData, culture, history } = props;
    const [closedHelpPopup, setClosedHelpPopup] = useState(false);
    const [closedMenuPopup, setClosedMenuPopup] = useState(false);

    let contact = props.networks.find(n => n.id === props.network).contact;

    const handleVisibleChange = useCallback((flag, isMenu) => {
        isMenu ? setClosedMenuPopup(flag) : setClosedHelpPopup(flag);
    }, [])

    const redirectFAQ = useCallback(() => {
        history.push(Project.getPageUrl(AppPages.FAQ));
        setClosedHelpPopup(false);
    }, [history])

    const redirectCGU = useCallback(() => {
        history.push(Project.getPageUrl(AppPages.CGU));
        setClosedHelpPopup(false);
    }, [history])

    const redirectMyAccount = useCallback(() => {
        history.push(Project.getPageUrl(AppPages.MyAccount));
        setClosedMenuPopup(false);
    }, [history])

    let logoutUrl = props.userData && props.userData.logoutUrl ? props.userData.logoutUrl : Project.getPageUrl(AppPages.Logout);


    let userMenu =
        <div className="list_menu ant-dropdown-menu">
            <ul>
                {props.userToken !== "guest" && <li><a onClick={() => redirectMyAccount()}><icon></icon><text><T>text.FRONT_ACCOUNT</T></text></a></li>} { //eslint-disable-line jsx-a11y/anchor-is-valid
                }
                <li className="separator"></li>
                <li command="easter" effect="material" className="disabled none_selectable" bottom=""><icon>info</icon><text><T>text.version</T></text><separator vertical=""></separator><text><b>{process.env.REACT_APP_VERSION}</b></text><text><count data-count=""></count></text></li>
                <li className="separator"></li>
                <li><a href={ logoutUrl }><icon></icon><text><T>text.sign_out</T></text></a></li>
            </ul>
        </div>;

let helpMenu =
    <div className="list_menu ant-dropdown-menu">
        <ul>
            {
                contact &&
                <>
                    {
                        contact.split('\r\n').map((item, index) => {
                            return <li className="none_selectable" key={index} //NOSONAR
                            >
                                <icon></icon>
                                <text>{props.network && item}</text>
                            </li>
                        })
                    }
                </>
            }
            <li className="separator"></li>
            <li><a onClick={() => redirectFAQ()}><icon></icon><text><T>text.FO_HEADER_FAQ</T></text></a></li> { //eslint-disable-line jsx-a11y/anchor-is-valid
            }
            <li className="separator"></li>
            <li><a onClick={() => redirectCGU()}><icon></icon><text>CGU</text></a></li> { //eslint-disable-line jsx-a11y/anchor-is-valid 
            }
        </ul>
    </div>;


    let location = window.location.pathname.toLowerCase();

    let selectedItem = null;

    return <>

        <toolbar accent="" id="mainHeader" className="main_header">
            <wrap>
                <action left="">
                    <logo></logo>
                    <separator vertical=""></separator>
                    <logo style={{ backgroundImage: "url(../../../content/img/logo/Home5.png)", backgroundSize: 25, width: 30 }}><Link to={Project.getPageUrl(AppPages.Home)} onClick={closeAllPopups}> </Link></logo>
                    {/* <logo atlantic="" style={{ backgroundImage: `url(/content/img/logo/${Project.getLogo(props.networks && (props.networks.find(item => item.id === props.network) || {}).code)}.svg)`}}></logo> */}
                    <separator vertical=""></separator>
                    <logo style={{ backgroundImage: "url(../../../content/img/logo/RetourAppli.png)", backgroundSize: 20, width: 30 }}><Link to={Project.getPageUrl(AppPages.Applications)} onClick={closeAllPopups}></Link></logo>
                    {/* <logo atlantic="" style={{ backgroundImage: `url(/content/img/logo/${Project.getLogo(props.networks && (props.networks.find(item => item.id === props.network) || {}).code)}.svg)`}}></logo> */}
                    <nav launcher="" id="launcher">
                        <Scroll>
                            <ul>
                                {menuItems.map((item, i) => { //NOSONAR
                                    const url = Project.getPageUrl((item.to && Project.hasRoleAccess(role, item.to)) ? item.to : item.subMenu && [...item.subMenu].sort(sm => sm.isDefault ? -1 : 1).find(sub => Project.hasRoleAccess(role, sub.to))?.to) || "";

                                    const selected = (url && url.toLowerCase() === location) || (item.detail && location.startsWith(Project.getPageUrl(item.detail) + "/"))
                                        || (item.subMenu && item.subMenu.some(sub => location.startsWith(Project.getPageUrl(sub.to)) || (sub.detail && location.startsWith(Project.getPageUrl(sub.detail)))))
                                        || (item.pages && item.pages.map(p => Project.getPageUrl(p)).indexOf(location) !== -1);
                                    if (selected) {
                                        document.onkeydown = (e) => {
                                            const keyF1 = 'F1';

                                            if (e.key === keyF1) {
                                                if (!item.help.default) {
                                                    e.preventDefault();
                                                    const urlPDF = `/help/${(item.help.fr && culture === "fr") ? item.help.fr : item.help.en}`;
                                                    window.open(urlPDF, "_blank");
                                                }

                                            }
                                        };
                                        selectedItem = {
                                            url,
                                            subMenu: item.subMenu || []
                                        };
                                    }
                                    if (!url) {
                                        return <div key={i} //NOSONAR
                                            style={{ display: 'none' }}></div >;
                                    }
                                    return null

                                })}
                            </ul>
                        </Scroll>
                    </nav>
                </action>
                <action right="">
                    {
                        props.isLoggedIn && !Project.skipHeader(location) && userData &&
                        <>
                            {props.userToken !== "guest" && <>
                                <div effect="material" className='button' onClick={() => { history.push(Project.getPageUrl(AppPages.Etude)) }}>
                                    <text><T>text.FO_HEADER_STUDIES</T></text>
                                </div>
                                <separator vertical=""></separator>
                            </>
                            }

                            <Dropdown
                                className=""
                                trigger={['click']}
                                overlay={helpMenu} 
                                placement="topRight"
                                // onClick={() => setClosedHelpPopup(true)}
                                onVisibleChange={(e) => handleVisibleChange(e, false)} //NOSONAR
                                visible={closedHelpPopup}
                            >
                                <div command="helpMenu" className="button">
                                    <icon data-tip="helpMenu">info</icon>
                                    <text><T>text.FO_HEADER_HELP</T></text>
                                </div>
                            </Dropdown>
                            <separator vertical=""></separator>

                            <Dropdown
                                className=""
                                trigger={['click']}
                                overlay={userMenu} placement="topRight"
                                // onClick={() => setClosedMenupPopup(true)}
                                onVisibleChange={(e) => handleVisibleChange(e, true)} //NOSONAR
                                visible={closedMenuPopup}
                            >
                                <div command="userMenu" className="button">
                                    <icon data-tip={userData.userName}>user</icon>
                                    <text>{userData.userName}</text>
                                </div>
                            </Dropdown>
                            <separator vertical=""></separator>

                            <LanguageChangeComponent />
                        </>
                    }
                </action>
            </wrap>
        </toolbar>
        {selectedItem && selectedItem.subMenu.length && selectedItem.subMenu.some(sub => Project.hasRoleAccess(role, sub.to)) ?
            <toolbar className="main_header">
                <nav launcher="">
                    <ul>
                        {selectedItem.subMenu.map((item, i) => {
                            const url = (Project.hasRoleAccess(role, item.to) && Project.getPageUrl(item.to)) || "";
                            return url ? <li key={i} //NOSONAR
                                className={(url.toLowerCase() === location || (item.detail && location.startsWith(Project.getPageUrl(item.detail) + "/")) ? "selected" : "")}>
                                <Link to={url} onClick={closeAllPopups} ><text>{item.text}</text></Link>
                            </li> : <div key={i} //NOSONAR
                                style={{ display: 'none' }}></div >
                        })}
                    </ul>
                </nav>
            </toolbar> : <></>}
    </>
}
export default connect(state => ({
    role: state.role,
    culture: state.culture,
    userData: state.userData,
    isLoggedIn: state.isLoggedIn,
    networks: state.networks,
    network: state.network,
    userToken: state.userToken
}),
    dispatch => ({ closeAllPopups: () => dispatch(closeAllPopups()), logout: () => dispatch(logout()) }))(withRouter(Menu));