import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Dropdown, notification } from 'antd';

import { AppPages, ApiUrl } from '../project/Defines.js';
import { Project } from '../project/Project.js';
import { Ajax } from '../components/Ajax';
import { login, clearListStates } from '../store/actions/index.js';
import { T } from '../components/Translations';

import { LanguageChangeComponent } from '../components/Navigation/Menu';
import { trackConnections } from '../project/utilities';


function getParams() {
    return new URLSearchParams(window.location.search);
}

/**
 * the Login Component where user authorization occurs
 * @exports Login
 */
function Login(props) {
    const { isLoggedIn, history, currentNetwork } = props;
    const query = getParams();
    const redirectUrl = query.get("redirectUrl") || "";
    const ref = useRef(false);


    useEffect(() => {
        ref.current = true;

        return () => {
            ref.current = false;
        }

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoggedIn) {
            if (redirectUrl && redirectUrl !== Project.getPageUrl(AppPages.Login)) {
                history.push(redirectUrl);
            }
            else {
                history.push(Project.getPageUrl(AppPages.Home));
            }

        }
        return () => { }
    }, [isLoggedIn, history, redirectUrl]);

    

    const doAction = useCallback((url, openNewWindow, isInternal = false) => {
        if (ref.current) {
            Ajax.post({
                url: url,
                data: {isInternal: isInternal},
                success: function (response) {
                    if (response && response.redirectUrl) {
                        if (openNewWindow) {
                            window.open(response.redirectUrl, '_blank');
                        } else {
                            window.location.href = response.redirectUrl;
                        }
                    } else {
                        notification.warning({
                            description: 'Please check sso configuration',
                            placement: 'top',
                            duration: 3
                        });
                    }
                }
            })
        }
    }, [])

    const doConnect = useCallback(() => {
        doAction(ApiUrl.Connect, false, false);
    }, [doAction])
    const doConnectAd = useCallback(() => {
        doAction(ApiUrl.Connect, false, true);
    }, [doAction])
    const doRegister = useCallback(() => {
        doAction(ApiUrl.Register, true);
    }, [doAction])

    const doEnter = useCallback(() => {
        
        props.dispatch(clearListStates());
        props.dispatch(login({
            userToken: "guest", userName: "Guest", role: '',
            userData: { userName: "Guest" }
        }));
        history.push(Project.getPageUrl(AppPages.Home));

        trackConnections();
    }, [props, history])

    let networkMenu =
        <div className="list_menu ant-dropdown-menu">
            <ul>
                {
                    props.networks && props.networks.map((item, index) => {
                        return <li key={index} className={item.id === props.network ? "highlight" : ""} //NOSONAR
                        >
                            <a href={`${item.code}/login`}><icon></icon><text><T>{item.name}</T></text></a>
                        </li>
                    })
                }
            </ul>
        </div>;

    let networkMenuACV =
        <div className="list_menu ant-dropdown-menu">
            <ul>
                {
                    props.networks && props.networks.filter(n => n.brand === "ACV").map((item, index) => {
                        return <li key={index} className={item.id === props.network ? "highlight" : ""} //NOSONAR
                        >
                            <a href={`${item.code}/login`}><icon></icon><text><T>{item.name}</T></text></a>
                        </li>
                    })
                }
            </ul>
        </div>;

    //let networkCode = /:\/\/([^\/]+)/.exec(window.location.href)[1].split('.')[0].toUpperCase();// eslint-disable-line no-useless-escape
    let hideNetwork = true; //props.networks && (props.networks.find(item => item.code === networkCode));

    const doAutologin = useCallback(() => {
        history.push('loginredirect/test');
    }, [history])


    if (!props.isLoggedIn) {
        return <view transparent="" login="">
                <toolbar transparent="">
                    <wrap vertical="">

                        <action right="">
                            <LanguageChangeComponent />
                        </action>

                        <action right="">
                            <logo ></logo>
                            {/* <logo atlantic="" style={{ backgroundImage: `url(/content/img/logo/${Project.getLogo(props.networks && (props.networks.find(item => item.id === props.network) || {}).code)}.svg)`}}></logo> */}
                        </action>

                        {
                            currentNetwork.brand === "ACV" && < action right="">
                                <Dropdown
                                    className=""
                                    overlay={networkMenuACV} placement="topRight">
                                    <div className="button language">
                                        <text><T>{props.networks && (props.networks.find(item => item.id === props.network) || {}).name}</T></text>
                                    </div>
                                </Dropdown>
                            </action>
                        }
                        {
                            !hideNetwork && < action right=""> 
                                <Dropdown
                                    className=""
                                    overlay={networkMenu} placement="topRight">
                                    <div className="button language">
                                        {/*<text><T>{Project.getNetworkName(props.network)}</T></text>*/}
                                        <text><T>{props.networks && (props.networks.find(item => item.id === props.network) || {}).name}</T></text>
                                    </div>
                                </Dropdown>
                            </action>
                        }
                    </wrap>
                </toolbar>
                <view intro="" transparent="">
                    <login>
                        <login_cont>
                        { props.location.state?.isMaintainance && <div className="login_message">
                                <text className=""><b>This website is on maintenance now.</b></text>
                            </div>
                        }
                            <wrap id="container" multiple="multiple">
                                {props.networks && (props.networks.find(item => item.id === props.network) || {}).theme !== "ACV" &&
                                    <logo aquatic="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="219.855" height="135.893" viewBox="0 0 219.855 135.893">
                                            <g id="Group_595" data-name="Group 595" transform="translate(-13.336 -21.479)">
                                                <path id="Path_553" data-name="Path 553" d="M21.346,52.149a11.129,11.129,0,0,1,.136-1.67A10.437,10.437,0,0,1,22,48.573a8.763,8.763,0,0,1,2.891-3.7c1.4-1.108,3.508-1.685,6.324-1.685A8.9,8.9,0,0,1,37.929,45.8c1.67,1.73,2.512,4.3,2.512,7.671v3.493H23.372V63.19H40.442V84.3H46.68V53.037c0-4.729-1.51-8.463-4.5-11.239A16.226,16.226,0,0,0,30.712,37.63q-7.424,0-11.445,4.167a14.226,14.226,0,0,0-4.022,10.282V84.3h6.1Z" transform="translate(-0.196 14.291)" fill="#4698cb" />
                                                <path id="Path_554" data-name="Path 554" d="M35.648,65.973a17.2,17.2,0,0,0,4.607,7.969,12.506,12.506,0,0,0,9.4,3.611h.948a24.193,24.193,0,0,0,4.531-.4,10.566,10.566,0,0,0,4.09-1.647v-6.6a7.345,7.345,0,0,1-1.821,1.314,12.278,12.278,0,0,1-2.232.956,12.659,12.659,0,0,1-2.217.63,10.95,10.95,0,0,1-1.858.175,11.465,11.465,0,0,1-7.179-2.437c-2.134-1.63-3.2-4.3-3.2-8.037q0-4.45,2.224-6.791a11.626,11.626,0,0,1,4.895-3.2,11.044,11.044,0,0,1,1.958-.5,13.921,13.921,0,0,1,1.823-.136A9.945,9.945,0,0,1,52.989,51a6.851,6.851,0,0,1,1.466.4A10.394,10.394,0,0,1,59.2,55c1.427,1.806,2.149,4.424,2.149,7.847V91.738h5.737V61.228c0-4.341-1.184-7.809-3.523-10.382a16.57,16.57,0,0,0-7.733-5.017c-.637-.136-1.274-.243-1.889-.349-.637-.1-1.238-.175-1.823-.221l-1.016.068c-5.191.243-9.077,1.755-11.688,4.516s-4.037,6.823-4.28,12.2a18.929,18.929,0,0,0,.106,1.928A10.236,10.236,0,0,0,35.648,65.973Z" transform="translate(17.401 21.042)" fill="#4698cb" />
                                                <path id="Path_555" data-name="Path 555" d="M69.8,78.191A11.881,11.881,0,0,0,79.1,74.3a13.6,13.6,0,0,0,3.483-9.4V45.542h-5.67V64.319a10.641,10.641,0,0,1-.068,1.071,8.331,8.331,0,0,1-.221,1.061,10.043,10.043,0,0,1-2.322,4.22,5.747,5.747,0,0,1-4.5,1.943,7.324,7.324,0,0,1-7.841-7.848V45.542H56.372V63.81c0,5.191,1.252,8.895,3.764,11.081A14.118,14.118,0,0,0,69.8,78.191Z" transform="translate(36.195 21.292)" fill="#4698cb" />
                                                <path id="Path_556" data-name="Path 556" d="M89.018,78.045h.942a16.614,16.614,0,0,0,5.259-.66A11.457,11.457,0,0,0,98.6,75.472V68.195c-.449.454-.858.873-1.238,1.236-.394.379-.773.729-1.144,1.078a13.353,13.353,0,0,1-2.482,1.4,7.037,7.037,0,0,1-2.763.524h-.569a1.45,1.45,0,0,1-.515-.077,11.731,11.731,0,0,1-6.755-2.846c-2.026-1.7-3.044-4.394-3.044-8.052a10.335,10.335,0,0,1,6.018-9.569,9.356,9.356,0,0,1,2.322-.812,11.4,11.4,0,0,1,2.041-.228c3.713,0,6.346.85,7.871,2.55A8.6,8.6,0,0,1,100.706,59V77.521h6.254V58.784a13.044,13.044,0,0,0-4.145-9.29q-4.142-4.19-11.839-4.2c-5.434,0-9.524,1.579-12.31,4.722a16.779,16.779,0,0,0-4.16,11.513q0,7.447,4.35,11.976A13.609,13.609,0,0,0,89.018,78.045Z" transform="translate(52.242 21.074)" fill="#4698cb" />
                                                <path id="Path_557" data-name="Path 557" d="M96.079,72.2a12.634,12.634,0,0,0,2.39,6.231c1.359,1.858,3.476,2.776,6.329,2.776h.516a.565.565,0,0,0,.2-.038.421.421,0,0,0,.221-.1h7.773V75.3l-7.272.068c-2.079,0-3.285-.432-3.628-1.3a8.366,8.366,0,0,1-.5-3.042V54.536h11.9V48.98h-11.9V41.133h-6.1v29.5a5.534,5.534,0,0,0,.04.8C96.062,71.7,96.079,71.954,96.079,72.2Z" transform="translate(71.268 17.391)" fill="#4698cb" />
                                                <rect id="Rectangle_924" data-name="Rectangle 924" width="5.669" height="32.224" transform="translate(193.218 66.371)" fill="#4698cb" />
                                                <path id="Path_558" data-name="Path 558" d="M119.486,67.414a17.273,17.273,0,0,0,4.607,6.785c2.2,2.087,5.313,3.217,9.336,3.353h6.6v-5.57h-4.569a2.192,2.192,0,0,0-.228.03.719.719,0,0,1-.273.038h-.083a.3.3,0,0,1-.228.068h-.205a8.172,8.172,0,0,1-5.964-2.049,10.829,10.829,0,0,1-2.886-4.584c-.151-.592-.273-1.161-.379-1.723a8.835,8.835,0,0,1-.136-1.662c0-2.748.652-4.85,2-6.307a10.357,10.357,0,0,1,4.394-2.982,9.235,9.235,0,0,1,2.1-.592c.669-.107,1.306-.175,1.89-.228h4.569V45.26l-6.1.068a13.193,13.193,0,0,0-9.283,3.212,18.225,18.225,0,0,0-4.933,7.066,13.758,13.758,0,0,0-.941,3.087,14.6,14.6,0,0,0-.3,2.778v.09a16.041,16.041,0,0,0,.241,2.778A23.611,23.611,0,0,0,119.486,67.414Z" transform="translate(91.142 21.042)" fill="#4698cb" />
                                                <path id="Path_559" data-name="Path 559" d="M117.564,63.654c7.711,0,16.788-9.737,8.416-22.177a60.326,60.326,0,0,1-8.6-20,56.07,56.07,0,0,1-8.416,20.06C100.025,54.79,109.845,63.654,117.564,63.654Zm5.685-22.177c4.471,6.642,3.969,12.515,1.351,16.545-1.336,2.066-2.808,1.678-2.041-.622,1.334-4.007,1.425-9.547-1.17-15.763C120.038,38.412,121.115,38.313,123.248,41.477Z" transform="translate(79.586 0)" fill="#4698cb" />
                                                <path id="Path_560" data-name="Path 560" d="M17.548,88.215a403.472,403.472,0,0,1,67.926-5.59c22.788.06,39.076,4.165,54.826,8.137,14.922,3.762,29.349,7.4,48.046,7.4,12.779,0,27.556-1.7,45.845-6.224l-1.809-7.319c-43.217,10.687-66.056,4.929-90.237-1.169-16.193-4.083-32.938-8.3-56.651-8.365a404.838,404.838,0,0,0-69.257,5.7c-.407.072-.675.121-.8.138l.464,3.74-1.564,3.429A4.683,4.683,0,0,0,17.548,88.215Z" transform="translate(-1 47.431)" fill="#4698cb" />
                                                <path id="Path_561" data-name="Path 561" d="M142.145,89.7c-16.193-4.083-32.938-8.3-56.651-8.365a401.068,401.068,0,0,0-69.257,5.7c-.407.072-.675.121-.8.138l.464,3.74-1.564,3.429a4.683,4.683,0,0,0,3.212.121,399.578,399.578,0,0,1,67.926-5.59c22.788.06,39.076,4.165,54.826,8.137,14.922,3.762,29.349,7.4,48.046,7.4,12.779,0,27.556-1.7,45.845-6.224l-1.809-7.319C189.164,101.559,166.326,95.8,142.145,89.7Z" transform="translate(-1 52.96)" fill="#4698cb" />
                                            </g>
                                        </svg>
                                    </logo>
                                }
                                <form defaultbutton="[command='login']" autoComplete="off">
                                    <toolbar none="" transparent="">
                                        {currentNetwork && currentNetwork.requiredConnexion && <button className="button large right extra accent white" type="button" command="login" onClick={doConnect} disabled={props.location.state?.isMaintainance}><text><T>text.FO_CONNECT</T></text></button>}
                                        {currentNetwork && currentNetwork.requiredConnexion && <button className="button large right extra accent white" type="button" command="login" onClick={doConnectAd} disabled={props.location.state?.isMaintainance}><text><T>AZUREAD_CONNEXION_BUTTON</T></text></button>}
                                        {currentNetwork && !currentNetwork.requiredConnexion && <button className="button large right extra accent white" type="button" command="login" onClick={doEnter}><text><T>text.FO_ENTER</T></text></button>}
                                        {process.env.REACT_APP_ENABLE_AUTO_LOGIN && process.env.REACT_APP_ENABLE_AUTO_LOGIN === "true" && <button className="button large right extra accent white" type="button" command="login" onClick={doAutologin} disabled={props.location.state?.isMaintainance}><text><T>008</T></text></button>}
                                    </toolbar>
                                </form>
                                <info highlight="">
                                    {currentNetwork && currentNetwork.requiredConnexion && <div holder="" right="">
                                        <a onClick={doRegister}><b><T>text.FO_ACCOUNT_CREATION</T></b></a>{// eslint-disable-line jsx-a11y/anchor-is-valid 
                                        }
                                        <separator vertical=""></separator>
                                    </div>}
                                    <p className="info"><T>text.FO_TOOLS_DESCRIPTION</T></p>
                                    {/*<p className="info">Cet outil, destiné à la maison individuelle, est mis à disposition pour vous aider au dimensionnement et à la sélection de la gamme pompe à chaleur air/eau ou eau/eau la plus adaptée.Il vous permettra aussi de sélectionner les accessoires et des systèmes de chauffage complémentaires et vous accompagnera pour la personnalisation de votre devis.</p>*/}
                                </info>
                            </wrap>
                        </login_cont>
                    </login>
                </view>
                <back style={{ backgroundImage: "url(/content/img/connection2.jpg)"}}></back>
            </view>
    } else {
        return <></>;
    }
}

export default connect(state => ({ isLoggedIn: state.isLoggedIn, networks: state.networks, network: state.network, currentNetwork: state.networks && state.network && state.networks.find(item => item.id === state.network), culture: state.culture }), null)(Login);