import React, { useEffect, useState } from "react";
import { NumberBox } from 'devextreme-react/number-box';
import { /*message,*/ notification } from "antd";
import { T } from "../../components/Translations";
import i18n from "../Translation/i18n";
import { SubAppCode } from "../../project/Defines";
import { useDispatch, useSelector } from "react-redux";
import { setApplicationData, setNumberChangeInProcess } from '../../store/actions';

const InputNumber = React.forwardRef((props, ref) => { //NOSONAR
    const { min, max, setData, disabled, name, label, tabIndex, onError, allData, /*active, id,*/ onKeyDown, allowDecimal, subapplication, data, id, required, allowMessages } = props;
    const [textColor, setTextColor] = useState(null);
    const dispatch = useDispatch();
    const { applicationData/*, numberInProcess*/ } = useSelector(state => state);

    let _onKeyDown = (e) => {
        let code = e.event.code;
        if ((code === 'Enter' || code === 'NumpadEnter') && document.activeElement.value) {
            e.event.preventDefault();
            document.activeElement.blur();
        }
    }

    const showNotification = () => {
        notification.info({
            message: `Notification`,
            description: <T args={[i18n(label), min, max]}>{`text.${[allData[name]["errorMessage"]]}`}</T>,
            placement: 'top',
            duration: 0,
            key: name
        });
    }
/* eslint-disable */
    useEffect(() => {
        let value = props.value;
        if (value !== "") {// && applicationData[id]?.edited
            value = allowDecimal ? +parseFloat(value).toFixed(2) : Math.round(value);
            if ((value > max || value < min) && (!(allData[name].allowZerro && value == 0) || !allData[name].allowZerro)) { 
                if (allData && allData[name] && allData[name]["errorMessage"] && allowMessages !== false) {
                    showNotification();
                }

                setTextColor("red");
                onError && onError()
            } else {
                setTextColor(null);
                notification.close(name);
            }
        } else {
            notification.close(name);

        }
    }, [min, max, props.value, allowMessages]);// eslint-disable-line react-hooks/exhaustive-deps
    /* eslint-enable */

    useEffect(() => {
        if (name === "Pboucle" && data && allData.Pboucle.default !== "") {
            if (subapplication === SubAppCode.LOGEMENTSPRIVES || subapplication === SubAppCode.LOGEMENTSSOCIAUX) {
                if (allData.Pboucle.default > data.nbtTotal * 0.6) {
                    setTextColor("red");
                    onError && onError()
                }
            } else if (subapplication === SubAppCode.RESIDENCESSENIORS) {
                if (allData.Pboucle.default > data.nbT1 * 0.2) {
                    setTextColor("red");
                    onError && onError();
                }
            }
            else if (subapplication === SubAppCode.FOYERDEJEUNESTRAVAILLEURS) {
                if (allData.Pboucle.default > data.NbLit * 0.2) {
                    setTextColor("red");
                    onError && onError();
                }
            } else if (subapplication === SubAppCode.AFFAIRES || subapplication === SubAppCode.MONTAGNE || subapplication === SubAppCode.TOURISME) {
                if (allData.Pboucle.default > data.NbC * 0.2) {
                    setTextColor("red");
                    onError && onError()
                }
            }

        }

    }, [data, allData]);// eslint-disable-line react-hooks/exhaustive-deps

    const onValueChanged = (e) => {
        dispatch(setNumberChangeInProcess(false));
        let value = e.event.target.value;
        if (value !== "") {
            value = allowDecimal ? +parseFloat(value).toFixed(2) : Math.round(value);
            //if ((value > max || value < min) && (!(allData[name].allowZerro && value == 0) || !allData[name].allowZerro)) {
            //    if (allData && allData[name] && allData[name]["errorMessage"]) {
            //        showNotification();
            //    }
            //    setTextColor("red");
            //    onError && onError()
            //} else {
            //    setTextColor(null);
            //    notification.close(name);
            //}
        }
        //else {
        //    notification.close(name);
        //}
        if (setData && !isNaN(value)) {
            setData(item => ({ ...item, [name]: value }));
            if (applicationData && applicationData[id]) {
                dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], edited: true } }))
            }
        }
    }

    return <NumberBox
        ref={ref}
        tabIndex={tabIndex}
        inputAttr={{
            style: `color:${textColor}; box-shadow:  ${required && (!props.value && props.value !== 0) ? '0 0 0 1px rgb(255 0 0 / 40%)' : ''}`,
            minVal: min,
            maxVal: max
        }}
        onKeyDown={onKeyDown || _onKeyDown}
        value={props.value === 0 ? 0 : ((props.value && +props.value) || null)} elementAttr={{ name: name }}
        onFocusOut={onValueChanged} //NOSONAR
        onFocusIn={() => { dispatch(setNumberChangeInProcess(true)) }}
        disabled={disabled} />;
});

export default InputNumber;
