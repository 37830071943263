import React from "react";

import { connect } from "react-redux";


function CGU(){

    return(<>
        <embed src={`/help/cgu_fr.pdf`} type="application/pdf" width="100%" height="100%"></embed>
    </>)
}

export default connect(state => ({ culture: state.culture }), null)(CGU);