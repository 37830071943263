import React, { useState, useCallback } from 'react';
import { Spin } from 'antd';
import { EVENT_NAME, useCustomEvent, dispatchCustomEvent } from '../../project/utilities';

export default function Loader(props) {
    const [innerLoaders, setInnerLoaders] = useState([]);
    const showLoader = useCallback((e) => {
        setInnerLoaders((oldLoaders) => {
            return [...oldLoaders.filter(item => item !== e.detail.loaderName), e.detail.loaderName]
        });
    }, []);

    const hideLoader = useCallback((e) => {
        setInnerLoaders((oldLoaders) => {
            return oldLoaders.filter(item => item !== e.detail.loaderName)
        })
    }, []);

    useCustomEvent(EVENT_NAME.SHOW_LOADER, showLoader);
    useCustomEvent(EVENT_NAME.HIDE_LOADER, hideLoader);
//    useEffect(() => { setLoaders(innnerLoaders) },[ innerLoaders])
    return (
        innerLoaders.length > 0 || props.force?

                <div preloader="">
                    <Spin size="large" />
                </div >
            :
            <></>
    );
}

export function showLoader(loaderName) {
    dispatchCustomEvent(EVENT_NAME.SHOW_LOADER, { loaderName });
}

export function hideLoader(loaderName) {
    dispatchCustomEvent(EVENT_NAME.HIDE_LOADER, { loaderName });
}

