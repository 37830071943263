import React, { useCallback, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { connect } from "react-redux";
import i18n from '../Shared/Translation/i18n';
import { TemplateDetails, TemplateServiceCallDetails } from '../pages/Template';
import SolutionDetails from '../pages/Applications/Application/Zone4/details';
import Solutions from '../pages/Applications/Application/Zone4/solutions';
import { closePopup } from './../store/actions';
import { T } from './Translations';
import EtudePickup from '../pages/EtudePickup';
import DocumentDetails from '../pages/EtudeDocument/DocumentDetails';
import ShareDocumentPopup from '../pages/EtudeDocument/ShareDocumentPopup.js'
import DownloadPDFPopup from '../pages/Etude/DownloadPDFPopup.js'

/**
 * specifies all the popups without navigation
 */
export const PopupTypes = {
    Confirm: "Confirm",
    Prompt: "Prompt",
    Alert: "Alert",
    TemplateDetails: "TemplateDetails",
    TemplateServiceCallDetails: "TemplateServiceCallDetails",
    SolutionDetails: "SolutionDetails",
    EtudePickup: "EtudePickup",
    DocumentDetails: "DocumentDetails",
    Solutions: "Solutions",
    Email: "Email",
    DownloadPDF: "DownloadPDF"
    // FrontDocument: "FrontDocument"
}

export const PopupClasses = {
    Small: "small",
    Medium: "medium",
    Large: "large",
}

/**
 * the main function generating all opened popups
 * @param {object} props  contains props for fulscreen, class, all opened popups info
 */
function Popups(props) {
    let { popups, fullScreen } = props;
    popups = (popups && popups.filter(item => (item.fullScreen || false) === (fullScreen || false))) || [];
    let window_container = (popups.length > 0) ? "window_container open" : "window_container";
    return <div className={window_container}>
        {
            popups.map((item, i) => {
                let closeOutside = false;
                let ComponentVar;
                const { type, bodyProps, className } = item;
                let open_class = type + (item.fullScreen ? " open fullscreen" : " open");
                const onClose = (e) => props.closePopup(item.windowKey);
                let skipCloseClassNames = '';
                switch (type) {
                    case PopupTypes.Confirm:
                        return <InnerPopup key={type + i} className="open" onClose={onClose} closeOnOutsideClick={true} //NOSONAR
                        >
                            <Confirm {...item} onClose={onClose} //NOSONAR
                            />
                        </InnerPopup>;

                    case PopupTypes.Prompt:
                        return <InnerPopup key={type + i} className="open" onClose={onClose} closeOnOutsideClick={false} //NOSONAR
                        >
                            <Prompt {...item} onClose={onClose} //NOSONAR
                            />
                        </InnerPopup>;

                    case PopupTypes.Alert:
                        return <InnerPopup key={type + i} className="open" onClose={onClose} closeOnOutsideClick={false} //NOSONAR
                        >
                            <Alert {...item} onClose={onClose} //NOSONAR
                            />
                        </InnerPopup>;

                    case PopupTypes.TemplateDetails:
                        ComponentVar = TemplateDetails;
                        break;
                    case PopupTypes.TemplateServiceCallDetails:
                        ComponentVar = TemplateServiceCallDetails;
                        break;
                    case PopupTypes.SolutionDetails:
                        ComponentVar = SolutionDetails;
                        break;
                    case PopupTypes.EtudePickup:
                        ComponentVar = EtudePickup;
                        break;
                    case PopupTypes.DocumentDetails:
                        ComponentVar = DocumentDetails;
                        break;
                    case PopupTypes.Solutions:
                        ComponentVar = Solutions;
                        break;
                    case PopupTypes.Email:
                        ComponentVar = ShareDocumentPopup;
                        break;
                    case PopupTypes.DownloadPDF:
                        ComponentVar = DownloadPDFPopup;
                        break;
                    // case PopupTypes.FrontDocument:
                    //     ComponentVar = FrontDocument;
                    //     break;
                    default:
                        return <div key={i} //NOSONAR
                        ></div>;
                }
                return <InnerPopup
                    key={type + i} //NOSONAR
                    className={`${open_class} ${className || ''}`}
                    onClose={onClose} //NOSONAR
                    skipCloseClassNames={skipCloseClassNames}
                    closeOnOutsideClick={i === popups.length - 1 && closeOutside}>
                    <PupupWrapper {...item} onClose={onClose} //NOSONAR
                    >
                        <ComponentVar {...bodyProps} onClose={onClose} //NOSONAR
                        />
                    </PupupWrapper>
                </InnerPopup>
            })
        }
    </div>
}
export default connect(state => ({
    popups: state.popups
}),
    dispatch => ({
        closePopup: (key) => dispatch(closePopup(key))
    })
)(Popups);

/**
 *custom hook for running logic on outer click of specified element with given ref
 * @param {func} onOuterClick
 * @param {object} innerRef the ref of element
 * @param {skipCloseClassNames} skipCloseClassNames
 * @param {bool} enable
 */
function useOuterClickNotifier(onOuterClick, innerRef, skipCloseClassNames, enable) {
    useEffect(
        () => {
            if (innerRef.current && enable) {
                document.addEventListener("click", handleClick);
            }

            return () => document.removeEventListener("click", handleClick);

            function handleClick(e) {
                innerRef.current &&
                    (innerRef.current === e.target || !innerRef.current.contains(e.target)) && !(document.getElementsByClassName(skipCloseClassNames)[0] && document.getElementsByClassName(skipCloseClassNames)[0].contains(e.target)) &&
                    onOuterClick(e);
            }
        },
        [enable, innerRef, skipCloseClassNames, onOuterClick] // invoke again, if inputs have changed
    );
}

/**
 * the outer warpper of all popups (for each one)
 * @param {any} props className, children, onClose, skipCloseClassNames, closeOnOutsideClick
 */
function InnerPopup(props) {
    const { className, children, onClose, skipCloseClassNames, closeOnOutsideClick } = props;
    const innerRef = useRef(null);

    useOuterClickNotifier(
        e => { onClose() },
        innerRef,
        skipCloseClassNames,
        closeOnOutsideClick
    );

    return (
        <div ref={innerRef} className={className}>
            {children}
        </div>
    );
}

/**
 *custom confirm popup
 * @param {object} props contains title, text, yesCallback, noCallback, buttonYesText, buttonNoText, onClose
 */
function Confirm(props) {
    const { title, text, yesCallback, noCallback, buttonYesText, buttonNoText, onClose } = props;
    const [, setUpdating] = useState(false);

    let onYesButtonClick = useCallback(function (e) {
        setUpdating(true);
        yesCallback && yesCallback(e);
        onClose();
    }, [yesCallback, onClose]);

    let onNoButtonClick = useCallback(function (e) {
        noCallback && noCallback();
        onClose();
    }, [noCallback, onClose]);

    return <div>
        <div>
            <div className="message_box">
                <div className="message_container">
                    <icon large="">question</icon>
                    <h3>{title}</h3>
                    <p>{text}</p>
                </div>
                <panel>
                    <button type="button" effect="material" command="yes" className="button primary" onClick={onYesButtonClick}>
                        <text>{buttonYesText}</text>
                    </button>
                    <separator vertical=""></separator>
                    <button type="button" effect="material" command="no" className="button accent" onClick={onNoButtonClick}>
                        <text>{buttonNoText}</text>
                    </button>
                </panel>
            </div>
        </div>
    </div>;
}

Confirm.defaultProps = {
    title: "",
    text: "",
    htmlText: false,
    buttonYesText: "OK",
    buttonNoText: "Cancel"
}

/**
 *custom prompt popup
 * @param {object} props contains title, text, yesCallback, noCallback, buttonYesText, buttonNoText, onClose
 */

function Prompt(props) {
    const { title, isPassword, placeholder, yesCallback, noCallback, buttonYesText, buttonNoText, onClose } = props;
    const [, setUpdating] = useState(false);
    const [value, setValue] = useState('');

    let onYesButtonClick = useCallback(function (e) {
        setUpdating(true);
        yesCallback && yesCallback(e, value);
        onClose();
    }, [yesCallback, onClose, value]);

    let onNoButtonClick = useCallback(function (e) {
        noCallback && noCallback();
        onClose();
    }, [noCallback, onClose]);

    return <div>
        <div>
            <div className="message_box">
                <div className="message_container">
                    <h3>{title}</h3>

                    <form>
                        <div className="form_container">
                            <div className="section_group">
                                <div className="sections ">
                                    <div className="form_fields">
                                        <input type={isPassword ? "password" : "text"} value={value} placeholder={placeholder} onChange={(e) => setValue(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
                <panel>
                    <button type="button" effect="material" command="yes" className="button primary" onClick={onYesButtonClick}>
                        <text>{buttonYesText}</text>
                    </button>
                    <separator vertical=""></separator>
                    <button type="button" effect="material" command="no" className="button accent" onClick={onNoButtonClick}>
                        <text>{buttonNoText}</text>
                    </button>
                </panel>
            </div>
        </div>
    </div>;
}

/**
 *custom alert popup
 * @param {object} props contains title, text, yesCallback, buttonYesText, onClose
 */
function Alert(props) {
    const { title, text, textKey, yesCallback, buttonYesText, buttonYesTextKey, onClose } = props;

    let onYesButtonClick = useCallback(function (e) {
        yesCallback && yesCallback(e);
        onClose();
    }, [yesCallback, onClose]);

    return <div>
        <div>
            <div className="message_box">
                <div className="message_container">
                    <icon large="">warning</icon>
                    <h3>{title}</h3>
                    <p>{textKey ? <div dangerouslySetInnerHTML={{ __html: i18n(textKey) }}></div> : text}</p>
                </div>
                <panel>
                    <button type="button" effect="material" command="yes" className="button primary" onClick={onYesButtonClick}>
                        <text>{buttonYesTextKey ? i18n(buttonYesTextKey) : buttonYesText}</text>
                    </button>
                </panel>
            </div>
        </div>
    </div>;
}

Prompt.defaultProps = {
    title: "",
    isPassword: false,
    placeholder: '',
    buttonYesText: "OK",
    buttonNoText: "Cancel"
}

/**
 * default carcas for all popups except confirm
 * @param {any} props
 */
function PupupWrapper(props) {
    const { title, fullScreen, showHeader, children, cancelCallback, onClose } = props;
    let onCancel = useCallback(function (e) {
        cancelCallback && cancelCallback(e);
        onClose();
    }, [onClose, cancelCallback]);

    let innerContent =
        <div className="box no-cursor">
            {showHeader &&
                <header>
                    {
                        fullScreen &&
                        <>
                            <div effect='material' className='button white mini back' command='close' onClick={onCancel}>
                                <icon>left</icon>
                            </div>
                            <separator vertical=""></separator>
                        </>
                    }

                    <div style={{ width: 'calc(100% - 20px)' }}>
                        <strong className="cursor"><div><p><T>{title}</T></p></div></strong>

                    </div>

                    {
                        !fullScreen &&
                        <div effect="material" className="button white mini close" command="close" onClick={onCancel}>
                            <icon>close</icon>
                        </div>
                    }
                </header>
            }
            <div>
                <div className="window_cont">
                    {children}
                </div>
            </div>
        </div>

    if (!fullScreen) {

        innerContent = <Draggable handle="strong">
            {innerContent}
        </Draggable>
    }
    return innerContent;
}

PupupWrapper.defaultProps = {
    showHeader: true,
    fullScreen: true,
}