import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

/**
 * a wrapper to detail form representink a page wih url
 * @param {any} props contains: backUrl, title, showPopup
 * @exports DetailView
 */
export default function DetailView(props) {
    const { backUrl, title, showPopup, showWindowCont } = props;
    const detailRef = useRef(null);

    return showPopup ?
        <view auto_height="" transparent="" id='windowContent' className={showWindowCont ? 'window_cont' : ''} ref={detailRef}>
            {props.children}
        </view>
         :
        <view ref={detailRef} >
            <toolbar>
                <div effect='material' className='button white mini back'>
                    <Link to={backUrl}><icon>left</icon></Link>
                </div>
                <separator vertical=""></separator>
                {title || ""}
                {/* <div>
                    <strong><text>{title || ""}</text></strong>
                </div> */}
            </toolbar>
            {props.children}
        </view>
        ;
}