const CACHENAME = 'websatc';
const options = {
    headers: {'content-type': 'application/json'}
};
const CacheNames = {
    clearOnLogout: `${CACHENAME}-cl-onLogout`,
    clearOnTime: `${CACHENAME}-cl-onTime`
}

const useSWCache = ({key, isClearOnLogout, clearMinutes}) => {
    const setDataClearsOnLogout = (data) => {
        caches.open(CacheNames.clearOnLogout).then(cache => {
            cache.put(key,  new Response(JSON.stringify(data), options));
        })
    };

    const setDataClearsOnTime = (data) => {
        let clearTime = new Date();
        clearTime.setMinutes(clearTime.getMinutes() + clearMinutes);

        caches.open(CacheNames.clearOnTime).then(cache => {
            cache.put(key,  new Response(JSON.stringify({data: data, clearTime: clearTime}), options));
        })
    };

    const getDataClearsOnLogout = async () => {
        let data = await caches.open(CacheNames.clearOnLogout).then(cache => {
            return cache.match(new Request(key, options)).then(d => {
                if(d) {
                    return d.json().then(_data => {
                        return _data;
                    })
                } else {
                    return null;
                }
            })
        })

        return data;
    };

    const getDataClearsOnTime = async () => {
        let data = await caches.open(CacheNames.clearOnTime).then(cache => {
            return cache.match(new Request(key, options)).then(d => {
                if(d) {
                    return d.json().then(_data => {
                        if(new Date(_data.clearTime) < new Date()) {
                            return null;
                        }

                        return _data.data;
                    })
                } else {
                    return null;
                }
            })
        });

        return data;
    };

    const setCacheData = (data) => {
        if(isClearOnLogout) {
            setDataClearsOnLogout(data);
        } else if(clearMinutes) {
            setDataClearsOnTime(data);
        }
    };

    const getCacheData = async () => {
        let data;
        if(isClearOnLogout) {
            data = getDataClearsOnLogout();
        } else if(clearMinutes) {
            data = getDataClearsOnTime();
        }
        
        return data;
    }

    return { setCacheData, getCacheData }
};

useSWCache.clearOnLogout = () => {// call this function on logout
    caches.delete(CacheNames.clearOnLogout);
    caches.delete(CacheNames.clearOnTime);
}

export { useSWCache };