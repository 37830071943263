import { ACTION } from './../actions';
import store from 'store';
import { useCacheData } from '../../Shared/CacheData'
import { useSWCache } from '../../Shared/SWCache'
import { StoreKeys } from '../../project/Defines'

const defaultState = {
    isLoggedIn: store.get(StoreKeys.LoggedIn),
    popups: [],
    listStates: store.get(StoreKeys.ListStates) || {},
    userToken: store.get(StoreKeys.UserToken),
    culture: store.get(StoreKeys.Culture),
    cultureMode: store.get(StoreKeys.CultureMode),
    network: store.get(StoreKeys.Network),
    networks: store.get(StoreKeys.Networks),
    applicationData: store.get(StoreKeys.ApplicationData),
    role: store.get(StoreKeys.Role),
    userData: store.get(StoreKeys.UserData),
    loaders: [],
    customTooltips: [], // [{ tooltipKey: "mnd", top: 200, left: 200, direction: 'left', Component: 'h1', componentProps: { style: {background:'turquoise'}}}],
    footerToolbars: [],
    isMaintenanceModeEnabled: true,
    maintenanceModeStart: new Date('Feb 12 2024 10:00:00 GMT+0100'),
    maintenanceModeEnd: new Date('Feb 12 2024 10:50:00 GMT+0100')
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ACTION.LOGIN:
            store.set(StoreKeys.ExpireTime, (new Date()).setMinutes((new Date()).getMinutes() + 1));
            store.set(StoreKeys.UserToken, action.payload.userToken);
            store.set(StoreKeys.LoggedIn, true);
            store.set(StoreKeys.UserData, action.payload.userData);
            store.set(StoreKeys.Culture, action.payload.culture);
            store.set(StoreKeys.Role, action.payload.role);

            return {
                ...state,
                isLoggedIn: true,
                userToken: store.get(StoreKeys.UserToken),
                role: store.get(StoreKeys.Role),
                userData: store.get(StoreKeys.UserData)
            };

        case ACTION.LOGOUT:
            store.remove(StoreKeys.UserToken);
            store.remove(StoreKeys.Role);
            store.remove(StoreKeys.UserData);
            store.remove(StoreKeys.LoggedIn);
            store.remove(StoreKeys.ApplicationData)
            useCacheData.clearOnLogout();
            useSWCache.clearOnLogout();
            return {
                ...state,
                isLoggedIn: false,
                userToken: '',
                userName: null,
                role: null
            };

        case ACTION.REFRESH_LOGIN:
            store.set(StoreKeys.ExpireTime, (new Date()).setMinutes((new Date()).getMinutes() + 1));
            store.set(StoreKeys.UserToken, action.payload.userToken);
            store.set(StoreKeys.LoggedIn, true);
            let user = {...(store.get(StoreKeys.UserData))};
            user.accessToken = action.payload.accessToken;
            user.logoutUrl = action.payload.logoutUrl;
            store.set(StoreKeys.UserData, user);

            return {
                ...state,
                isLoggedIn: true,
                userToken: store.get(StoreKeys.UserToken),
                role: store.get(StoreKeys.Role),
                userData: store.get(StoreKeys.UserData)
            };

        case ACTION.CHANGE_EMAIL:
            let _user = { ...(store.get(StoreKeys.UserData)) };
            _user.email = action.payload.email;
            store.set(StoreKeys.UserData, _user);
            return {
                ...state,
                userData: store.get(StoreKeys.UserData)
            };

        case ACTION.CHANGE_CULTURE:
            store.set(StoreKeys.Culture, action.data);
            store.set(StoreKeys.CultureMode, "M");
            return {
                ...state,
                culture: action.data,
                cultureMode: "M"
            };
        case ACTION.SET_CULTURE:
            store.set(StoreKeys.Culture, action.data);
            store.set(StoreKeys.CultureMode, "A");
            return {
                ...state,
                culture: action.data,
                cultureMode: "A"
            };

        case ACTION.CHANGE_NETWORK:
            store.set(StoreKeys.Network, action.data);
            return {
                ...state,
                network: action.data
            };

        case ACTION.OPEN_POPUP: {
            const popups = [...state.popups];
            popups.push(action.data);
            return {
                ...state,
                popups
            };
        }

        case ACTION.CLOSE_POPUP:
            {
                const popups = [...state.popups];
                const index = popups.findIndex(p => p.windowKey === action.windowKey);
                if (index >= 0) {
                    popups.splice(index, 1);
                }
                return {
                    ...state,
                    popups
                };
            }
        case ACTION.UPDATE_POPUP_TITLE:
            {
                const popups = [...state.popups];
                const index = popups.findIndex(p => p.windowKey === action.windowKey);
                if (index >= 0) {
                    popups[index].title = action.title;
                }
                return {
                    ...state,
                    popups
                };
            }

        case ACTION.CLOSE_ALL_POPUPS: {
            return {
                ...state,
                popups: []
            };
        }

        case ACTION.OPEN_TOOLTIP: {
            const customTooltips = [...state.customTooltips];
            customTooltips.push(action.data);
            return {
                ...state,
                customTooltips
            };
        }

        case ACTION.CLOSE_TOOLTIP:
            {
                const customTooltips = [...state.customTooltips];
                const index = customTooltips.findIndex(p => p.tooltipKey === action.tooltipKey);
                if (index >= 0) {
                    customTooltips.splice(index, 1);
                }
                return {
                    ...state,
                    customTooltips
                };
            }

        case ACTION.UPDATE_TOOLTIP_PROPS:
            {
                const customTooltips = [...state.customTooltips];
                const item = customTooltips.find(p => p.tooltipKey === action.data.tooltipKey);
                if (item) {
                    item.componentProps = action.data.componentProps;
                }
                return {
                    ...state,
                    customTooltips
                };
            }

        case ACTION.FOOTER_ADD_TOOLBAR: {
            const footerToolbars = [...state.footerToolbars];
            footerToolbars.push(action.data);
            return {
                ...state,
                footerToolbars
            };
        }

        case ACTION.FOOTER_REMOVE_TOOLBAR:
            {
                const footerToolbars = [...state.footerToolbars];
                const index = footerToolbars.findIndex(p => p.toolbarKey === action.toolbarKey);
                if (index >= 0) {
                    footerToolbars.splice(index, 1);
                }
                return {
                    ...state,
                    footerToolbars
                };
            }

        case ACTION.FOOTER_UPDATE_TOOLBAR:
            {
                const footerToolbars = [...state.footerToolbars];
                const index = footerToolbars.findIndex(p => p.toolbarKey === action.toolbarKey);
                if (index >= 0) {
                    footerToolbars.splice(index, 1);
                }
                footerToolbars.push(action.data);
                return {
                    ...state,
                    footerToolbars
                };
            }

        case ACTION.SET_LIST_STATE: {
            const ls = {
                ...state.listStates,
                [action.payload.listName]: action.payload.state
            };
            store.set(StoreKeys.ListStates, ls);
            return {
                ...state,
                listStates: ls
            };
        }
        case ACTION.CLEAR_LIST_STATES: {
            store.remove(StoreKeys.ListStates);
            return {
                ...state,
                listStates: {}
            };
        }

        case ACTION.STORAGE_CHANGE: {
            return {
                ...state,
                isLoggedIn: store.get(StoreKeys.LoggedIn),
                listStates: store.get(StoreKeys.ListStates) || {},
                userToken: store.get(StoreKeys.UserToken),
                culture: store.get(StoreKeys.Culture),
                role: store.get(StoreKeys.Role),
                userName: store.get(StoreKeys.UserName),
                userData: store.get(StoreKeys.UserData)
            };
        }

        case ACTION.SET_LOADERS: {
            return {
                ...state,
                loaders: action.payload
            };
        }

        case ACTION.SET_NETWORKS: {
            store.set(StoreKeys.Networks, action.payload);
            return {
                ...state,
                networks: action.payload
            };
        }
        case ACTION.SET_APPLICATION_DATA: {
            store.set(StoreKeys.ApplicationData, action.payload);
            return {
                ...state,
                applicationData: action.payload
            };
        }
        case ACTION.SET_NUMBER_CHANGE_IN_PROCESS: {
            store.set(StoreKeys.NumberInProcess, action.payload);
            return {
                ...state,
                numberInProcess: action.payload
            };
        }

        default:
            return state;
    }
}