import React, { useEffect, useState } from "react";
import { message, Collapse } from "antd";

import { Ajax } from '../components/Ajax';
import { ApiUrl } from '../project/Defines.js';
import { connect } from "react-redux";

const { Panel } = Collapse;

function FAQ(props){
    const { network, culture } = props;
    const [ data, setData ] = useState();

    useEffect(() => {
        Ajax.get({
            url: ApiUrl.GetFAQ,
            data: { network, culture },
            success: function (response) {
                if (response) {
                    setData(response.data);
                } else{
                    message.error();
                }
            },
            error: function (response) {
                message.error();
            }
        })
    }, [network, culture]);

    return(<>
        <toolbar>
            <div className="caption">
                <text><b>FAQ</b></text>
            </div>
        </toolbar>
        { data && <Collapse 
                // onChange={onChange}
            >
                {
                    data.map((item, index) => {
                        return <Panel header={item.question} key={index} //NOSONAR
                        >
                            <p>{item.answer}</p>
                        </Panel>
                    })
                }
        </Collapse>
        }
    </>)
}

export default connect(state => ({ network: state.network, culture: state.culture }), null)(FAQ);