import React, { useState, useCallback, useEffect, useRef } from 'react';

import { Ajax } from '../../components/Ajax';
import { ApiUrl } from '../../project/Defines.js';
import { Select } from 'antd';

import { useCustomEvent } from '../../project/utilities';
import { useSWCache } from '../../Shared/SWCache'
import { connect } from 'react-redux';
import { T } from '../../components/Translations';

const { Option } = Select;

/**
 * specific case of DropDownValueList with 'multiple' mode
 * @param {any} props valuelist, items, parentValue, additionalData, reloadEventName, ...restprops
 */
export function MultiSelectValueList(props) {
    return <DropDownValueList {...props} mode='multiple' />
}

/**
 * custom DropDownValueList working with backend value lists and custom given items
 * @param {object} props   valuelist, mode, items, parentValue, additionalData, reloadEventName, ...restprops
 */
export function DropDownValueList(props) {
    const [loaded, setLoaded] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const { value, restrictCustomValue, allowClear, valuelist, mode, size, items, parentValue, additionalData, reloadEventName, disableCach, culture, autoSelectFirst, dispatch, searchOptions, textAsValue, useTranslation, ...restprops } = props;
    const ref = useRef(false);

    const { getCacheData, setCacheData } = useSWCache({ key: `${culture}_${valuelist}`, isClearOnLogout: true });

    const manageValue = useCallback((data) => {
        if ((restrictCustomValue && !mode) && value && !data.some(item => item[textAsValue ? 'text' : 'value'] === value)) {
            props.onChange && props.onChange(autoSelectFirst ? data[0].value : '');
        } else if (autoSelectFirst && !value) {
            props.onChange && props.onChange(data[0].value);
        }

    }, [value, autoSelectFirst, textAsValue, restrictCustomValue, mode, props])

    const loadData = useCallback(() => { //NOSONAR
        if (valuelist) {
            if (typeof valuelist === 'string') {
                Ajax.post({
                    url: ApiUrl.ValueList,
                    data: {
                        valueList: valuelist,
                        parentValue: parentValue || "",
                        additionalData: additionalData || ""
                    },
                    success: function (response) {
                        if (response && ref.current) {
                            const data = response;
                            for (const element of data) {
                                //data[i].date = new Date(data[i].date)
                                element.key = element.uuid;
                            }
                            setDataSource(data);
                            setLoaded(true);
                            manageValue(data);
                            !disableCach && setCacheData(data);
                        }
                    }
                });
            } else {
                setDataSource(valuelist);
                setLoaded(true);
                manageValue(valuelist);
            }
        }
    }, [valuelist, parentValue, additionalData, autoSelectFirst, disableCach, setCacheData, manageValue]);// eslint-disable-line react-hooks/exhaustive-deps

    useCustomEvent(reloadEventName || 'reload_valuelist_' + valuelist, loadData);

    useEffect(() => {
        ref.current = true;
        if (items) {
            setDataSource([...items]);
            setLoaded(true);
            manageValue(items);
        }
        else {
            if (disableCach) {
                loadData();
            } else {
                getCacheData().then((data) => {
                    if (ref.current) {
                        if (data) {
                            setDataSource(data);
                            setLoaded(true);
                            manageValue(data);
                        } else {
                            loadData();
                        }
                    }
                });
            }
        }
        return () => { ref.current = false }
    }, [additionalData, valuelist, parentValue]);// eslint-disable-line react-hooks/exhaustive-deps

    const filterOption = (input, option) => {
        let text = searchOptions.caseSensitive ? option.text : option.text && option.text.toLowerCase();
        let searchInput = searchOptions.caseSensitive ? input : input && input.toLowerCase();
        if (searchOptions.noAccent) {
            text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            searchInput = searchInput.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }
        return !searchInput || (text && (searchOptions.contains ? text.includes(searchInput) : text.startsWith(searchInput)));
    };

    return <>
        {loaded && <Select
            allowClear={allowClear}
            size={size || "default"}
            mode={mode}
            virtual={false}
            value={ value}
            {...restprops}
            onChange={(e) => props.onChange(e, dataSource)} //NOSONAR
            filterOption={searchOptions && filterOption}
            showSearch={!!searchOptions}
        >
            {
                dataSource.map((item, i) => {
                    return <Option key={i} //NOSONAR
                        value={textAsValue ? item.text : item.value} text={item.text}>
                        {useTranslation ? <T>{`text.${item.text}`}</T> : item.text}
                    </Option>
                })
            }
        </Select>}
    </>
}

export default connect(state => ({ culture: state.culture }))(DropDownValueList);