import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Project } from '../../project/Project'
import { T } from '../../components/Translations';

/**
 * the Applications page
 * @exports Applications
 */
function Applications(props) {
    const { network, networks } = props;
    const [applications, setApplications] = useState(null);
    useEffect(() => {
        if (networks) {
            setApplications(networks.find(n => n.id === network).applications);
        }
    }, [networks])// eslint-disable-line react-hooks/exhaustive-deps
    return <> {applications &&
        <view applications="" transparent="">
        <view centred="" transparent="">
            <toolbar transparent=""><wrap><action center=""><text data-size="larger"><T>text.FO_APP_TITLE</T></text></action></wrap></toolbar>
                <div className="list_view_wrapper" view="grid">
                    <list template="common">
                        <wrap>
                            {applications.map(app => (
                                <block role="option" aria-selected="false" key={app.code + app.ID}>
                                    <item >
                                        <thumb className="dark" content="" ratio="4:3">
                                            <wrap>
                                                <picture style={{ backgroundImage: `url(/content/img/${Project.getApplicationImage(app.code)}.png)` }}></picture>
                                            </wrap>
                                        </thumb>
                                        <action group="">
                                            <ul fit="" >
                                                {app.subApplications.map(subApp => (<li key={subApp.label + subApp.ID}>
                                                    <Link to={Project.getPageUrl("applications", [app.code, subApp.code])} ><text data-size="xxx-small"><b><T>{`text.${subApp.label}`}</T></b></text></Link>
                                                </li>))}
                                            </ul>
                                            <Link to={Project.getPageUrl("applications") + `/${app.subApplications.length === 1 ? app.code.toLowerCase() + "/" + app.subApplications[0].code.toLowerCase() : app.code.toLowerCase()}`} fit=""> </Link>
                                        </action>
                                        <detail>
                                            <Link to={Project.getPageUrl("applications") + `/${app.subApplications.length === 1 ? app.code.toLowerCase() + "/" + app.subApplications[0].code.toLowerCase() : app.code.toLowerCase()}`} ><text><b><T>{`text.${app.label}`}</T></b></text></Link>
                                        </detail>
                                    </item>
                                </block>
                            ))}
                        </wrap>
                    </list>

                </div>
            </view>
        </view>
    };
    </>
}

export default connect(state => ({
    isLoggedIn: state.isLoggedIn,
    networks: state.networks,
    network: state.network
}), null)(Applications);