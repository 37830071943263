import { AppPages, Defines, UserRoles, NetworksCodes, AppCode, DocCodes } from './Defines.js';
import moment from 'moment';

/**
 *a global helper object with utility functions
 */
export const Project = {
    getEtudeLabel: function (etude, lang) {
        if (etude.label_traductions) {
            try {
                let products = Array.isArray(etude.label_traductions) ? etude.label_traductions : JSON.parse(etude.label_traductions);
                let s = "";
                products.map(p => {
                    if (s) {
                        s += ';';
                    }
                    let label = p.label;
                    if (p.traductions) {
                        let traduction = p.traductions.find(t => t.code === lang);
                        if (traduction) {
                            label = traduction.value;
                        }
                    }
                    s += p.count + 'x' + label;
                    return p;
                })
                return s;
            } catch (e) {
                
                return etude.label;
            }
        }
        return Array.isArray(etude.label) ? etude.label[0] : etude.label;
    },
    getProductLabel: function (product, lang) {
        if (product.traductions) {
            try {
                let traductions = Array.isArray(product.traductions) ? product.traductions : JSON.parse(product.traductions);
                let traduction = traductions.find(t => t.code === lang);
                if (traduction) {
                    return traduction.value;
                }
            } catch (e) {
                
                return product.label;
            }
        }
        return product.label;
    },
    getMonday: function (d) {
        d = new Date(d);
        let day = d.getDay(),
            diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    },
    getNextSunday: function (d) {
        d = new Date(d);
        let day = d.getDay(),
            diff = d.getDate() - day + (day > 0 ? 7 : 0); // adjust when day is sunday
        return new Date(d.setDate(diff));
    },

    getFullAddress: function (json) {
        return ("")
            .addItem(json.address1, ", ", true)
            .addItem(json.address2, ", ", true)
            .addItem(json.address3, ", ", true)
            .addItem(json.city, json.addressDelimiter || ", ", true)
            .addItem(json.state, ", ", true)
            .addItem(json.zipPostal, " ", true)
            .addItem(json.country, json.countryDelimiter || ", ", true);
    },

    getLocationAddress: function (data) {
        return Project.getFullAddress({
            address1: data.Address1,
            address2: data.Address2,
            city: data.City,
            state: data.State,
            zipPostal: data.ZipPostal,
            country: data.CountryName
        });
    },

    getContactInformation: function (json) {
        let s = ("")
            .addItem(json.firstName, ", ", true)
            .addItem(json.lastName, " ", true);

        if (!String.isNullOrEmpty(json.title)) {
            s += " (" + json.title + ")";
        }

        return s.addItem([json.phone, json.email], ', ', true);
    },

    formatName: function (firstName, lastName) {
        let name = firstName;
        if (lastName) {
            name += " " + lastName;
        }

        return name;
    },

    formatDuration: function (milliseconds, skipEmpty) {
        if (skipEmpty && !milliseconds) {
            return "";
        }

        if (!milliseconds) {
            return "0:00";
        }

        let seconds = Math.round(milliseconds / 1000),
            hr = Math.floor(seconds / 3600),
            min = Math.floor((seconds - (hr * 3600)) / 60),
            sec = seconds - (hr * 3600) - (min * 60);

        return (hr > 0 ? hr + ":" : "") + (hr > 0 && min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
    },

    formatFullDate: function (dt) {
        return moment((dt ? new Date(dt) : new Date()), Defines.Format.MomentDate);
    },

    formatFullTime: function (dt) {
        return moment((dt ? new Date(dt) : new Date()), Defines.Format.MomentFullTime);
    },

    formatFullDateTime: function (dt) {
        return dt ? moment(new Date(dt), Defines.Format.MomentDateTime) : null;
    },

    formatFullDateString: function (dt) {
        return dt ? moment(new Date(dt), Defines.Format.MomentDate).format(Defines.Format.MomentDate) : "";
    },

    formatFullTimeString: function (dt) {
        return dt ? moment(new Date(dt), Defines.Format.MomentFullTime).format(Defines.Format.MomentFullTime) : "";
    },

    formatFullDateTimeString: function (dt) {
        return dt ? moment(dt, Defines.Format.MomentDateTime).format(Defines.Format.MomentDateTime) : "";
    },

    formatTime: function (dt) {
        return dt ? moment(dt, Defines.Format.MomentDateTime).format(Defines.Format.MomentTime) : "";
    },

    formatToPrice(number) {
        return number.toLocaleString('fr-FR', { style: "currency", currency: "EUR" });
    },

    formatToNumber(number) {
        /* NOTE number = number ? number.toLocaleString('fr-FR') : "0"; */
        return number ? parseInt(number).toLocaleString('fr-FR').replace(",", " ") : "0";
    },

    round2Decimals(number) {
        return Math.round(number * 100) / 100;
    },

    encode: str => {
        let buf = [];

        for (let i = str.length - 1; i >= 0; i--) {
            buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));
        }

        return buf.join('');
    },

    decode: str => {
        return str.replace(/&#(\d+);/g, function (match, dec) {
            return String.fromCharCode(dec);
        });
    },

    getPageUrl: function (page, routeParams, quaryParams, fromRoute) {
        if (!page) {
            return null;
        }
        let url = "";
        switch (page.toLowerCase()) {
            case AppPages.Home:
                url = "/";
                break;
            case AppPages.Login:
                url = "/login";
                break;
            case AppPages.Logout:
                url = "/logout";
                break;
            case AppPages.Template:
                url = "/template";
                break;
            case AppPages.TemplateDetail:
                url = "/templatedetails";
                break;
            case AppPages.NotFound:
                url = "/notfound";
                break;
            case AppPages.Applications:
                url = "/applications";
                break;
            case AppPages.SolutionDetails:
                url = "/applications";
                break;
            case AppPages.EtudeDetails:
                url = "/etudedetails";
                break;
            case AppPages.Etude:
                url = "/etude";
                break;
            case AppPages.MyAccount:
                url = "/myaccount";
                break;
            case AppPages.FAQ:
                url = "/faq";
                break;
            case AppPages.CGU:
                url = "/cgu";
                break;
            case AppPages.DocumentDetails:
                url = "/applications";
                break;

            default:
                url = "/";
                break;
        }

        if (routeParams) {
            url += "/" + (Array.isArray(routeParams) ? routeParams.join('/') : routeParams);
        }

        if (quaryParams) {
            let p = [];
            for (let key in quaryParams) { p.push(key + "=" + quaryParams[key]) }
            url += "?" + p.join("&");
        }

        url = url.toLowerCase();

        if (!fromRoute && window.network) {
            url = "/" + window.network.toUpperCase() + url;
        }

        return url;
    },

    getPages: function () {
        return ["Login", "Logout"];
    },
    hasRoleAccess: function (userRole, appPage) {
        const checkingRoles = Project.getPageRoles(appPage);

        return !checkingRoles;
    },
    getPageRoles: function (appPage) {
        switch (appPage) {
            case AppPages.NotFound:
            case AppPages.AccessDenied:
            case AppPages.Home:
            case AppPages.Login:
            case AppPages.Logout:
            case AppPages.Register:
            case AppPages.Forgot:
            case AppPages.Template:
            case AppPages.TemplateDetail:
            case AppPages.Applications:
            case AppPages.SolutionDetails:
            case AppPages.Etude:
            case AppPages.EtudeDetails:
            case AppPages.MyAccount:
            case AppPages.FAQ:
            case AppPages.CGU:
            case AppPages.DocumentDetails:
                return null;
            default:
                return [UserRoles.USER];
        }
    },
    skipHeader: function (location) {
        const skipping_pages = [AppPages.Login, AppPages.Logout, AppPages.Home];
        return skipping_pages.some(sp => location.includes(sp));
    },

    restrictBrowser: function (location) {
        const skipping_pages = [AppPages.ExternalCard];

        return !skipping_pages.some(sp => location.includes(sp));
    },

    getNetworkName: function (code) {
        switch (code) {
            case NetworksCodes.ACVFR:
                return 'ACV France';
            case NetworksCodes.YGNBE:
                return 'Atlantic Belgium';
            case NetworksCodes.YGNIT:
                return 'Ygnis Italia';
            case NetworksCodes.ATLFR:
                return 'Atlantic Solutions Chaufferies';
            case NetworksCodes.ACVUK:
                return 'ACV UK';
            case NetworksCodes.ACVES:
                return 'ACV Espagne';
            case NetworksCodes.ACVGABe:
                return 'ACV GABe';
            case NetworksCodes.HAMUK:
                return 'Hamworthy';

            // no default
        }
    },

    getApplicationImage: (label) => {
        switch (label) {
            case AppCode.LOGEMENTS:
                return 'Logement';
            case AppCode.HOTEL:
                return 'Hotel1';
            case AppCode.CAMPING:
                return 'Camping';
            case AppCode.ETABLISSEMENTSSPORTIFS:
                return 'Etablissementsportif';
            case AppCode.ETABLISSEMENTSSCOLAIRES:
                return 'Internat';
            case AppCode.CASERNE:
                return 'Caserne';
                // case AppCode.ETABLISSEMENTDESANTE:
                // return 'Etablissementdesante2';
            case AppCode.SANTE:
                return 'Sante';
            case AppCode.RESTAURANT:
                return 'Restaurant';
            case AppCode.AUTRETERTIAIRE:
                return 'Autresapplications';
            case AppCode.PROCESSUSINDUSTRIEL:
                return 'Processusindustriel';

            // no default
        }
    },

    getLogo: (code) => {
        switch (code) {
            case NetworksCodes.ACVFR:
            case NetworksCodes.ACVES:
            case NetworksCodes.ACVUK:
                return 'Acv';
            case NetworksCodes.HAMUK:
                return 'Hamworthy';
            case NetworksCodes.YGNIT:
                return 'Ygnis';
            case NetworksCodes.YGNBE:
            case NetworksCodes.ATLFR:
                return 'Atlantic';

            // no default
        }
    },

    getDocLogo: (typeDoc) => {
        switch (typeDoc) {
            case DocCodes.WEB:
                return 'web';
            case DocCodes.WORD:
                return 'word';
            case DocCodes.PDF:
                return 'pdf';
            case DocCodes.REVIT:
                return 'revit';
            case DocCodes.BART:
                return 'bart';

            // no default
        }
    }
}