import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import i18n from '../../Shared/Translation/i18n';

import { T } from '../../components/Translations';

function ShareDocumentPopup(props) {
	const { userData, fileData } = props;
	const [ subject, setSubject ] = useState('');
	const [ recipient, setRecipient ] = useState('');
	const [ body, setBody ] = useState('');
	const [ _fileData, _SetFileData ] = useState();
	const fo_mail_body = i18n('text.FO_MAIL_BODY').replace(/\\n/g, '\n');

	useEffect(() => {
		let file = '';
		if (Array.isArray(fileData)) {
			for (const fd of fileData) {
				file += `%0D${fd}`;
			}
			_SetFileData(file);
		} else {
			file = `%0D${fileData}`
			_SetFileData(file);
		}
	}, [fileData])

	const onChangeObject = useCallback((e) => {
		let _subject = e.target.value;
		setSubject(_subject);
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const onChangeBody = useCallback((e) => {
		let _body = e.target.value;
		setBody(_body);
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<view vertical="" form="" transparent="">
			<div className="form_container">
			<div className="section_group">
				<div className="sections wide">
					<div className="form_fields">
						<label className="input_cont">
							<text data_length="x-large"><T>text.FO_MAIL_SHIPPER</T></text>
							<input type="text" value={userData?.email} disabled />
							<i></i>
						</label>
					</div>
					<div className="form_fields">
						<label className="input_cont">
							<text data_length="x-large"><T>text.FO_MAIL_RECIPIENT</T></text>
							<input required="" name="recipient" type="text" value={recipient} onChange={(e) => setRecipient(e.target.value)} />
							<i></i>
						</label>
					</div>
					<div className="form_fields">
						<label className="input_cont">
							<text data_length="x-large"><T>text.FO_MAIL_OBJECT</T></text>
							<input required="" type="text" value={ subject ? subject : i18n('text.FO_MAIL_TITLE') }  onChange={(e) => onChangeObject(e)}/>
							<i></i>
						</label>
					</div>
					<div className="form_fields">
						<label className="input_cont">
							<textarea required="" value={body ? body : fo_mail_body} onChange={(e) => onChangeBody(e)}></textarea>
						</label>
					</div>
				</div>
			</div>
			<toolbar transparent="">
				<wrap>
					<action right=''>
						<div
							effect="material"
							// onClick={() =>  }
							disabled={recipient === ""}
								className="button primary large">
								<a href={`mailto:${recipient}?subject=${subject ? subject : i18n('text.FO_MAIL_TITLE')}&body=${encodeURI(body ? body : fo_mail_body)}${_fileData}`} target="_blank" rel="noopener noreferrer"><T>text.FO_MAIL_SEND</T></a>
							{/* <a href="mailto:email@address.com?subject=Hello world&body=Line one%0Dhttps://projipac-crm-api.azurewebsites.net/upload/synthesis/e7f83f7f-6ae9-4639-b9c9-d3b98903f62e.pdf%0DLine two"><T>text.FO_MAIL_SEND</T></a> */}
						</div>
					</action>
				</wrap>
			</toolbar>
			</div>
		</view>
	)
}

export default connect(state => ({ userData: state.userData}), null)(ShareDocumentPopup);