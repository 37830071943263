export const ACTION = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    REFRESH_LOGIN: 'REFRESH_LOGIN',
    CHANGE_EMAIL: 'CHANGE_EMAIL',
    CHANGE_CULTURE: 'CHANGE_CULTURE',
    SET_CULTURE: 'SET_CULTURE',
    CHANGE_NETWORK: 'CHANGE_NETWORK',
    OPEN_POPUP: 'OPEN_POPUP',
    CLOSE_POPUP: 'CLOSE_POPUP',
    UPDATE_POPUP_TITLE: 'UPDATE_POPUP_TITLE',
    CLOSE_ALL_POPUPS: 'CLOSE_ALL_POPUPS',
    SET_LIST_STATE: 'SET_LIST_STATE',
    CLEAR_LIST_STATES: 'CLEAR_LIST_STATES',
    SET_PENDING_LIST_STATE: 'SET_PENDING_LIST_STATE',
    STORAGE_CHANGE: 'STORAGE_CHANGE',
    SET_LOADERS: 'SET_LOADERS',
    SET_NETWORKS: 'SET_NETWORKS',
    SET_APPLICATION_DATA: 'SET_APPLICATION_DATA',
    SET_NUMBER_CHANGE_IN_PROCESS: 'SET_NUMBER_CHANGE_IN_PROCESS',
    OPEN_TOOLTIP: 'OPEN_TOOLTIP',
    CLOSE_TOOLTIP: 'CLOSE_TOOLTIP',
    UPDATE_TOOLTIP_PROPS: 'UPDATE_TOOLTIP_PROPS',
    FOOTER_ADD_TOOLBAR: 'FOOTER_ADD_TOOLBAR',
    FOOTER_REMOVE_TOOLBAR: 'FOOTER_REMOVE_TOOLBAR',
    FOOTER_UPDATE_TOOLBAR: 'FOOTER_UPDATE_TOOLBAR',
}

export const login = (payload) => ({
    type: ACTION.LOGIN,
    payload
});

export const logout = () => ({
    type: ACTION.LOGOUT,
});

export const refreshLogin = (payload) => ({
    type: ACTION.REFRESH_LOGIN,
    payload
});

export const changeEmail = (payload) => ({
    type: ACTION.CHANGE_EMAIL,
    payload
});

export const changeCulture = payload => ({
    type: ACTION.CHANGE_CULTURE,
    data: payload
});

export const setCulture = payload => ({
    type: ACTION.SET_CULTURE,
    data: payload
});

export const changeNetwork = payload => ({
    type: ACTION.CHANGE_NETWORK,
    data: payload
});

export const openPopup = payload => ({
    type: ACTION.OPEN_POPUP,
    data: payload
});
export const closePopup = payload => ({
    type: ACTION.CLOSE_POPUP,
    windowKey: payload
});

export const updatePopupTitle = payload => ({
    type: ACTION.UPDATE_POPUP_TITLE,
    title: payload.title,
    windowKey: payload.windowKey
});

export const closeAllPopups = () => ({
    type: ACTION.CLOSE_ALL_POPUPS
});

export const setListState = payload => ({
    type: ACTION.SET_LIST_STATE,
    payload
});

export const clearListStates = () => ({
    type: ACTION.CLEAR_LIST_STATES
});

export const storageChange = () => ({
    type: ACTION.STORAGE_CHANGE
});

export const setLoaders = payload => ({
    type: ACTION.SET_LOADERS,
    payload
});

export const setNetworks = payload => ({
    type: ACTION.SET_NETWORKS,
    payload
});

export const setApplicationData = payload => ({
    type: ACTION.SET_APPLICATION_DATA,
    payload
});
export const setNumberChangeInProcess = payload => ({
    type: ACTION.SET_NUMBER_CHANGE_IN_PROCESS,
    payload
});
export const openTooltip = data => ({
    type: ACTION.OPEN_TOOLTIP,
    data
});

export const closeTooltip = tooltipKey => ({
    type: ACTION.CLOSE_TOOLTIP,
    tooltipKey
});

export const updateTooltipProps = data => ({
    type: ACTION.UPDATE_TOOLTIP_PROPS,
    data
});

// FooterBar actions
export const footerAddToolbar = data => ({
    type: ACTION.FOOTER_ADD_TOOLBAR,
    data
});

export const footerRemoveToolbar = toolbarKey => ({
    type: ACTION.FOOTER_REMOVE_TOOLBAR,
    toolbarKey
});

export const footerUpdateToolbar = (toolbarKey, data) => ({
    type: ACTION.FOOTER_UPDATE_TOOLBAR,
    toolbarKey,
    data
});

