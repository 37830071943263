import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { T } from '../../components/Translations';
import { closeTooltip, openTooltip, updateTooltipProps } from '../../store/actions';

function CustomTooltipsBody(props) {
    return <>{props.customTooltips.map(p => {
        const { tooltipKey, direction, left, top, Component, componentProps, headerText, hasHeader } = p;

        const onClick = () => {
            componentProps.setData(item => ({ ...item, resetExpertMode: true }))
        }

        return <><div key={tooltipKey} className={`context_menu custom primary ${direction} open`} style={{ transform: `translate(${left}px, ${top}px)` }} >
            <div className="context_container">
                <view transparent="" notification="" className="notification_container">
                    {hasHeader &&
                        <toolbar className="notification_head" transparent="" compact="">
                            <div className="caption"><text><b>{headerText ? <T>{headerText}</T> : ""}</b></text></div>
                            <action right=""><div onClick={() => { componentProps.setIsOpen(false) }} className="button mini close"><icon>close</icon></div></action>
                        </toolbar>
                    }
                    <view filter="" notification_wrapper="" transparent="">
                        <div className="list_view_wrapper">
                            <list>
                                <wrap className="notification_list">
                                    <Component {...componentProps} />
                                </wrap>
                            </list>
                        </div>
                    </view>
                    <toolbar className="footer" transparent="">
                        <action center="">
                            <button onClick={onClick} className="button primary"><text><T>text.restaurer_les_valeurs_par_defaut</T></text></button>
                        </action>
                    </toolbar>
                </view>

            </div>
        </div>
        </>
    })}</>;
}


export const CustomTooltips = connect(state => ({ customTooltips: state.customTooltips }))(CustomTooltipsBody);

function CustomTooltip(props) {
    const { refElement, openTooltip, closeTooltip, Component, componentProps, tooltipKey, updateTooltipProps, headerText, hasHeader, fixedHeaderSize } = props;
    useEffect(() => {
        console.log(refElement.current.getBoundingClientRect());
        let { left, bottom } = refElement.current.getBoundingClientRect();
        let elem = refElement.current;
        let scrollTop = 0;
        if (fixedHeaderSize) scrollTop -= fixedHeaderSize;
        while (elem.parentElement) {
            scrollTop += elem.parentElement.scrollTop;
            elem = elem.parentElement;
        }
        openTooltip({ tooltipKey, top: bottom + scrollTop, left, direction: 'left', Component, componentProps, closeTooltip, headerText, hasHeader });
        window.addEventListener('resize', handleResize);
        return () => {
            closeTooltip(tooltipKey);
            window.removeEventListener('resize', handleResize);
        };
    }, []);//eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        updateTooltipProps({ tooltipKey, componentProps });
    }, [componentProps])//eslint-disable-line react-hooks/exhaustive-deps

    const handleResize = useCallback(() => {
        let { left, bottom } = refElement.current.getBoundingClientRect();
        let elem = refElement.current;
        let scrollTop = 0;
        if (fixedHeaderSize) scrollTop -= fixedHeaderSize;
        while (elem.parentElement) {
            scrollTop += elem.parentElement.scrollTop;
            elem = elem.parentElement;
        }
        closeTooltip(tooltipKey);
        openTooltip({ tooltipKey, top: bottom + scrollTop, left, direction: 'left', Component, componentProps, closeTooltip, headerText, hasHeader });
    }, [openTooltip, closeTooltip, tooltipKey, refElement, fixedHeaderSize, componentProps, headerText, hasHeader, Component])

    return (<></>);
}

export default connect(null, dispatch => ({
    openTooltip: (props) => dispatch(openTooltip(props)),
    closeTooltip: (key) => dispatch(closeTooltip(key)),
    updateTooltipProps: (data) => dispatch(updateTooltipProps(data))
}))(CustomTooltip);