import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { T } from '../../components/Translations';
import { ApiUrl } from '../../project/Defines';
import i18n from '../../Shared/Translation/i18n';

function DownloadPDFPopup(props) {
    const { onYesCallback, onClose, etudes, culture, userToken, currentNetwork } = props;
    const [isChecked, setIsChecked] = useState(currentNetwork && currentNetwork.showPrice ? true : false);


    const radioChange = useCallback(e => {
        setIsChecked(e.target.checked);
    }, []);

    return (
        <view vertical="" form="" transparent="">
			<div className="form_container">
                {
                currentNetwork && currentNetwork.showPrice &&
                <div className="section_group">
                    <div className="sections">
                        <div className="form_fields">
                            <label checkbox="" large="">
                                <input
                                        name="show_price"
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={radioChange}
                                    />
                                    <box><check></check></box>
                                    <text wrap=""><T>text.FO_DOC_PRICE</T></text>
                                </label>
                            </div>
                    </div>
                </div>
                }
                <toolbar transparent="">
                    <wrap>
                        <action right=''>
                            {/* <button 
                                type="button" 
                                effect="material" 
                                command="yes" 
                                className="button primary"
                                onClick={() => {
                                    onClose();
                                    onYesCallback(isChecked);
                                }}>
                                <icon>upload</icon>
                                <text><T>text.FO_SIM_UPLOAD</T></text>
                            </button> */}

                            <form
                                action={process.env.REACT_APP_API_URL + ApiUrl.EtudePdf}
                                // onSubmit={() => dispatch(closePopup('wndDownloadPDF'))}
                                onSubmit={() => onYesCallback()}
                                method="post"
                                target={'_blank'}
                            >
                                <div
                                    className="button primary"
                                >
                                    <input type="hidden" name='user-Token' value={userToken} />
                                    <input type="hidden" name='etudes' value={JSON.stringify(etudes)} />
                                    <input type="hidden" name='showPrice' value={isChecked} />
                                    <input type="hidden" name="culture" value={culture} />
                                    <input 
                                        type="submit" 
                                        value={i18n('text.FO_DOC_UPLOAD')}
                                        data-weight="500"
                                    />
                                </div>
                            </form>

                            <separator vertical=""></separator>
                            <button type="button" effect="material" command="no" className="button accent" onClick={onClose}>
                                <text><T>text.cancel</T></text>
                            </button>
                        </action>
                    </wrap>
                </toolbar>
			</div>
		</view>


        // <div>
        //     <div className="message_box">
        //         <div className="message_container">
        //             <h3><text data-size="large"><T>text.FO_DOC_NOTEDECALCUL</T></text></h3>
        //             <form>
        //                 <div className="form_container">
        //                     <div className="section_group">
        //                         <div className="sections ">
        //                             <div className="form_fields">
        //                                 <label checkbox="" large="">
        //                                     <input
        //                                         name="show_price"
        //                                         type="checkbox"
        //                                         checked={isChecked}
        //                                         onChange={radioChange}
        //                                     />
        //                                     <box><check></check></box>
        //                                     <text wrap=""><T>text.FO_DOC_PRICE</T></text>
        //                                 </label>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </form>
        //         </div>

        //         <panel>
        //             <button type="button" effect="material" command="yes" className="button primary" onClick={() => {
        //                 onClose();
        //                 onYesCallback(isChecked);
        //             }}>
        //                 <icon>upload</icon>
        //                 <text><T>text.FO_SIM_UPLOAD</T></text>
        //             </button>
        //             <separator vertical=""></separator>
        //             <button type="button" effect="material" command="no" className="button accent" onClick={onClose}>
        //                 <text><T>text.cancel</T></text>
        //             </button>
        //         </panel>
        //     </div>
        // </div>
    );
}

export default connect(state => ({
    currentNetwork: state.networks && state.networks.find(item => item.id === state.network)
}), null)(DownloadPDFPopup);