
import { Breadcrumb, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Ajax } from '../../../components/Ajax';
import { useEngine, requestData, getUrl } from '../../../components/Engine';
import { ApiUrl, AppPages, /*ProductionMode,*/ SubAppCode, SystemCode } from '../../../project/Defines';
import { Project } from '../../../project/Project';
import { hideLoader, showLoader } from '../../../Shared/Loader';
import { setApplicationData, openPopup, setNumberChangeInProcess } from '../../../store/actions';
import { PopupTypes } from '../../../components/Popup';
import Zone1 from './Zone1';
import Zone2 from './Zone2';
import Zone3 from './Zone3';
import Zone4 from './Zone4';
import DocumentDetails from '../../EtudeDocument/DocumentDetails';
import { T } from '../../../components/Translations';
import { isSameObject, trackInitiatedStudies } from '../../../project/utilities';
import FooterToolbar from '../../../Shared/FooterToolbar';

function Applcation(props) {
    const [loadingController, setLoadingController] = useState(null);

    const sectionHeaderStyle = { fontSize: 15 };
    const { history, network, networks, applicationData, numberInProcess } = props;
    console.log(numberInProcess);
    const { application, subapplication, id, zone } = useParams(false);
    const [data, setData] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [calculatedApiData, setCalculatedApiData] = useState(null);
    const [popupProps, setPopupProps] = useState({});
    const [productionModes, setProductionModes] = useState(null);
    const [productionMode, setProductionMode] = useState(null);
    const [system, setSystem] = useState(null);
    const [expertModeType, setExpertModeType] = useState(null);
    const [systemData, setSystemData] = useState(null);
    const [zone3ApiData, setZone3ApiData] = useState(null);
    const [zone4Data, setZone4Data] = useState(null);
    const [solutions, setSolutions] = useState(null);
    const [mainSolution, setMainSolution] = useState(null);
    const [defaultSolution, setDefaultSolution] = useState(null);
    const [isAlternativeSolution, setIsAlternativeSolution] = useState(false);
    const [, setLastInputRef] = useState(null);
    /*const [applicationName, setApplicationName] = useState(null)*/ /*NOSONAR*/
    const [etudeAllData, setEtudeAllData] = useState();
    const [etudeDataModel, setEtudeDataModel] = useState(null);
    const [activeRowIndex, setActiveRowIndex] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [subApplicationsList, setSubApplicationsList] = useState(networks.find(n => n.id === network).applications.find(a => a.code === application).subApplications); //NOSONAR
    const [sendSolutionsRequest, setSendSolutionsRequest] = useState(false);
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        { name: 'Home', url: Project.getPageUrl(AppPages.Home), label: <T>text.FO_ARIANE_HOME</T> },
        { name: 'Etude', url: Project.getPageUrl(AppPages.Applications), label: <T>text.FO_ARIANE_STUDY</T> }
    ]);
    const [noSolutionMessage, setNoSolutionMessage] = useState(null);

    const solutionDetailWindowKey = "solutionDetails";
    const solutionsWindowKey = "solutions";
    const dispatch = useDispatch();
    const uuid = uuidv4();
    const [isDocumentOpen, setIsDocumentOpen] = useState(false);

    useEffect(() => {
        let routeParams = [application];
        if (subapplication) {
            routeParams.push(subapplication);
            routeParams.push(id || uuid);
            routeParams.push(zone || 1);
            const _productionModes = subApplicationsList.find(s => s.code === subapplication)?.productionMode
            setProductionModes(_productionModes);
        }
        history.replace(Project.getPageUrl(AppPages.Applications, routeParams), etudeAllData);
    }, [subapplication]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (sendSolutionsRequest) {
            solutionsRequest();

        }
    }, [sendSolutionsRequest]);// eslint-disable-line react-hooks/exhaustive-deps

    const getUniqueProducts = (skuArr) => { //NOSONAR
        let skus = [];
        for (const element of skuArr) {
            if (typeof element === 'object' && element !== null && !Array.isArray(element)) {
                if (skus.filter(s => s.refCom === element.refCom && s.type === element.type).length) {
                    skus.push(element)
                }
            } else if (Array.isArray(element)) {
                for (let item of element) {
                    if (typeof item === 'object' && item !== null && !Array.isArray(item)) {
                        if (skus.filter(s => s.refCom === item.refCom && s.type === item.type).length === 0) {
                            skus.push(item)
                        }
                    } else if (Array.isArray(item)) {
                        for (let _item of item) {
                            if (typeof _item === 'object' && _item !== null && !Array.isArray(_item)) {
                                if (skus.filter(s => s.refCom === _item.refCom && s.type === _item.type).length === 0) {
                                    skus.push(_item)
                                }
                            }
                        }
                    }
                }
            }
        }
        return skus;
    }

    const getSolutionRequestData = (_system, _systemData, _zone3ApiData, _data, _zone4Data) => { //NOSONAR
        let url = getUrl(null, null, null, "/solution");
        let _network = networks.find(n => n.id === network);

        let _requestData = {
            reseau: _network.code,
            marque: _network.theme,
            Tp: _systemData && _systemData.Tp,
            solution: _system,
            TEFS: _data && _data.TEFS,
            tr: systemData && _systemData.tr
        };

        (_zone3ApiData && _zone3ApiData.Pn) && (_requestData.Pn = _zone3ApiData.Pn);

        (_zone4Data.L || _zone4Data.L === 0) && (_requestData.L = _zone4Data.L);
        (_zone4Data.H || _zone4Data.H === 0) && (_requestData.H = _zone4Data.H);

        _requestData.Pboucle = _systemData && _systemData.Pboucle;
        // eslint-disable-next-line default-case
        switch (_system) {
            case SystemCode.ECHANGEUR_A_PLAQUES:
                _requestData.extensible = !!_zone4Data.extensible;
                _requestData.circPrim = _zone4Data.circPrim;
                break;
            case SystemCode.ECHANGEUR_A_PLAQUES_BALLONS_PRIMAIRES:
                _requestData.extensible = false;
                _requestData.V = _zone4Data.V;
                _requestData.nbBallon = _zone4Data.nbBallon;
                _requestData.brand = _zone4Data.brand;
                _requestData.circ = _zone4Data.circ;
                _requestData.circPrim = _zone4Data.circPrim;
                _requestData.Jaquette = _zone4Data.Jaquette;
                _requestData.INTER = _zone4Data.INTER;
                _requestData.Echangeur1 = _zone4Data.Echangeur1;
                _requestData.Echangeur2 = _zone4Data.Echangeur2;
                break;
            case SystemCode.ECHANGEUR_BALLONS_ECS:
                _requestData.V = _zone4Data.V;
                _requestData.P = _zone4Data.PA || _zone4Data.PI;
                _requestData.nbBallon = _zone4Data.nbBallon;
                _requestData.Jaquette = _zone4Data.Jaquette;
                _requestData.CuveInox = !!_zone4Data.CuveInox;
                _requestData.Ouverture = _zone4Data.Ouverture;
                _requestData.circPrim = _zone4Data.circPrim;
                _requestData.circSec = _zone4Data.circSec;
                _requestData.extensible = !!_zone4Data.extensible;
                break;
            case SystemCode.BALLONS_ECS_TANK_IN_TANK:
                _requestData.V = _zone4Data.V;
                _requestData.P = _zone4Data.PA || _zone4Data.PI;
                _requestData.nbBallon = _zone4Data.nbBallon;
                //_requestData.circPrim = _zone4Data.circPrim
                break;
            case SystemCode.BALLONS_ECS_PREPARATEURS:
                _requestData.V = _zone4Data.V;
                _requestData.P = _zone4Data.PA || _zone4Data.PI;
                _requestData.nbBallon = _zone4Data.nbBallon;
                _requestData.Jaquette = _zone4Data.Jaquette;
                _requestData.CuveInox = !!_zone4Data.CuveInox;
                _requestData.Ouverture = _zone4Data.Ouverture;
                _requestData.circPrim = _zone4Data.circPrim;
                break;
            case SystemCode.BALLONS_ELECTRIQUES:
                _requestData.V = Math.round(_zone4Data.V);
                _requestData.P = _zone4Data.P;
                _requestData.nbBallon = _zone4Data.nbBallon;
                _requestData.CuveInox = !!_zone4Data.CuveInox;
                _requestData.rechauff = !!_zone4Data.rechauff;
                _requestData.Jaquette = _zone4Data.Jaquette;
                if (_zone4Data.rechauff === undefined) _zone4Data.rechauff = true;
                break;
            case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS:
            case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS_HMWM:
                _requestData.V = _zone4Data.V;
                _requestData.P = _zone4Data.PA || _zone4Data.PI;

                _requestData.CuveInox = !!_zone4Data.CuveInox;
                _requestData.Jaquette = _zone4Data.Jaquette;
                _requestData.Ouverture = _zone4Data.Ouverture;
                _requestData.nbAcc = _zone4Data.nbAcc;
                _requestData.nbBallon = _zone4Data.nbBallon;
                _requestData.typeGaz = _zone4Data.typeGaz;
                break;
        }

        _requestData = {
            ..._requestData,
            ...requestData(_data, application, subapplication, 2, expertModeType).normalizedData,
            ..._systemData,
            application: application,
            sous_application: subapplication
        };

        Object.keys(_requestData).forEach(k => { !_requestData[k] && _requestData[k] !== 0 && _requestData[k] !== false && (delete _requestData[k]) });//delete all empty fields
        return { requestData: _requestData, url: url }
    }

    const setSolutionProducts = (allProducts, res) => { //NOSONAR
        let _solutions = [],
            skuArr = res.solutions.map(sku => sku.refComs);

        // eslint-disable-next-line array-callback-return
        skuArr.map((item, index) => {
            let solution = { products: [] };
            if (Array.isArray(item)) {
                for (const element of item) {
                    if (Array.isArray(element)) {
                        let productsForMerge = [...allProducts.products.filter(p => p.sku === element[0].refCom && p.type === element[0].type), ...allProducts.products.filter(p => p.sku === element[1].refCom && p.type === element[1].type)];

                        let mergedProduct = {
                            ...productsForMerge[0],
                            merged: true,
                            label: `${productsForMerge[0].label} ${productsForMerge[1]?.resistance_puissance ? "- " + productsForMerge[1]?.resistance_puissance + "kW" : ""}`,
                            previewUrls: [productsForMerge[0].previewUrl, productsForMerge[1]?.previewUrl],
                            subProduct: productsForMerge[1] || {}
                        }

                        solution.products.push(mergedProduct);

                    } else {

                        solution.products.push(...allProducts.products.filter(p => p.sku === element.refCom && p.type === element.type));
                    }
                }
            } else {
                solution.products.push(...allProducts.products.filter(p => p.sku === item.refCom && p.type === item.type));
            }


            let solutionItem = { ...solution, largeur_mini: getLargeurMiniValue(solution.products), hauteur_min: getHauteurMiniValue(solution.products), prix: res.solutions[index].prix }
            solutionItem.productNames = solution.products.map(p => p.label).join('\n');

            //solutionItem.key = solutionItem.productNames + index
            solutionItem.key = solution.products.map(p => p.sku + p.type).join("");
            res.solutions[index].P = Math.round(res.solutions[index].P);
            if (res.solutions[index].Pn) {
                res.solutions[index].Pn = Math.round(res.solutions[index].Pn)
            }
            _solutions[index] = { ...res.solutions[index], ...solutionItem, principial: index === 0 };
        })

        return _solutions;
    }

    useEffect(() => {
        if (subApplicationsList && subApplicationsList.length === 1 && !+id && zone !== 'document') {
            history.push(Project.getPageUrl(AppPages.Applications, [application, subApplicationsList[0].code, uuid, zone || 1]), etudeAllData);
        }
    }, [subApplicationsList]);// eslint-disable-line react-hooks/exhaustive-deps

    const { defaultData, calculatedData, allData, setAllData, sendRequest, expertModeChanged, setExpertModeChanged, isValid, allowMessages, setAllowMessages } = useEngine({ application, subapplication, data });

    const { ...systemEngine } = useEngine({ productionMode, system, data: systemData });

    useEffect(() => {
        if (zone === 'document') {
            return;
        }
        if (calculatedData) {
            if (SubAppCode.isValid(subapplication)) {
                setData(calculatedData);
                setPopupProps({ data: calculatedData, setData, setIsOpen, isOpen, application, subapplication, subApplicationsList, defaultData, allData, setExpertModeType, expertModeType, onError: resetZone2, setEtudeAllData, id });
                setEtudeAllData(item => ({ ...item, data: calculatedData, application, subapplication, subApplicationsList, defaultData, allData, expertModeType, expertModeChanged }));

                if ((system && isValid && systemEngine.isValid) || calculatedApiData) {
                    calculate(calculatedData);
                    if (systemEngine.calculatedData && typeof systemEngine.calculatedData.Pboucle === "number" && (applicationData && applicationData[id] && applicationData[id].edited)) {
                        calculateEtap12(calculatedData);
                    }
                }
            } else {/*NOTE: Arman remove*/ /*NOSONAR*/
                setData({})
                setPopupProps({ data: {}, setData, setIsOpen, isOpen, application, subapplication, subApplicationsList, defaultData: {}, allData: {}, setExpertModeType, expertModeType, onError: resetZone2, setEtudeAllData, id });
                setEtudeAllData(item => ({ ...item, data, application, subapplication, subApplicationsList, defaultData: {}, allData: {}, expertModeType, calculatedData, expertModeChanged }));
            }
        }
    }, [calculatedData, expertModeType]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setEtudeAllData({ ...etudeAllData, zone4Data, activeRowIndex })
    }, [zone4Data])// eslint-disable-line react-hooks/exhaustive-deps

    const setRefreshData = (_data, modes) => { //NOSONAR
        if (!id) {
            return;
        }
        const serializedData = JSON.parse(_data.jsonData);
        dispatch(setApplicationData({ ...applicationData, [id]: { ...serializedData, edited: false } }));
        setEtudeAllData({ ...serializedData })
        if (!serializedData.data || Object.keys(serializedData.data).length === 0) {
            return;
        }

        let notificationKey = "solutionrequest";
        notification.close(notificationKey)
        const _requestData = requestData(serializedData.data, application, subapplication, null, serializedData.expertModeType);
        sendRequest(_requestData.normalizedData, _requestData.url, (response) => {
            setAllData(serializedData.allData);
            setData(serializedData.data);
            setProductionMode(serializedData.productionMode);
            setExpertModeType(serializedData.expertModeType);
            if (serializedData.calculatedApiData) {
                setCalculatedApiData(response);
                setExpertModeChanged(serializedData.expertModeChanged);
                if (!response) {
                    showNotification("error");
                    return;
                }
                else if (response.Err) {
                    showNotification(response.Err[0][1]);
                    return;
                } else {
                    let mode = modes.filter(item => item.code === serializedData.productionMode);
                    if (mode.length > 0) {

                        let _system = mode ? mode[0].subSystems.filter(item => item.systemCode === serializedData.system) : [];
                        if (_system.length > 0) {
                            setSystem(serializedData.system);
                            setSystemData(serializedData.systemData);
                            //history.replace(Project.getPageUrl(AppPages.Applications, [application, subapplication, id, 3]))

                            if (!serializedData.systemData.Pboucle || serializedData.systemData.Pboucle === '') {
                                if (serializedData.systemData.Pboucle !== 0) {
                                    return;
                                }
                            }

                            const etap12RequestData = requestData(serializedData.data, application, subapplication, 2, expertModeType);
                            let _network = networks.find(n => n.id === network);

                            sendRequest({ ...etap12RequestData.normalizedData, ...serializedData.systemData, mode: serializedData.productionMode, systeme: serializedData.system, reseau: _network.code, marque: _network.theme }, etap12RequestData.url, (_response) => {
                                if (!_response) {
                                    showNotification("error");
                                    return; //NOSONAR
                                }
                                else if (_response.Err) {
                                    showNotification(<T>{`text.ERR_MOTEUR_${_response.Err[0][0]}`}</T>);
                                    return;
                                } else {
                                    let _zone4Data = null;
                                    setZone3ApiData(_response);

                                    if (serializedData.zone4Data) {
                                        // eslint-disable-next-line default-case
                                        switch (serializedData.system) {
                                            case SystemCode.ECHANGEUR_A_PLAQUES:
                                                // eslint-disable-next-line eqeqeq
                                                if (serializedData.zone4Data.key != _response.Pn) {
                                                    serializedData.zone4Data.key = _response.Pn;
                                                    serializedData.zone4Data.name = _response.Pn;
                                                }
                                                break;
                                            case SystemCode.BALLONS_ELECTRIQUES:
                                                // eslint-disable-next-line eqeqeq
                                                if (serializedData.zone4Data.V != Math.round(_response.V) || serializedData.zone4Data.P != _response.P) {
                                                    serializedData.zone4Data.P = _response.P;
                                                    serializedData.zone4Data.V = Math.round(_response.V);
                                                }
                                                break;
                                            case SystemCode.ECHANGEUR_A_PLAQUES_BALLONS_PRIMAIRES:
                                                _zone4Data = _response.PV.filter(item => item.V === serializedData.zone4Data.V)[0];
                                                if (_zone4Data) {
                                                    serializedData.activeRowIndex = _response.PV.findIndex(item => item.V === serializedData.zone4Data.V);

                                                    if (_zone4Data.P !== serializedData.zone4Data.P || _zone4Data.Qe !== serializedData.zone4Data.Qe) {
                                                        serializedData.zone4Data.key = Math.round(_zone4Data.V);
                                                        serializedData.zone4Data.V = Math.round(_zone4Data.V);
                                                        serializedData.zone4Data.P = _zone4Data.P;
                                                        serializedData.zone4Data.Qe = _zone4Data.Qe;
                                                        serializedData.zone4Data.INTER = _zone4Data.INTER;
                                                        serializedData.zone4Data.Echangeur1 = _zone4Data.Echangeur1;
                                                        serializedData.zone4Data.Echangeur2 = _zone4Data.Echangeur2;
                                                    }
                                                } else {
                                                    return;
                                                }
                                                break;
                                            case SystemCode.ECHANGEUR_BALLONS_ECS:
                                            case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS:
                                            case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS_HMWM:
                                            case SystemCode.BALLONS_ECS_TANK_IN_TANK:
                                            case SystemCode.BALLONS_ECS_PREPARATEURS:
                                                _zone4Data = _response.Vs.filter(item => item.V === serializedData.zone4Data.V)[0];
                                                if (_zone4Data) {
                                                    serializedData.activeRowIndex = _response.Vs.findIndex(item => item.V === serializedData.zone4Data.V);
                                                    if (_zone4Data.P !== serializedData.zone4Data.P) {
                                                        serializedData.zone4Data.key = Math.round(_zone4Data.V);
                                                        serializedData.zone4Data.V = Math.round(_zone4Data.V);
                                                        serializedData.zone4Data.P = _zone4Data.P;
                                                    }
                                                } else {
                                                    return;
                                                }
                                                break;
                                        }
                                        setActiveRowIndex(serializedData.activeRowIndex);
                                        setZone4Data(serializedData.zone4Data);

                                        const solutionsRequestData = getSolutionRequestData(serializedData.system, serializedData.systemData, serializedData.zone3ApiData, serializedData.data, serializedData.zone4Data);
                                        sendRequest(solutionsRequestData.requestData, solutionsRequestData.url, (res) => {
                                            if (res && res.solutions && res.solutions.length) {
                                                showLoader();
                                                let skuArr = res.solutions.map(sku => sku.refComs);

                                                let getProductData = { skus: JSON.stringify({ skus: getUniqueProducts(skuArr) }) };

                                                // eslint-disable-next-line default-case
                                                switch (serializedData.system) {
                                                    case SystemCode.ECHANGEUR_A_PLAQUES:
                                                    case SystemCode.ECHANGEUR_A_PLAQUES_BALLONS_PRIMAIRES:
                                                    case SystemCode.ECHANGEUR_BALLONS_ECS:
                                                    case SystemCode.BALLONS_ECS_PREPARATEURS:
                                                    case SystemCode.BALLONS_ECS_TANK_IN_TANK:
                                                        getProductData.system = serializedData.system;
                                                        getProductData.TenSec = serializedData.data.TEFS;
                                                        getProductData.TenPrim = serializedData.systemData && serializedData.systemData.Tp;
                                                        getProductData.TSorSec = serializedData.systemData && serializedData.systemData.TECS;
                                                        break;
                                                    case SystemCode.BALLONS_ELECTRIQUES:
                                                        getProductData.system = serializedData.system;
                                                        getProductData.TenSec = serializedData.data.TEFS;
                                                        //getProductData.TenPrim = serializedData.systemData && serializedData.systemData.tr
                                                        getProductData.TSorSec = serializedData.systemData && serializedData.systemData.TECS;
                                                        break;
                                                    case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS:
                                                    case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS_HMWM:
                                                        // eslint-disable-next-line no-lone-blocks
                                                        {
                                                            getProductData.system = serializedData.system;
                                                            getProductData.TenSec = serializedData.data.TEFS;
                                                            getProductData.TSorSec = serializedData.systemData && serializedData.systemData.TECS;
                                                        }
                                                        break;
                                                }

                                                getProductData.productionMode = serializedData.productionMode;

                                                Ajax.post({
                                                    url: ApiUrl.GetProducts,
                                                    data: getProductData,
                                                    success: function (allProducts) {
                                                        hideLoader();
                                                        if (res.solutions.length && allProducts.products && allProducts.products.length) {

                                                            let _solutions = setSolutionProducts(allProducts, res);
                                                            let _mainSolution = _solutions.filter(s => s.key === serializedData.mainSolution?.key);
                                                            setDefaultSolution(serializedData.defaultSolution);


                                                            if (_mainSolution.length > 0 && isSameObject(_mainSolution[0], serializedData.mainSolution)) {
                                                                _mainSolution = serializedData.mainSolution;
                                                            } else {
                                                                _mainSolution = _solutions[0];
                                                            }

                                                            setMainSolution(_mainSolution);
                                                            setSolutions(_solutions);
                                                            setEtudeAllData({
                                                                ...serializedData,
                                                                productionMode: serializedData.productionMode,
                                                                system: serializedData.system,
                                                                data: serializedData.data,
                                                                systemData: serializedData.systemData,
                                                                calculatedApiData: response,
                                                                zone3ApiData: serializedData.zone3ApiData,
                                                                activeRowIndex: serializedData.activeRowIndex,
                                                                zone4Data: serializedData.zone4Data,
                                                                mainSolution: _mainSolution,
                                                                defaultSolution: serializedData.defaultSolution,
                                                                solutions: _solutions
                                                            });

                                                            setEtudeDataModel(serializedData.etudeDataModel);
                                                            if (zone === 'document') {
                                                                setIsDocumentOpen(true);
                                                                //trackInitiatedStudies(id);
                                                            }
                                                        } else {
                                                            // NOTE
                                                        }
                                                    }
                                                })
                                            } else {
                                                setSolutions(null);
                                                setMainSolution(null);
                                                setDefaultSolution(null);

                                                setEtudeAllData(item => ({ ...item, solutions: null, defaultSolution: null, mainSolution: null }));

                                                //const errMessageText = `text.ERR_MOTEUR_${res.Err[0][0]}`;
                                                if (res.Err[0][0] >= 1000) {
                                                    setNoSolutionMessage('text.FO_NOSOLUTION');
                                                }
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    }
                }
            }



        })

    }

    const setExistingAppData = (_data, modes) => { //NOSONAR
        if (!id) {
            return;
        }
        const serializedData = JSON.parse(_data.jsonData);
        if (applicationData) {
            dispatch(setApplicationData({ ...applicationData, [id]: { ...serializedData, etudeDataModel: { ..._data, ...applicationData[id]?.etudeDataModel }, edited: false } }));
        } else {
            dispatch(setApplicationData({ [id]: { ...serializedData, etudeDataModel: _data, edited: false } }));
        }

        if (!serializedData.data || Object.keys(serializedData.data).length === 0) {
            return
        }
        setData(serializedData.data);
        history.replace(Project.getPageUrl(AppPages.Applications, [application, subapplication, id, zone ? zone : 4]));
        const _requestData = requestData(serializedData.data, application, subapplication, null, serializedData.expertModeType);

        sendRequest(_requestData.normalizedData, _requestData.url, (response) => {
            if (!response) {
                //setData(serializedData.data);
                setExpertModeType(serializedData.expertModeType);
                showNotification("error");
            }
            else if (response.Err) {
                //setData(serializedData.data);
                setExpertModeType(serializedData.expertModeType);
                showNotification(<T>{`text.ERR_MOTEUR_${response.Err[0][0]}`}</T>);
            } else {
                const etap12RequestData = requestData(serializedData.data, application, subapplication, 2, expertModeType);
                let _network = networks.find(n => n.id === network);

                sendRequest({ ...etap12RequestData.normalizedData, ...serializedData.systemData, mode: serializedData.productionMode, systeme: serializedData.system, reseau: _network.code, marque: _network.theme }, etap12RequestData.url, (_response) => {
                    if (!_response) {
                        //setData(serializedData.data);
                        setExpertModeType(serializedData.expertModeType)
                        setProductionMode(serializedData.productionMode);
                        setCalculatedApiData(response);
                        setSystem(serializedData.system);
                        setExpertModeChanged(serializedData.expertModeChanged);
                        showNotification("error");
                    }
                    else if (_response.Err) {
                        //setData(serializedData.data);
                        setExpertModeType(serializedData.expertModeType)
                        setProductionMode(serializedData.productionMode);
                        setCalculatedApiData(response);
                        setSystem(serializedData.system);
                        setExpertModeChanged(serializedData.expertModeChanged);
                        showNotification(<T>{`text.ERR_MOTEUR_${_response.Err[0][0]}`}</T>);
                    } else {
                        const solutionsRequestData = getSolutionRequestData(serializedData.system, serializedData.systemData, serializedData.zone3ApiData, serializedData.data, serializedData.zone4Data);
                        sendRequest(solutionsRequestData.requestData, solutionsRequestData.url, (res) => {
                           
                            if (res && res.solutions && res.solutions.length) {
                                showLoader();
                                let skuArr = res.solutions.map(sku => sku.refComs);

                                let getProductData = { skus: JSON.stringify({ skus: getUniqueProducts(skuArr) }) };
                                // eslint-disable-next-line default-case
                                switch (serializedData.system) {
                                    case SystemCode.ECHANGEUR_A_PLAQUES:
                                    case SystemCode.ECHANGEUR_A_PLAQUES_BALLONS_PRIMAIRES:
                                    case SystemCode.ECHANGEUR_BALLONS_ECS:
                                    case SystemCode.BALLONS_ECS_PREPARATEURS:
                                    case SystemCode.BALLONS_ECS_TANK_IN_TANK:

                                        getProductData.system = serializedData.system;
                                        getProductData.TenSec = serializedData.data.TEFS;
                                        getProductData.TenPrim = serializedData.systemData && serializedData.systemData.Tp;
                                        getProductData.TSorSec = serializedData.systemData && serializedData.systemData.TECS;
                                        break;
                                    case SystemCode.BALLONS_ELECTRIQUES:
                                        getProductData.system = serializedData.system;
                                        getProductData.TenSec = serializedData.data.TEFS;
                                        //getProductData.TenPrim = serializedData.systemData && serializedData.systemData.tr
                                        getProductData.TSorSec = serializedData.systemData && serializedData.systemData.TECS;
                                        break;
                                    case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS:
                                    case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS_HMWM:
                                        // eslint-disable-next-line no-lone-blocks
                                        {
                                            getProductData.system = serializedData.system;
                                            getProductData.TenSec = serializedData.data.TEFS;
                                            getProductData.TSorSec = serializedData.systemData && serializedData.systemData.TECS;
                                        }
                                        break;
                                }

                                getProductData.productionMode = serializedData.productionMode;

                                Ajax.post({
                                    url: ApiUrl.GetProducts,
                                    data: getProductData,
                                    success: function (allProducts) {
                                        hideLoader();
                                        if (res.solutions.length && allProducts.products && allProducts.products.length) {
                                            let mode = modes.filter(item => item.code === serializedData.productionMode);
                                            if (mode.length > 0) {
                                                setProductionMode(serializedData.productionMode);
                                                let _system = mode ? mode[0].subSystems.filter(item => item.systemCode === serializedData.system) : [];
                                                let _zone4Data = null;
                                                if (_system.length > 0) {
                                                    let _solutions = setSolutionProducts(allProducts, res);
                                                    let _mainSolution = _solutions.filter(s => s.key === serializedData.mainSolution.key);
                                                    setSystem(serializedData.system);
                                                    //setData(serializedData.data);
                                                    setExpertModeType(serializedData.expertModeType)
                                                    setCalculatedApiData(response);
                                                    setExpertModeChanged(serializedData.expertModeChanged);
                                                    setSystemData(serializedData.systemData);

                                                    setZone3ApiData(serializedData.zone3ApiData);
                                                    switch (serializedData.system) {
                                                        case SystemCode.ECHANGEUR_A_PLAQUES:
                                                            // eslint-disable-next-line eqeqeq
                                                            if (serializedData.zone4Data.key != _response.Pn) {
                                                                showNotification("ERRMESS_UPDATE_PV");
                                                                serializedData.zone4Data.key = _response.Pn;
                                                                serializedData.zone4Data.name = _response.Pn;
                                                            }
                                                            break;
                                                        case SystemCode.BALLONS_ELECTRIQUES:
                                                            // eslint-disable-next-line eqeqeq
                                                            if (serializedData.zone4Data.V != Math.round(_response.V) || serializedData.zone4Data.P != _response.P) {
                                                                showNotification("ERRMESS_UPDATE_PV");
                                                                serializedData.zone4Data.P = _response.P;
                                                                serializedData.zone4Data.V = _response.V;
                                                            }
                                                            break;
                                                        case SystemCode.ECHANGEUR_A_PLAQUES_BALLONS_PRIMAIRES:
                                                            _zone4Data = _response.PV.filter(item => item.V === serializedData.zone4Data.V)[0];
                                                            if (_zone4Data) {
                                                                serializedData.activeRowIndex = _response.PV.findIndex(item => item.V === serializedData.zone4Data.V);

                                                                if (_zone4Data.P !== serializedData.zone4Data.P || _zone4Data.Qe !== serializedData.zone4Data.Qe) {
                                                                    showNotification("ERRMESS_UPDATE_PV_HYGIA");
                                                                    serializedData.zone4Data.key = Math.round(_zone4Data.V);
                                                                    serializedData.zone4Data.V = Math.round(_zone4Data.V);
                                                                    serializedData.zone4Data.P = _zone4Data.P;
                                                                    serializedData.zone4Data.Qe = _zone4Data.Qe;
                                                                    serializedData.zone4Data.INTER = _zone4Data.INTER;
                                                                    serializedData.zone4Data.Echangeur1 = _zone4Data.Echangeur1;
                                                                    serializedData.zone4Data.Echangeur2 = _zone4Data.Echangeur2;
                                                                }
                                                            } else {
                                                                showNotification("ERRMESS_UPDATE_PV_HYGIA");
                                                                setEtudeAllData(serializedData);
                                                                return;
                                                            }
                                                            break;
                                                        case SystemCode.ECHANGEUR_BALLONS_ECS:
                                                        case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS:
                                                        case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS_HMWM:
                                                        case SystemCode.BALLONS_ECS_TANK_IN_TANK:
                                                        case SystemCode.BALLONS_ECS_PREPARATEURS:
                                                            _zone4Data = _response.Vs.filter(item => item.V === serializedData.zone4Data.V)[0];
                                                            if (_zone4Data) {
                                                                serializedData.activeRowIndex = _response.Vs.findIndex(item => item.V === serializedData.zone4Data.V);
                                                                let pa = serializedData.zone4Data.PA || serializedData.zone4Data.PI
                                                                if (_zone4Data.P !== pa) {
                                                                    showNotification("ERRMESS_UPDATE_PV");
                                                                    serializedData.zone4Data.key = Math.round(_zone4Data.V);
                                                                    serializedData.zone4Data.V = Math.round(_zone4Data.V);
                                                                    serializedData.zone4Data.PA = _zone4Data.P;
                                                                }
                                                            } else {
                                                                showNotification("ERRMESS_UPDATE_PV");
                                                                setEtudeAllData(serializedData)
                                                                return;
                                                            }
                                                            break;
                                                        default:
                                                            if (!isSameObject(_solutions, serializedData.solutions)) {
                                                                showNotification("ERRMESS_UPDATE_SOLUTION");
                                                            }

                                                    }
                                                    setActiveRowIndex(serializedData.activeRowIndex);
                                                    setZone4Data(serializedData.zone4Data);
                                                    setDefaultSolution(serializedData.defaultSolution);

                                                    if (_mainSolution.length > 0 && isSameObject(_mainSolution[0], serializedData.mainSolution)) {
                                                        _mainSolution = serializedData.mainSolution;
                                                    } else {
                                                        _mainSolution = _solutions[0];
                                                    }
                                                    setMainSolution(_mainSolution);
                                                    setSolutions(_solutions);
                                                    setEtudeAllData({
                                                        ...serializedData,
                                                        productionMode: serializedData.productionMode,
                                                        system: serializedData.system,
                                                        data: serializedData.data,
                                                        systemData: serializedData.systemData,
                                                        calculatedApiData: response,
                                                        zone3ApiData: serializedData.zone3ApiData,
                                                        activeRowIndex: serializedData.activeRowIndex,
                                                        zone4Data: serializedData.zone4Data,
                                                        mainSolution: _mainSolution,
                                                        defaultSolution: serializedData.defaultSolution,
                                                        solutions: _solutions
                                                    });

                                                    setEtudeDataModel(_data);
                                                    if (zone === 'document') {
                                                        setIsDocumentOpen(true);
                                                        //trackInitiatedStudies(id);
                                                    }

                                                }
                                            }

                                        } else {
                                            setData(serializedData.data);
                                            setExpertModeType(serializedData.expertModeType)
                                            setProductionMode(serializedData.productionMode);
                                            setCalculatedApiData(response);
                                            setSystem(serializedData.system);
                                            setExpertModeChanged(serializedData.expertModeChanged);
                                            setSystemData(serializedData.systemData);
                                            setZone3ApiData(serializedData.zone3ApiData);
                                            setEtudeAllData({
                                                ...serializedData
                                            });
                                            showNotification("error");
                                        }
                                    }
                                })
                            } else {
                              
                                setData(serializedData.data);
                                setExpertModeType(serializedData.expertModeType)
                                setProductionMode(serializedData.productionMode);
                                setCalculatedApiData(response);
                                setSystem(serializedData.system);
                                setExpertModeChanged(serializedData.expertModeChanged);
                                setSystemData(serializedData.systemData);
                                showNotification("error");
                                setEtudeAllData({
                                    ...serializedData
                                });
                            }
                        })
                    }
                })
            }
        })
    }


    useEffect(() => {
        if (id) {

            if (etudeAllData && applicationData && applicationData[id]) {
                dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], ...etudeAllData, edited: applicationData[id].edited, } }));
            } else {
                dispatch(setApplicationData({ ...applicationData, [id]: { edited: false } }));
            }
        }
    }, [etudeAllData]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (etudeAllData && applicationData && applicationData[id]) {
            setEtudeAllData(item => ({ ...item, expertModeChanged: expertModeChanged }));
            dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], expertModeChanged: expertModeChanged } }));
        }
    }, [expertModeChanged]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { //NOSONAR
        if (!subApplicationsList.length) {
            history.push(Project.getPageUrl(AppPages.Applications));
        } else {
            const currentsubapp = subapplication && subApplicationsList.find(e => e.code === subapplication);
            if (!currentsubapp) {
                history.push(Project.getPageUrl(AppPages.Applications, [application]));
            } else {
                setBreadcrumbItems(item => [...item, { name: subApplicationsList[0].appLabel, url: Project.getPageUrl(AppPages.Applications, [application, subapplication]), label: <T>{`text.${subApplicationsList[0].appLabel}`}</T> }]);
            }
        }
        let modes = subapplication && subApplicationsList.find(s => s.code === subapplication)?.productionMode;
        if (!!+id) {
            Ajax.get({
                url: ApiUrl.EtudeGetData,
                data: { id: id },
                success: function (_data) {
                    if (!_data.hasError) {
                        setExistingAppData(_data, modes)
                        if (_data.obsolete) {
                            dispatch(openPopup({
                                windowKey: 'wndAlert',
                                type: PopupTypes.Alert,
                                textKey: 'text.FO_OBSOLETE_MESSAGE',
                                buttonYesTextKey: 'text.FO_BUTTON_VALIDATE',
                                yesCallback: function () {
                                }
                            }));
                        }

                    }
                },
                error: (e) => {
                    history.push(Project.getPageUrl(AppPages.Applications, [application, subapplication, id, 1]));
                }
            })
        } else {
            if (applicationData && applicationData[id]) {
                setRefreshData({ jsonData: JSON.stringify({ ...applicationData[id], edited: false }) }, modes);

            } else if (applicationData) {
                dispatch(setApplicationData({ ...applicationData, [uuid]: { edited: false } }));
            }
        }

        return () => {
            setData(null);
            setCalculatedApiData(null);
            setIsOpen(false);
            setProductionMode(null);
            setSystem(null);
            setZone3ApiData(null);
            setZone4Data(null);
            setExpertModeChanged(false);
            setEtudeDataModel(null);
            systemEngine.setCalculatedData(null);

            setSolutions(null);
            setMainSolution(null);
            setDefaultSolution(null);
            setActiveRowIndex(null);
            //dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], edited: false } }))
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (expertModeType && applicationData && applicationData[id] && applicationData[id].edited) {
            setPopupProps({ ...popupProps, expertModeType, id });
        }
    }, [expertModeType]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        subapplication && setExpertModeType(null);
        if (!subapplication) {
            setData(null);
            setCalculatedApiData(null);
            setIsOpen(false);
            setZone3ApiData(null);
            setZone4Data(null);
            setSystem(null);
            setProductionMode(null);
            setExpertModeChanged(false);
            setEtudeDataModel(null);
            systemEngine.setCalculatedData(null);
            setSolutions(null);
            setSolutions(null);
            setMainSolution(null);
            setDefaultSolution(null);

        }
        /*
         * 
        if (applicationData && applicationData[id]) {

            dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], edited: false } }));
        }
        */
    }, [subapplication, subApplicationsList]);// eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => { //NOSONAR
        if (applicationData && applicationData[id] && applicationData[id].edited) {
            if (systemEngine.calculatedData) {
                let _calculatedData = { ...systemEngine.calculatedData }
                if (subapplication === SubAppCode.STATIONDELAVAGE || subapplication === SubAppCode.CAMPING || subapplication === SubAppCode.PROCESSUSINDUSTRIEL) {
                    _calculatedData.Pboucle = 0;
                }

                setEtudeAllData(item => ({ ...item, systemData: _calculatedData }));
                setSystemData(_calculatedData);

                if (typeof _calculatedData.Pboucle === "number") { //NOSONAR
                    calculateEtap12(data);
                    //(applicationData && applicationData[id] && applicationData[id].edited) ||
                } else if ((subapplication === SubAppCode.STATIONDELAVAGE || subapplication === SubAppCode.CAMPING || subapplication === SubAppCode.PROCESSUSINDUSTRIEL)) { //NOSONAR
                    calculateEtap12(data); //NOSONAR
                }

                if (_calculatedData.Pboucle === "" && zone3ApiData) {
                    resetZone4();
                }
            }
        }
    }, [systemEngine.calculatedData]);// eslint-disable-line react-hooks/exhaustive-deps

    const applicationChange = (e) => {
        setData(null);
        setCalculatedApiData(null);
        setIsOpen(false);
        setZone3ApiData(null);
        setZone4Data(null);
        setSystemData(null);
        setProductionMode(null);
        setSystem(null)
        setExpertModeChanged(false);
        setEtudeDataModel(null);
        systemEngine.setCalculatedData(null);
        setSolutions(null);
        setMainSolution(null);
        setDefaultSolution(null);
        setActiveRowIndex(null);
        let uuIDv1 = uuidv4();
        setEtudeAllData(null);
        dispatch(setApplicationData({ ...applicationData, [uuIDv1]: { edited: false } }));
        history.push(Project.getPageUrl(AppPages.Applications, [application, subApplicationsList.filter(item => item.id === e)[0].code, uuIDv1]));
    };

    const showNotification = (text, key) => {
        notification.info({
            message: `Notification`,
            description: <div>{text}</div>, //<T args={[t(label), min, max]}>{`text.${[allData[name]["errorMessage"]]}`}</T>,
            placement: 'top',
            duration: 0,
            key: key
        });
    }

    const calculate = (_data) => { //NOSONAR
        setAllowMessages(true);

        const _requestData = requestData(_data, application, subapplication, null, expertModeType);
        if (isValid) {
            sendRequest(_requestData.normalizedData, _requestData.url, (response) => {
                if (!response) {
                    showNotification("error")
                }
                else if (response.Err) {
                    showNotification(<T>{`text.ERR_MOTEUR_${response.Err[0][0]}`}</T>);
                    resetZone2();
                } else {
                    setCalculatedApiData(response);
                    setEtudeAllData(item => ({ ...item, calculatedApiData: response }));
                    if (+zone === 1/* && (applicationData && applicationData[id] && applicationData[id].edited)*/) {
                        history.push(Project.getPageUrl(AppPages.Applications, [application, subapplication, id || uuid, 2]), etudeAllData);
                    }
                    //else if (+zone === 2) {
                    //    history.replace(Project.getPageUrl(AppPages.Applications, [application, subapplication, id || uuid, 2]), etudeAllData);
                    //}

                }
            });
        }
    }

    const calculateEtap12 = (_data) => { //NOSONAR
        const _requestData = requestData(_data, application, subapplication, 2, expertModeType);
        let _network = networks.find(n => n.id === network);
        /*
         * 
         setActiveRowIndex(null);
         setZone4Data(null);
        */

        if (systemEngine.isValid && isValid && system) {
            sendRequest({ ..._requestData.normalizedData, ...systemData, mode: productionMode, systeme: system, reseau: _network.code, marque: _network.theme }, _requestData.url, (response) => {
                if (!response) {
                    showNotification("error");
                    setZone3ApiData(null);
                    setMainSolution(null);
                    setDefaultSolution(null);
                    setSolutions(null);
                }
                else if (response.Err) {
                    showNotification(<T>{`text.ERR_MOTEUR_${response.Err[0][0]}`}</T>);
                    setZone3ApiData(null);
                    setMainSolution(null);
                    setDefaultSolution(null);
                    setSolutions(null);
                } else {
                    let _zone3Data = { ...response, Pn: Math.round(response.Pn) }
                    setZone3ApiData(_zone3Data);
                    setEtudeAllData(item => ({ ...item, zone3ApiData: _zone3Data }));

                    if (zone4Data) {
                        let _zone4Data = { ...zone4Data };
                        let index = null;
                        // eslint-disable-next-line default-case
                        switch (system) {
                            case SystemCode.ECHANGEUR_A_PLAQUES:
                                if (zone4Data.key !== response.Pn) {
                                    _zone4Data.key = response.Pn;
                                    _zone4Data.name = response.Pn;
                                    setZone4Data({ ..._zone4Data, opened: true });
                                    setActiveRowIndex(0)
                                }
                                break;
                            case SystemCode.BALLONS_ELECTRIQUES:
                                // eslint-disable-next-line eqeqeq
                                if (Math.round(zone4Data.V) != Math.round(response.V) || zone4Data.P != response.P) {
                                    _zone4Data.P = response.P;
                                    _zone4Data.V = Math.round(response.V);
                                    setZone4Data({ ..._zone4Data, opened: true });
                                    setActiveRowIndex(0)
                                }
                                break;
                            case SystemCode.ECHANGEUR_A_PLAQUES_BALLONS_PRIMAIRES:
                                _zone4Data = { ..._zone4Data, ...(response.PV.length === 1 ? response.PV[0] : response.PV.filter(item => item.V === zone4Data.V)[0]) };
                                if (_zone4Data) {
                                    index = response.PV.length === 1 ? 0 : response.PV.findIndex(item => item.V === zone4Data.V);

                                    if (index === -1) {
                                        setActiveRowIndex(null)
                                        setZone4Data(null);
                                    } else {

                                        if (_zone4Data.P !== zone4Data.P || _zone4Data.Qe !== zone4Data.Qe) {
                                            _zone4Data.key = Math.round(_zone4Data.V);
                                            _zone4Data.V = Math.round(_zone4Data.V);
                                        }
                                        setActiveRowIndex(index)
                                        setZone4Data({ ..._zone4Data, opened: true });
                                    }
                                } else {
                                    setActiveRowIndex(null)
                                    setZone4Data(null);
                                }
                                break;
                            case SystemCode.ECHANGEUR_BALLONS_ECS:
                            case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS:
                            case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS_HMWM:
                            case SystemCode.BALLONS_ECS_TANK_IN_TANK:
                            case SystemCode.BALLONS_ECS_PREPARATEURS:
                                _zone4Data = { ..._zone4Data, ...(response.Vs.length === 1 ? response.Vs[0] : response.Vs.filter(item => item.V === zone4Data.V)[0]) };
                                if (_zone4Data) {
                                    index = response.Vs.length === 1 ? 0 : response.Vs.findIndex(item => item.V === zone4Data.V);
                                    if (_zone4Data.P !== zone4Data.P) {
                                        _zone4Data.key = Math.round(_zone4Data.V);
                                        _zone4Data.V = Math.round(_zone4Data.V);
                                    }
                                    setZone4Data({ ..._zone4Data, opened: true });
                                    setActiveRowIndex(index)
                                } else {
                                    setActiveRowIndex(null)
                                    setZone4Data(null);
                                }
                                break;
                        }
                        setEtudeAllData(item => ({ ...item, zone4Data: _zone4Data }));
                    }
                }
            });
        }
    }

    const getLargeurMiniValue = (products) => {
        let values = [];
        for (const element of products) {
            switch (element.type) {
                case "stockecs":
                case "stockprep":
                case "stockprim":
                case "stocktint":
                    values.push(element.stockage_largeur_passage);
                    break;
                case "accgaz":
                    values.push(element.accgaz_diametre_hors_tout);
                    break;
                case "hmwm":
                    values.push(element.WMHM_largeur_passage);
                    break;
                case "exchange":
                    values.push(element.exchange_width);
                    break;
                case "rsb":
                    values.push(element.RSB_width);
                    break;

                default:
                    values.push(0);
            }
        }
        return Math.max(...values);
    }

    const getHauteurMiniValue = (products) => {
        let values = [];
        for (const element of products) {
            switch (element.type) {
                case "stockecs":
                case "stockprep":
                case "stockprim":
                case "stocktint":
                    values.push(element.stockage_hauteur_mini_local);
                    break
                case "accgaz":
                    values.push(element.accgaz_hauteur_mini_local);
                    break;
                case "hmwm":
                    values.push(element.WMHM_hauteur_mini_local);
                    break
                case "exchange":
                    values.push(element.exchange_height);
                    break;
                case "rsb":
                    values.push(element.RSB_height);
                    break;
                default:
                    values.push(0);
            }
        }
        return Math.max(...values);
    }

    const solutionsRequest = () => { //NOSONAR
        /*
         * 
        if (etudeAllData && applicationData && applicationData[id] && !applicationData[id].edited) {
            return
        }
        */
        const _requestData = getSolutionRequestData(system, systemData, zone3ApiData, data, zone4Data)
        dispatch(setNumberChangeInProcess(true));
        let notificationKey = "solutionrequest";
        notification.close(notificationKey)
        setNoSolutionMessage(null);

        if (loadingController)
            loadingController.abort();

        let controller = sendRequest(_requestData.requestData, _requestData.url, (res) => {
            if (res && res.solutions && res.solutions.length) {
                showLoader();
                let skuArr = res.solutions.map(sku => sku.refComs);
                let getProductData = { skus: JSON.stringify({ skus: getUniqueProducts(skuArr) }) };
                // eslint-disable-next-line default-case
                switch (system) {
                    case SystemCode.ECHANGEUR_A_PLAQUES:
                    case SystemCode.ECHANGEUR_A_PLAQUES_BALLONS_PRIMAIRES:
                    case SystemCode.ECHANGEUR_BALLONS_ECS:
                    case SystemCode.BALLONS_ECS_PREPARATEURS:
                    case SystemCode.BALLONS_ECS_TANK_IN_TANK:
                        getProductData.system = system;
                        getProductData.TenSec = data.TEFS;
                        getProductData.TenPrim = systemData && systemData.Tp;
                        getProductData.TSorSec = systemData && systemData.TECS;
                        break;
                    case SystemCode.BALLONS_ELECTRIQUES:
                        getProductData.system = system;
                        getProductData.TenSec = data.TEFS;
                        //getProductData.TenPrim = systemData && systemData.tr
                        getProductData.TSorSec = systemData && systemData.TECS;
                        break;
                    case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS:
                    case SystemCode.ACCUMULATEURS_GAZ_BALLONS_ECS_HMWM:
                        // eslint-disable-next-line no-lone-blocks
                        {
                            getProductData.system = system;
                            getProductData.TenSec = data.TEFS;
                            getProductData.TSorSec = systemData && systemData.TECS;
                        }
                        break;
                }
                getProductData.productionMode = productionMode;

                Ajax.post({
                    url: ApiUrl.GetProducts,
                    data: getProductData,
                    success: function (allProducts) {
                        dispatch(setNumberChangeInProcess(false));
                        hideLoader();
                        if (res.solutions.length && allProducts.products && allProducts.products.length) {
                            let _solutions = setSolutionProducts(allProducts, res);
                            if (_solutions && _solutions.length > 0) {
                                if (!_solutions[0].Pn) {
                                    delete _solutions[0].Pn;
                                }

                                setMainSolution(_solutions[0]);
                                setDefaultSolution(_solutions[0]);
                                setSolutions(_solutions);

                                setEtudeAllData(item => ({ ...item, solutions: _solutions, defaultSolution: _solutions[0], mainSolution: _solutions[0] }));
                            }
                        }
                    }
                })

            } else {
                setSolutions(null);
                setMainSolution(null);
                setDefaultSolution(null);

                setEtudeAllData(item => ({ ...item, solutions: null, defaultSolution: null, mainSolution: null }));

                if (res) {
                    const errMessageText = `text.ERR_MOTEUR_${res.Err[0][0]}`;
                    if (res.Err[0][0] >= 1000) {
                        setNoSolutionMessage('text.FO_NOSOLUTION');
                    } else {
                        showNotification(<T>{errMessageText}</T>, notificationKey);
                    }
				}
            }
        })

        setLoadingController(controller);
    }

    const resetZone2 = () => {
        resetZone4();
        setCalculatedApiData(null);
        /*setIsOpen(false);*/ //NOSONAR
        setZone3ApiData(null);
        setZone4Data(null);
        setSystem(null);
        setProductionMode(null);
        setSolutions(null);
        dispatch(setApplicationData({ ...applicationData, [id]: { ...applicationData[id], productionMode: null, calculatedApiData: null, zone3ApiData: null, zone4Data: null, system: null, solutions: null } }));
    }

    const resetZone4 = () => {
        setZone3ApiData(null);
        setZone4Data(null);
    }

    const openDocumentDetails = () => {
        let isNew = id && (typeof (id) === "string" && (id.length === 36 || id === "create"));
        let _id = !isNew ? id : 0;

        Ajax.post({
            url: ApiUrl.EtudeUpdateJson,
            data: { id: _id, jsonData: JSON.stringify(etudeAllData) },
            success: function (response) {
                setEtudeDataModel(response);
                dispatch(setApplicationData({ ...applicationData, [response.ID]: { ...applicationData[_id], etudeDataModel: { ...applicationData[_id]?.etudeDataModel, projectId: response.projectId, version: response.version, numero: response.numero, ID: response.ID, jsonData: response.jsonData } } }));
                history.push(Project.getPageUrl(AppPages.Applications, [application, subapplication, response.ID, "document"]));
                setIsDocumentOpen(true);

                trackInitiatedStudies(response.ID);
            }
        })

    }

    useEffect(() => {
        if (zone !== "document") {
            setIsDocumentOpen(false);
        }
        //if (applicationData && applicationData[id]) {
        //    setEtudeDataModel({ ...etudeDataModel, zone: zone });
        //}
    }, [zone])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let _breadcrumbItems = [];
        const applicationName = subApplicationsList[0].appLabel;
        if (isDocumentOpen) {
            _breadcrumbItems = [
                { name: 'Home', url: Project.getPageUrl(AppPages.Home), label: <T>text.FO_ARIANE_HOME</T> },
                { name: 'Etude', url: Project.getPageUrl(AppPages.Applications), label: <T>text.FO_ARIANE_STUDY</T> },
                { name: applicationName, url: Project.getPageUrl(AppPages.Applications, [application, subapplication, id, 4]), label: <T>{`text.${applicationName}`}</T> },
                { name: 'Document', label: <T>text.FO_ARIANE_DOCUMENT</T> }
            ]
        } else {
            _breadcrumbItems = [
                { name: 'Home', url: Project.getPageUrl(AppPages.Home), label: <T>text.FO_ARIANE_HOME</T> },
                { name: 'Etude', url: Project.getPageUrl(AppPages.Applications), label: <T>text.FO_ARIANE_STUDY</T> }

            ];
            if (applicationName) {
                _breadcrumbItems.push({ name: applicationName, url: Project.getPageUrl(AppPages.Applications, [application, subapplication, id, zone]), label: <T>{`text.${applicationName}`}</T> })
            }

        }

        setBreadcrumbItems(_breadcrumbItems);
    }, [isDocumentOpen]);// eslint-disable-line react-hooks/exhaustive-deps



    return (
        <>{subApplicationsList &&
            <view horizontal="" space="" transparent="">
                <toolbar transparent="">
                    <Breadcrumb separator=">" style={sectionHeaderStyle}>
                        {breadcrumbItems && breadcrumbItems.map(item => <Breadcrumb.Item
                            key={item.name}>
                            {breadcrumbItems[breadcrumbItems.length - 1].name !== item.name ? <Link to={item.url}>{item.label}</Link> : item.label}
                        </Breadcrumb.Item>)
                        }
                    </Breadcrumb>
                </toolbar>

                {!isDocumentOpen ? <>
                    <view vertical="" transparent="" auto_height="">
                        {zone !== 'document' && <Zone1 popupProps={popupProps}
                            subApplicationsList={subApplicationsList}
                            application={application}
                            subapplication={subapplication}
                            applicationChange={applicationChange} //NOSONAR
                            data={data}
                            setData={setData}
                            defaultData={defaultData}
                            calculatedApiData={calculatedApiData}
                            setCalculatedApiData={setCalculatedApiData}
                            calculate={calculate} //NOSONAR
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            allData={allData}
                            expertModeChanged={expertModeChanged}
                            onError={resetZone2} //NOSONAR
                            zone={zone}
                            sectionHeaderStyle={sectionHeaderStyle}
                            allowMessages={allowMessages}
                        />}
                        <view filter="" transparent="">
                            {subapplication && calculatedApiData &&
                                +zone >= 2 &&
                                <Zone2
                                    setSystem={setSystem}
                                    setProductionMode={setProductionMode}
                                    setZone3ApiData={setZone3ApiData}
                                    setSystemData={setSystemData}
                                    systemData={systemData}
                                    setZone4Data={setZone4Data}
                                    setSolutions={setSolutions}
                                    subapplication={subapplication}
                                    history={history}
                                    application={application}
                                    productionMode={productionMode}
                                    productionModes={productionModes}
                                    system={system}
                                    id={id}
                                    zone={zone}
                                    zone4Data={zone4Data}
                                    sectionHeaderStyle={sectionHeaderStyle}
                                    setData={setData}
                                    setMainSolution={setMainSolution}
                                    setDefaultSolution={setDefaultSolution}
                                    setActiveRowIndex={setActiveRowIndex}
                                    setEtudeAllData={setEtudeAllData}
                                    setCalculatedApiData={setCalculatedApiData}
                                />
                            }

                        </view>
                    </view>
                    <view vertical="" transparent="">
                    {system && 
                            +zone >= 3 &&
                            (
                                <Zone3
                                    system={system}
                                    mode={productionMode}
                                    engine={systemEngine}
                                    setData={setSystemData}
                                    data={data}
                                    calculate={calculateEtap12} //NOSONAR
                                    zone3ApiData={zone3ApiData}
                                    setZone4Data={setZone4Data}
                                    zone4Data={zone4Data}
                                    solutionsRequest={solutionsRequest} //NOSONAR
                                    setZone3ApiData={setZone3ApiData}
                                    history={history}
                                    application={application}
                                    subapplication={subapplication}
                                    onError={resetZone4} //NOSONAR
                                    solutions={solutions}
                                    id={id}
                                    zone={zone}
                                    sectionHeaderStyle={sectionHeaderStyle}
                                    setLastInputRef={setLastInputRef}
                                    setSystem={setSystem}
                                    activeRowIndex={activeRowIndex}
                                    setActiveRowIndex={setActiveRowIndex}
                                    setSendSolutionsRequest={setSendSolutionsRequest}
                                    setEtudeAllData={setEtudeAllData}
                                />
                            )}

                        <view horizontal="" transparent="" overflow="hidden">
                            {zone4Data &&
                                +zone >= 4 &&
                                system ? <Zone4
                                zone4Data={zone4Data}
                                setZone4Data={setZone4Data}
                                system={system}
                                setSystem={setSystem}
                                productionMode={productionMode}
                                solutions={solutions}
                                mainSolution={mainSolution}
                                history={history}
                                application={application}
                                subapplication={subapplication}
                                dataItem={data}
                                engine={systemEngine}
                                setMainSolution={setMainSolution}
                                setSolutions={setSolutions}
                                defaultSolution={defaultSolution}
                                setData={setData}
                                solutionDetailWindowKey={solutionDetailWindowKey}
                                solutionsWindowKey={solutionsWindowKey}
                                setIsAlternativeSolution={setIsAlternativeSolution}
                                isAlternativeSolution={isAlternativeSolution}
                                sectionHeaderStyle={sectionHeaderStyle}
                                calculate={calculateEtap12} //NOSONAR
                                solutionsRequest={solutionsRequest} //NOSONAR
                                id={id}
                                zone={zone}
                                setEtudeAllData={setEtudeAllData}
                                noSolutionMesssage={noSolutionMessage}
                                setActiveRowIndex={setActiveRowIndex}
                                setIsDocumentOpen={setIsDocumentOpen}
                            /> : zone3ApiData && !activeRowIndex &&
                            <div className="section_group">
                                <div className="sections auto">
                                    <div className="form_fields ">
                                        <div className="data_help">
                                            <text data_length="xxx-large"><T>text.FO_ETU_ZONE3_HELP</T></text>
                                        </div>
                                    </div>

                                </div>
                            </div>}
                            {/*<view vertical="" transparent="">*/}
                            {/*    <view scroll_wrapper=""></view>*/}
                            {/*</view>*/}
                            {/*<toolbar></toolbar>*/}
                        </view>

                    </view>
                    {defaultData && mainSolution && +zone >= 4 && system && zone4Data &&
                        <FooterToolbar toolbarKey="toolbarEtude" >
                            <toolbar transparent="" >
                                <wrap limited="">
                                    <action right="">
                                        <div disabled={numberInProcess} className="button primary large" onClick={() => { openDocumentDetails() }}>
                                            <icon final="" middle_s=""></icon>
                                            <text data-size="large" wrap=""><T>text.FO_BUTTON_DOCUMENT</T></text>
                                        </div>
                                    </action>
                                </wrap>
                            </toolbar>
                        </FooterToolbar>
                    }
                </> : <DocumentDetails
                    allData={etudeAllData}
                    system={system}
                    solutions={solutions}
                    mainSolution={mainSolution}
                    application={application}
                    subapplication={subapplication}
                    setIsDocumentOpen={setIsDocumentOpen}
                    setEtudeDataModel={setEtudeDataModel}
                    etudeDataModel={etudeDataModel}
                    setRefreshData={setRefreshData} //NOSONAR
                    setExistingAppData={setExistingAppData} //NOSONAR
                    subApplicationsList={subApplicationsList}
                    userToken={props.userToken}
                    setBreadcrumbItems={setBreadcrumbItems}
                    isDocumentOpen={isDocumentOpen}
                    setEtudeAllData={setEtudeAllData}
                />
                }

            </view>}
        </>)

}

export default connect(state => ({
    networks: state.networks,
    network: state.network,
    applicationData: state.applicationData,
    userToken: state.userToken,
    numberInProcess: state.numberInProcess
}))(Applcation);
