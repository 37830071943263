import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';

/**
 * Picture wrapper
 * @param {any} props contains: url
 * @exports Picture
 */
export function Picture(props) {
    const { url, middle } = props;
    const [size, setSize] = useState(null);
    const [backgroundSize, setBackgroundSize] = useState('cover');

    useEffect(() => { //NOSONAR
        const defHeight = 160;
        if (url) {
            const img = new Image();
            img.onload = function (e) {
                
                let h = e.target.height;
                let w = e.target.width;
                if (h > 5000 || (h <= 240 && w <= 320)) {
                    setBackgroundSize('contain')
                }
                if (h > w) {
                    let c = h / defHeight;
                    h = defHeight;
                    w = (w / c);
                } else {
                    h = defHeight;
                    w = defHeight;
                }
                setSize({
                    width: Math.ceil(w),
                    height: Math.ceil(h)
                });

                
            }
            img.onerror = function (e) {
                setSize({
                    width: 100,
                    height: defHeight
                });
            }

            img.src = url.replace("'", "").replace("'", "");
        }

    }, [url]);// eslint-disable-line react-hooks/exhaustive-deps


    if (size) {
        return <picture {...(middle ? { middle: "" } : {})} style={{ backgroundImage: `url(${url})`, width: size.width, height: size.height, backgroundSize: backgroundSize }}></picture>
    }


    return <picture {...(middle ? { middle: "" } : {})}>
        <div preloader="">
            <Spin size="large" />
        </div >
        </picture>
;
}