import React, { useCallback } from "react";
import { connect } from "react-redux";
import i18n from "../Shared/Translation/i18n.js";
import { ApiUrl, AppPages } from '../project/Defines.js';
import { Project } from '~/project/Project';
import { T } from '../components/Translations';
import CustomUpload from '../Shared/CustomUpload';
import useDetail from '../Shared/useDetail';
import InputCont from '../Shared/Form/InputCont';
import { FormField } from '../Shared/Form/formFields.js';
import { changeEmail } from '../store/actions/index.js';

function MyAccount(props) {
    const { dataItem, setDataItem, updateData, hasChanges, onChangeData, validation, detailRef } = useDetail({
        data: { ID: 0 },
        updateUrl: ApiUrl.SocieteUserUpdate,
        dataLoadUrl: ApiUrl.SocieteUserLoad,
        detailUrl: Project.getPageUrl(AppPages.MyAccount),
        // changingValidation: true,
        validationFields: ['firstName', 'lastName', {
            name: "contact", rules: [
                { type: "email" }
            ]
        }, {
            name: "telephone", rules: [
                { type: "phone" }
            ]
            }]
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            props.dispatch(changeEmail({ email: response.contact }))
        }
    }, [detailRef, props]);

    return <>
        <toolbar>
            <div className="caption">
                <text><b>Espace personnel</b></text>
            </div>
        </toolbar>
        {dataItem && 
            <>
            <view vertical="" form="" transparent="">
                <view transparent="">
                    <div className="section_group compact">
                        <div className="sections">
                            <InputCont label="text.FO_COMP_NAME"
                                inputProps={{ validation, name: 'lastName', value: dataItem.lastName, onChange: onChangeData, maxLength: "200" }} />
                            <InputCont label="text.FO_COMP_FIRSTNAME"
                                inputProps={{ validation, name: 'firstName', value: dataItem.firstName, onChange: onChangeData, maxLength: "200" }} />
                            <InputCont label="text.FO_COMP_COMPANY"
                                inputProps={{ validation, name: 'societe', value: dataItem.societe, onChange: onChangeData, maxLength: "200" }} />
                            <InputCont label="text.FO_COMP_EMAIL"
                                inputProps={{ validation, name: 'email', value: dataItem.email, onChange: onChangeData, disabled: true, maxLength: "200" }} />
                            <InputCont label="text.FO_COMP_EMAIL_BIS"
                                inputProps={{ validation, name: 'contact', value: dataItem.contact, onChange: onChangeData, maxLength: "200"}} />
                        </div>
                    
                        <div className="sections">
                            <InputCont label="text.FO_COMP_PHONENUMBER"
                                inputProps={{ validation, name: 'telephone', value: dataItem.telephone, onChange: onChangeData, maxLength: "15" }} />
                            <InputCont label="text.FO_COMP_ADRESS"
                                inputProps={{ validation, name: 'address_postale', value: dataItem.address_postale, onChange: onChangeData, maxLength: "200" }} />
                            <InputCont label="text.FO_COMP_CP"
                                inputProps={{ validation, name: 'code_postal', value: dataItem.code_postal, onChange: onChangeData, maxLength: "50" }} />
                            <InputCont label="text.FO_COMP_CITY"
                                inputProps={{ validation, name: 'ville', value: dataItem.ville, onChange: onChangeData, maxLength: "200" }} />
                    
                        </div>
                        <div className="sections wide">
                            <InputCont
                                inputProps={{ validation, type: 'textarea', value: i18n('text.FO_COMP_PERSONAL_INFO'), disabled: true }} />
                        </div>
                        
                    </div>
                </view>
                <view transparent="">
                    <div className="section_group compact">
                        <div className="sections">
                            <FormField label="text.FO_COMP_LOGO">
                                <CustomUpload
                                    className='avatar-uploader big'
                                    imageUrl='image_url'
                                    imageField='images'
                                    dataItem={dataItem}
                                    setDataItem={setDataItem}
                                    type={'user'}
                                />
                            </FormField>
                        </div>
                    </div>
                </view>
            </view>
            <toolbar>
                <wrap>
                    <action right=''>
                        <div onClick={(e) => updateData(updateSuccessCallback)} className={hasChanges ? ' button primary' : 'button'}><icon>save</icon><T>text.save</T></div>
                    </action>
                </wrap>
            </toolbar>
        </>
        }
    </>
   
}

export default connect(state => ({
    isLoggedIn: state.isLoggedIn,
    popups: state.popups,
    culture: state.culture,
    role: state.role,
    userToken: state.userToken
}))(MyAccount);

