import React, { useCallback, useEffect } from 'react';

import { message } from 'antd';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Ajax } from '../components/Ajax';
import { T } from '../components/Translations';
import { PopupTypes } from '../components/Popup';

import { hideLoader, showLoader } from '../Shared/Loader';

import store from './../store';
import { openPopup } from '../store/actions';

import { Project } from './Project';
import moment from 'moment';
import { ApiUrl, AppPages /*, Defines*/ } from './Defines';

/**
 *the list of custom events occuring for specific actions
 */
export const EVENT_NAME = {
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    SERVICE_CALL_LIST_UPDATE: "SERVICE_CALL_LIST_UPDATE",
    PLANNING_BLOCK_LIST_UPDATE: "PLANNING_BLOCK_LIST_UPDATE",
    APPOINMENT_SCHEDULE_REPAINT: "APPOINMENT_SCHEDULE_REPAINT",
    SERVICE_CALL_UPDATED: "SERVICE_CALL_UPDATED",
    FEE_LIST_UPDATE: "FEE_LIST_UPDATE",
    SPARE_PART_LIST_UPDATE: "SPARE_PART_LIST_UPDATE",
    DPC_LIST_UPDATE: "DPC_LIST_UPDATE",
    ATTACHMENTS_LIST_UPDATE: "ATTACHMENTS_LIST_UPDATE",
    ETUDE_LIST_UPDATE: "ETUDE_LIST_UPDATE"
}

/**
 *the method for custom event creation
 * @param {string} eventName
 * @param {Function} callback
 */
export function useCustomEvent(eventName, callback) {
    useEffect(
        () => {
            if (eventName && callback) {
                let events = eventName.split(" ");
                for (const element of events) {
                    document.addEventListener(element, handler);
                }
                return () => {
                    for (const element of events) {
                        document.removeEventListener(element, handler);
                    }
                }

                function handler(e) {
                    callback(e);
                }
            }
        },
        [eventName, callback]
    );
}

/**
 *the method to trigger custom event
 * @param {string} eventName
 * @param {any} data
 */
export function dispatchCustomEvent(eventName, data) {
    let events = eventName && eventName.split(" ");
    if (events) {
        for (const element of events) {
            const event = new CustomEvent(element, { bubbles: true, detail: data });
            document.dispatchEvent(event);
        }
    }
}

/**
 *custom hook to ensure(for front end) the login state on specific pages
 * @param {any} props
 */
export function useLoginCheck(props) {
    const historyHook = useHistory();
    useEffect(() => {
        if (!props.skipLogin && !store.getState().isLoggedIn) {
            let history = props.history || historyHook;
            history.push(Project.getPageUrl(AppPages.Login) + "?redirectUrl=" + window.location.pathname);
        }
    }, [props.history, props.skipLogin, historyHook]);
}

const deleteLoaderName = 'DELETE_LOADER';

/**
 *custom hook creating specific delete method for each list and form given props
 * @param {any} props { nameField, deleteUrl, backPage, deleteKeys, listUpdateEvent, endDelete, deleteMessage, textYes, textNo }
 */
export function useDelete(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { nameField, deleteUrl, backPage, deleteKeys, listUpdateEvent, startDelete, endDelete, deleteMessage, popupTitle, textYes, textNo, post } = props;
    const onDelete = useCallback((record, callback) => { //NOSONAR
        dispatch(openPopup({
            windowKey: 'wndConfirmDelete',
            type: PopupTypes.Confirm,
            title: <T args={[nameField ? record[nameField] : record.nom]}>{popupTitle || 'message.delete_title'}</T>,//`Delete ‘${record.nom}‘`,
            text: <T>{deleteMessage || "message.delete_confirm"}</T>, //'This action cannot be undone. Are you sure you want to continue?',
            buttonYesText: <T>{textYes || "text.delete"}</T>,//Defines.Messages.confirmDelete,
            buttonNoText: <T>{textNo || "text.cancel"}</T>,//Defines.Messages.cancelDelete,
            yesCallback: function () {
                showLoader(deleteLoaderName);
                startDelete && startDelete();
                if (deleteKeys) {
                    let data = [];
                    deleteKeys.map((key) => {
                        return data[key] = record[key] //NOSONAR
                    })
                    Ajax.post({
                        url: deleteUrl,
                        data: {
                            data
                        },
                        success: function (response) {
                            if (response) {
                                dispatchCustomEvent(listUpdateEvent, { action: "delete" });
                                (callback && callback(response)) || (backPage && history.push(Project.getPageUrl(backPage)));
                            }

                            endDelete && endDelete();
                            hideLoader(deleteLoaderName);
                        },
                        error: function (response) {
                            if (response.message) {
                                message.error(response.message);
                            }
                            hideLoader(deleteLoaderName);
                        }
                    })
                } else {
                    Ajax[post ? "post" : "get"]({
                        url: deleteUrl,
                        data: record,
                        success: function (response) {
                            if (response) {
                                dispatchCustomEvent(listUpdateEvent, { action: "delete" });
                                (callback && callback(response)) || (backPage && history.push(Project.getPageUrl(backPage)));
                            }
                            endDelete && endDelete();
                            hideLoader(deleteLoaderName);
                        },
                        error: function (response) {
                            if (response.message) {
                                message.error(response.message);
                            }
                            hideLoader(deleteLoaderName);
                        }
                    })
                }

            }
        }));
    }, [deleteUrl, nameField, listUpdateEvent, backPage, endDelete, startDelete, deleteKeys, deleteMessage, textNo, textYes, popupTitle,post, history, dispatch]);

    return onDelete;
}

export function cloneDataItem(dataItem) {
    return Object.assign({}, dataItem);
}

export function deepCopy(inObject) {
    let outObject, value, key

    if (typeof inObject !== "object" || inObject === null) {
        return inObject;
    }

    outObject = Array.isArray(inObject) ? [] : {}
    for (key in inObject) {
        value = inObject[key]
        outObject[key] = deepCopy(value)
    }

    return outObject
}

export function isSameObject(obj1, obj2) { //NOSONAR
    for (let p in obj1) {
        //Check property exists on both objects
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

        if (obj1[p] === null && obj2[p] === null) {
            continue
        } else {
            if ((obj1[p] === null && obj2[p] !== null) || (obj1[p] !== null && obj2[p] === null)) {
                return false;
            }
        }

        switch (typeof (obj1[p])) {
            //Deep compare objects
            case 'object':
                if (!isSameObject(obj1[p], obj2[p])) return false;
                break;
            //Compare function code
            case 'function':
                if (typeof (obj2[p]) === 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) return false;
                break;
            //Compare values
            default:
                if (obj1[p] !== obj2[p]) return false;
        }
    }

    //Check object 2 for any extra properties
    for (let p in obj2) {
        if (typeof (obj1[p]) === 'undefined') return false;
    }
    return true;
}

export function formatMinutes(minutes) {
    let hr = Math.floor(minutes / 60),
        min = Math.floor(minutes % 60);
    return moment(`${hr}:${min}`, "hhmm").format("HH:mm");
}

function trackStatistics(category, projectVersionID) {
    Ajax.post({
        url: ApiUrl.StatisticsTrack,
        data: { category, projectVersionID },
        success: function (response) {
        },
        error: function (response) {
            
        }
    })
}

export function trackInitiatedStudies(projectVersionID) {
    trackStatistics("initiated_studies", projectVersionID)
}

export function trackConnections() {
    trackStatistics("connections")
}