import { Badge, Spin } from 'antd';
import React, { Suspense, useEffect, useState, useCallback, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import CookieBot from 'react-cookiebot';
import Layout from './components/Layout';
import { menuItems } from './components/Navigation/Menu';
import Login from './pages/Login';
import LoginRedirect from './pages/LoginRedirect';
import Logout from './pages/Logout';
import Home from './pages/Home';
import Applications from './pages/Applications';
import Etude from './pages/Etude/index';
import NotFound from './pages/NotFound';
import { Template, Template2 } from './pages/Template';
import { ApiUrl, AppPages, StoreKeys, NetworksCodes } from './project/Defines';
import { Project } from './project/Project';
import { storageChange, changeNetwork, setNetworks, setCulture } from './store/actions';
import Applcation from './pages/Applications/Application';
import MyAccount from './pages/MyAccount';
import FAQ from './pages/FAQ';
import CGU from './pages/CGU';
import EtudeDetails from './pages/EtudeDocument/DocumentDetails';
import LogoutRedirect from './pages/LogoutRedirect';

const routings = [
    { page: AppPages.Login, component: Login },
    { page: AppPages.Logout, component: Logout },
    { page: AppPages.Template, component: Template },
    { page: AppPages.Applications, component: Applications, matchExact: true },
    { page: AppPages.Applications, component: Applcation, routeParams: [':application?', ':subapplication?', ':id?', ":zone?"] },
    //{ page: AppPages.SolutionDetails, component: SolutionDetails, routeParams: [':application', ':subapplication', ':details'] },
    { page: AppPages.Etude, component: Etude, routeParams: [':name?'] },
    // { page: AppPages.EtudePickup, component: EtudePickup, routeParams: [':application?', ':subapplication?', ":zone2?", ":zone3?", ":zone4?", ':id?'] },
    { page: AppPages.EtudeDetails, component: EtudeDetails, routeParams: [':application?', ':subapplication?', ":zone2?", ":zone3?", ":zone4?", ":etudedetails", ':id?'] },
    { page: AppPages.MyAccount, component: MyAccount },
    { page: AppPages.FAQ, component: FAQ },
    { page: AppPages.CGU, component: CGU },
    { page: AppPages.Home, component: Home }
];

const createThemeStyle = function (theme, network) {
    const favicon = document.querySelector('[rel=icon]');
    const manifest = document.querySelector('[rel=manifest]');
    let elem = document.getElementById("theme");
    if (elem) elem.remove();
    if (theme) {
        document.getElementsByTagName("head")[0].insertAdjacentHTML(
            "beforeend",
            "<link id=\"theme\" rel=\"stylesheet\" href=\"/content/theme/" + theme + "/theme.css\" />");

            const brand = theme.toLowerCase();
            import(`./brands/${brand}/favicon.ico`).then(
                (x) => {
                    favicon.href = x.default;
                }
            );
            import(
                `./brands/${brand}/manifest.json`
            ).then((x) => {
                const json = { ...x.default };
                Promise.all(
                    json.icons.map((i) =>
                        import(
                            `./brands/${brand}/${i.src}`
                        ).then((val) => {
                            i.src = val.default.startsWith('/')
                                ? `${window.location.origin}${val.default}`
                                : val.default;
                        })
                    )
                ).then(() => {
                    json.start_url = window.location.origin + `/${network}`;
                    const stringManifest = JSON.stringify(json);
                    const blob = new Blob([stringManifest], {
                        type: 'application/manifest+json',
                    });
                    const manifestURL = URL.createObjectURL(blob);
                    manifest.href = manifestURL;

                    document.title = json.name;
                });
            });
    }
}

const getDefaultNetwork = function() {
    if (window.location.host.indexOf('.atlantic-pros.fr') !== -1)
    {
        return NetworksCodes.ATLFR;
    }
    else if (window.location.host.indexOf('.atlantic-guillot.fr') !== -1)
    {
        return NetworksCodes.ATLFR;
    }
    else if (window.location.host.indexOf('.ygnis.it') !== -1)
    {
        return NetworksCodes.YGNIT;
    }
    else if (window.location.host.indexOf('.ygnis.be') !== -1)
    {
        return NetworksCodes.YGNBE;
    }
    return NetworksCodes.ACVFR;
}

/**
*  the Base component that also manages routing
 * @module App
 */
function AppRouteInner(props) { //NOSONAR
    const { role, isLoggedIn, currentNetwork, culture, cultureMode, changeNetwork, isMaintenanceModeEnabled, maintenanceModeStart, maintenanceModeEnd } = props;
    let { path, /*url,*/ params } = useRouteMatch();
    const loading = <div preloader=""><Spin size="large" /></div>;
    const homeRedirect = Project.getPageUrl(menuItems.find(i => Project.hasRoleAccess(role, i.to))?.to);
    const [showPages, setShowPages] = useState(false);
    const [isMaintainance, setIsMaintainance] = useState(false);
    // const [currentNetwork, setCurrentNetwork] = useState("");
    const appRef = useRef(false);
    const dispatch = useDispatch();
    // const navigate = useNavigate();


    const doLoad = useCallback(() => {
        let networks;
        const request = new XMLHttpRequest();
        request.open('POST', process.env.REACT_APP_API_URL + ApiUrl.GetSalesNetworks, false);  // `false` makes the request synchronous
        request.send({});

        if (request.status === 200) {

            networks = JSON.parse(request.responseText).data;

            let network;
            if (params && params.network) {
                let params_network = networks.find(item => item.code === params.network || item.code === params.network.toUpperCase());

                network = params_network;
            } else {
                let _network_code = /:\/\/([^\/]+)/.exec(window.location.href)[1].split('.')[0]; // eslint-disable-line no-useless-escape
                let _network = networks.find(item => item.code === _network_code || item.code === _network_code.toUpperCase());
                const location_default_network_code = getDefaultNetwork();
                let _default_network = networks.find(item => item.code === location_default_network_code);

                network = _network ? _network : _default_network;
            }

            // setCurrentNetwork(network);

            if (network) {
                window.network = network.code;
                changeNetwork(network.id);
                createThemeStyle(network.theme, network.code);
                let languages = network.languages;
                let networkLang = "";
                let existCurrentCulture = true;
                if (languages && languages.length) {
                    networkLang = (languages.find(l => l.isDefault) || languages[0]).code;
                    if (cultureMode === "M") {
                        existCurrentCulture = languages.find(l => l.code === culture);
                    }
                }
                if (!culture || !cultureMode || cultureMode !== "M" || !existCurrentCulture) {
                    dispatch(setCulture(networkLang || "en"));
                }
                dispatch(setNetworks(networks));
            }
            setShowPages(true);
        }
    }, [changeNetwork, culture, cultureMode, dispatch, params]);

    useEffect(() => {

        setTimeout(() => {
            doLoad();
        }, 10)

        appRef.current = true;

        const handler = e => {
            const trackingKeys = [StoreKeys.UserToken, StoreKeys.UserName, StoreKeys.LoggedIn, StoreKeys.ListStates, StoreKeys.Culture, StoreKeys.Role, StoreKeys.ExpireTime, StoreKeys.UserData]
            if (trackingKeys.includes(e.key)) {
                storageChange();
            }
        };

        if (isMaintenanceModeEnabled) {
            let now = new Date();
            if (maintenanceModeEnd >= now) {
      
              if (maintenanceModeStart <= now) {
                setIsMaintainance(true);
                setTimeout(() => { appRef.current && setIsMaintainance(false); }, maintenanceModeEnd - new Date());
              } else {
                setTimeout(() => {
                  appRef.current && setIsMaintainance(true);
                  setTimeout(() => { appRef.current && setIsMaintainance(false); }, maintenanceModeEnd - new Date());
                }, maintenanceModeStart - now);
              }
            }
          }

        window.addEventListener('storage', handler);
        return () => {
            appRef.current = false;
            window.removeEventListener('storage', handler);
         }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     props.history.push( {
    //         pathname: `/${currentNetwork?.code}/login`,
    //         state: { isMaintainance: isMaintainance }
    //     });
    // }, [isMaintainance]);

    if (!showPages)
        return <>{loading}</>;

    if (!window.network) {
        return <><div preloader="" style={{ background: 'none' }}>non-valid network name</div></>
    }

    // if (isMaintainance) {
    //     return <h1> Maintenance <Badge>blabla</Badge> </h1>;
    // }

    // console.log({currentNetwork});
    // props.history.push( {
    //     pathname: `/${currentNetwork.code}/login`,
    //     state: { isMaintainance: isMaintainance }
    // });

    if (!isLoggedIn) {
        return <Suspense fallback={loading}>
            {currentNetwork && currentNetwork.cookiebot && <CookieBot domainGroupId={currentNetwork.cookiebot} />}
            <Layout>
                <Switch>
                    <Route
                        path={`${path}/loginredirect/:code`}
                        component={LoginRedirect}
                    />
                    <Route
                        path={`${path}/login`}
                        component={Login}
                    />
                    <Route
                        path={`${path}/logout`}
                        component={Logout}
                    />
                    <Route path={`${path}/template`} component={Template} />
                    <Route path={`${path}/templatedetails`} component={Template2} />
                    <Route path={`${path}/*`} >
                        <Redirect to={Project.getPageUrl(AppPages.Login, "", { redirectUrl: window.location.pathname })} />
                    </Route>
                </Switch>
            </Layout>
        </Suspense>

    }

    return <Suspense fallback={loading}>
        {currentNetwork && currentNetwork.cookiebot && <CookieBot domainGroupId={currentNetwork.cookiebot} />}
        <Layout>
            <Switch>
                <Route path={`${path}/login`}>
                    {(homeRedirect && <Redirect to={homeRedirect} />) || (<Redirect to={Project.getPageUrl(AppPages.Login)} />)}
                </Route>
                <Route
                        path={`${path}/logoutredirect`}
                        component={LogoutRedirect}
                    />
                {routings.filter(r => r.pages ? r.pages.some(p => Project.hasRoleAccess(role, p)) : Project.hasRoleAccess(role, r.page))
                    .map(r =>
                        <Route key={r.pages ? r.pages.join() : r.page} exact={!!r.matchExact} path={(r.pages ? r.pages.map(p => `${path}` + Project.getPageUrl(p.page || p, p.routeParams, null, true)) : `${path}` + Project.getPageUrl(r.page, r.routeParams, null, true))} component={r.component} />)}

                <Route path="*" component={NotFound} />
            </Switch>
        </Layout>
    </Suspense>;
}


function App(props) {
    return <>
        <Switch>
            <Route path="/:network?" component={AppRoute}></Route>
            {/*<Route path="*">*/}
            {/*    <Redirect to={`/${redirectNetwork}` + Project.getPageUrl(AppPages.Login, null, null, true)} />*/}
            {/*</Route>*/}
        </Switch>
    </>
}




export default connect(state => ({ 
        isLoggedIn: state.isLoggedIn,
        role: state.role, 
        networks: state.networks, 
        network: state.network, 
        culture: state.culture, 
        cultureMode: state.cultureMode, 
        userToken: state.userToken, 
        currentNetwork: state.networks && state.networks.find(item => item.id === state.network),
        isMaintenanceModeEnabled: state.isMaintenanceModeEnabled,
        maintenanceModeStart: state.maintenanceModeStart,
        maintenanceModeEnd: state.maintenanceModeEnd
    }),
    dispatch => ({
        storageChange: (key) => dispatch(storageChange()),
        changeNetwork: (key) => dispatch(changeNetwork(key)),
        setNetworks: (items) => dispatch(setNetworks(items))
    }))(App);

const AppRoute = connect(state => ({ 
        isLoggedIn: state.isLoggedIn, 
        role: state.role, 
        networks: state.networks, 
        network: state.network, 
        culture: state.culture, 
        cultureMode: state.cultureMode, 
        userToken: state.userToken, 
        currentNetwork: state.networks && state.networks.find(item => item.id === state.network),
        isMaintenanceModeEnabled: state.isMaintenanceModeEnabled,
        maintenanceModeStart: state.maintenanceModeStart,
        maintenanceModeEnd: state.maintenanceModeEnd
    }),
    dispatch => ({
        storageChange: (key) => dispatch(storageChange()),
        changeNetwork: (key) => dispatch(changeNetwork(key)),
        setNetworks: (items) => dispatch(setNetworks(items))
    }))(AppRouteInner);

