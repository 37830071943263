import React, { useCallback, useEffect } from 'react';
import { Ajax } from '../components/Ajax';
import { ApiUrl } from '../project/Defines.js';
import useDetail from '../Shared/useDetail';
import { message } from 'antd';
import { T } from '../components/Translations';
import { useHistory } from 'react-router-dom';


export default function EtudePickup(props) {
    const { windowKey, /*subapplication, system, solutions, mainSolution,*/ etudeAllData, id, etudeDataModel, setEtudeDataModel } = props;
    const history = useHistory();
    
    const { isNew, dataItem, setDataItem, updateData, originalDataItem, /*hasChanges, closePopup, onDetailDelete, validation */ detailRef   /*, isLoading, loadData*/ } = useDetail({
        data: { id: id === 'create' ? 0 : id},
        windowKey,
        updateUrl: ApiUrl.EtudeUpdate,
        validationFields: [ 'siteName', 'siteAdress', 'clientName']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
            let basePath = history.location.pathname;
            let newPath = basePath.replace('create', response.ID);
            history.push(newPath);
        }
    }, [setDataItem, detailRef]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeData = (e) => {
        setDataItem(item => ({ ...item, [e.target.name]: e.target.value }));
        setEtudeDataModel(item => ({ ...item, [e.target.name]: e.target.value }));
    }

    useEffect(() => {
        if (!etudeDataModel || id !== 'create') {
            let _id = id === 'create' ? 0 : id;

            Ajax.get({
                url: ApiUrl.EtudeGetData,
                data: { id: _id },
                success: function (response) {
                    if (response) {
                        const _response = { ...response };
                        _response.jsonData = JSON.stringify(etudeAllData);
                        setDataItem(_response);
                        setEtudeDataModel(_response)
                    } else {
                        message.error('API error');
                    }
                },
                error: function () {
                    message.error('API error');
                }
            })
        } else {
            setDataItem(etudeDataModel)
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    const resetData = () => {
        setDataItem(originalDataItem);
    }
        
    return (<>
        <view  space="" vertical="" form="" transparent="">
            <view space="" transparent="" middle="">
                <div className="form_container">
                    <div className="section_group">
                        <div className="sections extra">
                            <div className="form_fields">
                                <div className="data_label"><text data-size="x-large"><T>text.FO_DOC_ETUDE_DETAILS</T></text></div>
                            </div>
                            <div className="form_fields">
                                <div className="data_label title"><text data-size="large"><T>text.FO_DOC_PROJET_CHANTIER</T></text></div>
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_REFERENCE</T></b></text>
                                    <icon></icon>
                                    <input type="text" name="reference" value={dataItem?.reference} disabled={true} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_SITENAME</T></b>(*)</text>
                                    <icon pen=""></icon>
                                    <input required="" type="text" name="siteName" value={dataItem?.siteName} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_SITEADRESS</T></b>(*)</text>
                                    <icon pen=""></icon>
                                    <input required="" type="text" name="siteAdress" value={dataItem?.siteAdress} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_SITECP</T></b></text>
                                    <icon pen=""></icon>
                                    <input type="text" name="siteCP" value={dataItem?.siteCP} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_SITECITY</T></b></text>
                                    <icon pen=""></icon>
                                    <input type="text" name="siteCity" value={dataItem?.siteCity} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>

                            <div className="form_fields">
                                <div className="data_label title"><text data-size="large"><T>text.FO_DOC_CLIENT</T></text></div>
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_CLIENTNAME</T></b>(*)</text>
                                    <icon pen=""></icon>
                                    <input required="" type="text" name="clientName" value={dataItem?.clientName} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_CLIENTCONTACT</T></b></text>
                                    <icon pen=""></icon>
                                    <input required="" type="text" name="clientContact" value={dataItem?.clientContact} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_CLIENTADRESS</T></b></text>
                                    <icon pen=""></icon>
                                    <input required="" type="text" name="clientAdress" value={dataItem?.clientAdress} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_CLIENTCP</T></b></text>
                                    <icon pen=""></icon>
                                    <input required="" type="text" name="clientCP" value={dataItem?.clientCP} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_CLIENTCITY</T></b></text>
                                    <icon pen=""></icon>
                                    <input required="" type="text" name="clientCity" value={dataItem?.clientCity} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_CLIENTCMAIL</T></b></text>
                                    <icon pen=""></icon>
                                    <input required="" type="text" name="clientMail" value={dataItem?.clientMail} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_CLIENTPHONENUMBER</T></b></text>
                                    <icon pen=""></icon>
                                    <input required="" type="text" name="clientPhoneNumber" value={dataItem?.clientPhoneNumber} onChange={onChangeData} />
                                    <i></i>
                                </div>
                            </div>

                            <div className="form_fields">
                                <div className="data_label title"><text data-size="large"><T>text.FO_DOC_ETUDE</T></text></div>
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_ID</T></b></text>
                                    <icon></icon>
                                    <input type="text" name="ID" value={dataItem?.ID} disabled={true} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_VERSION</T></b></text>
                                    <icon></icon>
                                    <input type="text" name="version" value={dataItem?.version} disabled={true} />
                                    <i></i>
                                </div>
                            </div>
                            <div className="form_fields">
                                <div className="input_cont">
                                    <text label=""><b><T>text.FO_DOC_DATE</T></b></text>
                                    <icon></icon>
                                    <input type="text" name="date" value={isNew ? dataItem?.created_at : dataItem?.updated_at} disabled={true} />
                                    <i></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <toolbar transparent="">
                        <wrap>
                            <action>
                                <div 
                                    effect='material' 
                                    onClick={() => props.onClose()}
                                    className="button primary large">
                                    <icon>left</icon><text><T>text.FO_DOC_PREVIOUS</T></text>
                                </div>
                                <div 
                                    onClick={(e) => resetData()}
                                    className='button primary large'>
                                    <icon>undo</icon><text><T>text.FO_DOC_REINITIALISER</T></text>
                                </div>
                                <div 
                                    onClick={(e) => updateData(updateSuccessCallback)} 
                                    className='button primary large'>
                                    <icon>save</icon><text><T>text.FO_DOC_SAVE</T></text>
                                </div>
                            </action>
                        </wrap>
                    </toolbar>
                </div>
            </view>
            </view>
    </>);
}
